import { useRecoilValue } from 'recoil';
import { MouseEvent, useEffect, useState } from 'react';
import {
    Button, Grid, makeStyles, Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { CategoryResult } from 'src/components/Categories/CategoryResult';
import { InfiniteSearchResultsGrid } from 'src/components/common/Search/InfiniteSearchResultsGrid';
import { queryCategories, QUERY_KEY } from 'src/queries/queryCategories';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { CategoryDetailsDrawer } from 'src/components/Categories/CategoryDetailsDrawer';
import { SnackBarErrorBoundary } from 'src/components/common/SnackBarErrorBoundary';
import { useInfiniteScrollQuery } from 'src/hooks/useInfiniteScrollQuery';
import { EmptyState } from 'src/components/common/EmptyState';
import { LoadingGrid } from 'src/components/common/Loader/LoadingGrid';
import { NOT_APPLICABLE } from 'src/constants';

type Category = Models.ContentStoreApi.V3.Category;

interface PropTypes {
    search: App.Categories.Search;
}

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        paddingRight: theme.spacing(4),
    },
}));

export const CategoryResults = (props: PropTypes): JSX.Element => {
    const { search } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const [selectedId, setSelectedId] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const { data, fetchNextPage, hasNextPage } = useInfiniteScrollQuery<Category, Error, App.Categories.PagedQueryKey>(
        [QUERY_KEY, accessToken, search],
        queryCategories,
        {
            enabled: !!accessToken,
            retry: 2,
            refetchInterval: 30000,
        },
    );

    const closeDrawer = (): void => {
        setSelectedId('');
        setIsOpen(false);
    };

    const handleCloseDrawer = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        closeDrawer();
    };

    const handleShowDrawer = (id: string): void => {
        setSelectedId(id);
    };

    const handleNewCategory = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        if (accessToken) {
            setIsOpen(true);
        }
    };

    const loadMore = async (): Promise<void> => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    useEffect(() => {
        setIsOpen(!!selectedId);
    }, [selectedId]);

    return (
        <SnackBarErrorBoundary>
            <Grid
                container
                className={classes.actions}
                justifyContent="flex-end"
                spacing={4}
            >
                <Grid item>
                    <Button
                        color="primary"
                        startIcon={<Add />}
                        variant="contained"
                        onClick={handleNewCategory}
                    >
                        New Category
                    </Button>
                </Grid>
            </Grid>
            {!data && (<LoadingGrid />)}
            {(data && !data.length) && (
                <EmptyState>
                    Could not find categories for the given parameters
                </EmptyState>
            )}
            {(data && !!data.length) && (
                <InfiniteSearchResultsGrid
                    data={data}
                    hasMore={!!hasNextPage}
                    itemKey={(item: Models.ContentStoreApi.V3.Category): string => `item-${item.id}`}
                    loadMore={loadMore}
                    minWidth={200}
                    variant="cards"
                >
                    {(item: Models.ContentStoreApi.V3.Category): JSX.Element => (
                        <CategoryResult
                            id={item.id}
                            internalName={item.internalName || NOT_APPLICABLE}
                            onClick={handleShowDrawer}
                        />
                    )}
                </InfiniteSearchResultsGrid>
            )}
            {isOpen && (
                <CategoryDetailsDrawer
                    open
                    changeSelectedId={handleShowDrawer}
                    closeDrawer={closeDrawer}
                    id={selectedId}
                    onClose={handleCloseDrawer}
                />
            )}
        </SnackBarErrorBoundary>
    );
};
