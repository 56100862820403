import {
    IconButton, InputAdornment, TextField, Tooltip,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import {
    useState, ComponentProps, ChangeEvent, MouseEvent,
} from 'react';

export interface PropTypes extends Omit<ComponentProps<typeof TextField>, 'onChange'> {
    onChange?: (value: string) => void;
}

export const ClearableTextField = (props: PropTypes): JSX.Element => {
    const { onChange, value, ...rest } = props;
    const [initialValue] = useState<string>(value as string);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        if (onChange) {
            onChange(event.target.value);
        }
    };

    const handleClear = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (onChange) {
            onChange(initialValue);
        }
    };

    return (
        <TextField
            {...rest}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Tooltip title="clear changes">
                            <IconButton
                                aria-label="clear changes"
                                edge="end"
                                onClick={handleClear}
                            >
                                <Clear />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
            }}
            value={value}
            onChange={handleChange}
        />
    );
};
