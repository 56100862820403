import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;

class ContentStoreTaxonomyNodesApi extends AbstractService<TaxonomyNode> implements Services.ContentStoreApi.TaxonomyNodes {
    public async getTaxonomyNodes(
        bearerToken: string | false | undefined,
        parentNodeIds?: string[],
        taxononmyNodeIds?: string[],
        categoryIds?: string[],
        categoryKeys?: number[],
        monolithKeys?: number[],
        culture?: string,
        maxDepth?: number,
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<TaxonomyNode>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getTaxonomyNodes. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/taxonomyNodes`;
        const searchParams = qs.stringify({
            parentNodeIds,
            taxononmyNodeIds,
            categoryIds,
            categoryKeys,
            monolithKeys,
            culture,
            maxDepth,
            offset,
            limit,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<TaxonomyNode>>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch taxonomies',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async getTaxonomyTree(
        bearerToken: string | false | undefined,
        parentNodeIds?: string[],
        taxononmyNodeIds?: string[],
        categoryIds?: string[],
        categoryKeys?: number[],
        monolithKeys?: number[],
        culture?: string,
        maxDepth?: number,
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<TaxonomyNode>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getTaxonomyNodes. No bearerToken provided.',
            });
        }

        let url = `api/${this.version}/taxonomyNodes`;
        let searchParams = qs.stringify({
            parentNodeIds,
            taxononmyNodeIds,
            categoryIds,
            categoryKeys,
            monolithKeys,
            culture,
            maxDepth,
            offset,
            limit,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<TaxonomyNode>>();

            for (const taxonomyNode of json.results) {
                let current = taxonomyNode;

                while (current.parentNode) {
                    url = current.parentNode.href.substring(1);
                    searchParams = qs.stringify({
                        requestor: config.appName,
                    }, { arrayFormat: 'repeat' });

                    // eslint-disable-next-line no-await-in-loop
                    const parentNode = await this.api
                        .get(url, {
                            searchParams,
                            signal,
                            headers: {
                                Authorization: `Bearer ${bearerToken}`,
                            },
                        })
                        .json<TaxonomyNode>();

                    current.parentNode = parentNode;
                    current = parentNode;
                }
            }

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch taxonomies',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }
}

export const ContentStoreTaxonomyNodesService = new ContentStoreTaxonomyNodesApi(config.services.contentStoreApi);
