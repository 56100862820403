import { ResultSkeleton } from 'src/components/common/skeletons/ResultSkeleton';
import { InfiniteSearchResultsGrid } from 'src/components/common/Search/InfiniteSearchResultsGrid';

const PLACEHOLDERS: number[] = Array.from({ length: 20 }, (_, i) => i + 1);

export const ResultsSkeleton = (): JSX.Element => (
    <InfiniteSearchResultsGrid<number>
        data={PLACEHOLDERS}
        hasMore={false}
        itemKey={(item: number): string => `item-${item}`}
        variant="cards"
    >
        {(): JSX.Element => (<ResultSkeleton />)}
    </InfiniteSearchResultsGrid>
);
