import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryCategorySuggestions, QUERY_KEY } from 'src/queries/queryCategorySuggestions';

export const useQueryCategorySuggestions = (
    accessToken: string | undefined | false,
    search: App.Suggestions.Search,
    options?: UseQueryOptions<Models.ContentStoreApi.V3.SourcedCategory[],
    Error, Models.ContentStoreApi.V3.SourcedCategory[], App.Suggestions.QueryKey>,
): UseQueryResult<Models.ContentStoreApi.V3.SourcedCategory[],
Error> => useQuery<Models.ContentStoreApi.V3.SourcedCategory[],
Error, Models.ContentStoreApi.V3.SourcedCategory[],
App.Suggestions.QueryKey>(
    [QUERY_KEY, accessToken, search],
    queryCategorySuggestions,
    options,
);
