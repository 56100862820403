import { Drawer } from '@material-ui/core';
import { ComponentProps, MouseEvent } from 'react';

import { SaveLoadingButton } from 'src/components/common/buttons/SaveLoadingButton';
import { useRecoilValue } from 'recoil';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { Footer } from 'src/components/Taxonomies/TaxonomyDetailsDrawer/Footer';
import { useCreateTaxonomy } from 'src/components/Taxonomies/hooks/useCreateTaxonomy';
import { toCreateTaxonomyNodeHierarchy } from 'src/components/Taxonomies/TaxonomyDetailsDrawer/utils';
import { isTaxonomyValidState } from 'src/atoms/taxonomyAtom';

export interface PropTypes extends ComponentProps<typeof Drawer> {
    taxonomyName: string;
    taxonomyNode: Models.ContentStoreApi.V3.TaxonomyNode[];
    intent?: string;
    confirmationDialogOpened: boolean;
    setId: (id: string) => void;
    onSave: () => Promise<void>;
    onAccept: (event: MouseEvent<HTMLButtonElement>) => void;
    onModalClose: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const CreateFooter = (props: PropTypes): JSX.Element => {
    const {
        taxonomyName,
        taxonomyNode,
        intent,
        confirmationDialogOpened,
        setId,
        onSave,
        onAccept,
        onModalClose,
    } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;

    const createMutation = useCreateTaxonomy();
    const enableSave = useRecoilValue(isTaxonomyValidState);

    const handleSave = async (skipUpdate: boolean): Promise<void> => {
        const createTaxonomy: Models.ContentStoreApi.V3.UpsertTaxonomy = {
            taxonomyName,
            taxonomyNode: taxonomyNode.length
                ? toCreateTaxonomyNodeHierarchy(taxonomyNode[0], 0)
                : null,
            intent,
        };

        await createMutation.mutateAsync({ accessToken, taxonomy: createTaxonomy }, {
            onSuccess: (taxonomy: Models.ContentStoreApi.V3.Taxonomy) => {
                if (!skipUpdate) {
                    setId(taxonomy.id);
                }
            },
        });

        onSave();
    };

    const handleAccept = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        handleSave(true);
        onAccept(event);
    };

    return (
        <Footer
            confirmationDialogOpened={confirmationDialogOpened}
            onAccept={handleAccept}
            onModalClose={onModalClose}
        >
            <SaveLoadingButton
                disabled={!enableSave}
                tooltipText={!enableSave ? 'Taxonomy Name, Intent, and category are required' : undefined}
                variant="outlined"
                onClick={(): Promise<void> => handleSave(false)}
            >
                Save
            </SaveLoadingButton>
        </Footer>
    );
};
