import clsx from 'clsx';
import {
    makeStyles,
    SvgIcon,
    Theme,
    Tooltip,
} from '@material-ui/core';
import { Check, MergeType } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import {
    ComponentProps, useState,
} from 'react';

import { useSuccessEffect } from 'src/hooks/useSuccessEffect';
import { MergeDialog } from 'src/components/Categories/CategoryDetailsDrawer/MergeDialog';

export interface PropTypes extends Omit<ComponentProps<typeof LoadingButton>, 'pending' | 'onClick'> {
    onMerge: (replacementId: string, signal?: AbortSignal) => Promise<void>;
    onDelete: (signal?: AbortSignal) => Promise<void>;
    iconProps?: ComponentProps<typeof SvgIcon>;
}

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: 'rgba(209, 44, 11, 0.04)',
            borderColor: theme.palette.warning.main,
        },
    },
    iconButton: {
        minWidth: 'auto',
        borderRadius: '50%',
        padding: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '& .MuiButton-startIcon': {
            margin: 0,
        },
    },
    success: {
        color: theme.palette.success.main,
    },
}));

export const MergeLoadingButton = (props: PropTypes): JSX.Element => {
    const {
        children,
        className,
        iconProps = {},
        onMerge,
        onDelete,
        ...rest
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successEffect, setSuccessEffect] = useSuccessEffect(false);

    const handleConfirm = async (replacementId: string): Promise<void> => {
        const abortController = new AbortController();

        try {
            setIsLoading(true);
            await onMerge(replacementId, abortController.signal);
            setOpen(false);

            if (!abortController.signal.aborted) {
                setSuccessEffect(true);
            }
        } finally {
            if (!abortController.signal.aborted) {
                setIsLoading(false);
            }
        }
    };

    const handleClick = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <Tooltip title="merge with another category">
            <span>
                <LoadingButton
                    className={clsx(className, classes.button)}
                    loading={isLoading}
                    {...rest}
                    aria-label="merge"
                    startIcon={successEffect
                        ? <Check className={classes.success} {...iconProps} />
                        : <MergeType color="action" {...iconProps} />}
                    onClick={handleClick}
                >
                    Merge
                </LoadingButton>
                <MergeDialog
                    handleClose={handleClose}
                    handleConfirm={handleConfirm}
                    handleDelete={onDelete}
                    open={open}
                />
            </span>
        </Tooltip>
    );
};
