import { ContentStoreDesignTaggingGuidanceService } from 'src/services/DesignTaggingGuidances';

export const createDesignTaggingGuidance = async (
    {
        accessToken,
        designTaggingGuidance,
    }: App.DesignTaggingGuidance.CreateMutationVariables,
): Promise<Models.ContentStoreApi.V3.DesignTaggingGuidance> => ContentStoreDesignTaggingGuidanceService
    .createDesignTaggingGuidance(
        accessToken,
        designTaggingGuidance,
    );
