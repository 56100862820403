import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryProducts, QUERY_KEY } from 'src/queries/queryProducts';

type PagedProducts = Models.V3.PageResult<Models.ContentStoreApi.V3.Product>;
type PagedQueryKey = App.Products.PagedQueryKey;

export const useQueryProducts = (
    accessToken: string | undefined | false,
    search: App.Products.PagedSearch,
    options?: UseQueryOptions<PagedProducts, Error, PagedProducts, PagedQueryKey>,
): UseQueryResult<PagedProducts, Error> => useQuery<PagedProducts, Error, PagedProducts, PagedQueryKey>(
    [QUERY_KEY, accessToken, search],
    queryProducts,
    options,
);
