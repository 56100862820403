import { FormArea } from 'src/components/common/DetailsDrawer/FormArea';
import { CategoryMonolithKeysForm } from 'src/components/Categories/CategoryDetailsDrawer/CategoryMonolithKeysForm';
import { CategoryMonolithKeysHeader } from 'src/components/Categories/CategoryDetailsDrawer/CategoryMonolithKeysHeader';
import { ErrorRow } from 'src/components/common/ErrorState/ErrorRow';
import { LoadingRow } from 'src/components/common/Loader/LoadingRow';

export interface PropTypes {
    monolithKeys: Models.ContentStoreApi.V3.SourcedCategoryMonolithKey[];
    handleMonolithKeysChange: (newMonolithKeys: Models.ContentStoreApi.V3.SourcedCategoryMonolithKey[]) => void;
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
}

export const CategoryMonolithKeysTab = (props: PropTypes): JSX.Element => {
    const {
        monolithKeys,
        handleMonolithKeysChange,
        isLoading,
        isError,
        error,
    } = props;

    return (
        <>
            {isLoading && (<LoadingRow />)}
            {isError && (
                <ErrorRow
                    error={error}
                    title="Failed to get category details"
                />
            )}
            <FormArea title={(
                <CategoryMonolithKeysHeader
                    monolithKeys={monolithKeys}
                    onMonolithKeysChange={handleMonolithKeysChange}
                />
            )}
            >
                <CategoryMonolithKeysForm
                    monolithKeys={monolithKeys}
                    onMonolithKeysChange={handleMonolithKeysChange}
                />
            </FormArea>
        </>
    );
};
