import {
    Grid, Typography, Chip, Box, styled, useTheme,
} from '@mui/material';
import { MouseEvent } from 'react';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { EmptyState } from 'src/components/common/EmptyState';

interface PropTypes {
    availableProductOptions?: MCP.Products.ProductSearch.Models.Option[];
    selectedProductOptions?: App.Entities.ProductOptions;
    handleFilterChange: (optionName: string, optionValue: string) => void ;
}

const ProductOptionsGrid = styled(CSSGrid)(({ theme }) => ({
    padding: theme.spacing(0, 4, 0),
    gap: theme.spacing(4),
    overflow: 'auto',
}));

const SelectedChip = styled(Chip)(() => ({
    border: '1px solid transparent',
}));

export const ProductOptionToggles = (props: PropTypes): JSX.Element => {
    const {
        availableProductOptions,
        selectedProductOptions = {},
        handleFilterChange,
    } = props;

    const theme = useTheme();

    const onFilterClick = (optionName: string, optionValue: string) => (event: MouseEvent<HTMLInputElement>): void => {
        event.preventDefault();

        handleFilterChange(optionName, optionValue);
    };

    return (
        <ProductOptionsGrid>
            {!!availableProductOptions && !availableProductOptions.length && (
                <EmptyState>
                    Could not find product options for the given product key and version.
                </EmptyState>
            )}
            {!!availableProductOptions && !!availableProductOptions.length
            && availableProductOptions.map((productOption) => (
                <Grid item key={productOption.name} xs={12}>
                    <Typography marginBottom={theme.spacing(2)} variant="body2">
                        <Box component="span" fontWeight="bold">
                            {productOption.name}
                        </Box>
                    </Typography>
                    <Grid container direction="row" spacing={2}>
                        {productOption.values.length && productOption.values.map((value) => {
                            const selected = selectedProductOptions[productOption.name]?.includes(value);
                            const ChipVariant = selected ? SelectedChip : Chip;

                            return (
                                <Grid item key={`${productOption.name}-${value}`}>
                                    <ChipVariant
                                        color={selected ? 'primary' : 'secondary'}
                                        label={value}
                                        variant={selected ? 'filled' : 'outlined'}
                                        onClick={onFilterClick(productOption.name, value)}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            ))}
        </ProductOptionsGrid>
    );
};
