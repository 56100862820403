import { createFacet } from 'src/components/common/Search/FacetedSearch';
import { isAlphaNumeric, isGuid } from 'src/utils';

export const TAXONOMY_ID_FACET: FacetedSearch.Facet = createFacet({
    name: 'Id',
    key: 'id',
    type: 'single',
    isCompatible: async (val: string): Promise<string[] | false> => (isGuid(val) ? [val] : false),
});

export const TAXONOMY_NAME_FACET: FacetedSearch.Facet = createFacet({
    name: 'Name',
    key: 'names',
    type: 'multiple',
    isCompatible: async (val: string): Promise<string[] | false> => (isAlphaNumeric(val) ? [val] : false),
});

export const TAXONOMY_INTENT_FACET: FacetedSearch.Facet = createFacet({
    name: 'Intent',
    key: 'taxonomyIntents',
    type: 'multiple',
    isCompatible: async (val: string): Promise<string[] | false> => (isAlphaNumeric(val) ? [val] : false),
});

export const FACETS: FacetedSearch.Facet[] = [
    TAXONOMY_ID_FACET,
    TAXONOMY_NAME_FACET,
    TAXONOMY_INTENT_FACET,
];
