export const generateSrcSet = (srcSet: Models.ContentStoreApi.V3.PreviewUrls | undefined): string | undefined => (
    srcSet
        ? Object.entries(srcSet)
            .map(([size, src]) => (`${src} ${size}`))
            .join(', ')
        : undefined
);

export const generateSizes = (srcSet: Models.ContentStoreApi.V3.PreviewUrls | undefined): string | undefined => {
    if (!srcSet) {
        return srcSet;
    }

    const sizes = Object.keys(srcSet);
    const defaultSize = sizes.pop();

    return `${
        sizes.map((size) => `(max-width: ${size}) ${size}`)
            .join(', ')
    }, ${defaultSize}`;
};
