import { useState, MouseEvent } from 'react';
import {
    Button,
    Grid,
    makeStyles, Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useRecoilValue } from 'recoil';

import { TaxonomyResult } from 'src/components/Taxonomies/TaxonomyResult';
import { InfiniteSearchResultsGrid } from 'src/components/common/Search/InfiniteSearchResultsGrid';
import { queryTaxonomies, QUERY_KEY } from 'src/queries/queryTaxonomies';
import { SnackBarErrorBoundary } from 'src/components/common/SnackBarErrorBoundary';
import { useInfiniteScrollQuery } from 'src/hooks/useInfiniteScrollQuery';
import { EmptyState } from 'src/components/common/EmptyState';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { LoadingGrid } from 'src/components/common/Loader/LoadingGrid';
import { TaxonomyDetailsDrawer } from 'src/components/Taxonomies/TaxonomyDetailsDrawer';

type Taxonomy = Models.ContentStoreApi.V3.Taxonomy;

interface PropTypes {
    search: App.Taxonomies.Search;
}

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        paddingRight: theme.spacing(4),
    },
}));

export const TaxonomyResults = (props: PropTypes): JSX.Element => {
    const { search } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const [selectedId, setSelectedId] = useState<string | null>();
    const [cloneId, setCloneId] = useState<string | null>(null);

    const { data, fetchNextPage, hasNextPage } = useInfiniteScrollQuery<Taxonomy, Error, App.Taxonomies.QueryKey>(
        [QUERY_KEY, accessToken, search],
        queryTaxonomies,
        {
            enabled: !!accessToken,
            retry: 2,
            refetchInterval: 30000,
        },
    );

    const handleShowDrawer = (id: string | null): void => {
        setSelectedId(id);
    };

    const handleCloseDrawer = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        setSelectedId('');
        setCloneId(null);
    };

    const handleNewTaxonomy = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        handleShowDrawer(null);
    };

    const handleClone = async (id: string): Promise<void> => {
        setCloneId(id);
        setSelectedId(null);
    };

    const loadMore = async (): Promise<void> => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    return (
        <SnackBarErrorBoundary>
            {/* TODO Common Inline Add Component */}
            <Grid
                container
                className={classes.actions}
                justifyContent="flex-end"
                spacing={4}
            >
                <Grid item>
                    <Button
                        color="primary"
                        startIcon={<Add />}
                        variant="contained"
                        onClick={handleNewTaxonomy}
                    >
                        New Taxonomy
                    </Button>
                </Grid>
            </Grid>
            {!data && (<LoadingGrid />)}
            {(data && !data.length) && (
                <EmptyState>
                    Could not find categories or taxonomies for the given parameters
                </EmptyState>
            )}
            {(data && !!data.length) && (
                <InfiniteSearchResultsGrid
                    data={data}
                    hasMore={!!hasNextPage}
                    itemKey={(item: Models.ContentStoreApi.V3.Taxonomy): string => `item-${item.id}`}
                    loadMore={loadMore}
                    minWidth={400}
                    variant="cards"
                >
                    {(item: Models.ContentStoreApi.V3.Taxonomy): JSX.Element => (
                        <TaxonomyResult
                            id={item.id}
                            intent={item.intent}
                            taxonomyName={item.taxonomyName}
                            onClick={handleShowDrawer}
                            onClone={handleClone}
                        />
                    )}
                </InfiniteSearchResultsGrid>
            )}
            {(selectedId || selectedId === null) && (
                <TaxonomyDetailsDrawer
                    open
                    id={selectedId}
                    preloadDataId={cloneId}
                    setId={setSelectedId}
                    onClose={handleCloseDrawer}
                />
            )}
        </SnackBarErrorBoundary>
    );
};
