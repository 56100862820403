import {
    makeStyles,
    Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import { ComponentProps } from 'react';

import { CSSGrid } from 'src/components/common/CSSGrid';

const useStyles = makeStyles((theme: Theme) => ({
    taggingPanel: {
        overflowY: 'auto',
        gap: theme.spacing(3),
        height: '100%',
        paddingBottom: theme.spacing(12),
        paddingRight: theme.spacing(4),
        flex: 1,
    },
}));

export const TaggingPanel = (props: ComponentProps<typeof CSSGrid>): JSX.Element => {
    const {
        className,
        ...rest
    } = props;
    const classes = useStyles();

    return (<CSSGrid className={clsx(classes.taggingPanel, className)} {...rest} />);
};
