import { useRecoilValue } from 'recoil';
import { TreeView } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useQueryCategoryTaxonomies } from 'src/components/Categories/hooks/useQueryCategoryTaxonomies';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { StyledTreeItem } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/StyledTreeItem';
import { ErrorRow } from 'src/components/common/ErrorState/ErrorRow';
import { LoadingRow } from 'src/components/common/Loader/LoadingRow';

export interface PropTypes {
    id: string;
}

export const CategoryTaxonomiesTab = (props: PropTypes): JSX.Element => {
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const { id } = props;

    const {
        data,
        error,
        isError,
        isLoading,
    } = useQueryCategoryTaxonomies(accessToken, id, { suspense: false });
    const {
        taxonomyNodes,
        rootTaxonomies,
    } = data || {};

    const categoryTaxonomyNodes = taxonomyNodes?.results || [];
    const taxonomies = rootTaxonomies?.results || [];

    return (
        <>
            {isLoading && (<LoadingRow />)}
            {isError && (
                error?.map((e: Error | null) => (
                    <ErrorRow
                        error={e}
                        title="Failed to get category details"
                    />
                ))
            )}
            {categoryTaxonomyNodes.map((node) => {
                let index = 0;
                let current: Models.ContentStoreApi.V3.TaxonomyNode | undefined = node;
                let tree;

                while (current) {
                    // eslint-disable-next-line no-loop-func
                    const taxonomy = taxonomies?.find((t) => t.taxonomyNode.id === current?.id);

                    tree = (
                        <StyledTreeItem
                            labelInfo={taxonomy ? (
                                // eslint-disable-next-line no-loop-func
                                <IconButton
                                    href={`${window.location.origin}/taxonomies?id=${taxonomy.id}`}
                                    target="_blank"
                                >
                                    <Launch />
                                </IconButton>
                            ) : undefined}
                            labelText={taxonomy
                                ? taxonomy.taxonomyName
                                : current._embedded.category.internalName}
                            nodeId={index.toString()}
                        >
                            {tree}
                        </StyledTreeItem>
                    );
                    index += 1;
                    current = current.parentNode;
                }
                return (
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpanded={[...Array(index).keys()].map((i) => i.toString())}
                        defaultExpandIcon={<ChevronRightIcon />}
                    >
                        {tree}
                    </TreeView>
                );
            })}
        </>
    );
};
