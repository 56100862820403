import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { mergeCategory } from 'src/mutations/mergeCategory';
import { QUERY_KEY } from 'src/queries/queryCategory';

export const useMergeCategory = (): UseMutationResult<string, Error, App.Category.MergeMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<string, Error, App.Category.MergeMutationVariables>(
        mergeCategory,
        {
            onSuccess: () => {
                queryClient.refetchQueries(QUERY_KEY);
            },
            useErrorBoundary: true,
        },
    );
};
