import clsx from 'clsx';
import { Container, makeStyles } from '@material-ui/core';
import { ComponentProps } from 'react';

const useStyles = makeStyles({
    container: {
        gridArea: 'content',
    },
});

export const SearchResultsContainer = (props: ComponentProps<typeof Container>): JSX.Element => {
    const { children, className, ...rest } = props;
    const classes = useStyles(props);

    return (
        <Container maxWidth={false} {...rest} className={clsx(classes.container, className)}>
            {children}
        </Container>
    );
};
