import { atom } from 'recoil';

export const selectedIdsState = atom({
    key: 'selectedIdsState',
    default: [] as string[],
});

export const renderedIdsState = atom({
    key: 'renderedIdsState',
    default: [] as string[],
});

export const selectAllState = atom({
    key: 'selectAllState',
    default: false,
});

export const totalIdsState = atom({
    key: 'totalIdsState',
    default: 0,
});
