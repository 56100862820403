import { Drawer } from '@material-ui/core';
import {
    ComponentProps, MouseEvent, useRef, useState,
} from 'react';

import { DeleteLoadingButton } from 'src/components/common/buttons/DeleteLoadingButton';
import { SaveLoadingButton } from 'src/components/common/buttons/SaveLoadingButton';
import { useRecoilValue } from 'recoil';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { Footer } from 'src/components/Taxonomies/TaxonomyDetailsDrawer/Footer';
import { useMutateTaxonomy } from 'src/components/Taxonomies/hooks/useMutateTaxonomy';
import { useDeleteTaxonomy } from 'src/components/Taxonomies/hooks/useDeleteTaxonomy';
import { toCreateTaxonomyNodeHierarchy } from 'src/components/Taxonomies/TaxonomyDetailsDrawer/utils';
import { ConfirmationDialog } from 'src/components/common/ConfirmationDialog';

export interface PropTypes extends ComponentProps<typeof Drawer> {
    id: string;
    taxonomyName: string;
    taxonomyNode: Models.ContentStoreApi.V3.TaxonomyNode[];
    intent?: string;
    confirmationDialogOpened: boolean;
    setId: (id: string) => void;
    onSave: () => Promise<void>;
    onClose: (event: MouseEvent<HTMLButtonElement>) => void;
    onAccept: (event: MouseEvent<HTMLButtonElement>) => void;
    onModalClose: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const UpdateFooter = (props: PropTypes): JSX.Element => {
    const {
        id,
        taxonomyName,
        taxonomyNode,
        intent,
        confirmationDialogOpened,
        setId,
        onSave,
        onClose,
        onAccept,
        onModalClose,
    } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const deleteModalPrompt = `Are you sure you want to delete taxonomy ${taxonomyName}?`;
    const abortControllerRef = useRef<AbortController | undefined>();

    const updateMutation = useMutateTaxonomy();
    const deleteMutation = useDeleteTaxonomy();

    const handleSave = async (skipUpdate: boolean): Promise<void> => {
        const updatedTaxonomy: Models.ContentStoreApi.V3.UpsertTaxonomy = {
            taxonomyName,
            taxonomyNode: taxonomyNode.length
                ? toCreateTaxonomyNodeHierarchy(taxonomyNode[0], 0)
                : null,
            intent,
        };

        await updateMutation.mutateAsync({ accessToken, id, taxonomy: updatedTaxonomy }, {
            onSuccess: (taxonomy: Models.ContentStoreApi.V3.Taxonomy) => {
                if (!skipUpdate) {
                    setId(taxonomy.taxonomyNode.id);
                }
            },
        });

        onSave();
    };

    const handleDeleteModal = (
        event: MouseEvent<HTMLButtonElement>,
        abortController?: AbortController,
    ): void => {
        event.preventDefault();
        event.stopPropagation();

        abortControllerRef.current = abortController;
        abortControllerRef.current?.abort();
        setDeleteModalOpen(true);
    };

    const onDeleteModalClose = (): void => {
        if (!abortControllerRef.current?.signal.aborted) {
            abortControllerRef.current?.abort();
        }
        setDeleteModalOpen(false);
    };

    const handleDelete = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        await deleteMutation.mutateAsync({ accessToken, id });

        onClose(event);
        setDeleteModalOpen(false);
    };

    const handleAccept = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        handleSave(true);
        onAccept(event);
    };

    return (
        <Footer
            confirmationDialogOpened={confirmationDialogOpened}
            onAccept={handleAccept}
            onModalClose={onModalClose}
        >
            <DeleteLoadingButton variant="outlined" onClick={handleDeleteModal}>
                Delete
            </DeleteLoadingButton>

            <SaveLoadingButton
                variant="outlined"
                onClick={(): Promise<void> => handleSave(false)}
            >
                Save
            </SaveLoadingButton>
            <ConfirmationDialog
                open={deleteModalOpen}
                title={deleteModalPrompt}
                onAccept={handleDelete}
                onCancel={onDeleteModalClose}
            />
        </Footer>
    );
};
