import {
    Button, Card, Dialog, DialogProps, Grid, makeStyles, TextField, Theme, Typography,
} from '@material-ui/core';
import { Cancel, Save } from '@material-ui/icons';
import { DesktopDatePicker, LocalizationProvider } from '@material-ui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MouseEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import * as routes from 'src/lib/routes';
import { createRankingStrategy } from 'src/mutations/rankingStrategy';
import { QUERY_KEY } from 'src/queries/queryRankingStrategies';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';

export interface PropTypes extends Omit<DialogProps, 'onClose'> {
    onClose: () => void,
}

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: theme.spacing(4),
    },
}));

export const NewForcedRanking = (props: PropTypes): JSX.Element => {
    const {
        onClose,
        ...rest
    } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const queryClient = useQueryClient();
    const [strategyName, setStrategyName] = useState('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const navigate = useNavigate();
    const classes = useStyles();

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue = event.target.value;

        if (/^[a-zA-Z0-9\s]+$/.test(newValue) || newValue === '') {
            setStrategyName(newValue);
        }
    };

    const handleStartChange = (newValue: Date | null): void => {
        setStartDate(newValue);
    };

    const handleEndChange = (newValue: Date | null): void => {
        setEndDate(newValue);
    };

    const mutation = useMutation<
    Models.ContentStoreApi.V3.RankingStrategy, Error, App.RankingStrategy.CreateMutationVariables>(
        createRankingStrategy,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            },
            useErrorBoundary: true,
        },
    );

    const handleNewRankingStrategy = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        if (accessToken) {
            const newRankingStrategy = await mutation.mutateAsync({
                accessToken,
                rankingStrategy: {
                    strategyName,
                    startDate,
                    endDate,
                    strategyType: 'forced',
                },
            });

            navigate(`/${routes.FORCEDRANKING}/${newRankingStrategy.rankingStrategyId}`);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog onClose={onClose} {...rest}>
                <Card raised>
                    <Grid container className={classes.wrapper} direction="column" spacing={4}>
                        <Grid item>
                            <Typography>
                                Create New Forced Ranking
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                required
                                id="strategy-name"
                                label="Ranking strategy name"
                                value={strategyName}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid container item spacing={4}>
                            <Grid item>
                                <DesktopDatePicker
                                    inputFormat="MM/DD/YYYY"
                                    label="Start Date"
                                    renderInput={(params): JSX.Element => <TextField {...params} />}
                                    value={startDate}
                                    onChange={handleStartChange}
                                />
                            </Grid>
                            <Grid item>
                                <DesktopDatePicker
                                    inputFormat="MM/DD/YYYY"
                                    label="End Date"
                                    renderInput={(params): JSX.Element => <TextField {...params} />}
                                    value={endDate}
                                    onChange={handleEndChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent="space-between" spacing={4}>
                            <Grid item xs={5}>
                                <Button fullWidth color="secondary" startIcon={<Cancel />} variant="outlined" onClick={onClose}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={5}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    disabled={!strategyName}
                                    startIcon={<Save />}
                                    variant="contained"
                                    onClick={handleNewRankingStrategy}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Dialog>
        </LocalizationProvider>
    );
};
