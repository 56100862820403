import {
    Checkbox,
    Divider,
    experimentalStyled,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {
    ComponentProps,
    MouseEvent,
    Suspense,
    useEffect,
    useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AddTranslationToolbar } from 'src/components/common/DetailsDrawer/AddTranslationToolbar';
import { AddTranslationToolbarSkeleton } from 'src/components/common/DetailsDrawer/AddTranslationToolbarSkeleton';
import { TranslationRow } from 'src/components/common/DetailsDrawer/TranslationRow';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { useQueryKeyword } from 'src/components/Keywords/hooks/useQueryKeyword';
import { EmptyStateRow } from 'src/components/common/DetailsDrawer/EmptyStateRow';
import { LoadingRow } from 'src/components/common/Loader/LoadingRow';
import { KeywordDetailsForm } from 'src/components/Keywords/KeywordDetailsDrawer/KeywordDetailsForm';
import { useDeleteKeyword } from 'src/components/Keywords/hooks/useDeleteKeyword';
import { useMutateKeyword } from 'src/components/Keywords/hooks/useMutateKeyword';
import { DetailsDrawer } from 'src/components/common/DetailsDrawer';
import { FormArea } from 'src/components/common/DetailsDrawer/FormArea';
import { ErrorRow } from 'src/components/common/ErrorState/ErrorRow';
import { drawerUpdatedState } from 'src/atoms/drawerUpdatedAtom';
import { useCreateKeyword } from 'src/components/Keywords/hooks/useCreateKeyword';
import { DEFAULT_CULTURE } from 'src/constants';

export interface PropTypes extends Omit<ComponentProps<typeof DetailsDrawer>, 'id' | 'onDelete' | 'onSave'> {
    id?: string;
}

const StyledTableContainer = experimentalStyled(TableContainer)(({ theme }) => ({
    marginTop: theme.spacing(6),
}));

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        marginBottom: theme.spacing(6),
    },
    autoTranslateContainer: {
        display: 'grid',
        gridAutoFlow: 'column',
    },
    text: {
        alignSelf: 'center',
    },
}));

export const KeywordDetailsDrawer = (props: PropTypes): JSX.Element => {
    const {
        id = '',
        open,
        onClose,
        ...rest
    } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const [keywordName, setKeywordName] = useState<string>('');
    const [isExcludedFromAltText, setIsExcludedFromAltText] = useState<boolean>(false);
    const [toBeDeleted, setToBeDeleted] = useState<boolean>(false);
    const [translations, setTranslations] = useState<Models.ContentStoreApi.V3.Translations>({});
    const setUpdatedState = useSetRecoilState(drawerUpdatedState);
    const [locales, setLocales] = useState<string[]>(Object.keys(translations));
    const [autoTranslate, setAutoTranslate] = useState<boolean>(false);
    const disableTranslate = !translations[DEFAULT_CULTURE];
    const disableAutoTranslateText = 'Add an en-us translation to enable auto-translate';

    const {
        data, error, isLoading, isError,
    } = useQueryKeyword(accessToken, id, {
        enabled: !!id,
        suspense: false,
        refetchInterval: false,
    });

    const createMutation = useCreateKeyword();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const mutation = id && useMutateKeyword(accessToken, id);
    const deleteMutation = useDeleteKeyword();

    useEffect(() => {
        if (data) {
            setKeywordName(data.keywordName);
            setTranslations(data.translations);
            setLocales(Object.keys(data.translations));
            setIsExcludedFromAltText(data.isExcludedFromAltText);
            setToBeDeleted(data.toBeDeleted);
        }
    }, [data]);

    const handleClose = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (onClose) {
            onClose(event, 'escapeKeyDown');
        }
    };

    const handleDelete = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        if (id) {
            await deleteMutation.mutateAsync({ accessToken, id });
        }

        handleClose(event);
    };

    const handleTranslationDelete = async (locale: string): Promise<void> => {
        const newTranslations = { ...translations };

        delete newTranslations[locale];
        setTranslations(newTranslations);
        setLocales((previousLocales) => previousLocales.filter((l) => l !== locale));
        setUpdatedState(true);
    };

    const handleKeywordNameChange = (newKeywordName: string): void => {
        setKeywordName(newKeywordName);
        setUpdatedState(true);
    };

    const handleIsExcludedFromAltTextChange = (newIsExcludedFromAltText: boolean): void => {
        setIsExcludedFromAltText(newIsExcludedFromAltText);
        setUpdatedState(true);
    };

    const handleAutoTranslateChange = (newAutoTranslate: boolean): void => {
        setAutoTranslate(newAutoTranslate);
        setUpdatedState(true);
    };

    const handleSave = async (): Promise<void> => {
        if (id && mutation) {
            const updatedKeyword: Models.ContentStoreApi.V3.UpdateKeyword = {
                keywordName,
                isExcludedFromAltText,
                translations,
            };

            await mutation.mutateAsync({ accessToken, id, keyword: updatedKeyword });
        } else {
            await createMutation.mutateAsync({
                accessToken,
                keyword: {
                    keywordName,
                    translations,
                },
                autoTranslate,
            });
        }
        setUpdatedState(false);
    };

    const handleTranslationChange = (locale: string, translation: string): void => {
        const updateTranslations = {
            ...translations,
            [locale]: translation,
        };

        setTranslations(updateTranslations);
        setLocales((previousLocales) => {
            previousLocales.push(locale);
            return previousLocales;
        });
        setUpdatedState(true);
    };

    const autoTranslateOption = (
        <Tooltip title={disableTranslate ? disableAutoTranslateText : ''}>
            <div className={classes.autoTranslateContainer}>
                <Grid item>
                    <Checkbox
                        checked={autoTranslate}
                        disabled={disableTranslate}
                        onChange={(ev): void => handleAutoTranslateChange(ev.target.checked)}
                    />
                </Grid>
                <Grid item className={classes.text}>
                    <Typography>Auto-translate on save</Typography>
                </Grid>
            </div>
        </Tooltip>
    );

    return (
        <DetailsDrawer
            open={open}
            options={!id ? autoTranslateOption : undefined}
            onClose={onClose}
            onDelete={handleDelete}
            onSave={handleSave}
            {...rest}
        >
            <FormArea title="Keyword Details">
                <KeywordDetailsForm
                    id={id}
                    isExcludedFromAltText={isExcludedFromAltText}
                    keywordName={keywordName}
                    toBeDeleted={toBeDeleted}
                    onIsExcludedFromAltTextChange={handleIsExcludedFromAltTextChange}
                    onKeywordNameChange={handleKeywordNameChange}
                />
            </FormArea>
            <Divider className={classes.divider} />
            <Suspense fallback={<AddTranslationToolbarSkeleton />}>
                <AddTranslationToolbar blackList={locales} onSubmit={handleTranslationChange} />
            </Suspense>
            <StyledTableContainer>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" component="th">
                                Locale
                            </TableCell>
                            <TableCell align="left" component="th">
                                Translation
                            </TableCell>
                            <TableCell align="right" component="th" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (<LoadingRow />)}
                        {isError && (
                            <ErrorRow
                                error={error}
                                title="Failed to get keyword details"
                            />
                        )}
                        {(!isLoading && !locales.length) && (<EmptyStateRow />)}
                        {(!isLoading && !!locales.length) && locales.map((locale) => (
                            <TranslationRow
                                key={locale}
                                locale={locale}
                                translation={translations[locale]}
                                onChange={handleTranslationChange}
                                onDelete={handleTranslationDelete}
                            />
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </DetailsDrawer>
    );
};
