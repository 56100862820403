import {
    Chip, Grid, makeStyles, Popover, Theme, Tooltip, Typography,
} from '@material-ui/core';
import {
    ComponentProps, useState, MouseEvent,
} from 'react';

import { ManageSearch } from '@material-ui/icons';
import { CopyButton } from 'src/components/common/buttons/CopyButton';

export interface PropTypes extends ComponentProps<typeof Chip> {
    facet: FacetedSearch.FacetedSearchRecord
}

const useStyles = makeStyles((theme: Theme) => ({
    activeFacet: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.htmlFontSize,
        paddingTop: '2.5px',
        paddingLeft: theme.spacing(2),
        whiteSpace: 'nowrap',
    },
    chip: {
        marginBottom: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        whiteSpace: 'nowrap',
    },
    popover: {
        '& .MuiPopover-paper': {
            borderRadius: theme.spacing(3),
        },
    },
    bulkListContainer: {
        padding: theme.spacing(4),
        overflow: 'auto',
        maxWidth: theme.spacing(115),
    },
    copy: {
        marginLeft: theme.spacing(2),
    },
    copyBox: {
        display: 'grid',
        gridTemplateColumns: 'auto min-content',
        flexDirection: 'column',
        justifyContent: 'left',
        marginBottom: theme.spacing(2),
    },
    breakBullets: {
        wordBreak: 'break-all',
    },
    searchTermChip: {
        padding: theme.spacing(2, 2, 0, 0),
        color: theme.palette.grey[600],
        '&.MuiChip-root': {
            height: 'max-content',
            padding: theme.spacing(1.5, 0),
            '& > .MuiChip-label': {
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                textOverflow: 'clip',
            },
            '& > .MuiChip-icon': {
                marginLeft: '7px',
                opacity: 0,
                visibility: 'hidden',
                width: 0,
                color: theme.palette.secondary,
                transition: '.1s ease-in',
                transitionDelay: '0s',
            },
            '&:hover': {
                '& > .MuiChip-icon': {
                    opacity: 1,
                    width: '1em',
                    visibility: 'visible',
                    transition: '.1s ease-out',
                    transitionDelay: '.2s',
                },
            },
        },
    },
    chipGrid: {
        width: 'auto',
        marginLeft: theme.spacing(-2),
    },
}));

export const BulkTag = (props: PropTypes): JSX.Element => {
    const {
        facet,
        ...rest
    } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();

        setAnchorEl(event.currentTarget);
    };

    const handleFiltersClose = (): void => {
        setAnchorEl(null);
    };

    const bulkSearchOpen = Boolean(anchorEl);
    const bulkSearchPopoverId = bulkSearchOpen ? 'bulk-search-popover' : undefined;

    return (
        <>
            <Tooltip title="view bulk search list">
                <Chip
                    className={classes.chip}
                    {...rest}
                    icon={<ManageSearch />}
                    label={facet.facet?.name}
                    onClick={handleClick}
                    {...rest}
                    data-testid={`${facet.facet?.key}-bulk-tag`}
                />
            </Tooltip>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={classes.popover}
                id={bulkSearchPopoverId}
                open={bulkSearchOpen}
                onClose={handleFiltersClose}
            >
                <div className={classes.bulkListContainer}>
                    <div className={classes.copyBox}>
                        <Typography>
                            {`${facet.facet?.name} Bulk Search`}
                        </Typography>
                        <CopyButton
                            className={classes.copy}
                            value={facet.bulkValue?.join(facet.facet?.bulkSearchSeperator) || ''}
                        />
                    </div>
                    <Grid container className={classes.chipGrid} spacing={2}>
                        {facet.bulkValue?.map((value) => (
                            <Grid item>
                                <Chip
                                    className={classes.searchTermChip}
                                    data-testid="bulk-term-chip"
                                    icon={(
                                        <CopyButton
                                            className={classes.copy}
                                            copySuccessText=""
                                            value={value}
                                        />
                                    )}
                                    label={value}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Popover>
        </>
    );
};
