import { createFacet } from 'src/components/common/Search/FacetedSearch';

export const KEYWORD_NAME_FACET: FacetedSearch.Facet = createFacet({
    name: 'Keyword Name',
    key: 'keywordName',
    type: 'single',
});

export const KEYWORD_PREFIX_FACET: FacetedSearch.Facet = createFacet({
    name: 'Keyword Name Prefix',
    key: 'prefix',
    type: 'single',
});

export const KEYWORD_IDS_FACET: FacetedSearch.Facet = createFacet({
    name: 'id',
    key: 'ids',
    type: 'multiple',
});

export const KEYWORD_ALT_FACET: FacetedSearch.Facet = createFacet({
    name: 'Excluded From Alt Text',
    key: 'isExcludedFromAltText',
    type: 'single',
});

export const FACETS: FacetedSearch.Facet[] = [
    KEYWORD_NAME_FACET,
    KEYWORD_PREFIX_FACET,
    KEYWORD_IDS_FACET,
    KEYWORD_ALT_FACET,
];
