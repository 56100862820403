import {
    forwardRef,
    HTMLAttributes,
    KeyboardEvent,
    MouseEvent,
} from 'react';
import { InternalStandardProps as StandardProps } from '@material-ui/core';
import { useTreeItem } from '@material-ui/lab';
import clsx from 'clsx';

export interface TreeItemContentProps extends StandardProps<HTMLAttributes<HTMLElement>> {
    /**
     * className applied to the root element.
     */
    className?: string;
    /**
     * Override or extend the styles applied to the component.
     */
    classes: {
        /** Styles applied to the root element. */
        root: string;
        /** Pseudo-class applied to the content element when expanded. */
        expanded: string;
        /** Pseudo-class applied to the content element when selected. */
        selected: string;
        /** Pseudo-class applied to the content element when focused. */
        focused: string;
        /** Pseudo-class applied to the element when disabled. */
        disabled: string;
        /** Styles applied to the tree node icon and collapse/expand icon. */
        iconContainer: string;
        /** Styles applied to the label element. */
        label: string;
    };
    /**
     * The tree node label.
     */
    label?: React.ReactNode;
    /**
     * The id of the node.
     */
    nodeId: string;
    /**
     * The icon to display next to the tree node's label.
     */
    icon?: React.ReactNode;
    /**
     * The icon to display next to the tree node's label. Either an expansion or collapse icon.
     */
    expansionIcon?: React.ReactNode;
    /**
     * The icon to display next to the tree node's label. Either a parent or end icon.
     */
    displayIcon?: React.ReactNode;
}

export const TreeItemContent = forwardRef<unknown, TreeItemContentProps>((props: TreeItemContentProps, ref) => {
    const {
        classes,
        className,
        displayIcon,
        expansionIcon,
        icon: iconProp,
        label,
        nodeId,
        onClick,
        onKeyDown,
        onMouseDown,
        ...other
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
        preventSelection(event);

        if (onKeyDown) {
            onKeyDown(event);
        }
    };

    const handleMouseDown = (event: MouseEvent<HTMLDivElement>): void => {
        preventSelection(event);

        if (onMouseDown) {
            onMouseDown(event);
        }
    };

    const handleClick = (event: MouseEvent<HTMLDivElement>): void => {
        handleExpansion(event);
        handleSelection(event);

        if (onClick) {
            onClick(event);
        }
    };

    return (
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            // @ts-expect-error
            ref={ref}
            role="button"
            tabIndex={0}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            {...other}
        >
            <div className="Mui-ContentWrapper">
                <div className={classes.iconContainer}>{icon}</div>
                <div className={classes.label}>{label}</div>
            </div>
        </div>
    );
});
