import {
    Checkbox,
    FormControlLabel,
    makeStyles, Theme,
} from '@material-ui/core';

import { useRecoilValue } from 'recoil';

import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { queryTaxonomies, QUERY_KEY } from 'src/queries/queryTaxonomies';
import { useInfiniteScrollQuery } from 'src/hooks/useInfiniteScrollQuery';
import { InfiniteSearchResultsGrid } from '../../Search/InfiniteSearchResultsGrid';

type PagedTaxonomyKey = App.Taxonomies.PagedQueryKey;
type Taxonomy = Models.ContentStoreApi.V3.Taxonomy;

export interface TaxonomyListPropTypes{
    selectedTaxonomyIds: string[];
    handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void ;
}

export const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    grid: {
        height: '100%',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        gridTemplateRows: 'auto 1fr',
        gridAutoFlow: 'row dense',
    },
    loader: {
        marginTop: theme.spacing(10),
    },
    tree: {
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
    },
    root: {
        height: 'fit-content',
    },
    content: {
        padding: theme.spacing(5),
    },
    search: {
        paddingBottom: theme.spacing(6),
    },
}));
export const TaxonomyList = (props: TaxonomyListPropTypes): JSX.Element => {
    const {
        selectedTaxonomyIds: selectedTaxonomies,
        handleCheck,
    } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery);

    const { data, fetchNextPage, hasNextPage } = useInfiniteScrollQuery<Taxonomy, Error, PagedTaxonomyKey>(
        [QUERY_KEY, accessToken, null],
        queryTaxonomies,
        {
            enabled: !!accessToken,
            retry: 2,
            refetchInterval: 30000,
        },
    );

    function isTaxonomySelected(id: string): boolean {
        return selectedTaxonomies.includes(id);
    }

    const loadMore = async (): Promise<void> => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    return (
        <div>
            {(!!data?.length) && (
                <InfiniteSearchResultsGrid
                    data={data}
                    hasMore={!!hasNextPage}
                    itemKey={(item: Models.ContentStoreApi.V3.Taxonomy): string => `item-${item.id}`}
                    loadMore={loadMore}
                    minWidth={400}
                >
                    {(item: Models.ContentStoreApi.V3.Taxonomy): JSX.Element => (
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={isTaxonomySelected(item.id)}
                                    color="primary"
                                    value={item.id}
                                    onChange={handleCheck}
                                />
        )}
                            label={item.taxonomyName}
                        />
                    )}
                </InfiniteSearchResultsGrid>
            )}
        </div>
    );
};
