import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryTaxonomy, QUERY_KEY } from 'src/queries/queryTaxonomy';

type Taxonomy = Models.ContentStoreApi.V3.Taxonomy;

export const useQueryTaxonomy = (
    accessToken: string | undefined | false,
    id: string,
    options?: UseQueryOptions<Taxonomy, Error, Taxonomy, App.Taxonomy.QueryKey>,
    queryKey?: string,
): UseQueryResult<Taxonomy, Error> => useQuery<Taxonomy, Error, Taxonomy, App.Taxonomy.QueryKey>(
    [queryKey ?? QUERY_KEY, accessToken, id],
    queryTaxonomy,
    options,
);
