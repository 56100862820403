import clsx from 'clsx';
import {
    makeStyles, Button, Typography,
} from '@material-ui/core';
import { Add, Category as CategoryIcon, ChevronRight } from '@material-ui/icons';
import { ComponentProps, SyntheticEvent } from 'react';

import { CopyButton } from 'src/components/common/buttons/CopyButton';
import { findId } from 'src/lib/findId';
import { TaggingData } from 'src/components/common/Tagging/TaggingData';
import { Tagging } from 'src/components/common/Tagging';
import { TaggingHeader } from 'src/components/common/Tagging/TaggingHeader';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { Chip } from 'src/components/common/Tagging/Chip';
import { handlePillEventFactory } from 'src/components/Entity/EntityDetailsDrawer/utils/handlePillEvent';
import { DataSource } from 'src/constants';

type Category = Models.ContentStoreApi.V3.SourcedCategory;

export type PropTypes = Omit<ComponentProps<typeof Button>, 'onChange'> & {
    data: Category[];
    onChange?: (
        event: SyntheticEvent<Element, Event>,
        value: Category[] | Category,
    ) => void;
    onAddAllSuggestedCategories?: () => void;
};

const HELPER_TEXT = `\
Click the Select button to search for and select categories. \
Click on any suggested pill to add them to be saved, and \
click on any selected pill to remove them from being saved\
`;

const useStyles = makeStyles({
    button: {
        maxHeight: 'none',
        '& .MuiButton-label': {
            display: 'flex',
            flexWrap: 'nowrap',
        },
    },
    endIcon: {
        '& .MuiButton-endIcon': {
            marginLeft: 0,
        },
    },
});

export const CategoriesForm = (props: PropTypes): JSX.Element => {
    const {
        className,
        data = [],
        onChange,
        onAddAllSuggestedCategories,
        ...rest
    } = props;
    const classes = useStyles();

    const handleEvent = onChange && handlePillEventFactory(data, onChange);

    const suggestionsExist = !!data?.find((k) => k.source === DataSource.Suggested);

    return (
        <Tagging icon={<CategoryIcon />}>
            <TaggingHeader
                helperText={HELPER_TEXT}
                title={(
                    <CSSGrid
                        gap={2}
                        gridTemplateAreas={'". ."'}
                        gridTemplateColumns="auto 1fr"
                    >
                        <Typography variant="h6">Categories</Typography>
                        <div>
                            <CopyButton
                                value={
                                    data.map(
                                        (x) => (typeof x === 'string' ? x : findId((x as Models.V3.HrefOnly).href)),
                                    ).join(',')
                                }
                            />
                        </div>
                    </CSSGrid>
                )}
            >
                <Button
                    {...rest}
                    fullWidth
                    className={clsx(classes.button, classes.endIcon, className)}
                    color="primary"
                    endIcon={<ChevronRight />}
                    variant="outlined"
                >
                    Select Categories
                </Button>
            </TaggingHeader>
            <TaggingData data={data.sort((a, b) => a.internalName.localeCompare(b.internalName))}>
                {(item: Category): JSX.Element => (
                    <Chip
                        data={item}
                        label={item.internalName}
                        onClick={handleEvent}
                        onDelete={handleEvent}
                    />
                )}
            </TaggingData>
            {suggestionsExist && (
                <Button
                    color="secondary"
                    size="small"
                    startIcon={<Add />}
                    sx={{ mt: 4 }}
                    variant="text"
                    onClick={onAddAllSuggestedCategories}
                >
                    Add All Suggestions
                </Button>
            )}
        </Tagging>
    );
};
