import { DataSource } from 'src/constants';

type Category = Models.ContentStoreApi.V3.Category;
type Taxonomy = Models.ContentStoreApi.V3.Taxonomy;
type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;
type SourcedCategory = Models.ContentStoreApi.V3.SourcedCategory;
type ValueMap = Record<string, SourcedCategory>;

export const filterByNamesList = (item: Taxonomy, search: string[]): Taxonomy | Category | undefined => {
    if (search.includes(item.taxonomyName)) {
        return item;
    }

    if (search.includes(item.taxonomyNode._embedded.category.internalName)) {
        return item.taxonomyNode._embedded.category;
    }

    if (item.taxonomyNode.children?.length) {
        for (const child of item.taxonomyNode.children) {
            if (search.includes(child._embedded.category.internalName)) {
                return child._embedded.category;
            }

            if (child.children?.length) {
                for (const grandchild of child.children) {
                    if (search.includes(grandchild._embedded.category.internalName)) {
                        return grandchild._embedded.category;
                    }
                }
            }
        }
    }

    return undefined;
};

export const filterBySubstring = (item: Taxonomy, search?: string): Taxonomy | Category | undefined => {
    const searchLower = search?.toLowerCase();

    if (!searchLower || item.taxonomyName.toLowerCase().includes(searchLower)) {
        return item;
    }

    if (item.taxonomyNode._embedded.category.internalName.toLowerCase().includes(searchLower)) {
        return item.taxonomyNode._embedded.category;
    }

    if (item.taxonomyNode.children?.length) {
        for (const child of item.taxonomyNode.children) {
            if (child._embedded.category.internalName.toLowerCase().includes(searchLower)) {
                return child._embedded.category;
            }

            if (child.children?.length) {
                for (const grandchild of child.children) {
                    if (grandchild._embedded.category.internalName.toLowerCase().includes(searchLower)) {
                        return grandchild._embedded.category;
                    }
                }
            }
        }
    }

    return undefined;
};

export const getOptionLabel = (option: Category | Taxonomy): string => (
    (option as Category).internalName || (option as Taxonomy).taxonomyName
);

export const getOptionSelected = (
    option: Category | Taxonomy,
    value: Category | Taxonomy,
): boolean => option.href === value.href;

export const sort = <T extends Category | Taxonomy>(a: T, b: T): number => (
    (a as Category).internalName || (a as Taxonomy).taxonomyName
).localeCompare(
    (b as Category).internalName || (b as Taxonomy).taxonomyName,
);

export function findNumberSelectedCategories(children: TaxonomyNode[] | undefined, valueMap: ValueMap | undefined): number {
    return children?.length ? children.reduce((accum, child) => {
        let currCount = accum;

        if (valueMap && valueMap[child._embedded.category.href]
            && valueMap[child._embedded.category.href].source !== DataSource.Unselected) {
            currCount += 1;
        }

        return currCount + findNumberSelectedCategories(child.children, valueMap);
    }, 0) : 0;
}
