import { TableCell, TableRow, Typography } from '@material-ui/core';

import { EmptyState } from 'src/components/common/EmptyState';

export const EmptyStateRow = (): JSX.Element => (
    <TableRow>
        <TableCell colSpan={3}>
            <EmptyState>
                <Typography gutterBottom variant="h6">
                    No Translations found
                </Typography>
                <Typography variant="body2">
                    Start by adding translations
                </Typography>
            </EmptyState>
        </TableCell>
    </TableRow>
);
