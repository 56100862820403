import {
    Card, CardActionArea, CardHeader, Grid, IconButton, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { ContentCopyOutlined, Delete } from '@material-ui/icons';
import clsx from 'clsx';
import { ComponentProps, MouseEvent, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { ConfirmationDialog } from 'src/components/common/ConfirmationDialog';
import { useDeleteTaxonomy } from 'src/components/Taxonomies/hooks/useDeleteTaxonomy';

export interface PropTypes extends Omit<ComponentProps<typeof Card>, 'onClick'> {
    id: string;
    taxonomyName: string;
    intent?: string;
    onClick: (id: string) => void;
    onClone: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    iconArea: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        justifyContent: 'flex-end',
    },
    cloneIcon: {
        color: theme.palette.primary.main,
        '& svg': {
            height: theme.typography.body1.fontSize,
            width: theme.typography.body1.fontSize,
        },
    },
    deleteIcon: {
        color: theme.palette.error.main,
        '& svg': {
            height: theme.typography.body1.fontSize,
            width: theme.typography.body1.fontSize,
        },
    },
    cardActionArea: {
        height: '100%',
    },
    cardHeader: {
        marginTop: theme.spacing(1),
        height: '100%',
        alignItems: 'flex-start',
        maxWidth: 'auto',
        display: 'block',
    },
}));

export const TaxonomyResult = (props: PropTypes): JSX.Element => {
    const {
        id,
        taxonomyName,
        intent,
        className,
        onClick,
        onClone,
        ...rest
    } = props;
    const classes = useStyles();

    const accessToken = useRecoilValue(isAuthorizedQuery);
    const deleteMutation = useDeleteTaxonomy();

    const [modalOpen, SetModalOpen] = useState(false);
    const modalPrompt = `Are you sure you want to delete Taxonomy "${taxonomyName}"?`;

    const onModalClose = (): void => {
        SetModalOpen(false);
    };

    const onModalOpen = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        SetModalOpen(true);
    };

    const handleDelete = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        if (accessToken && id) {
            deleteMutation.mutateAsync({
                accessToken,
                id,
            });
        }

        SetModalOpen(false);
    };

    const handleClone = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        if (onClone) {
            onClone(id);
        }
    };

    const handleShowDrawer = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (onClick) {
            onClick(id);
        }
    };

    return (
        <Card {...rest} className={clsx(classes.card, className)}>
            <CardActionArea className={classes.cardActionArea} disabled={!onClick} onClick={handleShowDrawer}>
                <Grid container className={classes.iconArea} spacing={1}>
                    <Grid item>
                        <IconButton
                            className={classes.cloneIcon}
                            size="small"
                            onClick={handleClone}
                    // Stops the ripple effect in the rest of the card
                            onMouseDown={(event): void => event.stopPropagation()}
                            onTouchStart={(event): void => event.stopPropagation()}
                        >
                            <ContentCopyOutlined />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            className={classes.deleteIcon}
                            size="small"
                            onClick={onModalOpen}
                    // Stops the ripple effect in the rest of the card
                            onMouseDown={(event): void => event.stopPropagation()}
                            onTouchStart={(event): void => event.stopPropagation()}
                        >
                            <Delete />
                        </IconButton>
                    </Grid>
                </Grid>
                <CardHeader
                    classes={{
                        content: classes.cardHeader,
                    }}
                    className={classes.cardHeader}
                    subheader={`ID: ${id}`}
                    subheaderTypographyProps={{
                        variant: 'caption',
                    }}
                    title={(
                        <Typography
                            display="block"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            variant="h6"
                        >
                            {taxonomyName}
                        </Typography>
                      )}
                    titleTypographyProps={{
                        variant: 'h6',
                    }}
                />
            </CardActionArea>
            {modalOpen && (
                <ConfirmationDialog
                    open
                    title={modalPrompt}
                    onAccept={handleDelete}
                    onCancel={onModalClose}
                />
            )}
        </Card>
    );
};
