import { Box } from '@material-ui/core';
import { TabList as MuiTabList } from '@material-ui/lab';
import { ComponentProps } from 'react';

interface PropTypes extends Partial<ComponentProps<typeof MuiTabList>> {
    boxProps?: Partial<ComponentProps<typeof Box>>;
}

export const TabList = (props: PropTypes): JSX.Element => {
    const {
        boxProps = {},
        children,
        ...rest
    } = props;

    return (
        <Box {...boxProps}>
            <MuiTabList
                {...rest}
                aria-label="entity tagging and property tabs"
            >
                {children}
            </MuiTabList>
        </Box>
    );
};
