import { ContentStoreDesignTaggingGuidanceService } from 'src/services/DesignTaggingGuidances';

export const mutateDesignTaggingGuidance = async (
    {
        accessToken,
        id,
        designTaggingGuidance,
        signal,
    }: App.DesignTaggingGuidance.MutationVariables,
): Promise<Models.ContentStoreApi.V3.DesignTaggingGuidance> => ContentStoreDesignTaggingGuidanceService
    .updateDesignTaggingGuidance(
        accessToken,
        id,
        designTaggingGuidance,
        signal,
    );
