import {
    Grid, makeStyles,
} from '@material-ui/core';
import { useState, Suspense } from 'react';

import { TitledAppBar } from 'src/components/common/TitledAppBar';
import { SearchResultsContainer } from 'src/components/common/Search/SearchResultsContainer';
import { FacetedSearch } from 'src/components/common/Search/FacetedSearch';
import { KeywordResults } from 'src/components/Keywords/KeywordResults';
import { ResultsSkeleton } from 'src/components/common/skeletons/ResultsSkeleton';
import { FACETS, KEYWORD_PREFIX_FACET } from 'src/components/Keywords/constants';
import { ErrorBoundary } from 'src/components/common/ErrorBoundary';
import { SEARCH_KEYWORDS_PLACEHOLDER } from 'src/components/common/Search/constants';

const useStyles = makeStyles({
    container: {
        gridArea: 'content',
    },
});

export const KeywordsPage = (): JSX.Element => {
    const classes = useStyles();
    const [search, setSearch] = useState<App.Keywords.Search>({} as App.Keywords.Search);

    const handleSubmit = (facetedSearch: FacetedSearch.FacetedSearchResult): void => {
        const newSearch = {
            keywordName: facetedSearch.keywordName as string,
            prefix: facetedSearch.prefix as string,
            ids: facetedSearch.ids as string[],
            isExcludedFromAltText: facetedSearch.isExcludedFromAltText as string,
        };

        setSearch(newSearch);
    };

    return (
        <>
            <TitledAppBar title="Keywords">
                <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    wrap="nowrap"
                >
                    <Grid item xs={10}>
                        <FacetedSearch
                            defaultFacet={KEYWORD_PREFIX_FACET}
                            facets={FACETS}
                            label="Search Keywords"
                            placeholder={SEARCH_KEYWORDS_PLACEHOLDER}
                            onSubmit={handleSubmit}
                        />
                    </Grid>
                </Grid>
            </TitledAppBar>
            <SearchResultsContainer className={classes.container}>
                <ErrorBoundary>
                    <Suspense fallback={<ResultsSkeleton />}>
                        <KeywordResults search={search} />
                    </Suspense>
                </ErrorBoundary>
            </SearchResultsContainer>
        </>
    );
};
