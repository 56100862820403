import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryEntitiesMetadata, QUERY_KEY } from 'src/queries/queryEntitiesMetadata';

type EntityMetadata = Models.ContentStoreApi.V3.EntityMetadata;

export const useQueryEntitiesMetadata = (
    accessToken: string | undefined | false,
    search: App.Entities.Search,
    options?: UseQueryOptions<EntityMetadata, Error, EntityMetadata, App.Entities.QueryKey>,
): UseQueryResult<EntityMetadata, Error> => useQuery<EntityMetadata, Error, EntityMetadata, App.Entities.QueryKey>(
    [QUERY_KEY, accessToken, search],
    queryEntitiesMetadata,
    options,
);
