import {
    Dialog, Grid, makeStyles, Theme, Typography, useTheme,
} from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';

import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { IMG_DIMENSION_MULTIPLIER } from 'src/constants';
import { useQueryCultures } from 'src/hooks/useQueryCultures';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { MagnifiedImage } from 'src/components/common/MagnifiedImage';
import { withPreviewUrl } from 'src/lib/decorators/withPreviewUrl';

interface PropTypes {
    entity: Models.ContentStoreApi.V3.Entity;
    open: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        '& .MuiPaper-root': {
            padding: theme.spacing(8),
        },
    },
    media: {
        height: theme.spacing(IMG_DIMENSION_MULTIPLIER),
        objectFit: 'contain',
        filter: 'drop-shadow(7px 7px 10px rgba(0,0,0,0.20))',
    },
    previews: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing(8),
        justifyContent: 'center',
    },
    grid: {
        flexDirection: 'column',
    },
    item: {
        padding: theme.spacing(5),
    },
}));

export const LocalizedPreviews = (props: PropTypes): JSX.Element => {
    const {
        entity,
        open,
        handleClose,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const { data: cultures } = useQueryCultures(accessToken);
    const [altText, setAltText] = useState(entity.id);
    const sortedCultures = cultures?.sort((a, b) => a.cultureValue
        .localeCompare(b.cultureValue));
    const previewListByCulture = sortedCultures?.map((culture) => {
        const previewWithCulture = {
            culure: culture,
            previewUrl: withPreviewUrl(entity, culture.cultureValue).previewUrls,
        };

        return previewWithCulture;
    });

    return (
        <Dialog className={classes.modal} open={open} onClose={handleClose}>
            <CSSGrid className={classes.previews}>
                <Grid container className={classes.grid}>
                    {previewListByCulture && previewListByCulture.map((previewWithCulture) => (
                        <Grid item className={classes.item}>
                            <Typography>
                                {previewWithCulture.culure.cultureValue}
                            </Typography>
                            <MagnifiedImage
                                imageProps={{
                                    alt: altText,
                                    className: classes.media,
                                    title: entity.id,
                                    onError: (): void => setAltText('No localization for this culture.'),
                                }}
                                magnifiedImageProps={{
                                    alt: entity.id,
                                    title: entity.id,
                                    height: 960,
                                    width: 960,
                                }}
                                previewUrls={previewWithCulture.previewUrl}
                                style={{
                                    height: theme.spacing(IMG_DIMENSION_MULTIPLIER),
                                    width: '100%',
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </CSSGrid>
        </Dialog>
    );
};
