import { useEffect, useState } from 'react';

type SuccessEffectOptions = {
    timeout?: number;
};

export const useSuccessEffect = (initialState: boolean, options?: SuccessEffectOptions): [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
] => {
    const { timeout = 3000 } = options || {};
    const [successEffect, setSuccessEffect] = useState<boolean>(initialState);

    useEffect(() => {
        let pid: number | null;

        if (successEffect) {
            pid = window.setTimeout(() => {
                setSuccessEffect(false);
            }, timeout);
        }

        return (): void => {
            if (pid) {
                clearTimeout(pid);
            }
        };
    }, [successEffect, timeout]);

    return [successEffect, setSuccessEffect];
};
