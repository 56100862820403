import { Component, ErrorInfo, SyntheticEvent } from 'react';

import { getLogger } from 'src/lib/logger';
import { AppError } from 'src/lib/errors';
import { Alert, Snackbar } from '@material-ui/core';

interface PropTypes {
    children: React.ReactNode;
}

interface State {
    error: Error | AppError | null;
}

const LOGGER = getLogger();

/**
 * Our app relies on handling errors at a central location to ensure consistent
 * reporting and consumption of any error-based states.
 *
 * Any error-based state, such as a non-recoverable network error, validation
 * error, etc., should be thrown from a Component to be caught and handled
 * in the ErrorBoundary.
 */
export class SnackBarErrorBoundary extends Component<PropTypes, State> {
    constructor(props: PropTypes) {
        super(props);

        this.state = {
            error: null,
        };
    }

    static getDerivedStateFromError(error: Error | AppError): State {
        return { error };
    }

    componentDidCatch(thrownError: Error, info: ErrorInfo): void {
        const error = new AppError(thrownError);

        LOGGER.error(error, null, { info });
    }

    handleSnackBarClose(event: SyntheticEvent<unknown>, reason?: string): void {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ error: null });
    }

    render(): JSX.Element {
        const { children } = this.props;
        const { error } = this.state;

        return (
            <>
                {children}
                <Snackbar
                    autoHideDuration={6000}
                    open={!!error}
                    onClose={this.handleSnackBarClose}
                >
                    <Alert
                        severity="error"
                        sx={{
                            width: '100%',
                        }}
                        onClose={this.handleSnackBarClose}
                    >
                        {!!error && (error as unknown as Error | AppError).message}
                    </Alert>
                </Snackbar>
            </>
        );
    }
}
