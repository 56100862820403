import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';
import { ComponentProps } from 'react';

import { ErrorState } from 'src/components/common/ErrorState';

export interface PropTypes extends ComponentProps<typeof Grid> {
    error?: Error | null;
    title?: string;
}

const useStyles = makeStyles({
    container: {
        height: '100%',
    },
});

export const ErrorGrid = (props: PropTypes): JSX.Element => {
    const {
        className,
        error,
        title,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <Grid
            alignContent="center"
            justifyContent="center"
            {...rest}
            container
            className={clsx(classes.container, className)}
        >
            <Grid item>
                <ErrorState
                    message={error ? error.message : 'Unknown error'}
                    title={title}
                />
            </Grid>
        </Grid>
    );
};
