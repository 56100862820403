import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryRankingStrategy, QUERY_KEY } from 'src/queries/queryRankingStrategy';
import { ContentStoreRankingStrategiesService } from 'src/services/RankingStrategies';

type RankingStrategy = Models.ContentStoreApi.V3.RankingStrategy

export const createRankingStrategy = async (
    { accessToken, rankingStrategy }: App.RankingStrategy.CreateMutationVariables,
): Promise<Models.ContentStoreApi.V3.RankingStrategy> => ContentStoreRankingStrategiesService
    .createRankingStrategy(accessToken, rankingStrategy);

export const deleteRankingStrategy = (
    { accessToken, id }: App.RankingStrategy.DeleteMutationVariables,
): Promise<void> => ContentStoreRankingStrategiesService.deleteRankingStrategy(accessToken, id);

export const mutateRankingStrategy = async (
    {
        accessToken,
        id,
        rankingStrategy,
        signal,
    }: App.RankingStrategy.MutationVariables,
): Promise<Models.ContentStoreApi.V3.RankingStrategy> => ContentStoreRankingStrategiesService.updateRankingStrategy(
    accessToken,
    id,
    rankingStrategy,
    signal,
);

export const useQueryRankingStrategy = (
    accessToken: string | undefined | false,
    get: App.RankingStrategy.Get,
    options?: UseQueryOptions<RankingStrategy, Error, RankingStrategy, App.RankingStrategy.SingleQueryKey>,
): UseQueryResult<RankingStrategy, Error> => useQuery<
RankingStrategy, Error, RankingStrategy, App.RankingStrategy.SingleQueryKey>(
    [QUERY_KEY, accessToken, get],
    queryRankingStrategy,
    {
        staleTime: Infinity,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        ...options,
    },
);
