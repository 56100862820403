import { QueryFunctionContext } from 'react-query';

import { DtecDucsService } from 'src/services/Ducs';

export const QUERY_KEY = 'ducs';

export const queryDucs = (
    context: QueryFunctionContext<App.Ducs.QueryKey>,
): Promise<Models.DtecApi.Duc[]> => {
    const { queryKey } = context;
    const [, accessToken] = queryKey;

    return DtecDucsService.getDucs(accessToken);
};
