import {
    Autocomplete,
    Divider,
    experimentalStyled,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import {
    ComponentProps, useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { DetailsDrawer } from 'src/components/common/DetailsDrawer';
import { FormArea } from 'src/components/common/DetailsDrawer/FormArea';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { drawerUpdatedState } from 'src/atoms/drawerUpdatedAtom';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { TaxonomyList } from 'src/components/common/TaxonomyDiscovery/SearchPanels/TaxonomyList';
import { useQueryDucs } from 'src/hooks/useQueryDucs';
import { useCreateDesignTaggingGuidance } from 'src/components/DesignTaggingGuidances/hooks/useCreateDesignTaggingGuidance';

export interface PropTypes extends Omit<ComponentProps<typeof DetailsDrawer>, 'id' | 'onDelete' | 'onSave' | 'middleButton'> {
    id: string;
    changeSelectedId: (id: string) => void;
    closeDrawer: () => void;
    mappedDucs?: Models.ContentStoreApi.V3.DesignTaggingGuidance[];
}
const StyledTableContainer = experimentalStyled(TableContainer)(({ theme }) => ({
    marginTop: theme.spacing(6),
}));

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        marginTop: theme.spacing(6),
    },
    title: {
        display: 'flex',
        paddingRight: theme.spacing(18),
    },
    chipSkeleton: {
        borderRadius: theme.spacing(3),
        height: theme.spacing(12),
        width: theme.spacing(16),
        paddingLeft: theme.spacing(18),
        margin: 0,

    },
    tabPanel: {
        paddingTop: 0,
    },
}));

export const DesignTaggingGuidanceAddDrawer = (props: PropTypes): JSX.Element => {
    const {
        changeSelectedId,
        closeDrawer,
        id,
        open,
        onClose,
        mappedDucs,
        ...rest
    } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const createMutation = useCreateDesignTaggingGuidance();

    const [taxonomyIds, setTaxonomyIds] = useState<string[]>([]);
    const [selectedDucId, setSelectedDucId] = useState('');

    const setUpdatedState = useSetRecoilState(drawerUpdatedState);

    const {
        data: ducData,
    } = useQueryDucs(accessToken, {
        enabled: !!accessToken,
        suspense: false,
        refetchInterval: false,
    });

    const filteredDucData = ducData?.filter((d) => d.id);

    const handleSave = async (): Promise<void> => {
        if (selectedDucId) {
            const newDTG: Models.ContentStoreApi.V3.CreateDesignTaggingGuidance = {
                ducId: selectedDucId,
                taxonomyIds,

            };

            await createMutation.mutateAsync({ accessToken, designTaggingGuidance: newDTG });
            closeDrawer();

            setUpdatedState(false);
        }
    };

    const handleTaxonomyClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        if (event.target.checked) {
            if (!taxonomyIds.includes(event.target.value)) {
                setTaxonomyIds(taxonomyIds.concat(event.target.value));
            }
        } else {
            setTaxonomyIds(taxonomyIds.filter((t) => t !== event.target.value));
        }
    };

    function ducDisplayName(duc: Models.DtecApi.Duc): string {
        return `${duc.name} (${duc.id})`;
    }

    return (
        <DetailsDrawer
            open={open}
            onClose={onClose}
            onSave={handleSave}
            {...rest}
        >
            <FormArea title={(
                <CSSGrid className={classes.title}>
                    <Grid container>
                        <Grid container item xs={6}>
                            <Typography paragraph margin={0} variant="h6">
                                Add New Design Tagging Guidance
                            </Typography>
                        </Grid>
                    </Grid>
                </CSSGrid>
            )}
            >
                {filteredDucData && (
                    <Autocomplete
                        getOptionDisabled={(option): boolean => !!(mappedDucs?.find((m) => m.ducId === option.id))}
                        getOptionLabel={(option): string => ducDisplayName(option)}
                        id="duc-select"
                        options={filteredDucData.sort((a, b) => (a.name || '').localeCompare(b.name || ''))}
                        renderInput={(params): JSX.Element => (
                            <TextField {...params} label="DUC" variant="outlined" />)}
                        onChange={(_event, newDuc): void => {
                            setSelectedDucId(newDuc?.id || '');
                        }}
                    />
                )}
            </FormArea>
            <Divider className={classes.divider} />
            <StyledTableContainer>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" component="th">
                                Select Taxonomies
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TaxonomyList handleCheck={handleTaxonomyClick} selectedTaxonomyIds={taxonomyIds} />
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </DetailsDrawer>
    );
};
