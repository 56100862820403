import {
    Card,
    CardHeader,
    makeStyles,
    IconButton,
    CardActionArea,
    Theme,
    Grid,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import clsx from 'clsx';
import {
    ComponentProps,
    MouseEvent,
    useState,
} from 'react';
import { useRecoilValue } from 'recoil';

import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { ConfirmationDialog } from 'src/components/common/ConfirmationDialog';
import { UseMutationResult } from 'react-query';
import CardContent from '@mui/material/CardContent';
import { CSSGrid } from 'src/components/common/CSSGrid';

export interface PropTypes<T extends (string | number)> extends Omit<ComponentProps<typeof Card>, 'onClick' | 'id'> {
    id: T;
    title: string;
    itemName: string;
    onClick?: (id: T) => void;
    deleteMutation?: UseMutationResult<void, Error, App.Result.DeleteMutationVariables<T>>;
    CustomConfirmation?: (props: App.CustomConfirmation.PropTypes) => JSX.Element;
    customConfirmationProps?: Omit<App.CustomConfirmation.PropTypes, 'handleClose' | 'open'>;
    allowDefault?: boolean;
    headerRenderedComponent?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardActionArea: {
        height: '100%',
    },
    cardGrid: {
        height: '100%',
    },
    actions: {
        display: 'grid',
        gridTemplateAreas: '". ."',
        justifyItems: 'center',
    },
    title: {
        marginTop: theme.spacing(1),
        alignItems: 'flex-start',
        maxWidth: 'auto',
        display: 'block',
    },
    delete: {
        display: 'grid',
        justifyContent: 'flex-end',
        gridTemplateAreas: '". ."',
    },
    deleteIconRow: {
        position: 'absolute',

        justifyContent: 'flex-end',
    },
    deleteIcon: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        justifyContent: 'flex-end',
        color: theme.palette.error.main,
        '& svg': {
            height: theme.typography.body1.fontSize,
            width: theme.typography.body1.fontSize,
        },
    },
}));

export const ResultCard = <T extends (string | number)>(props: PropTypes<T>): JSX.Element => {
    const {
        allowDefault,
        className,
        id,
        title,
        itemName,
        onClick,
        deleteMutation,
        CustomConfirmation,
        customConfirmationProps,
        children,
        headerRenderedComponent,
        ...rest
    } = props;
    const classes = useStyles();
    const [modalOpen, SetModalOpen] = useState(false);
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const modalPrompt = `Are you sure you want to delete ${itemName} "${title}"?`;

    const onModalClose = (): void => {
        SetModalOpen(false);
    };

    const onModalOpen = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        SetModalOpen(true);
    };

    const handleDelete = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        if (accessToken && id && deleteMutation) {
            await deleteMutation.mutateAsync({
                accessToken,
                id,
            });
        }

        SetModalOpen(false);
    };

    const handleShowDrawer = (event: MouseEvent<HTMLButtonElement>): void => {
        if (!allowDefault) {
            event.preventDefault();
        }

        if (onClick) {
            onClick(id);
        }
    };

    return (
        <Card {...rest} className={clsx(classes.card, className)}>
            <CardActionArea className={classes.cardActionArea} disabled={!onModalOpen} onClick={handleShowDrawer}>
                <CSSGrid className={classes.cardGrid}>
                    {deleteMutation && (
                        <Grid container className={classes.deleteIconRow} spacing={1}>
                            <Grid item>
                                <IconButton
                                    className={classes.deleteIcon}
                                    size="small"
                                    onClick={onModalOpen}
                            // Stops the ripple effect in the rest of the card
                                    onMouseDown={(event): void => event.stopPropagation()}
                                    onTouchStart={(event): void => event.stopPropagation()}
                                >
                                    <Delete />
                                </IconButton>

                            </Grid>
                        </Grid>
                    )}
                    {headerRenderedComponent}
                    <CardHeader
                        className={classes.title}
                        subheader={`ID: ${id}`}
                        subheaderTypographyProps={{
                            variant: 'caption',
                        }}
                        title={title}
                        titleTypographyProps={{
                            variant: 'h6',
                            display: 'block',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    />
                    <CardContent>
                        {children}
                    </CardContent>
                </CSSGrid>
            </CardActionArea>

            {modalOpen && !CustomConfirmation && (
                <ConfirmationDialog
                    open
                    title={modalPrompt}
                    onAccept={handleDelete}
                    onCancel={onModalClose}
                />
            )}
            {modalOpen && !!CustomConfirmation && !!customConfirmationProps && (
                <CustomConfirmation {...customConfirmationProps} handleClose={onModalClose} open={modalOpen} />
            )}
        </Card>
    );
};
