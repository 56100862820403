import { Drawer } from '@material-ui/core';
import { ComponentProps, MouseEvent } from 'react';

import { ConfirmationDialog } from 'src/components/common/ConfirmationDialog';

export interface PropTypes extends ComponentProps<typeof Drawer> {
    confirmationDialogOpened: boolean;
    onAccept: (event: MouseEvent<HTMLButtonElement>) => void;
    onModalClose: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const Footer = (props: PropTypes): JSX.Element => {
    const {
        confirmationDialogOpened,
        onAccept,
        onModalClose,
        children,
    } = props;
    const modalTitle = 'You have unsaved changes.';
    const modalDescription = 'Would you like to save before closing?';

    return (
        <>
            {children}
            <ConfirmationDialog
                description={modalDescription}
                open={confirmationDialogOpened}
                title={modalTitle}
                onAccept={onAccept}
                onCancel={onModalClose}
            />
        </>
    );
};
