import {
    useEffect, useMemo, useState,
} from 'react';

import { findIntersection } from 'src/components/Entity/EntityDetailsDrawer/utils/findIntersection';
import { saveable } from 'src/components/Entity/EntityDetailsDrawer/utils/saveable';
import { union } from 'src/utils/union';
import { toSourcedBase } from 'src/utils/toSourcedBase';
import { filterSuggested } from 'src/components/Entity/EntityDetailsDrawer/utils/filterSuggested';

type Entity = Models.ContentStoreApi.V3.Entity;
type EntityToModelMapper<Model> = (entity: Entity) => Model[];
type SetSharedModels<Model> = (models: Model[]) => void;
type GenerateSaveableList = (entity: Entity) => Models.V3.SourcedBase[];
type InitializeModels = (data: Entity[]) => void;
type HookReturnValue<Model> = [
    Model[],
    SetSharedModels<Model>,
    GenerateSaveableList,
    InitializeModels,
    Model[],
];

export const useBulkSourcedModels = <Model extends Models.V3.SourcedBase>(
    entities: Entity[] | false | undefined,
    entityToModelMapper: EntityToModelMapper<Model>,
    suggestions?: Model[] | false,
): HookReturnValue<Model> => {
    const [sharedModels, setSharedModels] = useState<Model[]>([]);

    useEffect(() => {
        if (entities) {
            const allModels = entities.map(entityToModelMapper);
            const newSharedModels = findIntersection<Model>(allModels);

            setSharedModels(newSharedModels);
        }
    }, [entities, entityToModelMapper]);

    const generateSaveableList: GenerateSaveableList = (
        entity: Entity,
    ): Models.V3.SourcedBase[] => (
        union(entityToModelMapper(entity), sharedModels).filter(saveable).map((x) => toSourcedBase(x))
    );

    const initializeSharedModels = (data: Entity[]): void => {
        const allModels = data.map(entityToModelMapper);
        const newSharedModels = findIntersection<Model>(allModels);

        setSharedModels(newSharedModels);
    };

    const setSharedModelsProxy = (models: Model[]): void => {
        setSharedModels(models.filter(filterSuggested));
    };

    const sharedModelsWithSuggestions = useMemo(
        () => (suggestions ? union(sharedModels, suggestions) : sharedModels),
        [sharedModels, suggestions],
    );

    return [
        sharedModelsWithSuggestions,
        setSharedModelsProxy,
        generateSaveableList,
        initializeSharedModels,
        sharedModels,
    ];
};
