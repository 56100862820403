import { IconButton, Tooltip } from '@material-ui/core';
import {
    ComponentProps, MouseEvent,
} from 'react';
import { Launch } from '@material-ui/icons';

export interface PropTypes extends Omit<ComponentProps<typeof IconButton>, 'onClick'> {
    categoryId: string;
}

export const OpenCategoryButton = (props: PropTypes): JSX.Element => {
    const {
        categoryId,
        ...rest
    } = props;

    const onClick = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        window.open(`${window.location.origin}/categories?ids=${categoryId}`, '_blank');
    };

    return (
        <Tooltip title={categoryId}>
            <IconButton onClick={onClick} {...rest}>
                <Launch />
            </IconButton>
        </Tooltip>
    );
};
