import clsx from 'clsx';
import {
    Grid, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { ReactNode } from 'react';

import { GhostSvg } from 'src/components/common/icons/GhostSvg';

const useStyles = makeStyles((theme: Theme) => ({
    ghost: {
        height: theme.spacing(20),
    },
    wrapper: {
        paddingTop: theme.spacing(8),
    },
}));

interface EmptyStateProps {
    className?: string,
    children: ReactNode;
}

export const EmptyState = (props: EmptyStateProps): JSX.Element => {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <Grid
            container
            alignItems="center"
            className={clsx(classes.wrapper, className)}
            direction="column"
            justifyContent="center"
            spacing={2}
        >
            <Grid item>
                <GhostSvg className={classes.ghost} />
            </Grid>
            <Grid item>
                <Typography>
                    {children}
                </Typography>
            </Grid>
        </Grid>
    );
};
