import {
    experimentalStyled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {
    Suspense,
} from 'react';

import { AddTranslationToolbarSkeleton } from 'src/components/common/DetailsDrawer/AddTranslationToolbarSkeleton';
import { AddTranslationToolbar } from 'src/components/common/DetailsDrawer/AddTranslationToolbar';
import { EmptyStateRow } from 'src/components/common/DetailsDrawer/EmptyStateRow';
import { TranslationRow } from 'src/components/common/DetailsDrawer/TranslationRow';
import { ErrorRow } from 'src/components/common/ErrorState/ErrorRow';
import { LoadingRow } from 'src/components/common/Loader/LoadingRow';

export interface PropTypes {
    translations: Models.ContentStoreApi.V3.Translations;
    locales: string[];
    handleTranslationChange: (locale: string, translation: string) => void;
    handleTranslationDelete: (locale: string) => Promise<void>;
    isLoading: boolean,
    isError: boolean,
    error: Error | null,
}

const StyledTableContainer = experimentalStyled(TableContainer)(({ theme }) => ({
    marginTop: theme.spacing(6),
}));

export const CategoryTranslationsTab = (props: PropTypes): JSX.Element => {
    const {
        translations,
        locales,
        isLoading,
        isError,
        error,
        handleTranslationChange,
        handleTranslationDelete,
    } = props;

    return (
        <>
            <Suspense fallback={<AddTranslationToolbarSkeleton />}>
                <AddTranslationToolbar blackList={locales} onSubmit={handleTranslationChange} />
            </Suspense>
            <StyledTableContainer>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" component="th">
                                Locale
                            </TableCell>
                            <TableCell align="left" component="th">
                                Translation
                            </TableCell>
                            <TableCell align="right" component="th" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (<LoadingRow />)}
                        {isError && (
                            <ErrorRow
                                error={error}
                                title="Failed to get category details"
                            />
                        )}
                        {(!isLoading && !locales.length) && (<EmptyStateRow />)}
                        {(!isLoading && !!locales.length) && locales.map((locale) => (
                            <TranslationRow
                                key={locale}
                                locale={locale}
                                translation={translations[locale]}
                                onChange={handleTranslationChange}
                                onDelete={handleTranslationDelete}
                            />
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </>
    );
};
