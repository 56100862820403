import { QueryFunctionContext } from 'react-query';

import { ContentStoreEntitiesService } from 'src/services/Entities';
import { prepareEntityForEditing } from 'src/utils/prepareEntityForEditting';

export const QUERY_KEY = 'entity';

export const queryEntity = async (
    context: QueryFunctionContext<App.Entity.QueryKey>,
): Promise<Models.ContentStoreApi.V3.Entity> => {
    const [, accessToken, id] = context.queryKey;

    return prepareEntityForEditing(await ContentStoreEntitiesService.getEntity(accessToken as string, id as string));
};
