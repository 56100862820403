import { makeStyles, Theme, Typography } from '@material-ui/core';
import { ComponentProps, ReactNode } from 'react';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { FormArea } from 'src/components/common/DetailsDrawer/FormArea';

export interface PropTypes extends Omit<ComponentProps<typeof FormArea>, 'title'> {
    actions?: ReactNode;
    title?: ReactNode;
    children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        display: 'inline-flex',
        alignSelf: 'center',
    },
    imageAndTextColumnGrid: {
        gap: theme.spacing(8),
        gridTemplateColumns: 'minmax(auto, max-content) minmax(50%, auto)',
        paddingRight: theme.spacing(12),
        paddingBottom: theme.spacing(4),
    },
    titleAndActionsGrid: {
        gap: theme.spacing(4),
        gridTemplateColumns: '2fr 1fr 1fr',
    },
    titleRowAndPropertiesGrid: {
        gap: theme.spacing(4),
        gridTemplateRows: 'auto  1fr',
    },
    thumbnailBox: {
        width: theme.spacing(62),
        height: theme.spacing(62),
        display: 'flex',
        justifyContent: 'center',
    },
}));

export const HeaderTextColumn = (props: PropTypes): JSX.Element => {
    const {
        actions,
        children,
        title,
    } = props;
    const classes = useStyles();

    return (
        <CSSGrid className={classes.titleRowAndPropertiesGrid}>
            <CSSGrid className={classes.titleAndActionsGrid}>
                <Typography className={classes.title} fontWeight="medium" variant="body1">
                    {title}
                </Typography>
                {actions}
            </CSSGrid>
            {children}
        </CSSGrid>

    );
};
