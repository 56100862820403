import {
    InfiniteData,
    QueryFunction,
    QueryKey,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
} from 'react-query';
import { useRecoilValue } from 'recoil';

import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';

type PageResult<T> = Models.V3.PageResult<T>;

export type UseInfiniteScrollQueryResult<TData, TError> = Omit<UseInfiniteQueryResult<unknown, TError>, 'data'> & {
    data?: TData[];
    pages?: InfiniteData<PageResult<TData>>,
};

export const useInfiniteScrollQuery = <TData, TError, TQueryKey extends QueryKey>(
    queryKey: TQueryKey,
    queryFn: QueryFunction<PageResult<TData>, TQueryKey>,
    options?: UseInfiniteQueryOptions<PageResult<TData>, TError, PageResult<TData>, PageResult<TData>, TQueryKey>,
): UseInfiniteScrollQueryResult<TData, TError> => {
    const accessToken = useRecoilValue(isAuthorizedQuery);

    const { data, ...rest } = useInfiniteQuery<PageResult<TData>, TError, PageResult<TData>, TQueryKey>(
        queryKey,
        queryFn,
        {
            enabled: !!accessToken,
            getNextPageParam: (lastPage: PageResult<TData>) => {
                const offset = lastPage.offset + lastPage.limit;

                return (offset < lastPage.total) ? offset : undefined;
            },
            select: (response: InfiniteData<PageResult<TData>>): InfiniteData<PageResult<TData>> => {
                if ('results' in response) {
                    (response as InfiniteData<PageResult<TData>>).pages = [{
                        // @ts-ignore
                        results: response.results,
                    } as PageResult<TData>];
                }

                return response;
            },
            ...options,
        },
    );

    return {
        data: data?.pages.reduce(
            (accum, page) => (accum.concat(page.results)),
            [] as TData[],
        ),
        pages: data,
        ...rest,
    };
};
