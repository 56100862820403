import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { deleteCategory } from 'src/mutations/deleteCategory';
import { QUERY_KEY } from 'src/queries/queryCategory';

export const useDeleteCategory = (): UseMutationResult<void, Error, App.Category.DeleteMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, App.Category.DeleteMutationVariables>(
        deleteCategory,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            },
            useErrorBoundary: true,
        },
    );
};
