import {
    Card,
    CardContent,
    CardActions,
    CardActionArea,
    Checkbox,
    makeStyles,
    Theme,
    Typography,
    Grid,
    alpha,
    useTheme,
    CardHeader,
    Tooltip,
    Button,
} from '@material-ui/core';
import { memo, MouseEvent, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
    Edit, FilterAlt, Launch,
} from '@material-ui/icons';
import clsx from 'clsx';

import { KeywordsList } from 'src/components/Entity/EntityResult/KeywordsList';
import { IMG_DIMENSION_MULTIPLIER, UserEvent } from 'src/constants';
import { MagnifiedImage } from 'src/components/common/MagnifiedImage';
import { SingleEntityDetailsDrawer } from 'src/components/Entity/EntityDetailsDrawer';
import { selectedIdsState } from 'src/atoms/selectedIdsAtom';
import { CopyButton } from 'src/components/common/buttons/CopyButton';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { ApprovalBanner } from 'src/components/Entity/EntityResult/ApprovalBanner';
import { LocalizedPreviews } from 'src/components/common/LocalizedPreview';
import { useTrackEvent } from 'src/hooks/useTrackEvent';

export interface PropTypes {
    entity: Models.ContentStoreApi.V3.Entity;
    keywords?: Models.ContentStoreApi.V3.Entity.Embedded.Keyword[];
    tags?: Models.ContentStoreApi.V3.Entity.Embedded.Tag[];
    onClick: (id: string, reason: 'drawer' | 'designConceptId') => void;
    handleAllSelectedCheck: (newArrLength: number) => void;
    culturesCount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    idTitle: {
        whiteSpace: 'nowrap',
    },
    media: {
        height: theme.spacing(IMG_DIMENSION_MULTIPLIER),
        objectFit: 'contain',
        filter: 'drop-shadow(7px 7px 10px rgba(0,0,0,0.20))',
    },
    mediaContainer: {
        paddingBottom: theme.spacing(4),
    },
    cardActionArea: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    cardActions: {
        justifyContent: 'center',
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(4),
        width: '100%',
    },
    cardContent: {
        flexGrow: 1,
    },
    cardHeader: {
        paddingBottom: 0,
        paddingTop: theme.spacing(1),
    },
    editButton: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    editAllButton: {
        width: 'max-content',
        float: 'right',
        justifySelf: 'end',
    },
    keywordContainer: {
        height: '100%',
        marginBottom: theme.spacing(11),
    },
    hoveredButton: {
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.light, 0.1),
    },
    copy: {
        marginLeft: theme.spacing(2),
    },
    copyBox: {
        display: 'grid',
        gridTemplateColumns: 'auto min-content',
        flexDirection: 'column',
        justifyContent: 'left',
    },
    grid: {
        gridTemplateColumns: 'auto 1fr',
        '& > *:last-child': {
            gridColumn: 'span 2',
        },
        width: '100%',
    },
    buttonTitle: {
        whiteSpace: 'nowrap',
    },
}));

const EntityResultComponent = (props: PropTypes): JSX.Element => {
    const {
        entity,
        keywords,
        tags,
        culturesCount,
        onClick,
        handleAllSelectedCheck,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [showDrawer, setShowDrawer] = useState(false);
    const [editHover, setEditHover] = useState(false);
    const [selectedIds, setSelectedIds] = useRecoilState(selectedIdsState);
    const [openModal, setOpenModal] = useState(false);
    const { trackEvent } = useTrackEvent();

    const isChecked = selectedIds.indexOf(entity.id) > -1;

    const handleFilterDesignConcept = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        trackEvent({ eventName: UserEvent.DesignConceptFilterClick, eventValue: entity.designConceptId });

        if (entity.designConceptId) {
            if (event.metaKey || event.ctrlKey) {
                window.open(`${window.location.origin}/designs?designConceptId=${entity.designConceptId}`, '_blank');
            } else {
                onClick(entity.designConceptId, 'designConceptId');
            }
        }
    };

    const handleCloseDrawer = (event: MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();

        setShowDrawer(false);
    };

    const handleShowDrawer = (event: MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        setShowDrawer(true);
    };

    const handleEditButton = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        setShowDrawer(true);
    };

    const handleChange = (): void => {
        // Remove the design Id from the state on de-select
        const newSelectedIds = isChecked
            ? selectedIds.filter((x) => x !== entity.id)
            : [...selectedIds, entity.id];

        setSelectedIds(newSelectedIds);
        handleAllSelectedCheck(newSelectedIds.length);
    };

    const handlePreviewButton = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        setOpenModal(true);
    };

    return (
        <>
            <Card className={classes.root}>
                <ApprovalBanner
                    active={!!entity.isActive}
                    approved={!!entity.indexable}
                    culturesCount={culturesCount}
                />
                <CardHeader
                    action={(
                        <Checkbox
                            checked={isChecked}
                            color="primary"
                            onChange={handleChange}
                        />
                    )}
                    className={classes.cardHeader}
                    title={(
                        <div className={classes.copyBox}>
                            <Typography
                                className={classes.idTitle}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                variant="caption"
                            >
                                {`ID: ${entity.id}`}
                            </Typography>
                            <CopyButton className={classes.copy} value={entity.id} />
                        </div>
                    )}
                />
                <CardActionArea
                    className={classes.cardActionArea}
                    component="div"
                    onClick={handleShowDrawer}
                    onMouseOut={(): void => setEditHover(false)}
                    onMouseOver={(): void => setEditHover(true)}
                >
                    <CardContent className={classes.cardContent}>
                        <Grid
                            container
                            alignItems="center"
                            direction="column"
                            justifyContent="flex-start"
                            spacing={2}
                        >
                            <Grid item className={classes.mediaContainer}>
                                {/* TODO need a fallback image on error */}
                                <MagnifiedImage
                                    imageProps={{
                                        alt: entity.id,
                                        className: classes.media,
                                        title: entity.id,
                                    }}
                                    magnifiedImageProps={{
                                        alt: entity.id,
                                        title: entity.id,
                                        height: 960,
                                        width: 960,
                                    }}
                                    previewUrls={entity.previewUrls}
                                    style={{
                                        height: theme.spacing(IMG_DIMENSION_MULTIPLIER),
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <KeywordsList keywords={keywords || []} tags={tags || []} />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <CSSGrid className={classes.grid} gap={2}>
                            <Tooltip title="Filter by Design Concept">
                                <Button
                                    className={classes.editButton}
                                    color="secondary"
                                    size="small"
                                    variant="outlined"
                                    onClick={handleFilterDesignConcept}
                                    onMouseDown={(event): void => event.stopPropagation()}
                                    onTouchStart={(event): void => event.stopPropagation()}
                                >
                                    <FilterAlt />
                                    <Typography
                                        className={classes.buttonTitle}
                                        variant="button"
                                    >
                                        Design Concept
                                    </Typography>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Edit Design">
                                <Button
                                    className={clsx(classes.editButton, { [classes.hoveredButton]: editHover })}
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    onClick={handleEditButton}
                                    onMouseDown={(event): void => event.stopPropagation()}
                                    onTouchStart={(event): void => event.stopPropagation()}
                                >
                                    <Edit />
                                    <Typography
                                        className={classes.buttonTitle}
                                        variant="button"
                                    >
                                        Edit
                                    </Typography>
                                </Button>
                            </Tooltip>
                            <Tooltip title="View Localized Previews">
                                <Button
                                    className={classes.editButton}
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    onClick={handlePreviewButton}
                                    onMouseDown={(event): void => event.stopPropagation()}
                                    onTouchStart={(event): void => event.stopPropagation()}
                                >
                                    <Launch />
                                    <Typography
                                        className={classes.buttonTitle}
                                        variant="button"
                                    >
                                        Localized Previews
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </CSSGrid>

                    </CardActions>
                </CardActionArea>
            </Card>
            {showDrawer && (
                <SingleEntityDetailsDrawer
                    open
                    id={entity.id}
                    onClose={handleCloseDrawer}
                />
            )}
            {openModal && (
                <LocalizedPreviews
                    entity={entity}
                    handleClose={(): void => setOpenModal(false)}
                    open={openModal}
                />
            )}
        </>
    );
};

export const EntityResult = memo(EntityResultComponent);
