import { QueryFunctionContext } from 'react-query';

import { ContentStoreTagsService } from 'src/services/Tags';
import { DEFAULT_LIMIT } from 'src/constants';

export const QUERY_KEY = 'tags';

export const queryTags = (
    context: QueryFunctionContext<App.Tags.QueryKey, App.Tags.PageParam>,
): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.SourcedTag>> => {
    const { pageParam: offset, queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentStoreTagsService.getTags(
        accessToken,
        search.ids,
        search.label,
        offset,
        DEFAULT_LIMIT,
    );
};
