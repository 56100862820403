import update from 'immutability-helper';
import { XYCoord } from 'react-dnd';

import { DraggableCommand } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableCommand';

type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;

export interface NodeWithPosition {
    node: TaxonomyNode;
    index: number;
    hierarchy: string[];
}

export const getCurrentCommandByPosition = (
    currentRef: HTMLDivElement,
    clientOffset: XYCoord,
    currentCommand: DraggableCommand | undefined,
): DraggableCommand | undefined => {
    const { height, top } = currentRef.getBoundingClientRect();

    const topRange: number = height * 0.3;
    const bottomRange: number = height - topRange;

    const hoverClientY = (clientOffset as XYCoord).y - top;

    let draggableCommand: DraggableCommand | undefined;

    if (hoverClientY > height * -0.8 && currentCommand === DraggableCommand.Above) {
        draggableCommand = DraggableCommand.Replace;
    } else if (hoverClientY <= topRange) {
        draggableCommand = DraggableCommand.Above;
    } else if (hoverClientY >= bottomRange && hoverClientY <= height * 1.3) {
        draggableCommand = DraggableCommand.Below;
    } else if (hoverClientY < bottomRange && hoverClientY > topRange) {
        draggableCommand = DraggableCommand.Replace;
    } else {
        draggableCommand = undefined;
    }

    return draggableCommand;
};

export const upsertElement = (
    nNodes: TaxonomyNode[],
    atIndex: number,
    nNode: TaxonomyNode,
    nHierarchy: string[],
): TaxonomyNode[] => {
    if (nHierarchy.length === 0) {
        return update(nNodes, { $splice: [[atIndex, 0, nNode]] });
    }

    const newNodes = [...nNodes];

    const newHierarchy = [...nHierarchy];
    const parentId = newHierarchy.shift();

    if (!parentId) {
        return newNodes;
    }

    const parent = newNodes.find((c) => c.id === parentId);

    if (parent && parent.children) {
        parent.children = upsertElement(parent.children, atIndex, nNode, newHierarchy);
    }

    return newNodes;
};

export const deleteElement = (
    index: number,
    nNodes: TaxonomyNode[],
    nHierarchy: string[],
): TaxonomyNode[] => {
    if (nHierarchy.length === 0) {
        return update(nNodes, {
            $splice: [[index, 1]],
        });
    }

    const newNodes = [...nNodes];

    const newHierarchy = [...nHierarchy];
    const parentId = newHierarchy.shift();

    if (!parentId) {
        return newNodes;
    }

    const parent = newNodes.find((c) => c.id === parentId);

    if (parent && parent.children) {
        parent.children = deleteElement(index, parent.children, newHierarchy);
    }

    return newNodes;
};

export const findNodeAndPosition = (
    nId: string,
    nNodes: TaxonomyNode[],
    hierarchy: string[],
): NodeWithPosition | undefined => {
    for (let i = 0; i < nNodes.length; i++) {
        const node = nNodes[i];

        if (node.id === nId) {
            return { node, index: i, hierarchy };
        }

        if (node.children) {
            const newHierarchy = [...hierarchy, node.id];
            const nodeAndPosition = findNodeAndPosition(nId, node.children, newHierarchy);

            if (nodeAndPosition) {
                return nodeAndPosition;
            }
        }
    }

    return undefined;
};
