import clsx from 'clsx';
import {
    makeStyles, SvgIcon, Theme, Tooltip,
} from '@material-ui/core';
import { Check, Save } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import {
    ComponentProps, MouseEvent, useState,
} from 'react';

import { useSuccessEffect } from 'src/hooks/useSuccessEffect';

export interface PropTypes extends Omit<ComponentProps<typeof LoadingButton>, 'pending' | 'onClick'> {
    onClick?: () => Promise<void>;
    Icon?: typeof SvgIcon;
    iconProps?: ComponentProps<typeof SvgIcon>;
    tooltipText?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    iconButton: {
        minWidth: 'auto',
        borderRadius: '50%',
        padding: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '& .MuiButton-startIcon': {
            margin: 0,
        },
    },
    success: {
        color: theme.palette.success.main,
    },
}));

export const SaveLoadingButton = (props: PropTypes): JSX.Element => {
    const {
        children,
        className,
        Icon = Save,
        iconProps = {},
        onClick,
        tooltipText,
        ...rest
    } = props;
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [successEffect, setSuccessEffect] = useSuccessEffect(false);

    const handleClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        if (!onClick) {
            return;
        }

        try {
            setIsLoading(true);
            await onClick();
            setSuccessEffect(true);
        } finally {
            setIsLoading(false);
        }
    };

    const toolTipTitle = tooltipText || 'save changes';

    return (
        <Tooltip title={toolTipTitle}>
            <span>
                <LoadingButton
                    className={clsx(className, { [classes.iconButton]: !children })}
                    loading={isLoading}
                    {...rest}
                    aria-label="save"
                    startIcon={successEffect
                        ? <Check className={classes.success} {...iconProps} />
                        : <Icon {...iconProps} />}
                    onClick={handleClick}
                >
                    {children}
                </LoadingButton>
            </span>
        </Tooltip>
    );
};
