import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { createKeyword } from 'src/mutations/createKeyword';
import { QUERY_KEY } from 'src/queries/queryKeyword';

export const useCreateKeyword = (): UseMutationResult<Models.ContentStoreApi.V3.Keyword, Error, App.Keyword.CreateMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Models.ContentStoreApi.V3.Keyword, Error, App.Keyword.CreateMutationVariables>(
        createKeyword,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            },
            useErrorBoundary: true,
        },
    );
};
