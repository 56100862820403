import { Card } from '@material-ui/core';
import { ComponentProps } from 'react';
import { useRecoilValue } from 'recoil';

import { ResultCard } from 'src/components/common/ResultCard';
import { useDeleteCategory } from 'src/components/Categories/hooks/useDeleteCategory';
import { MergeDialog } from 'src/components/Categories/CategoryDetailsDrawer/MergeDialog';
import { useMergeCategory } from 'src/components/Categories/hooks/useMergeCategory';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';

export interface PropTypes extends Omit<ComponentProps<typeof Card>, 'onClick'> {
    id: string;
    internalName: string;
    onClick: (id: string) => void;
}

export const CategoryResult = (props: PropTypes): JSX.Element => {
    const {
        id,
        internalName,
        ...rest
    } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;

    const deleteMutation = useDeleteCategory();
    const mergeMutation = useMergeCategory();

    const handleConfirm = async (replacementId: string): Promise<void> => {
        await mergeMutation.mutateAsync({
            accessToken,
            replacementId,
            id,
        });
    };

    const handleDelete = async (): Promise<void> => {
        await deleteMutation.mutateAsync({ accessToken, id });
    };

    const customConfirmation = MergeDialog;
    const customConfirmationProps = {
        handleConfirm,
        handleDelete,
    };

    return (
        <ResultCard
            {...rest}
            CustomConfirmation={customConfirmation}
            customConfirmationProps={customConfirmationProps}
            deleteMutation={deleteMutation}
            id={id}
            itemName="Category"
            title={internalName}
        />
    );
};
