import { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import {
    CssBaseline,
    ThemeProvider,
    StyledEngineProvider,
} from '@material-ui/core';
import { dttVisageInternalTheme } from '@vp/themes/packages/material-ui@v5';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { broadcastQueryClient } from 'react-query/broadcastQueryClient-experimental';

import { Head } from 'src/components/Head';
import { Routes } from 'src/components/Routes';
import { Splash } from 'src/components/common/Splash';
import { ErrorBoundary } from 'src/components/common/ErrorBoundary';
import { config } from 'src/lib/config';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
        },
    },
});

broadcastQueryClient({
    queryClient,
    broadcastChannel: `gallery-tagging-tool-${process.env.DEPLOYMENT}`,
});

export const App = (): JSX.Element => (
    <RecoilRoot>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={dttVisageInternalTheme}>
                <StyledEngineProvider injectFirst>
                    <Head />
                    <CssBaseline />
                    <ErrorBoundary>
                        <Suspense fallback={<Splash />}>
                            <BrowserRouter basename={config.basename}>
                                <Routes />
                            </BrowserRouter>
                        </Suspense>
                    </ErrorBoundary>
                </StyledEngineProvider>
            </ThemeProvider>
            <ReactQueryDevtools />
        </QueryClientProvider>
    </RecoilRoot>
);
