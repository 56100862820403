import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

class ContentStoreProductsApi extends AbstractService implements Services.ContentStoreApi.Products {
    public async getProducts(
        bearerToken: string | undefined | false,
        productKey?: string,
        productVersion?: string,
        productName?: string,
        ids?: string[],
        active?: boolean,
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.Product>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getProducts. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/products`;
        const searchParams = qs.stringify({
            productKey,
            productVersion,
            productName,
            ids,
            active,
            offset,
            limit,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.Product>>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to get products',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }
}

export const ContentStoreProductsService = new ContentStoreProductsApi(config.services.contentStoreApi);
