import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryCategory, QUERY_KEY } from 'src/queries/queryCategory';

type Category = Models.ContentStoreApi.V3.Category;

export const useQueryCategory = (
    accessToken: string | undefined | false,
    id: string,
    options?: UseQueryOptions<Category, Error, Category, App.Category.QueryKey>,
): UseQueryResult<Category, Error> => useQuery<Category, Error, Category, App.Category.QueryKey>(
    [QUERY_KEY, accessToken, id],
    queryCategory,
    options,
);
