import { useEffect } from 'react';

import { auth } from 'src/lib/auth';
import { UnauthorizedPage } from 'src/pages/UnauthorizedPage';

export const Logout = (): JSX.Element => {
    useEffect(() => {
        auth.logout();
    }, []);

    return (<UnauthorizedPage />);
};
