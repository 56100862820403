import { HTMLProps } from 'react';
import { useRecoilValue } from 'recoil';

import { UnauthorizedPage } from 'src/pages/UnauthorizedPage';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';

export const AuthBoundary = (props: HTMLProps<unknown>): JSX.Element => {
    const { children } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery);

    return (
        <>
            {accessToken === false && (<UnauthorizedPage style={{ gridColumn: '1/-1' }} />)}
            {accessToken && children}
        </>
    );
};
