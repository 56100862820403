import qs from 'qs';
import {
    DEFAULT_CULTURE,
    RENDERING_URL,
    CONTENT_DELIVERY_URL,
    UDS_URL,
} from 'src/constants';
import { config } from 'src/lib/config';

export class PreviewUrlBuilder {
    public static build(templateToken: string | undefined, width: string, culture?: string): string {
        const realizationUri = PreviewUrlBuilder.buildTemplateRealizationUri(
            culture || DEFAULT_CULTURE,
            templateToken ?? '',
            config.appName,
        );
        const instructionsUri = PreviewUrlBuilder.buildInstructionsUri(realizationUri);

        return PreviewUrlBuilder.buildPreviewUrl(
            instructionsUri,
            width,
            templateToken,
        );
    }

    private static buildPreviewUrl(
        instructionsUri: string,
        previewWidth: string,
        templateToken?: string,
        sceneUri?: string,
    ): string {
        const params = {
            bgcolor: 'f8f8f8',
            category: 'gp',
            format: 'jpeg',
            instructions_uri: instructionsUri,
            merchant_metadata: templateToken,
            quality: 95,
            scene: sceneUri,
            showerr: 'true',
            width: previewWidth.replace('px', ''),
        };

        return `${RENDERING_URL}${qs.stringify(params)}`;
    }

    private static buildTemplateRealizationUri(
        culture: string,
        templateToken: string,
        requestor: string,
    ): string {
        const params = {
            templateToken,
            culture,
            requestor,
        };

        return `${CONTENT_DELIVERY_URL}?${
            qs.stringify(params)
        }`;
    }

    private static buildInstructionsUri(realizationUri: string): string {
        const params = {
            documentUri: realizationUri,
            type: 'preview',
        };

        return `${UDS_URL}${qs.stringify(params)}`;
    }
}
