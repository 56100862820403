export const DEFAULT_PLACEHOLDER = 'Start typing to select a search term...';

export const PENDING_FACET_PROMPT = 'Enter the value you\'d like to search for';

export const SEARCH_DESIGNS_PLACEHOLDER = 'Start typing to see all attributes to search designs by';

export const SEARCH_KEYWORDS_PLACEHOLDER = 'Start typing to see all attributes to search keywords by';

export const SEARCH_FORCEDRANKING_PLACEHOLDER = 'Start typing to search forced ranking by strategy name';

export const HELPER_TEXT = 'To search for designs, first select an attribute to search for and then provide the value you want to search by';

export const DC_HELPER_TEXT = 'All design concepts that are present in your search results. Click one to filter further.';

export const SEARCH_DUCS_PLACEHOLDER = 'Start typing to see all attributes to search DUCs by';

export const SEARCH_DTG_PLACEHOLDER = 'Start typing to see all attributes to search Design Tagging Guidance by';
