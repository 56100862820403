import {
    Grid, makeStyles,
} from '@material-ui/core';
import { useState, Suspense } from 'react';

import { TitledAppBar } from 'src/components/common/TitledAppBar';
import { SearchResultsContainer } from 'src/components/common/Search/SearchResultsContainer';
import { FacetedSearch } from 'src/components/common/Search/FacetedSearch';
import { TagResults } from 'src/components/Tags/TagResults';
import { ResultsSkeleton } from 'src/components/common/skeletons/ResultsSkeleton';
import { FACETS, TAG_LABEL_FACET } from 'src/components/Tags/constants';
import { ErrorBoundary } from 'src/components/common/ErrorBoundary';

const useStyles = makeStyles({
    container: {
        gridArea: 'content',
    },
});

export const TagsPage = (): JSX.Element => {
    const classes = useStyles();
    const [search, setSearch] = useState<App.Tags.Search>({} as App.Tags.Search);

    const handleSubmit = (facetedSearch: FacetedSearch.FacetedSearchResult): void => {
        const newSearch = {
            label: facetedSearch.label as string,
            ids: facetedSearch.ids as string[],
        };

        setSearch(newSearch);
    };

    return (
        <>
            <TitledAppBar title="Tags">
                <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    wrap="nowrap"
                >
                    <Grid item xs={10}>
                        <FacetedSearch
                            defaultFacet={TAG_LABEL_FACET}
                            facets={FACETS}
                            label="Search Tags"
                            onSubmit={handleSubmit}
                        />
                    </Grid>
                </Grid>
            </TitledAppBar>
            <SearchResultsContainer className={classes.container}>
                <ErrorBoundary>
                    <Suspense fallback={<ResultsSkeleton />}>
                        <TagResults search={search} />
                    </Suspense>
                </ErrorBoundary>
            </SearchResultsContainer>
        </>
    );
};
