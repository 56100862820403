import { QueryFunctionContext } from 'react-query';

import { ContentStoreDesignTaggingGuidanceService } from 'src/services/DesignTaggingGuidances';

export const QUERY_KEY = 'designTaggingGuidance';

export const queryDesignTaggingGuidance = (
    context: QueryFunctionContext<App.DesignTaggingGuidance.QueryKey>,
): Promise<Models.ContentStoreApi.V3.DesignTaggingGuidance> => {
    const { queryKey: [, accessToken, id] } = context;

    return ContentStoreDesignTaggingGuidanceService.getDesignTaggingGuidance(accessToken, id);
};
