import {
    makeStyles, Dialog, Typography, Theme, Button, TextField,
} from '@material-ui/core';
import { Cancel, Search, CalendarToday } from '@material-ui/icons';
import { DesktopDatePicker, LocalizationProvider } from '@material-ui/lab';
import clsx from 'clsx';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { createdTimeSearchDialogOpenState } from 'src/atoms/createdTimeSearchDialogOpenAtom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { END_CREATED_TIME_KEY, START_CREATED_TIME_KEY } from 'src/components/common/Search/FacetedSearch/constants';

export interface PropTypes {
    existingSearch: FacetedSearch.FacetedSearchType;
    submitCreatedTimeSearchDialog: (
        startCreatedTime: Date | null | undefined,
        endCreatedTime: Date | null | undefined,
    ) => void
}

const useStyles = makeStyles((theme: Theme) => ({
    alignCenter: {
        alignItems: 'center',
        display: 'inline-flex',
    },
    cancel: {
        color: theme.palette.common.white,
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        '& .MuiPaper-root': {
            alignSelf: 'flex-start',
            padding: theme.spacing(8, 8, 3),
            width: '100%',
        },
        '& .MuiDialog-container': {
            width: '100%',
        },
    },
    container: {
        maxWidth: theme.spacing(1250),
    },
    grid: {
        gap: theme.spacing(4),
        overflow: 'hidden',
    },
    actions: {
        backgroundColor: theme.palette.common.white,
        bottom: 0,
        gap: theme.spacing(4),
        justifyContent: 'flex-end',
        gridAutoFlow: 'column dense',
        position: 'sticky',
    },
}));

export const CreatedTimeSearchDialog = (props: PropTypes): JSX.Element => {
    const { existingSearch, submitCreatedTimeSearchDialog } = props;
    const classes = useStyles();

    const existingSearchValues = Object.values(existingSearch);

    const existingStartCreatedTime = existingSearchValues.find((f) => f.facet?.key === START_CREATED_TIME_KEY)?.value;
    const [startCreatedTime, setStartCreatedTime] = useState<Date | null | undefined>(
        existingStartCreatedTime ? new Date(existingStartCreatedTime) : null,
    );
    const existingEndCreatedTime = existingSearchValues.find((f) => f.facet?.key === END_CREATED_TIME_KEY)?.value;
    const [endCreatedTime, setEndCreatedTime] = useState<Date | null | undefined>(
        existingEndCreatedTime ? new Date(existingEndCreatedTime) : null,
    );

    const [
        createdTimeSearchDialogOpen,
        setcreatedTimeSearchDialogOpen,
    ] = useRecoilState(createdTimeSearchDialogOpenState);

    const handleAddToSearch = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        submitCreatedTimeSearchDialog(startCreatedTime, endCreatedTime);
    };

    const handleStartTimeSelected = (date: Date | null | undefined): void => {
        if (date) {
            setStartCreatedTime(date);
        }
    };

    const handleEndTimeSelected = (date: Date | null | undefined): void => {
        if (date) {
            setEndCreatedTime(date);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog
                className={classes.modal}
                open={createdTimeSearchDialogOpen}
                onClose={(): void => setcreatedTimeSearchDialogOpen(false)}
            >
                <CSSGrid className={clsx(classes.container, classes.grid)}>
                    <div className={classes.alignCenter}>
                        <CalendarToday />
                        <Typography
                            pl={2}
                            textAlign="left"
                            variant="h6"
                        >
                            Create Date Search
                        </Typography>
                    </div>
                    <Typography variant="caption">
                        Define a starting date or an end date to narrow down your search, or use both to define a
                        date range.
                    </Typography>
                    <CSSGrid gap={4} gridTemplateColumns="1fr 1fr">
                        <DesktopDatePicker
                            disableFuture
                            inputFormat="YYYY/MM/DD"
                            label="Start Date"
                            maxDate={endCreatedTime}
                            renderInput={(params): JSX.Element => <TextField {...params} />}
                            value={startCreatedTime}
                            onChange={handleStartTimeSelected}
                        />
                        <DesktopDatePicker
                            disableFuture
                            inputFormat="YYYY/MM/DD"
                            label="End Date"
                            minDate={startCreatedTime}
                            renderInput={(params): JSX.Element => <TextField {...params} />}
                            value={endCreatedTime}
                            onChange={handleEndTimeSelected}
                        />
                    </CSSGrid>
                    <CSSGrid
                        className={classes.actions}
                        justifyContent="flex-end"
                    >
                        <Button
                            color="secondary"
                            startIcon={<Cancel />}
                            variant="outlined"
                            onClick={(): void => setcreatedTimeSearchDialogOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!startCreatedTime && !endCreatedTime}
                            startIcon={<Search />}
                            variant="contained"
                            onClick={handleAddToSearch}
                        >
                            Submit Search
                        </Button>
                    </CSSGrid>
                </CSSGrid>
            </Dialog>
        </LocalizationProvider>
    );
};
