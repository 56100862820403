import useCopy from '@react-hook/copy';
import clsx from 'clsx';
import {
    IconButton,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { Check, FileCopy } from '@material-ui/icons';
import { ComponentProps, useEffect } from 'react';
import { useTrackEvent } from 'src/hooks/useTrackEvent';
import { UserEvent } from 'src/constants';

export interface PropTypes extends ComponentProps<typeof IconButton> {
    value: string;
    copySuccessText?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    copy: {
        padding: 0,
        verticalAlign: 'top',
        '& svg': {
            height: '0.66em',
            width: '0.66em',
        },
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    success: {
        color: theme.palette.success.main,
        display: 'inline-flex',
        alignItems: 'center',
        '& > svg': {
            marginRight: theme.spacing(1),
        },
    },
}));

export const CopyButton = (props: PropTypes): JSX.Element => {
    const {
        className, value, copySuccessText, ...rest
    } = props;
    const classes = useStyles();
    const { copied, copy, reset } = useCopy(value);
    const { trackEvent } = useTrackEvent();

    useEffect(() => {
        if (copied) {
            trackEvent({ eventName: UserEvent.CopyClick });
            const pid = setTimeout(reset, 2500);

            return (): void => clearTimeout(pid);
        }

        return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copied]);

    return (
        <IconButton
            {...rest}
            className={clsx(classes.copy, className)}
            onClick={copy}
        >
            {!copied && (<FileCopy />)}
            {copied && (
                <Typography className={classes.success} variant="caption">
                    <Check />
                    {copySuccessText ?? `Copied`}
                </Typography>
            )}
        </IconButton>
    );
};
