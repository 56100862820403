import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { createTaxonomy } from 'src/mutations/createTaxonomy';
import { QUERY_KEY as S_QUERY_KEY } from 'src/queries/queryTaxonomies';

type Taxonomy = Models.ContentStoreApi.V3.Taxonomy;
type CreateMutationVariables = App.Taxonomy.CreateMutationVariables;

export const useCreateTaxonomy = (): UseMutationResult<Taxonomy, Error, CreateMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Taxonomy, Error, CreateMutationVariables>(
        createTaxonomy,
        {
            onSuccess: () => {
                setTimeout(() => { queryClient.refetchQueries([S_QUERY_KEY]); }, 1000);
            },
            useErrorBoundary: true,
        },
    );
};
