import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryDesignTaggingGuidance, QUERY_KEY } from 'src/queries/queryDesignTaggingGuidance';

type DesignTaggingGuidance = Models.ContentStoreApi.V3.DesignTaggingGuidance;

export const useQueryDesignTaggingGuidance = (
    accessToken: string | undefined | false,
    id: string,
    options?: UseQueryOptions<DesignTaggingGuidance, Error, DesignTaggingGuidance, App.DesignTaggingGuidance.QueryKey>,
): UseQueryResult<DesignTaggingGuidance, Error> => useQuery<
DesignTaggingGuidance, Error, DesignTaggingGuidance, App.DesignTaggingGuidance.QueryKey>(
    [QUERY_KEY, accessToken, id],
    queryDesignTaggingGuidance,
    options,
);
