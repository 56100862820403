import {
    Button, Tooltip, makeStyles, Theme,
} from '@material-ui/core';
import {
    useState,
} from 'react';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { DeleteLoadingButton } from 'src/components/common/buttons/DeleteLoadingButton';
import { SaveLoadingButton } from 'src/components/common/buttons/SaveLoadingButton';
import { ConfirmationDialog } from 'src/components/common/ConfirmationDialog';
import { Cancel } from '@material-ui/icons';

export interface PropTypes {
    disabled?: boolean;
    onClose: () => Promise<void>;
    onSave?: () => Promise<void>;
    onDelete?: () => Promise<void>;
    middleButton?: JSX.Element;
    deleteModalDescription?: string;
    deleteModalTitle?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        backgroundColor: theme.palette.common.white,
        bottom: 0,
        gap: theme.spacing(4),
        justifyContent: 'flex-end',
        gridAutoFlow: 'column dense',
        position: 'sticky',
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(5),
    },
}));

export const ActionButtons = (props: PropTypes): JSX.Element => {
    const {
        deleteModalDescription,
        deleteModalTitle,
        disabled,
        onClose,
        onDelete,
        onSave,
        middleButton,
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleDeleteButton = (): void => {
        setOpen(true);
    };

    const handleAcceptDelete = (): void => {
        if (onDelete) {
            onDelete();
        }
        setOpen(false);
    };

    const handleRejectDelete = (): void => {
        setOpen(false);
    };

    return (
        <CSSGrid
            className={classes.actions}
            justifyContent="flex-end"
        >
            <Tooltip title="cancel">
                <Button
                    color="secondary"
                    startIcon={<Cancel />}
                    variant="outlined"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </Tooltip>
            {!!onDelete && (
                <DeleteLoadingButton variant="outlined" onClick={handleDeleteButton}>
                    Delete
                </DeleteLoadingButton>
            )}
            {!!middleButton && middleButton}
            {onSave && (
                <SaveLoadingButton disabled={disabled} variant="outlined" onClick={onSave}>
                    Save
                </SaveLoadingButton>
            )}
            {!!onDelete && (
                <ConfirmationDialog
                    description={deleteModalDescription}
                    open={open}
                    title={deleteModalTitle!}
                    onAccept={handleAcceptDelete}
                    onCancel={handleRejectDelete}
                />
            )}
        </CSSGrid>
    );
};
