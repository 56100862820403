import clsx from 'clsx';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
    Alert,
    Autocomplete,
    AutocompleteFreeSoloValueMapping,
    AutocompleteProps,
    AutocompleteRenderGroupParams,
    CircularProgress,
    FilterOptionsState,
    InputAdornment,
    makeStyles,
    Snackbar,
    TextField,
    Theme,
    useTheme,
    Value,
} from '@material-ui/core';
import {
    ClipboardEvent, HTMLAttributes, SyntheticEvent, useState,
} from 'react';
import { UseQueryResult } from 'react-query';
import { useDebouncedCallback } from 'use-debounce';
import { Check } from '@material-ui/icons';

import { CopyButton } from 'src/components/common/buttons/CopyButton';
import { findId } from 'src/lib/findId';
import { DataSource, SNACKBAR_TIMEOUT } from 'src/constants';
import { isGuid } from 'src/utils';

type UsePlainOptionsReturnType<T> = Pick<UseQueryResult<ReadonlyArray<T>, Error>, 'data' | 'isLoading'>;

export interface PropTypes<
    T extends Models.V3.HrefOnly | Models.V3.SourcedBase | string | number,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'onPaste' | 'options' | 'onInputChange' | 'loading' | 'onChange'> {
    allowCopy?: boolean;
    label: string;
    onChange?: (
        event: SyntheticEvent<Element>,
        value: (T | AutocompleteFreeSoloValueMapping<FreeSolo>)[],
    ) => void;
    onPaste?: (idList: string[]) => Promise<T[]>;
    useQueryOptions?: (inputValue?: string) => UseQueryResult<T[], Error>;
    options?: ReadonlyArray<T>;
    filterOptions?: (options: Array<T>, state: FilterOptionsState<T>) => Array<T>;
    isKeywordLoading?: boolean;
    groupBy?: (option: T) => string;
    renderGroup?: ((params: AutocompleteRenderGroupParams) => React.ReactNode);
    hideFilterOptionsWhileLoading?: boolean;
}

const defaultGetOptionLabel = <T, >(option: T): string => (option as unknown as string);

const defaultGetOptionSelected = <T, >(option: T, value: T): boolean => (option === value);

const useStyles = makeStyles((theme: Theme) => ({
    copyButton: {
        marginRight: theme.spacing(1),
    },
    endAdornment: {
        height: '100%',
        position: 'absolute',
        right: '9px',
        top: `calc(50% - ${theme.spacing(4)})`,
    },
    input: {
        '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
            '& .MuiAutocomplete-endAdornment': {
                position: 'relative',
                right: 0,
            },
        },
        '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon': {
            '& > .MuiFormControl-root': {
                '& > .MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
                    // Push the input area more to the right to account for the additional load/copy buttons
                    paddingRight: theme.spacing(20),
                },
            },
        },
    },
    option: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    success: {
        color: theme.palette.success.main,
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.common.white,
        },
    },
}));

export const StandardSearch = <
    T extends Models.V3.HrefOnly | Models.V3.SourcedBase | string | number,
    M extends boolean | undefined,
    D extends boolean | undefined,
    F extends boolean | undefined
>(props: PropTypes<T, M, D, F>): JSX.Element => {
    const {
        autoComplete = true,
        allowCopy = true,
        className,
        filterOptions,
        filterSelectedOptions = true,
        getOptionLabel = defaultGetOptionLabel,
        getOptionSelected = defaultGetOptionSelected,
        label,
        multiple = true,
        onChange,
        onPaste,
        placeholder,
        // eslint-disable-next-line react/destructuring-assignment
        useQueryOptions = (): UsePlainOptionsReturnType<T> => ({ data: props.options || [], isLoading: false }),
        value,
        isKeywordLoading,
        groupBy,
        renderGroup,
        hideFilterOptionsWhileLoading,
        ...rest
    } = props;
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isDebouncing, setIsDebouncing] = useState(false);
    const [isPasteLoading, setIsPasteLoading] = useState(false);
    const [pasteError, setPasteError] = useState<Error | null>(null);
    const [pasteSuccess, setPasteSuccess] = useState<boolean>(false);

    // TODO if check to check options and useQueryOptions
    const useCurriedOptions = useQueryOptions;

    const { data: options, isLoading: isOptionsLoading } = useCurriedOptions(inputValue);

    const isAnythingLoading = (open && isOptionsLoading || isDebouncing) || isPasteLoading || isKeywordLoading;
    const enableCopy = !isAnythingLoading && allowCopy && !!value;

    const classes = useStyles({ enableCopy, isAnythingLoading });

    const debounced = useDebouncedCallback((newValue) => {
        setInputValue(newValue);
        setIsDebouncing(false);
    }, 750);

    const findCopyValue = (): string => {
        if (Array.isArray(value)) {
            return value.map((x) => (typeof x === 'string' ? x : findId((x as Models.V3.HrefOnly).href))).join(',');
        }
        return typeof value === 'string' ? value : findId((value as Models.V3.HrefOnly).href);
    };

    const handleChange = (
        event: SyntheticEvent<Element, Event>,
        incomingValue: Value<T, M, D, F>,
    ): void => {
        if (!onChange) {
            return;
        }

        const incoming = Array.isArray(incomingValue) ? incomingValue : [incomingValue];
        const modifiedValue = incoming.map((item) => ((typeof item === 'object')
            ? { ...item, source: DataSource.Selected }
            : item));

        onChange(event, modifiedValue);
    };

    const handleClose = (event: SyntheticEvent<unknown>): void => {
        event.preventDefault();

        setOpen(false);
    };

    const handleInputChange = (event: SyntheticEvent<unknown> | null, newValue: string, reason: string): void => {
        if (event) {
            event.preventDefault();
        }

        if (reason !== 'reset') {
            setIsDebouncing(true);
            debounced(newValue);
        }
    };

    const handleOpen = (event: SyntheticEvent<unknown>): void => {
        event.preventDefault();

        setOpen(true);
    };

    const handlePaste = async (event: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
        if (!onPaste) {
            return;
        }

        const pasteData = event.clipboardData.getData('text/plain');
        const ids = pasteData.split(',');

        // We only want to call the special id search if the pasted values are GUIDs
        if (ids.some((id) => !isGuid(id))) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        setOpen(false);

        try {
            setPasteError(null);
            setIsPasteLoading(true);
            const results = await onPaste(ids);

            if (onChange) {
                onChange(event, results);
                setPasteSuccess(true);
            }
        } catch (e) {
            setPasteError(e as Error);
        } finally {
            setIsPasteLoading(false);
            setTimeout(() => {
                setPasteSuccess(false);
            }, 2500);
        }
    };

    const handleSnackBarClose = (): void => {
        setPasteError(null);
    };

    const renderOption = (
        optionProps: HTMLAttributes<HTMLLIElement>,
        option: T,
    ): JSX.Element => {
        const { className: optionClassName, ...restOptionProps } = optionProps;
        const optionLabel = getOptionLabel(option);
        const matches = match(optionLabel, inputValue || '');
        const parts = parse(optionLabel, matches);

        return (
            <li className={clsx(classes.option, optionClassName)} {...restOptionProps}>
                <div>
                    {parts.map((part, index): JSX.Element => (
                        <span
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${part.text}-${index}`}
                            style={{
                                fontWeight: part.highlight
                                    ? theme.typography.fontWeightMedium
                                    : theme.typography.fontWeightRegular,
                            }}
                        >
                            {part.text}
                        </span>
                    ))}
                </div>
            </li>
        );
    };

    return (
        <>
            <Autocomplete
                {...rest}
                autoHighlight
                autoComplete={autoComplete}
                className={clsx(classes.input, className)}
                filterOptions={(hideFilterOptionsWhileLoading && isAnythingLoading) ? undefined : filterOptions}
                filterSelectedOptions={filterSelectedOptions}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                groupBy={groupBy}
                loading={isAnythingLoading}
                loadingText="Loading..."
                multiple={multiple as M}
                open={open}
                options={options || []}
                renderGroup={renderGroup}
                renderInput={(params): JSX.Element => (
                    <TextField
                        {...params}
                        className={classes.textField}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment className={classes.endAdornment} position="end">
                                    {isAnythingLoading && (<CircularProgress color="inherit" size={20} />)}
                                    {(!isAnythingLoading && allowCopy && value && !pasteSuccess) && (
                                        <CopyButton
                                            className={classes.copyButton}
                                            value={findCopyValue()}
                                        />
                                    )}
                                    {pasteSuccess && (<Check className={classes.success} />)}
                                    {params.InputProps.endAdornment}
                                </InputAdornment>
                            ),
                        }}
                        // eslint-disable-next-line
                        inputProps={{
                            ...params.inputProps,
                            onPaste: handlePaste,
                        }}
                        label={label}
                        placeholder={placeholder}
                    />
                )}
                renderOption={renderOption}
                value={value}
                onChange={handleChange}
                onClose={handleClose}
                onInputChange={handleInputChange}
                onOpen={handleOpen}
            />
            <Snackbar
                autoHideDuration={SNACKBAR_TIMEOUT}
                open={!!pasteError}
                onClose={handleSnackBarClose}
            >
                <Alert severity="error" onClose={handleSnackBarClose}>
                    {`Failed to paste: ${pasteError?.message}`}
                </Alert>
            </Snackbar>
        </>
    );
};
