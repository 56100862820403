import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { mutateEntity } from 'src/mutations/mutateEntity';
import { QUERY_KEY } from 'src/queries/queryEntity';

export const useMutateEntity = (
    accessToken: string,
    id: string,
): UseMutationResult<Models.ContentStoreApi.V3.Entity, Error, App.Entity.MutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Models.ContentStoreApi.V3.Entity, Error, App.Entity.MutationVariables>(
        mutateEntity,
        {
            onSuccess: (response: Models.ContentStoreApi.V3.Entity) => {
                queryClient.setQueryData([QUERY_KEY, accessToken, id], response);
            },
            useErrorBoundary: true,
        },
    );
};
