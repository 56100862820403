import { QueryFunctionContext } from 'react-query';

import { ContentStoreCulturesService } from 'src/services/Cultures';

export const QUERY_KEY = 'queryCultures';

export const queryCultures = (
    context: QueryFunctionContext<App.Cultures.QueryKey>,
): Promise<Models.ContentStoreApi.V3.Culture[]> => {
    const { queryKey } = context;
    const [, accessToken] = queryKey;

    return ContentStoreCulturesService.getCultures(accessToken);
};
