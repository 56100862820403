import { experimentalStyled, FormControl } from '@material-ui/core';

export const StyledFormControl = experimentalStyled(FormControl)(() => ({
    '.MuiInputLabel-formControl': {
        transform: 'translate(14px, 12px) scale(1)',
    },
    '.MuiInputLabel-formControl.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
    },
}));
