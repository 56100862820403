import {
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
    ChangeEvent,
    ComponentProps,
    useState,
} from 'react';
import { useRecoilValue } from 'recoil';

import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { StyledFormControl } from 'src/components/common/styled/StyledFormControl';
import { SaveLoadingButton } from 'src/components/common/buttons/SaveLoadingButton';
import { ClearableTextField } from 'src/components/common/inputs/ClearableTextField';
import { useQueryCultures } from 'src/hooks/useQueryCultures';

export interface PropTypes extends Omit<ComponentProps<typeof Grid>, 'onSubmit'> {
    blackList: string[];
    onSubmit: (locale: string, translation: string) => void;
}

export const AddTranslationToolbar = (props: PropTypes): JSX.Element => {
    const {
        blackList,
        className,
        onSubmit,
        ...rest
    } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const [locale, setLocale] = useState<string>('');
    const [translation, setTranslation] = useState<string>('');

    const { data } = useQueryCultures(accessToken);

    const handleChange = (event: ChangeEvent<{
        name?: string | undefined;
        value: string;
    }>): void => {
        event.preventDefault();

        setLocale(event.target.value);
    };

    const handleSubmit = async (): Promise<void> => {
        if (locale && translation) {
            await onSubmit(locale, translation);

            setTranslation('');
            setLocale('');
        }
    };

    const handleTranslationChange = (value: string): void => {
        setTranslation(value);
    };

    const whiteListedLocales = data?.filter((l) => !blackList.includes(l.cultureValue)) || [];

    return (
        <Grid
            {...rest}
            container
            alignItems="left"
            direction="column"
            spacing={4}
        >
            <Grid item>
                <Typography fontWeight="bold" variant="body1">
                    Translations
                </Typography>
                <Typography variant="body2">
                    Start by selecting a locale to add a new translation
                </Typography>
            </Grid>
            <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
                spacing={4}
                wrap="nowrap"
            >
                <Grid item xs={4}>
                    <StyledFormControl fullWidth>
                        <InputLabel id="translation-locale-select-label">Locale</InputLabel>
                        <Select
                            disabled={!data}
                            id="translation-locale-select"
                            labelId="translation-locale-select-label"
                            size="small"
                            value={locale}
                            variant="outlined"
                            onChange={handleChange}
                        >
                            <MenuItem key="none" value="">None</MenuItem>
                            {whiteListedLocales.map((l) => (
                                <MenuItem key={l.id} value={l.cultureValue}>
                                    {l.cultureValue}
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledFormControl>
                </Grid>
                <Grid item xs={6}>
                    <ClearableTextField
                        fullWidth
                        disabled={!locale}
                        label="Translation value"
                        size="small"
                        value={translation}
                        onChange={handleTranslationChange}
                    />
                </Grid>
                <Grid item xs={2}>
                    <SaveLoadingButton
                        Icon={Add}
                        onClick={handleSubmit}
                    >
                        Add
                    </SaveLoadingButton>
                </Grid>
            </Grid>
        </Grid>
    );
};
