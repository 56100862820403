import { QueryFunctionContext } from 'react-query';

import { ProductSearchService } from 'src/services/ProductSearchService';

export const QUERY_KEY = 'productVersion';

export const queryProductVersion = (
    context: QueryFunctionContext<App.ProductSearch.ProductVersionQueryKey>,
): Promise<MCP.Products.ProductSearch.Models.ProductVersionsResponse> => {
    const { queryKey } = context;
    const [, accessToken, search] = queryKey;
    const { version, productKey } = search;

    return ProductSearchService.getProductVersion(
        accessToken,
        productKey,
        version,
    );
};
