import {
    makeStyles,
    Dialog,
    Typography,
    Button,
    Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import { ComponentProps, MouseEvent } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    cancel: {
        color: theme.palette.common.white,
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        '& .MuiPaper-root': {
            padding: theme.spacing(8),
        },
    },
    buttons: {
        display: 'grid',
        paddingTop: theme.spacing(4),
        gap: theme.spacing(2),
        gridTemplateAreas: '". ."',
        justifyContent: 'center',
    },
}));

export interface PropTypes extends ComponentProps<typeof Dialog> {
    title: string,
    description?: string,
    onAccept: (event: MouseEvent<HTMLButtonElement>) => void,
    onCancel: (event: MouseEvent<HTMLButtonElement>) => void,
}

export const ConfirmationDialog = (props: PropTypes): JSX.Element => {
    const {
        open,
        title,
        description,
        children,
        className,
        onAccept,
        onCancel,
    } = props;
    const classes = useStyles();

    return (
        <Dialog
            className={clsx(classes.modal, className)}
            open={open}
            onClose={onCancel}
        >
            <Typography
                textAlign="center"
                variant="h6"
            >
                {title}
            </Typography>
            <Typography
                textAlign="center"
                variant="subtitle1"
            >
                {description}
            </Typography>
            <div className={classes.buttons}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onAccept}
                >
                    Yes
                </Button>
                <Button
                    className={classes.cancel}
                    color="secondary"
                    variant="contained"
                    onClick={onCancel}
                >
                    No
                </Button>
            </div>
            {children}
        </Dialog>
    );
};
