import { Info } from '@mui/icons-material';
import {
    CircularProgress, InputAdornment, styled, TextField, Tooltip,
} from '@mui/material';
import { ComponentProps } from 'react';

export type PropTypes = Omit<ComponentProps<typeof TextField>, 'error'> & {
    error?: string | boolean;
    isLoading?: boolean;
    description?: string;
};

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
    height: '100%',
    position: 'absolute',
    right: theme.spacing(8),
    top: `calc(50% - ${theme.spacing(4)})`,
}));

const StyledInfo = styled(Info)(({ theme }) => ({
    color: theme.palette.primary.light,
    height: theme.spacing(4),
}));

export const LoadableSelect = (props: PropTypes): JSX.Element => {
    const {
        children,
        description,
        error,
        helperText,
        isLoading = false,
        ...rest
    } = props;

    const helperContent = description
        ? (
            <>
                {helperText}
                <Tooltip title={description}>
                    <StyledInfo />
                </Tooltip>
            </>
        )
        : helperText;

    return (
        <TextField
            fullWidth
            select
            error={!!error}
            helperText={error || helperContent}
            InputProps={{
                endAdornment: (
                    <StyledInputAdornment position="end">
                        {isLoading && (<CircularProgress color="inherit" size={20} />)}
                    </StyledInputAdornment>
                ),
            }}
            SelectProps={{ MenuProps: { PaperProps: { style: { maxHeight: '200px' } } } }}
            size="small"
            {...rest}
        >
            {!isLoading && children}
        </TextField>
    );
};
