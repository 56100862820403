import {
    Grid,
} from '@material-ui/core';
import { ComponentProps, MouseEvent } from 'react';
import { Chip } from 'src/components/common/Tagging/Chip';
import { ZeroState } from 'src/components/common/Tagging/ZeroState';
import { DataSource } from 'src/constants';

export interface PropTypes extends Omit<ComponentProps<typeof Grid>, 'id' | 'onChange'> {
    monolithKeys: Models.ContentStoreApi.V3.SourcedCategoryMonolithKey[];
    onMonolithKeysChange: (newMonolithKeys: Models.ContentStoreApi.V3.SourcedCategoryMonolithKey[]) => void;
}

/// Copied from common/Tagging/TaggingData
/// Use that instead of this if copying for another use case
const sort = <T extends Models.V3.SourcedOnly>(a: T, b: T): number => {
    if (
        a.source === DataSource.Saved && b.source !== DataSource.Saved
        || a.source === DataSource.Selected && b.source === DataSource.Suggested
    ) {
        return -1;
    }

    if (
        a.source !== DataSource.Saved && b.source === DataSource.Saved
        || b.source === DataSource.Selected && a.source === DataSource.Suggested
    ) {
        return 1;
    }

    return 0;
};

/// Mostly copied from common/Tagging/TaggingData
/// Use that instead of this if copying for another use case
export const CategoryMonolithKeysForm = (props: PropTypes): JSX.Element => {
    const {
        monolithKeys,
        onMonolithKeysChange,
    } = props;

    const removeMonolithKey = (
        event: MouseEvent<HTMLElement>,
        keyToRemove: Models.ContentStoreApi.V3.SourcedCategoryMonolithKey,
        nextState: DataSource,
    ): void => {
        const newMonolithKeys = monolithKeys?.map((mk) => ({
            ...mk,
            source: mk.monolithKey === keyToRemove.monolithKey ? nextState : mk.source,
        }));

        onMonolithKeysChange(newMonolithKeys);
    };

    return (
        <Grid container spacing={2}>
            {!monolithKeys.length && (<ZeroState />)}
            {!!monolithKeys.length && monolithKeys
                .filter((item) => item.source !== DataSource.Deleted)
                .sort(sort)
                .map((item) => (
                    <Grid item key={item.monolithKey}>
                        <Chip
                            data={item}
                            label={item.monolithKey}
                            onClick={removeMonolithKey}
                            onDelete={removeMonolithKey}
                        />
                    </Grid>
                ))}
        </Grid>
    );
};
