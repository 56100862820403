import { ExpandMore, ChevronRight } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core';
import { TreeView } from '@material-ui/lab';
import { useDrop } from 'react-dnd';
import { ComponentProps } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
    },
    footer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
}));

interface PropTypes extends ComponentProps<'div'> {
    droppable: string[],
}

export const TreeWrapper = (props: PropTypes): JSX.Element => {
    const { droppable, children } = props;
    const [, drop] = useDrop(() => ({ accept: droppable }));
    const classes = useStyles();

    return (
        <div ref={drop} style={{ width: '100%' }}>
            <TreeView
                multiSelect
                className={classes.container}
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
            >
                {children}
            </TreeView>
        </div>
    );
};
