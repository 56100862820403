import {
    Grid,
    Typography,
    IconButton,
    makeStyles,
    Theme,
    Box,
    capitalize,
    experimentalStyled,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { LogoIcon } from 'src/components/common/icons/Logo';
import { KEYWORDS } from 'src/lib/routes';

const StyledIconButton = experimentalStyled(IconButton)(({ theme }) => ({
    margin: theme.spacing(2, 2),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    height: `calc(${theme.spacing(16)} - ${theme.spacing(4)})`,
}));

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        paddingTop: theme.spacing(1),
    },
}));

export const Title = (): JSX.Element => {
    const classes = useStyles();

    return (
        <Grid container wrap="nowrap">
            <Grid item>
                <Link to={KEYWORDS}>
                    <StyledIconButton>
                        <LogoIcon size="sm" />
                    </StyledIconButton>
                </Link>
            </Grid>
            <Grid item className={classes.title}>
                <Typography variant="subtitle1">
                    <Box component="span" fontWeight="bold">
                        {`Gallery ${capitalize(process.env.DEPLOYMENT as string)}`}
                    </Box>
                </Typography>
                <Typography variant="body2">
                    Tagging Tool
                </Typography>
            </Grid>
        </Grid>
    );
};
