import { SyntheticEvent } from 'react';
import { DataSource } from 'src/constants';

export const handleSearchFactory = <T extends Models.V3.SourcedBase>(
    data: T[],
    onChange: (
        event: SyntheticEvent<Element, Event>,
        value: T[],
    ) => void,
) => (
        event: SyntheticEvent<Element, Event>,
        values: T[],
    ): void => {
        event.preventDefault();
        const v = Array.isArray(values) ? values : [values];
        const updatedValues = v.reduce((accum, value) => {
            const index = data.findIndex((item) => item.href === value.href);

            if (index > -1 && data[index].source !== DataSource.Deleted) {
                return accum;
            } if (index > -1 && data[index].source === DataSource.Deleted) {
                const updatedAccum = [...accum];

                updatedAccum[index] = value;

                return updatedAccum;
            }

            return [...accum, value];
        }, [...data]);

        onChange(event, updatedValues);
    };
