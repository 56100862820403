import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryEntityProductSpecification, QUERY_KEY } from 'src/queries/queryEntityProductSpecifications';

type PagedEntityProductSpecifications = Models.V3.PageResult<Models.ContentStoreApi.V3.ProductSpecification>;

export const useQueryEntityProductsSpecifications = (
    accessToken: string | undefined | false,
    search: App.EntityProductSpecifications.Search,
    options?: UseQueryOptions<PagedEntityProductSpecifications,
    Error, PagedEntityProductSpecifications, App.EntityProductSpecifications.QueryKey>,
): UseQueryResult<PagedEntityProductSpecifications, Error> => useQuery<PagedEntityProductSpecifications,
Error, PagedEntityProductSpecifications, App.EntityProductSpecifications.QueryKey>(
    [QUERY_KEY, accessToken, search],
    queryEntityProductSpecification,
    options,
);
