import equals from 'fast-deep-equal/es6';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TreeView } from '@material-ui/lab';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import {
    useCallback, memo, HTMLProps, RefObject,
} from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { v4 } from 'uuid';
import clsx from 'clsx';

import {
    deleteElement, findNodeAndPosition, upsertElement,
} from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/treeUtils';
import { TreeItemDraggableDecorator } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/TreeItemDraggableDecorator';
import { NonSelectableTreeItem } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/NonSelectableTreeItem';
import { DraggableType } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableType';
import { DraggableCommand } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableCommand';
import { useSetRecoilState } from 'recoil';
import { drawerUpdatedState } from 'src/atoms/drawerUpdatedAtom';

type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;
type Category = Models.ContentStoreApi.V3.Category;

export interface PropTypes extends HTMLProps<HTMLDivElement> {
    node: TaxonomyNode[];
    setData: (data: TaxonomyNode[]) => void,
}

const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        width: '100%',
        minHeight: '250px',
    },
    container: {
        width: '100%',
    },
    footer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    emptyTreeMessage: {
        opacity: '75%',
        color: theme.palette.primary.main,
    },
    emptyTreeBox: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(1),
        height: '250px',
        border: `1px dashed ${theme.palette.primary.main}`,
        opacity: '75%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tree: {
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
    },
    stripeBackground: {
        opacity: '40%',
        backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.main} 25%,
            transparent 25%, transparent 50%, ${theme.palette.primary.main} 50%,
            ${theme.palette.primary.main} 75%, transparent 75%, ${theme.palette.common.white})`,
        margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(0)}`,
        backgroundSize: '10px 10px',
        borderRadius: theme.spacing(2),
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        borderWidth: '1px',
        minHeight: theme.spacing(10),
    },
}));

export const SingleTaxonomyTreeView = memo((props: PropTypes): JSX.Element => {
    const {
        node,
        setData,
        className,
    } = props;
    const classes = useStyles();

    const setUpdatedState = useSetRecoilState(drawerUpdatedState);

    const [{ isOverCurrent }, drop] = useDrop(() => ({
        accept: [DraggableType.Internal, DraggableType.External, DraggableType.All],
        drop: (item: {node: TaxonomyNode | Category}, monitor: DropTargetMonitor): void => {
            const { node: itemNode } = item;
            const itemType = monitor.getItemType();
            const didDrop = monitor.didDrop();

            if (didDrop) {
                return;
            }

            if (!node.length && itemNode && itemType === DraggableType.External) {
                // Add taxonomy fields if root node is only a category
                const newNode: TaxonomyNode = ('_embedded' in itemNode)
                    ? {
                        ...itemNode,
                        id: v4(),
                        children: [],
                    }
                    : {
                        ...itemNode,
                        id: v4(),
                        children: [],
                        _embedded: {
                            category: itemNode,
                        },
                        category: itemNode,
                        sortOrder: 0,
                    } as TaxonomyNode;

                setData([newNode]);
                setUpdatedState(true);
            }
        },
        collect: (monitor): { isOver: boolean; isOverCurrent: boolean } => ({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),
    }), [node]);

    const findNode = useCallback((id: string) => {
        if (node) {
            return findNodeAndPosition(id, node, []);
        }

        return undefined;
    }, [node]);

    const moveNode = useCallback(
        (draggedId: string, hoveredId: string, droppedItem: TaxonomyNode | Category, command: DraggableCommand) => {
            if (!node) {
                return;
            }

            let droppedNode: TaxonomyNode;

            if ('_embedded' in droppedItem) {
                droppedNode = droppedItem;
            } else {
                // if we're moving a category, fake a taxonomyNode with the relevant information it needs on save
                droppedNode = {
                    _embedded: {
                        category: droppedItem,
                    },
                    category: droppedItem,
                    categoryKey: droppedItem.categoryKey,
                    sortOrder: 0,
                    id: '',
                    href: '',
                };
            }

            const currentNode = JSON.parse(JSON.stringify(node));
            const draggedNode = findNode(draggedId);
            const hoveredNode = findNode(hoveredId);

            let updatedNode: TaxonomyNode[] = [];

            if (draggedNode && command === DraggableCommand.Remove) { // Node being removed from the tree
                const { index, hierarchy } = draggedNode;

                updatedNode = deleteElement(index, currentNode, hierarchy);
            } else if (!draggedNode && hoveredNode) { // New node being inserted in the tree
                const { node: hNode, index, hierarchy } = hoveredNode;

                if (command === DraggableCommand.AddFirstChild) {
                    const newNode: TaxonomyNode = {
                        ...hNode,
                        children: [{ ...droppedNode, id: v4(), children: [] }],
                    };

                    updatedNode = upsertElement(
                        deleteElement(index, currentNode, hierarchy),
                        index,
                        newNode,
                        hierarchy,
                    );
                } else if (command === DraggableCommand.Replace) {
                    const newNode: TaxonomyNode = {
                        ...droppedNode,
                        id: v4(),
                        children: hNode.children ? [...hNode.children] : [],
                    };

                    updatedNode = upsertElement(
                        deleteElement(index, currentNode, hierarchy),
                        index,
                        newNode,
                        hierarchy,
                    );
                } else {
                    const newNode: TaxonomyNode = { ...droppedNode, id: v4(), children: [] };

                    const newIndex = command === DraggableCommand.Below ? index + 1 : index;

                    updatedNode = upsertElement(currentNode, newIndex, newNode, hierarchy);
                }
            } else if (draggedNode && hoveredNode) { // Existing node is being moved inside the tree
                const { node: dNode, index: dIndex, hierarchy: dHierarchy } = draggedNode;
                const { node: hNode, index: hIndex, hierarchy: hHierarchy } = hoveredNode;

                if (command === DraggableCommand.AddFirstChild) {
                    const newNode: TaxonomyNode = {
                        ...hNode,
                        children: [{ ...dNode }],
                    };

                    updatedNode = deleteElement(
                        dIndex,
                        upsertElement(
                            deleteElement(hIndex, currentNode, hHierarchy),
                            hIndex,
                            newNode,
                            hHierarchy,
                        ),
                        dHierarchy,
                    );
                } else {
                    const sameHierarchy = dHierarchy.length === hHierarchy.length
                    && dHierarchy.every((element, i) => element === hHierarchy[i]);

                    const newNodeHierarchy = sameHierarchy ? dHierarchy : hHierarchy;
                    const newIndex = command === DraggableCommand.Below ? hIndex + 1 : hIndex;

                    updatedNode = upsertElement(
                        deleteElement(dIndex, currentNode, dHierarchy),
                        newIndex,
                        dNode,
                        newNodeHierarchy,
                    );
                }
            }

            if (updatedNode.length <= 1) {
                setData(updatedNode);
                setUpdatedState(true);
            }
        },
        [node, findNode, setData, setUpdatedState],
    );

    const onDelete = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        id: string,
    ): void => {
        event.preventDefault();
        event.stopPropagation();

        let updatedNode: TaxonomyNode[] = [];

        const currentNode = JSON.parse(JSON.stringify(node));
        const draggedNode = findNode(id);

        if (draggedNode) {
            const { index, hierarchy } = draggedNode;

            updatedNode = deleteElement(index, currentNode, hierarchy);

            setData(updatedNode);
            setUpdatedState(true);
        }
    };

    const renderer = (
        nNode: TaxonomyNode,
        index: number,
        depth: number,
    ): JSX.Element => (
        <TreeItemDraggableDecorator
            depth={depth}
            draggable={DraggableType.Internal}
            droppable={[DraggableType.Internal, DraggableType.External, DraggableType.All]}
            id={nNode.id}
            index={index}
            key={nNode.id}
            moveNode={moveNode}
            node={nNode}
        >
            {(droppable: DraggableCommand | undefined, ref: RefObject<HTMLDivElement>): JSX.Element => (
                <NonSelectableTreeItem
                    categoryId={nNode._embedded.category.id}
                    depth={depth}
                    droppable={droppable}
                    labelText={nNode._embedded.category.internalName}
                    node={nNode}
                    treeItemRef={ref}
                    onDelete={onDelete}
                >
                    {renderer}
                </NonSelectableTreeItem>
            )}
        </TreeItemDraggableDecorator>
    );

    return (
        <div className={clsx(className, classes.grid)} ref={drop}>
            {(!node || !node.length) && !isOverCurrent && (
                <div className={clsx(classes.emptyTreeBox)}>
                    <Typography className={classes.emptyTreeMessage}>
                        Drop categories to build your taxonomy
                    </Typography>
                </div>
            )}
            {(!node || !node.length) && isOverCurrent && (
                <div className={classes.stripeBackground} />
            )}
            {!!node && !!node.length && (
                <div className={classes.tree}>
                    <TreeView
                        multiSelect
                        className={classes.container}
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpandIcon={<ChevronRight />}
                    >
                        {node.map((n, index) => renderer(n, index, 0))}
                    </TreeView>
                </div>
            )}
        </div>
    );
}, equals);
