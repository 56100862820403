import { Grid } from '@material-ui/core';
import { Suspense, useState } from 'react';

import { TitledAppBar } from 'src/components/common/TitledAppBar';
import { FacetedSearch } from 'src/components/common/Search/FacetedSearch';
import { FACETS, STRATEGY_NAME_FACET } from 'src/components/ForcedRanking/constants';
import { SEARCH_FORCEDRANKING_PLACEHOLDER } from 'src/components/common/Search/constants';
import { SearchResultsContainer } from 'src/components/common/Search/SearchResultsContainer';
import { ErrorBoundary } from 'src/components/common/ErrorBoundary';
import { ResultsSkeleton } from 'src/components/common/skeletons/ResultsSkeleton';
import { ForcedRankingResults } from 'src/components/ForcedRanking/ForcedRankingResults';

export const ForcedRankingPage = (): JSX.Element => {
    const [search, setSearch] = useState<App.RankingStrategy.Search>({} as App.RankingStrategy.Search);

    const handleSubmit = (facetedSearch: FacetedSearch.FacetedSearchResult): void => {
        const newSearch = {
            strategyName: facetedSearch.strategyName as string,
        };

        setSearch(newSearch);
    };

    return (
        <>
            <TitledAppBar title="Forced Rankings">
                <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    wrap="nowrap"
                >
                    <Grid item xs={10}>
                        <FacetedSearch
                            defaultFacet={STRATEGY_NAME_FACET}
                            facets={FACETS}
                            label="Search Forced Rankings"
                            placeholder={SEARCH_FORCEDRANKING_PLACEHOLDER}
                            onSubmit={handleSubmit}
                        />
                    </Grid>
                </Grid>
            </TitledAppBar>
            <SearchResultsContainer>
                <ErrorBoundary>
                    <Suspense fallback={<ResultsSkeleton />}>
                        <ForcedRankingResults search={search} />
                    </Suspense>
                </ErrorBoundary>
            </SearchResultsContainer>
        </>
    );
};
