import clsx from 'clsx';
import {
    makeStyles, Theme,
} from '@material-ui/core';
import {
    ComponentProps,
    useEffect,
    useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import { TreeView } from '@material-ui/lab';
import { useQueries } from 'react-query';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import {
    FormControl, Typography,
} from '@mui/material';
import { Loader } from 'src/components/common/Loader';
import { DesignGuidanceTaxonomy, DesignGuidanceTaxonomyPropTypes } from 'src/components/common/TaxonomyDiscovery/SearchPanels/DesignGuidanceTaxonomy';
import { useQueryTaxonomyAssociation } from 'src/hooks/useQueryTaxonomyAssociation';
import { DEFAULT_LIMIT } from 'src/constants';
import { StandardSearch } from 'src/components/common/Search/StandardSearch';
import { QUERY_KEY } from 'src/queries/queryEntityProduct';
import { ContentStoreEntityProductsService } from 'src/services/EntityProduct';

type Category = Models.ContentStoreApi.V3.SourcedCategory;

export interface DesignGuidancePanelPropTypes<T extends Category> extends Omit<DesignGuidanceTaxonomyPropTypes<T>, 'taxonomyIds'> {
    gridProps?: Partial<ComponentProps<typeof CSSGrid>>;
    treeProps?: Partial<ComponentProps<typeof TreeView>>;
    entityId: string[];
}

export const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    grid: {
        height: '100%',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        gridTemplateRows: 'auto 1fr',
        gridAutoFlow: 'row dense',
    },
    loader: {
        marginTop: theme.spacing(10),
    },
    tree: {
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
    },
    root: {
        height: 'fit-content',
    },
    content: {
        padding: theme.spacing(5),
    },
    select: {
        paddingBottom: theme.spacing(6),
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.common.white,
        },
    },
    alignCenter: {
        alignContent: 'center',
    },
}));

const useEntityProducts = (
    entityIds: string[],
    accessToken: string | false,
): Models.ContentStoreApi.V3.EntityProduct[] | undefined => {
    const entityProductsQueries = useQueries(
        entityIds.map((entityId) => ({
            queryKey: [QUERY_KEY, accessToken, { id: entityId }],
            queryFn: () => ContentStoreEntityProductsService.getEntityProducts(
                accessToken,
                entityId,
            ),
            enabled: !!accessToken && !!entityId,
            suspense: false,
        })),
    );

    const entityProducts = entityProductsQueries.reduce((accum, curr) => {
        let ep = accum;

        if (curr?.isSuccess && curr?.data?.results.length) {
            ep = ep.concat(curr.data.results);
        }
        return ep;
    }, [] as Models.ContentStoreApi.V3.EntityProduct[]);

    return entityProducts;
};

export const DesignGuidancePanelWithPRD = <T extends Category>(props: DesignGuidancePanelPropTypes<T>): JSX.Element => {
    const {
        className,
        onChange,
        valueMap,
        draggable,
        droppable,
        entityId,
        ...rest
    } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const [selectedPRD, setSelectedPRD] = useState<string[]>();
    const [selectedProducts, setSelectedProducts] = useState<Models.ContentStoreApi.V3.EntityProduct[] | undefined>();
    const [taxonomyIds, setTaxonomyIds] = useState<string[]>();
    const entityProducts = useEntityProducts(entityId, accessToken);
    const productKeys = [...new Set(entityProducts?.map((ep) => ep.productKey))];
    const productKeysWithNames = [...new Set(entityProducts?.map((ep) => `${ep.productKey}: ${ep.productName}`))];

    const search = {
        productKeys,
    };

    const {
        data: taxonomyAssociations,
        isLoading,
    } = useQueryTaxonomyAssociation(
        accessToken,
        {
            ...search,
            offset: 0,
            limit: DEFAULT_LIMIT,
        },
        {
            enabled: !!accessToken && !!productKeys,
            retry: 3,
            staleTime: 750,
            suspense: false,
        },
    );

    const handleChange = (productKeysWithName: string[]): void => {
        const productKeysList = productKeysWithName.map((productKeyWithName) => productKeyWithName.split(':')[0]);
        const matchingProducts = entityProducts?.filter((val) => productKeysList.includes(val.productKey));

        setSelectedProducts(matchingProducts);
        setSelectedPRD(productKeysList);
    };

    useEffect(() => {
        if (selectedPRD?.length) {
            const filteredAssociations = taxonomyAssociations?.results
                .filter((taxAssoc) => selectedPRD.includes(taxAssoc.productKey));

            setTaxonomyIds(filteredAssociations?.map((taxonomyAssociation) => taxonomyAssociation.taxonomyId));
        } else {
            setTaxonomyIds(taxonomyAssociations?.results
                .map((taxonomyAssociation) => taxonomyAssociation.taxonomyId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPRD, taxonomyAssociations]);

    return (
        <CSSGrid {...rest} className={clsx(classes.grid, className)}>
            <div>
                <FormControl fullWidth className={classes.select}>
                    <StandardSearch
                        fullWidth
                        multiple
                        id="productKeySearch"
                        label={selectedPRD?.length ? 'Product Key' : 'Showing for all product keys'}
                        options={productKeysWithNames}
                        placeholder={selectedPRD?.length ? undefined : 'Select one or more product keys'}
                        value={selectedProducts?.map((selectedProduct) => `${selectedProduct?.productKey}: ${selectedProduct.productName}`)}
                        onChange={(_, v): void => handleChange(v)}
                    />
                </FormControl>
            </div>
            <div className={classes.tree}>
                {isLoading && (<Loader className={classes.loader} />)}
                {!isLoading && taxonomyIds && !!taxonomyIds.length && (
                    <DesignGuidanceTaxonomy taxonomyIds={taxonomyIds} {...props} />
                )}
                {!isLoading && taxonomyIds && !taxonomyIds.length && (
                    <CSSGrid className={classes.alignCenter}>
                        <Typography>
                            No Design Tagging Guidance found for the selected PRD:
                        </Typography>
                        <Typography textAlign="center" variant="caption">
                            { selectedPRD }
                        </Typography>
                    </CSSGrid>
                )}
            </div>
        </CSSGrid>
    );
};
