import clsx from 'clsx';
import {
    Button, Chip, Grid, makeStyles, Popover, Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
    ComponentProps, SyntheticEvent, useRef, useState,
} from 'react';

export interface PropTypes extends ComponentProps<typeof Grid> {
    keywords: Models.ContentStoreApi.V3.Entity.Embedded.Keyword[];
    tags: Models.ContentStoreApi.V3.Entity.Embedded.Tag[];
}

const useStyles = makeStyles((theme: Theme) => ({
    keywords: {
        height: 'fit-content',
    },
    popover: {
        padding: theme.spacing(4),
        maxWidth: '20%',
    },
    doNotApproveChip: {
        color: 'white',
        backgroundColor: 'rgb(211, 47, 47)',
    },
}));

export const KeywordsList = (props: PropTypes): JSX.Element => {
    const {
        className, keywords, tags, ...rest
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    const id = open ? 'keywords-popover' : undefined;

    const doNotApproveTagExists = tags.find((t) => t.label.toLowerCase().includes('do not approve'));

    const numKeywordsToShow = doNotApproveTagExists ? 5 : 6;
    const sortedKeywords = keywords.sort((a, b) => a.keywordName.localeCompare(b.keywordName));
    const keywordsToShow = sortedKeywords.slice(0, numKeywordsToShow);
    const shouldShowMore = sortedKeywords.length > numKeywordsToShow;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        setOpen(true);
    };

    const handleClose = (event: SyntheticEvent<unknown>): void => {
        event.stopPropagation();

        setOpen(false);
    };

    return (
        <>
            <Grid
                className={clsx(classes.keywords, className)}
                spacing={2}
                {...rest}
                container
                ref={ref}
            >
                {
                    doNotApproveTagExists && (
                        <Grid item key="do-not-approve-tag">
                            <Chip className={classes.doNotApproveChip} label="Do Not Approve" />
                        </Grid>
                    )
                }
                {Object.values(keywordsToShow).map((k) => (
                    <Grid item key={k.href}>
                        <Chip label={k.keywordName} />
                    </Grid>
                ))}
                {shouldShowMore && (
                    <Grid item>
                        <Button
                            aria-describedby={id}
                            color="secondary"
                            startIcon={<Add />}
                            onClick={handleClick}
                        >
                            {keywords.length - numKeywordsToShow}
                        </Button>
                    </Grid>
                )}
            </Grid>
            {shouldShowMore && (
                <Popover
                    anchorEl={ref.current}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    id={id}
                    open={open}
                    PaperProps={{
                        className: classes.popover,
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleClose}
                >
                    <Grid container spacing={2}>
                        {Object.values(keywords).map((k) => (
                            <Grid item key={k.href}>
                                <Chip label={k.keywordName} />
                            </Grid>
                        ))}
                    </Grid>
                </Popover>
            )}
        </>
    );
};
