import { DEFAULT_STATUS } from 'src/constants';
import { AppError } from 'src/lib/errors/AppError';

export class ServiceError extends AppError {
    constructor(payload: App.Error.Payload, error?: Error) {
        super({
            status: DEFAULT_STATUS,
            stack: error?.stack,
            ...error,
            ...payload,
        });

        this.name = 'ServiceError';
    }
}
