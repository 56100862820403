import {
    Checkbox,
    makeStyles,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    TextField,
    Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
    ChangeEvent, ComponentProps, useState,
} from 'react';
import { SaveLoadingButton } from 'src/components/common/buttons/SaveLoadingButton';

export interface PropTypes extends Omit<ComponentProps<typeof TableRow>, 'onSubmit'> {
    options?: Models.ContentStoreApi.V3.Culture[];
    values: Models.ContentStoreApi.V3.Entity.Culture[];
    onSubmit: (culture: Models.ContentStoreApi.V3.Entity.Culture) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    cell: {
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        paddingBottom: theme.spacing(4),
    },
}));

export const AddCultureForm = (props: PropTypes): JSX.Element => {
    const {
        options,
        values,
        onSubmit,
        ...rest
    } = props;
    const classes = useStyles();
    const [culture, setCulture] = useState<Models.ContentStoreApi.V3.Culture | string>('');
    const [enabled, setEnabled] = useState<boolean>(false);
    const [rankValue, setRankValue] = useState<number | null>(null);
    const selectedValue = (culture && typeof culture !== 'string') ? culture.cultureValue : 'None';

    const availableOptions = (options || [])
        .filter((option) => !(values.find((c) => c.href === option.href)))
        .sort((c1, c2) => c1.cultureValue.localeCompare(c2.cultureValue));

    const handleCultureChange = (event: ChangeEvent<{
        name?: string | undefined;
        value: string;
    }>): void => {
        event.preventDefault();

        const { value } = event.target;

        if (!value) {
            setCulture('');
        }

        const cultureToAdd = availableOptions.find((option) => option.cultureValue === value);

        if (cultureToAdd) {
            setCulture(cultureToAdd);
        }
    };

    const handleEnabledChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setEnabled(event.target.checked);
    };

    const handleRankValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setRankValue(parseInt(event.target.value, 10));
    };

    const handleSubmit = async (): Promise<void> => {
        if (culture && typeof culture !== 'string') {
            onSubmit({
                userEnabled: enabled,
                dTeCEnabled: false, // If this is a new culture, dtec didn't know about it
                rankValue,
                href: culture.href,
            } as Models.ContentStoreApi.V3.Entity.Culture);
        }
    };

    return (
        <TableRow {...rest}>
            <TableCell className={classes.cell}>
                <Select
                    id="new-culture-select"
                    inputProps={{
                        'aria-label': 'Select culture',
                    }}
                    size="small"
                    value={selectedValue}
                    variant="outlined"
                    onChange={handleCultureChange}
                >
                    <MenuItem value="None">None</MenuItem>
                    {!availableOptions?.length && (
                        <MenuItem disabled key="loading">Loading...</MenuItem>
                    )}
                    {!!availableOptions?.length && availableOptions.map((option) => (
                        <MenuItem key={option.id} value={option.cultureValue}>
                            {option.cultureValue}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell className={classes.cell}>
                <TextField
                    aria-label="new culture rank value"
                    disabled={!culture}
                    inputProps={{
                        type: 'number',
                    }}
                    label="rank"
                    name="rankValue"
                    size="small"
                    value={rankValue}
                    variant="outlined"
                    onChange={handleRankValueChange}
                />
            </TableCell>
            <TableCell className={classes.cell}>
                <Checkbox
                    checked={enabled}
                    color="primary"
                    disabled={!culture}
                    inputProps={{
                        'aria-label': 'new culture enabled',
                    }}
                    name="enabled"
                    onChange={handleEnabledChange}
                />
            </TableCell>
            <TableCell className={classes.cell}>
                No
            </TableCell>
            <TableCell className={classes.cell}>
                <SaveLoadingButton
                    disabled={!culture}
                    Icon={Add}
                    onClick={handleSubmit}
                >
                    Add
                </SaveLoadingButton>
            </TableCell>
        </TableRow>
    );
};
