import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { DataSource } from 'src/constants';

class ContentStoreKeywordsApi extends AbstractService implements Services.ContentStoreApi.Keywords {
    public async createKeyword(
        bearerToken: string | undefined | false,
        body: Models.ContentStoreApi.V3.CreateKeyword,
        autoTranslate?: boolean,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Keyword> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request createKeyword. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/keywords`;
        const searchParams = qs.stringify({
            autoTranslate,
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .post(url, {
                    json: body,
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Keyword>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to create keyword',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async deleteKeyword(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<void> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request deleteKeyword. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/keywords/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            await this.api
                .delete(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to delete keyword',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async getKeyword(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.SourcedKeyword> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getKeyword. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/keywords/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Keyword>();

            return {
                ...json,
                source: DataSource.Saved,
            };
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch keyword',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async getKeywords(
        bearerToken: string | undefined | false,
        keywordName?: string,
        prefix?: string,
        isExcludedFromAltText?: string,
        ids?: string[],
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.SourcedKeyword>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getKeywords. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/keywords`;
        const searchParams = qs.stringify({
            keywordName,
            prefix,
            ids,
            offset,
            isExcludedFromAltText,
            limit,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.Keyword>>();

            return {
                ...json,
                results: json.results.map((item) => ({
                    ...item,
                    source: DataSource.Saved,
                })),
            };
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to get keywords',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async updateKeyword(
        bearerToken: string | undefined | false,
        id: string,
        body: Models.ContentStoreApi.V3.UpdateKeyword,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Keyword> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request updateKeyword. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/keywords/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .put(url, {
                    signal,
                    json: body,
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Keyword>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to update keyword',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }
}

export const ContentStoreKeywordsService = new ContentStoreKeywordsApi(config.services.contentStoreApi);
