import { HTMLProps } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import { CSSGrid } from 'src/components/common/CSSGrid';

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        flex: 1,
        gridGap: theme.spacing(4),
        gridTemplateAreas: `
            "header header"
            ". content"
            ". footer"
        `,
        gridTemplateColumns: `${theme.spacing(4)} auto`,
        gridTemplateRows: 'auto 1fr auto',
        overflowY: 'auto',
    },
}));

export const Main = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const { children } = props;
    const classes = useStyles();

    return (
        <CSSGrid className={classes.main} component="main">
            {children}
        </CSSGrid>
    );
};
