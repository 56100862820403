import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { forwardRef, ComponentProps } from 'react';

export const Footer = forwardRef<HTMLDivElement, Partial<ComponentProps<typeof Grid>>>((props, ref): JSX.Element => (
    <Grid
        {...props}
        container
        alignContent="align"
        justifyContent="center"
        ref={ref}
        spacing={2}
    >
        <Grid item>
            <CircularProgress color="primary" />
        </Grid>
        <Grid item>
            <Typography variant="h6">Loading...</Typography>
        </Grid>
    </Grid>
));
