import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { mutateCategory } from 'src/mutations/mutateCategories';
import { QUERY_KEY } from 'src/queries/queryCategories';

export const useMutateCategory = (
    accessToken: string,
    id: string,
): UseMutationResult<Models.ContentStoreApi.V3.Category, Error, App.Category.MutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Models.ContentStoreApi.V3.Category, Error, App.Category.MutationVariables>(
        mutateCategory,
        {
            onSuccess: (response: Models.ContentStoreApi.V3.Category) => {
                queryClient.setQueryData([QUERY_KEY, accessToken, id], response);
            },
            useErrorBoundary: true,
        },
    );
};
