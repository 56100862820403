import { ContentStoreEntitiesService } from 'src/services/Entities';
import { prepareEntityForEditing } from 'src/utils/prepareEntityForEditting';

export const mutateEntities = async (
    {
        accessToken,
        data,
        signal,
    }: App.Entities.MutationVariables,
): Promise<App.Entities.BatchMutationResponse> => {
    const promises = Object.entries(data).map(([id, entity]) => (
        ContentStoreEntitiesService.updateEntity(
            accessToken,
            id,
            entity,
            signal,
        )
    ));

    const responses = await Promise.allSettled(promises);

    const fullfilledResponses:
    Models.ContentStoreApi.V3.Entity[] = (responses as PromiseFulfilledResult<Models.ContentStoreApi.V3.Entity>[])
        .filter((res) => res.status === 'fulfilled')
        .map((res) => prepareEntityForEditing(res.value));

    const rejectedIds: string[] = (responses as PromiseRejectedResult[])
        .filter((res) => res.status === 'rejected')
        .map((res) => res.reason.url.split('/').pop());

    return {
        fullfilledResponses,
        rejectedIds,
    };
};
