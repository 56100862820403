import { makeStyles, Theme } from '@material-ui/core';
import {
    ChangeEvent, MouseEvent, SyntheticEvent, useCallback,
} from 'react';

import { useInfiniteScroll } from 'src/hooks/useInfiniteScroll';
import { Footer } from 'src/components/common/Search/Footer';
import { findNumberSelectedCategories } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/utils';
import {
    deleteElement, findNodeAndPosition, upsertElement,
} from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/treeUtils';
import { SelectableTreeItem } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/SelectableTree/SelectableTreeItem';
import { TreeItemDraggableDecorator } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/TreeItemDraggableDecorator';
import { NonSelectableTreeItem } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/NonSelectableTreeItem';
import { DraggableType } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableType';
import { DraggableCommand } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableCommand';
import { DataSource } from 'src/constants';
import { TreeWrapper } from '../../TreeView/TreeWrapper';

type Category = Models.ContentStoreApi.V3.SourcedCategory;
type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;
type ValueMap<T extends Category> = Record<string, T>;

export interface PropTypes<T extends Category> {
    draggable?: string,
    droppable?: string[],
    hasMore: boolean;
    loadMore?: () => Promise<void>;
    nodes?: TaxonomyNode[];
    onChange: (event: SyntheticEvent<Element, Event>, value: Category[]) => void;
    selectable?: boolean;
    setData: (data: TaxonomyNode[]) => void,
    valueMap?: ValueMap<T>;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
    },
    footer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
}));

export const InfiniteTaxonomyTreeView = <T extends Category>(props: PropTypes<T>): JSX.Element => {
    const {
        draggable = DraggableType.None,
        droppable = [DraggableType.None],
        hasMore,
        loadMore,
        nodes = [],
        onChange,
        selectable = false,
        setData,
        valueMap,
    } = props;
    const classes = useStyles();

    const [ref] = useInfiniteScroll({
        hasMore,
        loadMore,
    });

    const handleChangeFactory = (taxonomyNode: TaxonomyNode | Models.ContentStoreApi.V3.Category) => (
        event: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLInputElement>,
    ): void => {
        event.preventDefault();
        event.stopPropagation();

        if (!('_embedded' in taxonomyNode)) {
            return;
        }

        const { category } = taxonomyNode._embedded;
        const updatedValueMap = { ...valueMap };
        const checked = updatedValueMap[category.href]
            && updatedValueMap[category.href].source !== DataSource.Deleted
            && updatedValueMap[category.href].source !== DataSource.Unselected;

        if (!checked) {
            const updatedItem = {
                ...category,
                source: DataSource.Selected,
            };

            updatedValueMap[category.href] = updatedItem as T;
        } else {
            const updatedItem = {
                ...category,
                source: DataSource.Unselected,
            };

            updatedValueMap[category.href] = updatedItem as T;
        }

        onChange(event, Object.values(updatedValueMap));
    };

    const findNode = useCallback(
        (id: string) => findNodeAndPosition(id, nodes, []),
        [nodes],
    );

    const moveNode = useCallback(
        (draggedId: string, hoveredId: string, droppedNode: TaxonomyNode) => {
            const draggedNode = findNode(draggedId);
            const hoveredNode = findNode(hoveredId);

            if (draggedNode && hoveredNode) {
                const { index, hierarchy } = draggedNode;

                const sameHierarchy = hierarchy.length === hoveredNode.hierarchy.length
                    && hierarchy.every((element, i) => element === hoveredNode.hierarchy[i]);

                const newList = [...nodes];
                const newNodeHierarchy = sameHierarchy ? hierarchy : hoveredNode.hierarchy;

                setData(
                    upsertElement(
                        deleteElement(index, newList, hierarchy),
                        hoveredNode.index,
                        droppedNode,
                        newNodeHierarchy,
                    ),
                );
            }
        },
        [nodes, findNode, setData],
    );

    const renderer = (
        node: Models.ContentStoreApi.V3.TaxonomyNode,
        index: number,
        depth: number,
    ): JSX.Element => (
        <TreeItemDraggableDecorator
            depth={depth}
            draggable={draggable}
            droppable={droppable}
            id={node.id}
            index={index}
            key={node.id}
            moveNode={moveNode}
            node={node}
        >
            {(droppableCommand: DraggableCommand | undefined): JSX.Element => (
                selectable ? (
                    <SelectableTreeItem
                        categoryId={node._embedded.category.id}
                        checked={!!valueMap && !!valueMap[node._embedded.category.href]
                            && valueMap[node._embedded.category.href].source !== DataSource.Deleted
                            && valueMap[node._embedded.category.href].source !== DataSource.Unselected}
                        depth={depth}
                        droppable={droppableCommand}
                        findNumberSelectedCategories={
                            (children?: TaxonomyNode[]): number => findNumberSelectedCategories(
                                children,
                                valueMap,
                            )
                        }
                        // eslint-disable-next-line max-len
                        labelText={node._embedded.category.internalName}
                        node={node}
                        onChange={handleChangeFactory}
                    >
                        {renderer}
                    </SelectableTreeItem>
                ) : (
                    <NonSelectableTreeItem
                        categoryId={node._embedded.category.id}
                        depth={depth}
                        droppable={droppableCommand}
                        labelText={node._embedded.category.internalName}
                        node={node}
                    >
                        {renderer}
                    </NonSelectableTreeItem>
                )
            )}
        </TreeItemDraggableDecorator>
    );

    return (
        <TreeWrapper droppable={droppable}>
            {nodes.map((n, index) => renderer(n, index, 0))}
            {hasMore && <Footer className={classes.footer} ref={ref} />}
        </TreeWrapper>
    );
};
