import { useRecoilValue } from 'recoil';
import { UseMutateFunction, useMutation } from 'react-query';
import { RiverService } from 'src/services/RiverService';
import { ServiceError } from 'src/lib/errors';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';

interface TrackEventParams {
    eventName: string;
    eventValue?: string;
}

export const useTrackEvent = (): {
    trackEvent: UseMutateFunction<void, ServiceError, TrackEventParams, unknown>;
} => {
    const accessToken = useRecoilValue(isAuthorizedQuery);

    const { mutate: trackEvent } = useMutation<
    void,
    ServiceError,
    TrackEventParams
    >(async ({ eventName, eventValue }: TrackEventParams) => {
        await RiverService.trackEvent(accessToken, { eventName, eventValue });
    });

    return { trackEvent };
};
