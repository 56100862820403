import { DataSource } from 'src/constants';

type Entity = Models.ContentStoreApi.V3.Entity;
type SourcedKeyword = Models.ContentStoreApi.V3.Entity.Embedded.Keyword;
type SourcedCategory = Models.ContentStoreApi.V3.Entity.Embedded.Category;
type SourcedTag = Models.ContentStoreApi.V3.Entity.Embedded.Tag;
type ValidEmbeddedRecord = Record<string, SourcedKeyword | SourcedCategory | SourcedTag>;

const updateEmbeddedEntitiesSource = (embeddedEntities: ValidEmbeddedRecord): ValidEmbeddedRecord => {
    const updatedEntities: ValidEmbeddedRecord = {};

    Object.keys(embeddedEntities).forEach((key) => {
        updatedEntities[key] = {
            ...embeddedEntities[key],
            source: DataSource.Saved,
        };
    });
    return updatedEntities;
};

export const prepareEntityForEditing = (entity: Entity): Entity => {
    const updatedEmbedded = { ...entity._embedded };

    if (entity._embedded) {
        if (entity._embedded.keywords) {
            updatedEmbedded.keywords = updateEmbeddedEntitiesSource(
                entity._embedded.keywords,
            ) as Record<string, SourcedKeyword>;
        }
        if (entity._embedded.tags) {
            updatedEmbedded.tags = updateEmbeddedEntitiesSource(
                entity._embedded.tags,
            ) as Record<string, SourcedTag>;
        }
        if (entity._embedded.categories) {
            updatedEmbedded.categories = updateEmbeddedEntitiesSource(
                entity._embedded.categories,
            ) as Record<string, SourcedCategory>;
        }
    }

    return { ...entity, _embedded: updatedEmbedded };
};
