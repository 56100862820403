import {
    experimentalStyled,
    TableCell,
    TableRow,
} from '@material-ui/core';
import {
    MouseEvent,
    ComponentProps,
} from 'react';
import { DeleteLoadingButton } from 'src/components/common/buttons/DeleteLoadingButton';
import { ClearableTextField } from 'src/components/common/inputs/ClearableTextField';

export interface PropTypes extends Omit<ComponentProps<typeof TableRow>, 'onChange'> {
    locale: string;
    translation?: string;
    onChange: (locale: string, translation: string) => void;
    onDelete: (locale: string, controller?: AbortController) => Promise<void>;
}

const StyledCell = experimentalStyled(TableCell)({
    border: 0,
});

export const TranslationRow = (props: PropTypes): JSX.Element => {
    const {
        locale,
        translation = '',
        onChange,
        onDelete,
        ...rest
    } = props;

    const handleChange = (value: string): void => {
        onChange(locale, value);
    };

    const handleDelete = (
        event: MouseEvent<HTMLButtonElement>,
        controller?: AbortController,
    ): Promise<void> => onDelete(locale, controller);

    return (
        <TableRow key={locale} {...rest}>
            <StyledCell align="left">{locale}</StyledCell>
            <StyledCell align="left">
                <ClearableTextField
                    fullWidth
                    size="small"
                    value={translation}
                    onChange={handleChange}
                />
            </StyledCell>
            <StyledCell align="right">
                <DeleteLoadingButton onClick={handleDelete} />
            </StyledCell>
        </TableRow>
    );
};
