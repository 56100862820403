import clsx from 'clsx';
import {
    makeStyles, Typography, useTheme,
} from '@material-ui/core';
import { HTMLProps } from 'react';

import { PropertyCell } from 'src/components/Entity/EntityDetailsDrawer/PropertiesPanel/PropertyCell';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { NOT_APPLICABLE } from 'src/constants';
import { useQueryDucs } from 'src/hooks/useQueryDucs';
import { useRecoilValue } from 'recoil';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { toDUCsWithNames } from 'src/utils/ducUtils';

export interface PropTypes extends Omit<HTMLProps<HTMLDivElement>, 'data'> {
    data: Models.ContentStoreApi.V3.Entity;
}

const useStyles = makeStyles(() => ({
    grid: {
        gridTemplateAreas: '". ."',
        height: '100%',
        overflowY: 'auto',
    },
    cell: {
        borderBottom: '0',
    },
    value: {
        margin: 0,
        whiteSpace: 'normal',
    },
}));

export const TitleProperties = (props: PropTypes): JSX.Element => {
    const {
        className,
        data,
        ...rest
    } = props;
    const classes = useStyles();
    const theme = useTheme();

    const accessToken = useRecoilValue(isAuthorizedQuery);
    const { data: ducs } = useQueryDucs(accessToken, {
        suspense: false,
        enabled: !!accessToken,
    });

    return (
        <CSSGrid className={clsx(classes.grid, className)} {...rest}>
            <PropertyCell
                allowCopy
                className={classes.cell}
                title="Template Token"
                value={data.entityDescriptor || NOT_APPLICABLE}
            >
                <Typography
                    paragraph
                    className={classes.value}
                    fontWeight={theme.typography.fontWeightMedium}
                    variant="body2"
                >
                    {data.entityDescriptor || NOT_APPLICABLE}
                </Typography>
            </PropertyCell>
            <PropertyCell
                allowCopy
                className={classes.cell}
                title="Entity Id"
                value={data.id || NOT_APPLICABLE}
            >
                <Typography
                    paragraph
                    className={classes.value}
                    fontWeight={theme.typography.fontWeightMedium}
                    variant="body2"
                >
                    {data.id || NOT_APPLICABLE}
                </Typography>
            </PropertyCell>

            <PropertyCell
                allowCopy
                className={classes.cell}
                title="DUC IDs"
                value={data.ducIds ? toDUCsWithNames(data.ducIds, ducs) : NOT_APPLICABLE}
            >
                <Typography
                    paragraph
                    className={classes.value}
                    fontWeight={theme.typography.fontWeightMedium}
                    variant="body2"
                >
                    {data.ducIds ? toDUCsWithNames(data.ducIds, ducs) : NOT_APPLICABLE}
                </Typography>
            </PropertyCell>

            <PropertyCell
                allowCopy
                className={classes.cell}
                title="Design Concept"
                value={data.designConceptId || NOT_APPLICABLE}
            >
                <Typography
                    paragraph
                    className={classes.value}
                    fontWeight={theme.typography.fontWeightMedium}
                    variant="body2"
                >
                    {data.designConceptId || NOT_APPLICABLE}
                </Typography>
            </PropertyCell>
        </CSSGrid>
    );
};
