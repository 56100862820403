import { QueryFunctionContext } from 'react-query';
import { ENTITY_IDS_BATCH_SIZE } from 'src/constants';
import { ContentStoreEntitiesService } from 'src/services/Entities';
import { prepareEntityForEditing } from 'src/utils/prepareEntityForEditting';

export const QUERY_KEY = 'bulkEntities';

export const queryEntitiesByBatchedIds = async (
    context: QueryFunctionContext<App.Entities.QueryKey, App.Entities.PageParam>,
): Promise<Models.ContentStoreApi.V3.Entity[]> => {
    const { queryKey } = context;
    const [, accessToken, search] = queryKey;

    const requests: Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.Entity>>[] = [];

    const batchableEntityIds = search.ids || [];

    // Include no more than 100 entity ids in each request to avoid long URLs
    for (let i = 0; i < batchableEntityIds.length; i += ENTITY_IDS_BATCH_SIZE) {
        const batchedEntityIds = batchableEntityIds.slice(i, i + ENTITY_IDS_BATCH_SIZE);

        const batchedRequest = ContentStoreEntitiesService.getEntities(
            accessToken,
            { ...search, ids: batchedEntityIds },
            undefined,
            ENTITY_IDS_BATCH_SIZE,
        );

        requests.push(batchedRequest);
    }

    const responses = await Promise.all(requests);

    const concatenatedResponses = responses.reduce((accum, response) => {
        const newArray = accum.concat(response.results);

        return newArray;
    }, [] as Models.ContentStoreApi.V3.Entity[]);

    return concatenatedResponses.map(prepareEntityForEditing);
};
