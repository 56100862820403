import { useRecoilValue } from 'recoil';
import { SyntheticEvent, useState } from 'react';
import { UseQueryResult } from 'react-query';
import clsx from 'clsx';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Alert, AlertTitle } from '@material-ui/core';
import { DEFAULT_LIMIT, MAX_RETRY_TIMEOUT, MIN_RETRY_TIMEOUT } from 'src/constants';
import { useQueryCategories } from 'src/hooks/useQueryCategories';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import {
    getOptionLabel,
    getOptionSelected,
    sort,
} from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/utils';
import { useStyles, TaxonomyPanelPropTypes } from 'src/components/common/TaxonomyDiscovery/SearchPanels/TaxonomyPanel';
import { StandardSearch } from 'src/components/common/Search/StandardSearch';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { Loader } from 'src/components/common/Loader';
import { CategoryTreeView } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/CategoryTreeView';

type Category = Models.ContentStoreApi.V3.SourcedCategory;

export const CategoriesPanel = <T extends Category>(props: TaxonomyPanelPropTypes<T>): JSX.Element => {
    const {
        selectable = false,
        className,
        onChange,
        valueMap,
        draggable,
        droppable,
        handleAsyncPaste,
        ...rest
    } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const [search, setSearch] = useState<(T | undefined)>(undefined);
    const {
        data: categories,
        isLoading,
        isError,
        error,
    } = useQueryCategories(accessToken, {
        offset: 0,
        limit: DEFAULT_LIMIT,
        internalName: search?.internalName,
    }, {
        suspense: false,
        retry: 3,
        retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : MIN_RETRY_TIMEOUT, MAX_RETRY_TIMEOUT),
    });

    const useQueryOptions = (inputValue?: string): UseQueryResult<(T)[], Error> => {
        const { data, ...other } = useQueryCategories(
            accessToken,
            {
                internalName: inputValue,
            },
            {
                enabled: !!accessToken,
                suspense: false,
                refetchOnWindowFocus: false,
            },
        );

        const options = data && data?.results?.length > 0
            ? data.results
                .filter(Boolean)
                .sort(sort)
            : search;

        return {
            data: options,
            ...other,
        } as UseQueryResult<(T)[], Error>;
    };

    const handleSearchChange = (
        event: SyntheticEvent<Element>,
        v: (T)[],
    ): void => {
        event.preventDefault();
        setSearch(v[0]);
    };

    return (
        <CSSGrid {...rest} className={clsx(classes.grid, className)}>
            <StandardSearch<T, false, false, false>
                autoComplete
                className={classes.search}
                freeSolo={false}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                label="Filter Categories"
                placeholder="Start typing to search categories..."
                useQueryOptions={useQueryOptions}
                value={search}
                onChange={handleSearchChange}
                onPaste={handleAsyncPaste}
            />
            <div className={classes.tree}>
                {isLoading && (<Loader className={classes.loader} />)}
                {isError && (
                    <Alert severity="error">
                        <AlertTitle>Failed to load Taxonomies</AlertTitle>
                        {error?.message}
                    </Alert>
                )}
                {categories && (
                    <DndProvider backend={HTML5Backend}>
                        <CategoryTreeView
                            categories={categories.results}
                            draggable={draggable}
                            droppable={droppable}
                            selectable={selectable}
                            valueMap={valueMap}
                            onChange={onChange}
                        />
                    </DndProvider>
                )}
            </div>
        </CSSGrid>
    );
};
