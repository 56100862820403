import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryProductVersion, QUERY_KEY } from 'src/queries/queryProductVersion';

export const productVersionQueryKey = (
    accessToken: string | undefined | false,
    search: App.ProductSearch.VersionSearch,
): App.ProductSearch.ProductVersionQueryKey => [QUERY_KEY, accessToken, search];

type Products = MCP.Products.ProductSearch.Models.ProductVersionsResponse;

export const useQueryProductVersion = (
    accessToken: string | undefined | false,
    search: App.ProductSearch.VersionSearch,
    options?: UseQueryOptions<Products, Error, Products, App.ProductSearch.ProductVersionQueryKey>,
): UseQueryResult<Products, Error> => useQuery<Products, Error, Products, App.ProductSearch.ProductVersionQueryKey>(
    productVersionQueryKey(accessToken, search),
    queryProductVersion,
    options,
);
