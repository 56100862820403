import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

class DtecDucsApi extends AbstractService implements Services.DtecApi.Ducs {
    public async getDucs(
        bearerToken: string | undefined | false,
    ): Promise<Models.DtecApi.Duc[]> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getDucs. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/designUseCases`;

        try {
            return await this.api
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.DtecApi.Duc[]>();
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch DUCs',
                info: (e as Error).message,
                url,
            });
        }
    }
}

export const DtecDucsService = new DtecDucsApi(config.services.dtecAPI);
