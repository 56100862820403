import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

class ContentStoreEntityProductsApi extends AbstractService implements Services.ContentStoreApi.EntityProducts {
    public async getEntityProducts(
        bearerToken: string | undefined | false,
        entityId: string,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.EntityProduct>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request updateTag. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/entities/${entityId}/products`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .get(url, {
                    signal,
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.EntityProduct>>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch Entity Product',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }
}

export const ContentStoreEntityProductsService = new ContentStoreEntityProductsApi(config.services.contentStoreApi);
