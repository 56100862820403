import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryKeywords, QUERY_KEY } from 'src/queries/queryKeywords';

type PagedKeywords = Models.V3.PageResult<Models.ContentStoreApi.V3.SourcedKeyword>;

export const useQueryKeywords = (
    accessToken: string | undefined | false,
    search: App.Keywords.Search,
    options?: UseQueryOptions<PagedKeywords, Error, PagedKeywords, App.Keywords.QueryKey>,
): UseQueryResult<PagedKeywords, Error> => useQuery<PagedKeywords, Error, PagedKeywords, App.Keywords.QueryKey>(
    [QUERY_KEY, accessToken, search],
    queryKeywords,
    options,
);
