import { makeStyles } from '@material-ui/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useErrorHandler } from 'react-error-boundary';

import { PaginatedSearchResultsGrid } from 'src/components/common/Search/PaginatedSearchResultsGrid';
import { EmptyState } from 'src/components/common/EmptyState';
import { EntityResult } from 'src/components/Entity/EntityResult';
import {
    renderedIdsState, selectAllState, selectedIdsState, totalIdsState,
} from 'src/atoms/selectedIdsAtom';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { ZeroSearchState } from 'src/components/common/ZeroSearchState';
import { isSearchPopulated } from 'src/utils';
import { DEFAULT_LIMIT, MAX_RETRY_TIMEOUT, MIN_RETRY_TIMEOUT } from 'src/constants';
import { Pagination, styled } from '@mui/material';
import { useQueryEntities } from 'src/hooks/useQueryEntities';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

interface PropTypes {
    onClick: (id: string, reason: 'drawer' | 'designConceptId') => void;
    search: App.Entities.Search;
}

const useStyles = makeStyles({
    grid: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    },
});

const StyledPagination = styled(Pagination)(({ theme }) => ({
    margin: `${theme.spacing(2)} auto`,
    alignContent: 'center',
    width: 'fit-content',
}));

const PaginationContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    width: `calc(100% + ${theme.spacing(2)})`,
    transform: `translateX(${theme.spacing(-1)})`,
    position: 'sticky',
    left: '0',
    padding: theme.spacing(4),
    bottom: theme.spacing(-3),
}));

export const EntityResults = (props: PropTypes): JSX.Element => {
    const { onClick, search } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const selectedIds = useRecoilValue(selectedIdsState);
    const setSelectAll = useSetRecoilState(selectAllState);
    const [renderedIds, setRenderedIds] = useRecoilState(renderedIdsState);
    const errorHandler = useErrorHandler();
    const setTotalIds = useSetRecoilState(totalIdsState);
    const values = qs.parse(window.location.search.slice(1));
    const navigate = useNavigate();
    const location = useLocation();
    const page = values.page ? parseInt(values.page?.toString(), 10) : 1;

    const {
        data,
    } = useQueryEntities(
        accessToken,
        {
            ...search,
            offset: (page - 1) * DEFAULT_LIMIT,
            limit: DEFAULT_LIMIT,
        },
        {
            enabled: !!accessToken && isSearchPopulated(search),
            retry: 3,
            retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : MIN_RETRY_TIMEOUT, MAX_RETRY_TIMEOUT),
            staleTime: 750,
            suspense: true,
            onError: errorHandler,
        },
        false,
    );

    // When data is queried for the first time (i.e. renderedIds in less than 50),
    // mark them as rendered with recoil
    if (data && data.results.length) {
        const dataSet = new Set<string>();
        const dataIds = data?.results.map((e) => {
            dataSet.add(e.id);
            return e.id;
        });

        if (renderedIds.length !== dataIds.length || !renderedIds.every((el) => dataSet.has(el))) {
            setRenderedIds(data?.results.map((e) => e.id));
        }
        setTotalIds(data.total);
    }

    const handleAllSelectedCheck = (newArrLength: number): void => {
        if (data) {
            setSelectAll(newArrLength === data?.results.length);
        }
    };

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number): void => {
        event.preventDefault();
        handleAllSelectedCheck(selectedIds.length);
        navigate({
            ...location,
            search: qs.stringify({
                ...search,
                page: value > 1 ? value : undefined,
            }, { arrayFormat: 'repeat' }),
        });
    };

    return (
        <>
            {!isSearchPopulated(search) && (<ZeroSearchState />)}
            {isSearchPopulated(search) && !data?.results.length && (
                <EmptyState>
                    Could not find designs for the given parameters
                </EmptyState>
            )}
            {!!data?.results.length && (
                <PaginatedSearchResultsGrid
                    className={classes.grid}
                    data={data.results}
                    itemKey={(item: Models.ContentStoreApi.V3.Entity): string => `item-${item.id}`}
                >
                    {(item: Models.ContentStoreApi.V3.Entity): JSX.Element => {
                        const keywords = item._embedded && Object.values(item._embedded.keywords);
                        const tags = item._embedded && Object.values(item._embedded.tags);
                        const culturesCount = item?.cultures ? item.cultures.filter(
                            (c) => c.dTeCEnabled && c.userEnabled,
                        ).length : 0;

                        return (
                            <EntityResult
                                culturesCount={culturesCount}
                                entity={item}
                                handleAllSelectedCheck={handleAllSelectedCheck}
                                keywords={keywords}
                                tags={tags}
                                onClick={onClick}
                            />
                        );
                    }}
                </PaginatedSearchResultsGrid>
            )}
            {data?.results && data.total > DEFAULT_LIMIT && (
                <PaginationContainer>
                    <StyledPagination
                        color="primary"
                        count={Math.ceil(data.total / DEFAULT_LIMIT)}
                        page={page}
                        onChange={handlePaginationChange}
                    />
                </PaginationContainer>
            )}
        </>
    );
};
