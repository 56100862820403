import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryDesignTaggingGuidances, QUERY_KEY } from 'src/queries/queryDesignTaggingGuidances';

type PageDesignTaggingGuidances = Models.V3.PageResult<Models.ContentStoreApi.V3.DesignTaggingGuidance>;
type PagedQueryKey = App.DesignTaggingGuidances.PagedQueryKey;

export const useQueryDesignTaggingGuidances = (
    accessToken: string | undefined | false,
    search: App.DesignTaggingGuidances.PagedSearch,
    options?: UseQueryOptions<PageDesignTaggingGuidances, Error, PageDesignTaggingGuidances, PagedQueryKey>,
): UseQueryResult<PageDesignTaggingGuidances, Error> => useQuery<PageDesignTaggingGuidances,
Error, PageDesignTaggingGuidances, PagedQueryKey>(
    [QUERY_KEY, accessToken, search],
    queryDesignTaggingGuidances,
    options,
);
