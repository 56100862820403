import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryCultures, QUERY_KEY } from 'src/queries/queryCultures';

type Culture = Models.ContentStoreApi.V3.Culture;

export const useQueryCultures = (
    accessToken: string | undefined | false,
    options?: UseQueryOptions<Culture[], Error, Culture[], App.Cultures.QueryKey>,
): UseQueryResult<Culture[], Error> => useQuery<Culture[], Error, Culture[], App.Cultures.QueryKey>(
    [QUERY_KEY, accessToken],
    queryCultures,
    {
        staleTime: Infinity,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        ...options,
    },
);
