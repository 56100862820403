import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { DataSource } from 'src/constants';

class ContentStoreSuggestionsApi extends AbstractService implements Services.ContentStoreApi.Suggestions {
    public async getCategorySuggestions(
        bearerToken: string | undefined | false,
        ids: string[],
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.SourcedCategory[]> {
        const url = `api/${this.version}/suggestions/categories`;
        const searchParams = qs.stringify({
            ids,
            limit,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Category[]>();

            return Object.values(json).map((item) => ({ ...item, source: DataSource.Suggested }));
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: `Failed to get category suggestions: ${(e as Error).message}`,
                info: (e as Error).message,
                url,
                searchParams,
            }, (e as Error));
        }
    }

    public async getKeywordSuggestions(
        bearerToken: string | undefined | false,
        ids: string[],
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.SourcedKeyword[]> {
        const url = `api/${this.version}/suggestions/keywords`;
        const searchParams = qs.stringify({
            ids,
            limit,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Keyword[]>();

            return Object.values(json).map((item) => ({ ...item, source: DataSource.Suggested }));
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: `Failed to get keyword suggestions: ${(e as Error).message}`,
                info: (e as Error).message,
                url,
                searchParams,
            }, (e as Error));
        }
    }
}

export const ContentStoreSuggestionsService = new ContentStoreSuggestionsApi(config.services.contentStoreApi);
