type Culture = Models.ContentStoreApi.V3.Entity.Culture;

export interface PropTypes {
    children: (culture: Culture, cultureValue: string) => JSX.Element;
    cultures: Culture[];
    cultureOptions: Models.ContentStoreApi.V3.Culture[];
}

export const CultureList = (props: PropTypes): JSX.Element => {
    const { children, cultures, cultureOptions } = props;
    const cultureOptionsById = cultureOptions.reduce((accum, culture) => ({
        ...accum,
        [culture.href]: { ...culture },
    }), {} as Record<string, Models.ContentStoreApi.V3.Culture>);
    const sortedCultures = cultures.sort((a, b) => cultureOptionsById[a.href]
        .cultureValue.localeCompare(cultureOptionsById[b.href].cultureValue));

    return (
        <>
            {sortedCultures.map((culture) => children(culture, cultureOptionsById[culture.href].cultureValue))}
        </>
    );
};
