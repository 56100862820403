import { createFacet } from 'src/components/common/Search/FacetedSearch';
import { isAlphaNumeric, isGuid } from 'src/utils';

export const DUC_NAME_FACET: FacetedSearch.Facet = createFacet({
    name: 'DUC Name',
    key: 'ducName',
    type: 'single',
    isCompatible: async (val: string): Promise<string[] | false> => (isAlphaNumeric(val) ? [val] : false),
});

export const DUC_IDS_FACET: FacetedSearch.Facet = createFacet({
    name: 'DUC Id',
    key: 'ducIds',
    type: 'multiple',
    isCompatible: async (val: string): Promise<string[] | false> => (isAlphaNumeric(val) ? [val] : false),
});

export const TAXONOMY_NAME_FACET: FacetedSearch.Facet = createFacet({
    name: 'Taxonomy Name',
    key: 'taxonomyName',
    type: 'single',
    isCompatible: async (val: string): Promise<string[] | false> => (isAlphaNumeric(val) ? [val] : false),
});

export const TAXONOMY_IDS_FACET: FacetedSearch.Facet = createFacet({
    name: 'Taxonomy Id',
    key: 'taxonomyIds',
    type: 'multiple',
    isCompatible: async (val: string): Promise<string[] | false> => (isGuid(val) ? [val] : false),
});

export const FACETS: FacetedSearch.Facet[] = [
    DUC_NAME_FACET,
    DUC_IDS_FACET,
    TAXONOMY_NAME_FACET,
    TAXONOMY_IDS_FACET,
];
