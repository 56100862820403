import { ContentStoreTaxonomiesService } from 'src/services/Taxonomies';

export const mutateTaxonomy = async (
    {
        accessToken,
        id,
        taxonomy,
        signal,
    }: App.Taxonomy.MutationVariables,
): Promise<Models.ContentStoreApi.V3.Taxonomy> => ContentStoreTaxonomiesService.updateTaxonomy(
    accessToken,
    id,
    taxonomy,
    signal,
);
