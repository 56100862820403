import { experimentalStyled as styled, makeStyles, Theme } from '@material-ui/core';
import { ComponentProps } from 'react';

import { CSSGrid } from 'src/components/common/CSSGrid';

export interface PropTypes extends ComponentProps<typeof CSSGrid> {
    icon?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        color: theme.palette.secondary.main,
        paddingTop: '10px',
    },
}));

const StyledCSSGrid = styled(CSSGrid)(({ theme }) => ({
    padding: `${theme.spacing(6)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
}));

export const Tagging = (props: PropTypes): JSX.Element => {
    const {
        children,
        gridTemplateAreas = '". ."',
        gridTemplateColumns = 'auto 1fr',
        icon,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <StyledCSSGrid
            gap={2}
            gridTemplateAreas={gridTemplateAreas}
            gridTemplateColumns={gridTemplateColumns}
            {...rest}
        >
            {icon && (
                <div className={classes.icon}>
                    {icon}
                </div>
            )}
            <div>
                {children}
            </div>
        </StyledCSSGrid>
    );
};
