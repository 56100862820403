import {
    ChangeEvent, MouseEvent, SyntheticEvent,
} from 'react';
import { DataSource } from 'src/constants';
import { TreeWrapper } from 'src/components/common/TreeView/TreeWrapper';
import { DraggableCommand } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableCommand';
import { DraggableType } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableType';
import { NonSelectableTreeItem } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/NonSelectableTreeItem';
import { SelectableTreeItem } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/SelectableTree/SelectableTreeItem';
import { TreeItemDraggableDecorator } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/TreeItemDraggableDecorator';

type SourcedCategory = Models.ContentStoreApi.V3.SourcedCategory;
type Category = Models.ContentStoreApi.V3.Category;
type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;
type ValueMap<T extends Category> = Record<string, T>;

interface PropTypes<T extends SourcedCategory> {
    categories?: Models.ContentStoreApi.V3.Category[];
    draggable?: string,
    droppable?: string[],
    onChange: (event: SyntheticEvent<Element, Event>, value: T[]) => void;
    selectable?: boolean;
    valueMap?: ValueMap<T>;
}

export const CategoryTreeView = <T extends SourcedCategory>(props: PropTypes<T>): JSX.Element => {
    const {
        categories,
        draggable = DraggableType.None,
        droppable = [DraggableType.None],
        onChange,
        selectable,
        valueMap,
    } = props;

    const moveNode = (): void => undefined;

    const handleChangeFactory = (node: TaxonomyNode | Category) => (
        event: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLInputElement>,
    ): void => {
        event.preventDefault();
        event.stopPropagation();

        if (('_embedded' in node)) {
            return;
        }
        const category = node;
        const updatedValueMap = { ...valueMap };
        const checked = updatedValueMap[category.href]
            && updatedValueMap[category.href].source !== DataSource.Deleted
            && updatedValueMap[category.href].source !== DataSource.Unselected;

        if (!checked) {
            const updatedItem = {
                ...category,
                source: DataSource.Selected,
            };

            updatedValueMap[category.href] = updatedItem as T;
        } else {
            const updatedItem = {
                ...category,
                source: DataSource.Unselected,
            };

            updatedValueMap[category.href] = updatedItem as T;
        }

        onChange(event, Object.values(updatedValueMap));
    };

    return (
        <TreeWrapper droppable={droppable}>
            {categories?.map((category, index) => (
                <TreeItemDraggableDecorator
                    depth={0}
                    draggable={draggable}
                    droppable={droppable}
                    id={category.id}
                    index={index}
                    key={category.id}
                    moveNode={moveNode}
                    node={category}
                >
                    {(droppableCommand: DraggableCommand | undefined): JSX.Element => (
                        selectable ? (
                            <SelectableTreeItem
                                categoryId={category.id}
                                checked={!!valueMap && !!valueMap[category.href]
                                    && valueMap[category.href].source !== DataSource.Deleted
                                    && valueMap[category.href].source !== DataSource.Unselected}
                                depth={0}
                                descriptionText={category.id}
                                droppable={droppableCommand}
                                findNumberSelectedCategories={(): number => 0}
                                labelText={category.internalName}
                                node={category}
                                onChange={handleChangeFactory}
                            />
                        ) : (
                            <NonSelectableTreeItem
                                categoryId={category.id}
                                depth={0}
                                descriptionText={category.id}
                                droppable={droppableCommand}
                                labelText={category.internalName}
                                node={category}
                            />
                        )
                    )}
                </TreeItemDraggableDecorator>
            ))}
        </TreeWrapper>
    );
};
