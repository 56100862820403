const DEFAULT_STATUS = 0;

export function resolveMessage(payload: App.Error.Payload): string {
    if ('message' in payload) {
        const { message } = payload;

        return (typeof message === 'object') ? JSON.stringify(message) : message;
    }

    return JSON.stringify(payload);
}

export function resolveStatus(payload: App.Error.Payload): number {
    if ('status' in payload && payload.status) {
        return payload.status;
    }

    // Use the `in` check to cover typechecking errors
    if ('response' in payload && payload.response && (payload.response as Response).status) {
        return (payload.response as Response).status;
    }

    return DEFAULT_STATUS;
}
