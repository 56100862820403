import clsx from 'clsx';
import { HTMLProps } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    topHeader: {
        zIndex: 3,
        position: 'sticky',
        boxShadow: `0 ${theme.spacing(4)} ${theme.spacing(4)} rgba(0, 0, 0, 0.1), ${theme.spacing(4)} 0 ${theme.spacing(4)} #f5f6f7`,
        backgroundColor: '#f5f6f7',
        transform: `translateX(${theme.spacing(-11)}) translateY(${theme.spacing(-4)})`,
        padding: `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(11)}`,
        width: `calc(100% + ${theme.spacing(14)})`,
        top: theme.spacing(20),
    },
}));

export const StickyHeader = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const { children, className, ...rest } = props;
    const classes = useStyles();

    return (
        <div {...rest} className={clsx(className, classes.topHeader)}>
            {children}
        </div>
    );
};
