import { UseQueryOptions, UseQueryResult } from 'react-query';

import { useQueryCategoryTaxonomyTree } from 'src/components/Categories/hooks/useQueryCategoryTaxonomyTree';
import { useQueryTaxonomies } from 'src/hooks/useQueryTaxonomies';

type PagedTaxonomyNodes = Models.V3.PageResult<Models.ContentStoreApi.V3.TaxonomyNode>;
type PagedTaxonomies = Models.V3.PageResult<Models.ContentStoreApi.V3.Taxonomy>;
type QueryResult = Pick<UseQueryResult<{
    taxonomyNodes: PagedTaxonomyNodes,
    rootTaxonomies: PagedTaxonomies,
}, [Error | null, Error | null]>, 'data' | 'error' | 'isLoading' | 'isError'>;

export const useQueryCategoryTaxonomies = (
    accessToken: string | undefined | false,
    id: string,
    options?: UseQueryOptions<any, Error, any, any>,
): QueryResult => {
    const res1 = useQueryCategoryTaxonomyTree(accessToken, id, options);

    const roots = res1.data?.results.map((node) => {
        let root = node;

        while (root.parentNode) {
            root = root.parentNode;
        }

        return root.id;
    });

    const res2 = useQueryTaxonomies(accessToken, { taxonomyNodeIds: roots }, options);

    return {
        data: (res1.data && res2.data) ? { taxonomyNodes: res1.data, rootTaxonomies: res2.data } : undefined,
        error: [res1.error, res2.error],
        isLoading: res1.isLoading || res2.isLoading,
        isError: res1.isError || res2.isError,
    };
};
