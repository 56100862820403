import {
    makeStyles, Theme,
} from '@material-ui/core';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { generateSrcSet, generateSizes } from 'src/lib/image';
import { MagnifiedImage } from 'src/components/common/MagnifiedImage';
import { withPreviewUrl } from 'src/lib/decorators/withPreviewUrl';

export type PropTypes = {
    culture?: string;
    previewData: App.ForcedRanking.InputData | undefined;
    previewEntities: Models.ContentStoreApi.V3.Entity[] | undefined;
};

export interface ExistingRankings {
    [culture: string]: App.ForcedRanking.InputData | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    previewArea: {
        gridTemplateColumns: 'repeat(4, minmax(auto, 1fr))',
        alignItems: 'start',
        marginTop: theme.spacing(4),
    },
}));

export const ForcedRankingPreview = (props: PropTypes): JSX.Element => {
    const { culture, previewData, previewEntities } = props;
    const classes = useStyles();

    return (
        <CSSGrid className={classes.previewArea} gap={4}>
            {previewData && previewEntities && previewEntities.length
            && previewData.map((input) => {
                const entity = previewEntities?.find((ent) => ent.id === input[0]);
                const previewUrls = culture && entity
                    ? withPreviewUrl(entity, culture).previewUrls
                    : entity?.previewUrls;
                const srcSet = generateSrcSet(previewUrls);
                const sizes = generateSizes(previewUrls);

                const item = {
                    id: entity?.id,
                    original: entity?.previewUrls && entity.previewUrls['1080w'],
                    srcSet,
                    sizes,
                    zoomSrc: entity?.previewUrls && entity.previewUrls['1920w'],
                };

                return (
                    <div className={classes.imageWrapper}>
                        <MagnifiedImage
                            imageProps={{
                                sizes: item.sizes,
                                src: item.original,
                                srcSet: item.srcSet,
                                title: entity?.entityDescriptor,
                            }}
                            magnifiedImageProps={{
                                height: 960,
                                width: 960,
                            }}
                            style={{ height: 'auto' }}
                        />
                    </div>
                );
            })}
        </CSSGrid>
    );
};
