export const isAlphaNumeric = (value: string): boolean => value.match('^[a-zA-Z0-9_]*$') !== null;

export const containsLetter = (value: string): boolean => value.match('.*[a-zA-Z].*') !== null;

export const isIntegerOnly = (value: string): boolean => value.match('^[0-9]+$') !== null;

export const isGuid = (value: string): boolean => value
    .match('^({{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}}{0,1})$') !== null;

export const isProductKey = (value?: string): boolean => !!value && value
    .match('^((PRD-)([A-Z0-9]){8,})$') != null;

export const isSearchPopulated = (
    search: App.Entities.Search | App.Keywords.Search | App.Tags.Search | App.Ducs.Search,
): boolean => Object
    .values(search)
    .reduce((accum: boolean, value) => (accum || !!(Array.isArray(value) ? value.length : value)), false);
