import {
    makeStyles, Button, Theme,
} from '@material-ui/core';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import {
    HTMLProps, MouseEvent, useState,
} from 'react';
import { useCallbackRef } from 'use-callback-ref';

import { DesignConceptChip } from 'src/components/Entity/EntityBulkSelectionToolbar/DesignConceptChip';

export interface PropTypes extends HTMLProps<HTMLDivElement> {
    designConceptIds?: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
    scrollBox: {
        display: 'inline-flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },

}));

export const DesignConceptBox = (props: PropTypes): JSX.Element => {
    const {
        designConceptIds = [],
    } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const scrollContainer = useCallbackRef<HTMLDivElement>(null, (current) => {
        if (current) {
            current.addEventListener('wheel', (e) => {
                const containerScrollPosition = current.scrollLeft;

                if (current != null) {
                    const diff: number = containerScrollPosition + e.deltaY * 3 + e.deltaX * 3;

                    current.scrollTo({
                        top: 0,
                        left: diff,
                        behavior: 'smooth',
                    });
                }
                e.preventDefault();
            });
        }
    });

    const handleExpand = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        setExpanded(!expanded);
    };

    return (
        <>
            {expanded && (
                <>
                    <Button
                        endIcon={<ChevronRight />}
                        variant="outlined"
                        onClick={handleExpand}
                    >
                        Hide
                    </Button>
                    <div className={classes.scrollBox} ref={scrollContainer}>
                        {designConceptIds?.map((designConceptId) => (
                            <DesignConceptChip
                                designConceptId={designConceptId}
                                key={designConceptId}
                            />
                        ))}
                    </div>
                </>
            )}
            {!expanded && (
                <Button
                    startIcon={<ChevronLeft />}
                    variant="outlined"
                    onClick={handleExpand}
                >
                    Show Design Concept Filters
                </Button>
            )}
        </>
    );
};
