import {
    Card,
    CardHeader,
    makeStyles,
    Skeleton,
    Theme,
} from '@material-ui/core';
import { ComponentProps } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    headerSkeleton: {
        marginBottom: theme.spacing(2),
    },
}));

export const ResultSkeleton = (props: ComponentProps<typeof Card>): JSX.Element => {
    const classes = useStyles();

    return (
        <Card {...props}>
            <CardHeader
                subheader={(
                    <Skeleton
                        animation="wave"
                        className={classes.headerSkeleton}
                        width="50%"
                    />
                )}
                title={(
                    <Skeleton
                        animation="wave"
                        className={classes.headerSkeleton}
                        width="80%"
                    />
                )}
            />
        </Card>
    );
};
