import { Checkbox } from '@material-ui/core';
import {
    ChangeEvent, ComponentProps, MouseEvent,
} from 'react';

import { StyledTreeItem } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/StyledTreeItem';
import { NumberSelectedIcon } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/SelectableTree/NumberSelectedIcon';
import { OpenCategoryButton } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/OpenCategoryButton';

type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;
type Category = Models.ContentStoreApi.V3.Category;

export interface PropTypes<T extends (Category | TaxonomyNode)> extends Omit<ComponentProps<typeof StyledTreeItem>, 'onChange' | 'children' | 'nodeId'> {
    node: T;
    depth: number;
    children?: (node: TaxonomyNode, index: number, depth: number) => JSX.Element;
    onChange?: (node: T) => (
        event: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLInputElement>,
    ) => void;
    checked: boolean;
    findNumberSelectedCategories: (children: TaxonomyNode[] | undefined) => number;
    categoryId: string;
    descriptionText?: string;
}

export const SelectableTreeItem = <T extends (Category | TaxonomyNode)>(props: PropTypes<T>): JSX.Element => {
    const {
        categoryId,
        node,
        depth,
        children,
        onChange,
        checked,
        findNumberSelectedCategories,
        labelText,
        descriptionText,
        ...rest
    } = props;

    const handleCheckboxMouseDown = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <StyledTreeItem
            descriptionText={descriptionText}
            labelAction={onChange && (
                <Checkbox
                    checked={checked}
                    inputProps={{
                        'aria-label': `${checked ? 'Un-select' : 'Select'} ${labelText}`,
                    }}
                    onChange={onChange(node)}
                    onClick={onChange(node)}
                    onMouseDown={handleCheckboxMouseDown}
                />
            )}
            labelInfo={(
                <>
                    <OpenCategoryButton categoryId={categoryId} />
                    <NumberSelectedIcon
                        count={findNumberSelectedCategories(('children' in node) ? node.children : undefined)}
                    />
                </>
                        )}
            labelText={labelText}
            nodeId={`${categoryId}-${depth}`}
            {...rest}
        >
            {('children' in node) && children && !!node.children && node.children.map((c, index) => children(c, index, depth + 1))}
        </StyledTreeItem>
    );
};
