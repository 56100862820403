import {
    CardContent, Chip, Grid, makeStyles, Theme,
} from '@material-ui/core';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { SimpleCard } from 'src/components/common/SimpleCard';

export interface PropTypes {
    productSpecifications?: Models.ContentStoreApi.V3.ProductSpecification[];
    productName: string;
    productVersion: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    productSpecificationsGrid: {
        gap: theme.spacing(3),
        gridTemplateColumns: '1fr 1fr',
    },
}));

export const ProductOptionsGrid = (props: PropTypes): JSX.Element => {
    const {
        productSpecifications,
        productName,
        productVersion,
    } = props;
    const classes = useStyles();

    return (
        <CSSGrid className={classes.productSpecificationsGrid}>
            {productSpecifications?.map((productSpecification): unknown => (
                <SimpleCard
                    id={productSpecification.selector.toString()}
                    subtitle="Content Selector"
                    title={`${productName} (v${productVersion})`}
                >
                    <CardContent>
                        <Grid container spacing={2}>
                            {Object.entries(productSpecification.options).map(([name, value]) => (
                                <Grid item>
                                    <Chip label={`${name}: ${value}`} variant="filled" />
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </SimpleCard>
            ))}
        </CSSGrid>
    );
};
