export const generateEntityRankHref = (
    entity: Models.ContentStoreApi.V3.Entity,
    rankingStrategy: Models.ContentStoreApi.V3.RankingStrategy,
): string => {
    const href = `${entity.href}/RankingStrategy/${rankingStrategy.rankingStrategyId}`;

    return href;
};

export const generateRanksByCulture = (
    cultures: string[],
    ranksByCulture: Models.ContentStoreApi.V3.EntityRanks.RanksByCulture,
    inputValue?: number,
): Models.ContentStoreApi.V3.EntityRanks.RanksByCulture => cultures.reduce((ranksMemo, c) => {
    /* eslint-disable no-param-reassign */
    if (!ranksMemo[c] || ranksMemo[c] !== inputValue) {
        if (inputValue) {
            ranksMemo[c] = inputValue;
        } else {
            delete ranksMemo[c];
        }
    }
    return ranksMemo;
    /* eslint-enable no-param-reassign */
}, ranksByCulture);

export const getRank = (
    entity: Models.ContentStoreApi.V3.Entity,
    cultureOption: Models.ContentStoreApi.V3.Culture,
    rankingStrategy: Models.ContentStoreApi.V3.RankingStrategy,
): number => {
    const href = generateEntityRankHref(entity, rankingStrategy);
    const strategyRanks = entity._embedded?.strategyRanks[href];
    const rank = strategyRanks.ranks.ranksByCulture[cultureOption.cultureValue];

    return rank;
};

export const compareCultures = (a: Models.ContentStoreApi.V3.Culture, b: Models.ContentStoreApi.V3.Culture): number => {
    if (a.cultureValue < b.cultureValue) {
        return -1;
    }
    if (a.cultureValue > b.cultureValue) {
        return 1;
    }
    return 0;
};
