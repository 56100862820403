import clsx from 'clsx';
import {
    makeStyles, Theme, Typography,
} from '@material-ui/core';
import { ComponentProps, ReactNode } from 'react';

import { CSSGrid } from 'src/components/common/CSSGrid';

export interface PropTypes extends ComponentProps<typeof CSSGrid> {
    helperText?: string;
    title?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingBottom: theme.spacing(4),
        width: '100%',
    },
}));

export const TaggingHeader = (props: PropTypes): JSX.Element => {
    const {
        children,
        className,
        helperText,
        title,
        display,
        gridTemplateColumns = '1fr 33%',
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <CSSGrid
            className={clsx(classes.root, className)}
            gap={2}
            {...rest}
        >
            <CSSGrid
                alignItems="center"
                gap={2}
                gridTemplateAreas={'". ."'}
                gridTemplateColumns={gridTemplateColumns}
            >
                {typeof title === 'string' && (
                    <Typography variant="h6">
                        {title}
                    </Typography>
                )}
                {typeof title !== 'string' && (title)}
                <div>{children}</div>
            </CSSGrid>
            {helperText && (
                <Typography variant="caption">
                    {helperText}
                </Typography>
            )}
        </CSSGrid>
    );
};
