import { DataSource } from 'src/constants';

export const generateKeywordSuggestionsPlaceholderData = (): Models.ContentStoreApi.V3.SourcedKeyword[] => (
    [{
        id: '-1',
        href: '',
        source: DataSource.Loading,
        keywordName: 'Loading Suggestions...',
        isExcludedFromAltText: false,
        toBeDeleted: false,
        translations: {},
    }]
);
