import { makeStyles, Theme } from '@material-ui/core';
import { ComponentProps, ReactNode } from 'react';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { FormArea } from 'src/components/common/DetailsDrawer/FormArea';

export interface PropTypes extends Omit<ComponentProps<typeof FormArea>, 'title'> {
    textColumn?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
    imageAndTextColumnGrid: {
        gap: theme.spacing(8),
        gridTemplateColumns: 'minmax(auto, max-content) minmax(50%, auto)',
        paddingRight: theme.spacing(12),
        paddingBottom: theme.spacing(4),
    },
    thumbnailBox: {
        width: theme.spacing(62),
        height: theme.spacing(62),
        display: 'flex',
        justifyContent: 'center',
    },
}));

export const HeaderImageThumbnail = (props: PropTypes): JSX.Element => {
    const {
        textColumn,
        children,
    } = props;
    const classes = useStyles();

    return (
        <CSSGrid className={classes.imageAndTextColumnGrid}>
            <div className={classes.thumbnailBox}>
                {children}
            </div>
            {textColumn}
        </CSSGrid>
    );
};
