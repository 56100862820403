import { QueryFunctionContext } from 'react-query';

import { ContentStoreRankingStrategiesService } from 'src/services/RankingStrategies';

export const QUERY_KEY = 'rankingStrategy';

export const queryRankingStrategy = (
    context: QueryFunctionContext<App.RankingStrategy.SingleQueryKey>,
): Promise<Models.ContentStoreApi.V3.RankingStrategy> => {
    const { queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentStoreRankingStrategiesService.getRankingStrategy(
        accessToken,
        search.id,
    );
};
