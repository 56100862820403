import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryCategories, QUERY_KEY } from 'src/queries/queryCategories';

type PagedCategories = Models.V3.PageResult<Models.ContentStoreApi.V3.SourcedCategory>;
type PagedQueryKey = App.Categories.PagedQueryKey;

export const useQueryCategories = (
    accessToken: string | undefined | false,
    search: App.Categories.PagedSearch,
    options?: UseQueryOptions<PagedCategories, Error, PagedCategories, PagedQueryKey>,
): UseQueryResult<PagedCategories, Error> => useQuery<PagedCategories, Error, PagedCategories, PagedQueryKey>(
    [QUERY_KEY, accessToken, search],
    queryCategories,
    options,
);
