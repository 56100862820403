import { TableCell, TableRow } from '@material-ui/core';

import { Loader } from 'src/components/common/Loader';

export const LoadingRow = (): JSX.Element => (
    <TableRow>
        <TableCell colSpan={3}>
            <Loader />
        </TableCell>
    </TableRow>
);
