import {
    makeStyles, Dialog, Typography, Theme, Button, MenuItem,
} from '@material-ui/core';
import { Cancel, Search, Tune } from '@material-ui/icons';
import clsx from 'clsx';
import {
    ChangeEvent, SyntheticEvent, useRef, useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { LoadableSelect } from 'src/components/common/inputs/LoadableSelect';
import { useQueryProducts } from 'src/hooks/useQueryProducts';
import { useQueryProductVersion } from 'src/hooks/useQueryProductVersion';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { isProductKey } from 'src/utils';
import { ProductOptionToggles } from 'src/components/common/Search/FacetedSearch/ProductOptionSearch/ProductOptionToggles';
import { ProductOptionTogglesSkeleton } from 'src/components/common/Search/FacetedSearch/ProductOptionSearch/ProductOptionTogglesSkeleton';
import { ZeroState } from 'src/components/common/Tagging/ZeroState';
import { productSearchDialogOpenState } from 'src/atoms/productSearchDialogOpenAtom';
import { StandardSearch } from 'src/components/common/Search/StandardSearch';
import { UseQueryResult } from 'react-query';

export interface PropTypes {
    existingSearch: FacetedSearch.FacetedSearchType;
    submitProductOptionDialog: (
        productKey: string,
        productVersion: string,
        productOptions: App.Entities.ProductOptions
    ) => void
}

type Product = Models.ContentStoreApi.V3.Product;

const useStyles = makeStyles((theme: Theme) => ({
    alignCenter: {
        alignItems: 'center',
        display: 'inline-flex',
    },
    cancel: {
        color: theme.palette.common.white,
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        '& .MuiPaper-root': {
            alignSelf: 'flex-start',
            padding: theme.spacing(8, 8, 3),
            width: '100%',
        },
        '& .MuiDialog-container': {
            width: '100%',
        },
    },
    buttons: {
        display: 'grid',
        paddingTop: theme.spacing(4),
        gap: theme.spacing(2),
        gridTemplateAreas: '". ."',
        justifyContent: 'center',
    },
    container: {
        maxWidth: theme.spacing(1250),
    },
    grid: {
        gap: theme.spacing(4),
        overflow: 'hidden',
    },
    buttonGroup: {
        gridAutoFlow: 'column',
    },
    errorButton: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: 'rgba(209, 44, 11, 0.04)',
            borderColor: theme.palette.error.main,
        },
    },
    actions: {
        backgroundColor: theme.palette.common.white,
        bottom: 0,
        gap: theme.spacing(4),
        justifyContent: 'flex-end',
        gridAutoFlow: 'column dense',
        position: 'sticky',
    },
    loadableSelect: {
        '& .MuiOutlinedInput-root': {
            background: theme.palette.common.white,
        },
        width: theme.spacing(45),
    },
}));

export const ProductOptionSearchDialog = (props: PropTypes): JSX.Element => {
    const { existingSearch, submitProductOptionDialog } = props;
    const classes = useStyles();

    const existingSearchValues = Object.values(existingSearch);
    const existingProductOptionsSearchValue = { ...existingSearchValues.find((f) => f.facet?.key === 'productOptions')?.productOptionsValue };
    const [productKey, setProductKey] = useState<string | undefined>(
        existingSearchValues.find((f) => f.facet?.key === 'productKey')?.value,
    );

    const [productVersion, setProductVersion] = useState<string | undefined>(existingSearchValues.find((f) => f.facet?.key === 'productVersion')?.value ?? '');
    const [selectedProductOptions, setSelectedProductOptions] = useState<App.Entities.ProductOptions>(
        existingProductOptionsSearchValue,
    );
    const [badgeCount, setBadgeCount] = useState<number>(Object.values(existingProductOptionsSearchValue)
        .reduce((accum, values) => accum + values.length, 0));
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const [productSearchDialogOpen, setProductSearchDialogOpen] = useRecoilState(productSearchDialogOpenState);

    const {
        data: versionsResults,
        isLoading: isVersionsLoading,
        isError: isVersionsError,
    } = useQueryProducts(accessToken, {
        productKey: productKey ?? '',
        limit: 10000,
    }, {
        enabled: !!productKey && !!accessToken,
        useErrorBoundary: false,
        suspense: false,
    });

    const product = productKey ? versionsResults?.results.find((v) => v.active)
        ?? versionsResults?.results[0] : undefined;

    // When the versions query result changes, set the selected version as the active one if available
    if (product?.productVersion && !productVersion) {
        setProductVersion(product.productVersion.toString());
    }

    const queryProductVersionSearch = {
        productKey: productKey || '',
        version: productVersion || '',
    };

    const { data: productResponse, isLoading: isProductResponseLoading } = useQueryProductVersion(
        accessToken,
        queryProductVersionSearch,
        {
            enabled: !!isProductKey(productKey) && !!productVersion,
            suspense: false,
            useErrorBoundary: false,
        },
    );

    const availableProductOptions = productKey && productVersion
        ? productResponse?.result && !!productResponse?.result.length
        && productResponse.result[0].options || [] : undefined;

    const handleProductKeyChange = (event: SyntheticEvent<Element, Event>, value: (string | Product)[]): void => {
        event.preventDefault();
        event.stopPropagation();

        const val = value[0];

        setProductKey(typeof val === 'object' ? val.productKey : val);
        setProductVersion(undefined);
        setSelectedProductOptions({});
        setBadgeCount(0);
    };

    const handleProductVersionChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        setProductVersion(event.target.value);
        setSelectedProductOptions({});
        setBadgeCount(0);
    };

    const handleAddToSearch = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        if (productKey && productVersion) {
            setProductSearchDialogOpen(false);
            submitProductOptionDialog(productKey, productVersion, selectedProductOptions);
        }
    };

    const handleProductOptionChange = (optionName: string, optionValue: string): void => {
        const selectedProductOptionsCopy = { ...selectedProductOptions };

        if (selectedProductOptionsCopy[optionName]) {
            if (selectedProductOptionsCopy[optionName].includes(optionValue)) {
                if (selectedProductOptionsCopy[optionName].length === 1) {
                    delete selectedProductOptionsCopy[optionName];
                } else {
                    selectedProductOptionsCopy[optionName] = selectedProductOptionsCopy[optionName]
                        .filter((o) => o !== optionValue);
                }
                setBadgeCount(badgeCount - 1);
            } else {
                const newOptionsArr = selectedProductOptionsCopy[optionName];

                newOptionsArr.push(optionValue);
                selectedProductOptionsCopy[optionName] = newOptionsArr;
                setBadgeCount(badgeCount + 1);
            }
        } else {
            selectedProductOptionsCopy[optionName] = [optionValue];
            setBadgeCount(badgeCount + 1);
        }

        setSelectedProductOptions(selectedProductOptionsCopy);
    };

    const useQueryOptions = (inputValue?: string): UseQueryResult<Product[], Error> => {
        const { data: productsData, ...other } = useQueryProducts(
            accessToken,
            {
                productKey: isProductKey(inputValue) ? inputValue : '',
                productName: !isProductKey(inputValue) ? inputValue : '',
                limit: 10000,
            },
            {
                enabled: !!inputValue && !!accessToken,
                useErrorBoundary: false,
                suspense: false,
            },
        );

        const dedupeProducts = productsData?.results?.filter((p) => p.active);

        return {
            data: dedupeProducts,
            ...other,
        } as UseQueryResult<Product[], Error>;
    };

    const valueRef = useRef<Product | undefined>(product);

    return (
        <Dialog
            className={classes.modal}
            open={productSearchDialogOpen}
            onClose={(): void => setProductSearchDialogOpen(false)}
        >
            <CSSGrid className={clsx(classes.container, classes.grid)}>
                <div className={classes.alignCenter}>
                    <Tune />
                    <Typography
                        pl={2}
                        textAlign="left"
                        variant="h6"
                    >
                        Product Search
                    </Typography>
                </div>
                <Typography variant="caption">
                    Search for a product by Product Key or Product Name, then select a Product Version for your search.
                    Product Options will become selectable once the previous inputs are filled in.
                </Typography>
                <CSSGrid gap={4} gridTemplateColumns="2fr 1fr">
                    <StandardSearch
                        allowCopy
                        autoComplete
                        getOptionLabel={(option: Product): string => `${option.productKey}${option.productName ? ` (${option.productName})` : ''}`}
                        label="Product Key/Name"
                        multiple={false}
                        size="small"
                        useQueryOptions={useQueryOptions}
                        value={valueRef.current}
                        onChange={handleProductKeyChange}
                    />
                    <LoadableSelect
                        disabled={(!isProductKey(productKey)) || isVersionsError}
                        helperText={isVersionsError && 'Failed to fetch product versions. Try again.'}
                        id="product-version-select"
                        isLoading={isVersionsLoading}
                        label="Product Version"
                        name="productVersion"
                        SelectProps={{ MenuProps: { PaperProps: { style: { maxHeight: '200px' } } } }}
                        value={productVersion}
                        onChange={handleProductVersionChange}
                    >
                        {productVersion && versionsResults?.results?.length && versionsResults.results.sort(
                            (a, b) => (b?.productVersion || 0) - (a.productVersion || 0),
                        )
                            .map((option) => (
                                <MenuItem
                                    key={`${option.productVersion}-version-key`}
                                    value={option.productVersion.toString()}
                                >
                                    {`${option.productVersion}${option.active ? ' (Current)' : ''}`}
                                </MenuItem>
                            ))}
                    </LoadableSelect>
                </CSSGrid>
                <Typography
                    textAlign="left"
                    variant="subtitle2"
                >
                    {`Product Options${badgeCount ? ` (${badgeCount} selected)` : ''}`}
                </Typography>
                {(!productKey || !productVersion) && <ZeroState />}
                {isProductResponseLoading && <ProductOptionTogglesSkeleton />}
                {!isProductResponseLoading && (
                <ProductOptionToggles
                    availableProductOptions={availableProductOptions}
                    handleFilterChange={handleProductOptionChange}
                    selectedProductOptions={selectedProductOptions}
                />
                )}
                <CSSGrid
                    className={classes.actions}
                    justifyContent="flex-end"
                >
                    <Button
                        color="secondary"
                        startIcon={<Cancel />}
                        variant="outlined"
                        onClick={(): void => setProductSearchDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!isProductKey(productKey)}
                        startIcon={<Search />}
                        variant="contained"
                        onClick={handleAddToSearch}
                    >
                        Submit Search
                    </Button>
                </CSSGrid>
            </CSSGrid>
        </Dialog>
    );
};
