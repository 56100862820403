import {
    makeStyles, Theme, Tooltip, Button, Typography,
} from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { useState } from 'react';
import { LocalizedPreviews } from 'src/components/common/LocalizedPreview/';
import { UserEvent } from 'src/constants';
import { useTrackEvent } from 'src/hooks/useTrackEvent';

export interface PropTypes {
    entity: Models.ContentStoreApi.V3.Entity;
}

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        borderRadius: theme.spacing(15),
        whiteSpace: 'nowrap',
        padding: theme.spacing(2.5),
        height: 'min-content',
        width: 'min-content',
    },
}));

export const LocalizedPreviewButton = (props: PropTypes): JSX.Element => {
    const [openModal, setOpenModal] = useState(false);
    const {
        entity,
    } = props;
    const classes = useStyles();
    const { trackEvent } = useTrackEvent();

    const handlePreviewButton = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        trackEvent({ eventName: UserEvent.LocalizedPreviewButtonClick });
        setOpenModal(true);
    };

    return (
        <>
            <Tooltip title="Preview design in all locales">
                <Button
                    className={classes.button}
                    color="primary"
                    size="medium"
                    variant="outlined"
                    onClick={handlePreviewButton}
                >
                    <Launch />
                    <Typography>Localized Previews</Typography>
                </Button>
            </Tooltip>
            {openModal && (
                <LocalizedPreviews
                    entity={entity}
                    handleClose={(): void => setOpenModal(false)}
                    open={openModal}
                />
            )}
        </>
    );
};
