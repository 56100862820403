import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { mutateTaxonomy } from 'src/mutations/mutateTaxonomy';
import { QUERY_KEY as S_QUERY_KEY } from 'src/queries/queryTaxonomies';

type Taxonomy = Models.ContentStoreApi.V3.Taxonomy;
type MutationVariables = App.Taxonomy.MutationVariables;

export const useMutateTaxonomy = (): UseMutationResult<Taxonomy, Error, MutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Taxonomy, Error, MutationVariables>(
        mutateTaxonomy,
        {
            onSuccess: () => {
                setTimeout(() => { queryClient.refetchQueries([S_QUERY_KEY]); }, 1000);
            },
            useErrorBoundary: true,
        },
    );
};
