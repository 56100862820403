import { Grid, TextField } from '@material-ui/core';
import { ComponentProps } from 'react';

export interface PropTypes extends Omit<ComponentProps<typeof Grid>, 'id' | 'onChange'> {
    id: string;
    internalName: string;
}

export const DesignTaggingGuidanceDetailsForm = (props: PropTypes): JSX.Element => {
    const {
        id,
        internalName,
        ...rest
    } = props;

    return (
        <Grid container direction="column" spacing={6} {...rest}>
            <Grid item>
                <TextField
                    disabled
                    fullWidth
                    label="ID"
                    size="small"
                    value={id}
                />
            </Grid>
            <Grid container item spacing={4}>
                <Grid item xs>
                    <TextField
                        disabled
                        fullWidth
                        label="DUC name"
                        size="small"
                        value={internalName}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
