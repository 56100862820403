import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { mutateKeyword } from 'src/mutations/mutateKeyword';
import { QUERY_KEY } from 'src/queries/queryKeyword';

export const useMutateKeyword = (
    accessToken: string,
    id: string,
): UseMutationResult<Models.ContentStoreApi.V3.Keyword, Error, App.Keyword.MutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Models.ContentStoreApi.V3.Keyword, Error, App.Keyword.MutationVariables>(
        mutateKeyword,
        {
            onSuccess: (response: Models.ContentStoreApi.V3.Keyword) => {
                queryClient.setQueryData([QUERY_KEY, accessToken, id], response);
            },
            useErrorBoundary: true,
        },
    );
};
