import { QueryFunctionContext } from 'react-query';
import { DEFAULT_LIMIT } from 'src/constants';

import { ContentApiTaxonomyAssociationService } from 'src/services/TaxonomyAssociation';

export const QUERY_KEY = 'taxonomyAssociation';

export const queryTaxonomyAssociation = (
    context: QueryFunctionContext<App.TaxonomyAssociation.PagedQueryKey>,
): Promise<Models.V3.PageResult<Models.ContentApi.V3.TaxonomyAssociation>> => {
    const { pageParam: offset, queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentApiTaxonomyAssociationService.getTaxonomyAssociation(
        accessToken,
        search,
        offset,
        DEFAULT_LIMIT,
    );
};
