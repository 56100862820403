import clsx from 'clsx';
import {
    Alert, AlertTitle, makeStyles, Theme,
} from '@material-ui/core';
import { TreeView } from '@material-ui/lab';
import {
    ComponentProps, HTMLProps, SyntheticEvent, useEffect, useState,
} from 'react';
import { UseQueryResult } from 'react-query';
import { useRecoilValue } from 'recoil';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { StandardSearch } from 'src/components/common/Search/StandardSearch';
import { queryTaxonomies, QUERY_KEY } from 'src/queries/queryTaxonomies';
import { useInfiniteScrollQuery } from 'src/hooks/useInfiniteScrollQuery';
import { InfiniteTaxonomyTreeView } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/InfiniteTaxonomyTreeView';
import { useQueryTaxonomies } from 'src/hooks/useQueryTaxonomies';
import { Loader } from 'src/components/common/Loader';
import {
    getOptionLabel,
    filterBySubstring,
    getOptionSelected,
    sort,
} from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/utils';
import { MAX_RETRY_TIMEOUT, MIN_RETRY_TIMEOUT } from 'src/constants';

type Category = Models.ContentStoreApi.V3.SourcedCategory;
type PagedTaxonomyKey = App.Taxonomies.PagedQueryKey;
type Taxonomy = Models.ContentStoreApi.V3.Taxonomy;
type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;
type ValueMap<T extends Category> = Record<string, T>;

export interface TaxonomyPanelPropTypes<T extends Category> extends Omit<HTMLProps<HTMLDivElement>, 'value' | 'onChange' | 'draggable'> {
    selectable?: boolean,
    draggable?: string,
    droppable?: string[],
    gridProps?: Partial<ComponentProps<typeof CSSGrid>>;
    onChange: (event: SyntheticEvent<Element, Event>, value: Category[]) => void;
    treeProps?: Partial<ComponentProps<typeof TreeView>>;
    valueMap?: ValueMap<T>;
    handleAsyncPaste: (ids: string[]) => Promise<T[]>;
}

export const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    grid: {
        height: '100%',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        gridTemplateRows: 'auto 1fr',
        gridAutoFlow: 'row dense',
    },
    loader: {
        marginTop: theme.spacing(10),
    },
    tree: {
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
    },
    root: {
        height: 'fit-content',
    },
    content: {
        padding: theme.spacing(5),
    },
    search: {
        paddingBottom: theme.spacing(6),
    },
}));

export const TaxonomyPanel = <T extends Category>(props: TaxonomyPanelPropTypes<T>): JSX.Element => {
    const {
        selectable = false,
        className,
        onChange,
        valueMap,
        draggable,
        droppable,
        handleAsyncPaste,
        ...rest
    } = props;
    const classes = useStyles();
    const [search, setSearch] = useState<(T | Taxonomy)[]>([]);
    const [taxonomyNodes, setTaxonomyNodes] = useState<TaxonomyNode[] | undefined>(undefined);
    const accessToken = useRecoilValue(isAuthorizedQuery);

    const {
        data,
        error,
        fetchNextPage,
        isError,
        isLoading,
        isSuccess,
        hasNextPage,
    } = useInfiniteScrollQuery<Taxonomy, Error, PagedTaxonomyKey>(
        [`${QUERY_KEY}_taxonomy_panel`, accessToken, {
            culture: 'en-us', // TODO remove
            names: search.map((s) => (s as Taxonomy).taxonomyName || (s as T).internalName),
            maxDepth: 2,
        }],
        queryTaxonomies,
        {
            suspense: false,
            retry: 3,
            retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : MIN_RETRY_TIMEOUT, MAX_RETRY_TIMEOUT),
        },
    );

    useEffect(() => {
        if (isSuccess && data && !!data.length) {
            setTaxonomyNodes(data.map((t) => t.taxonomyNode));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, search]);

    const loadMore = async (): Promise<void> => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    const useQueryOptions = (inputValue?: string): UseQueryResult<(T | Taxonomy)[], Error> => {
        const inputValueNonNull = inputValue as string;
        const { data: taxonomies, ...other } = useQueryTaxonomies(
            accessToken,
            {
                names: inputValue ? [inputValueNonNull] : undefined,
                maxDepth: 2,
            },
            {
                enabled: !!accessToken,
                suspense: false,
                refetchOnWindowFocus: false,
            },
        );

        const options = taxonomies && taxonomies?.results?.length > 0
            ? taxonomies.results
                .map((item) => filterBySubstring(item, inputValue))
                .filter(Boolean)
                .concat(search)
                // @ts-expect-error
                .sort(sort)
            : search;

        return {
            data: options,
            ...other,
        } as UseQueryResult<(T | Taxonomy)[], Error>;
    };

    const handleSearchChange = (
        event: SyntheticEvent<Element>,
        v: (T | Taxonomy)[] | T | Taxonomy,
    ): void => {
        event.preventDefault();
        setSearch(v as T[]);
    };

    return (
        <CSSGrid {...rest} className={clsx(classes.grid, className)}>
            <StandardSearch<T | Taxonomy, true, false, false>
                autoComplete
                className={classes.search}
                freeSolo={false}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                // helperText="Search taxonomies by either taxonomy names or category names"
                label="Filter Taxonomies & Categories"
                placeholder="Start typing to search taxonomies..."
                useQueryOptions={useQueryOptions}
                value={search}
                onChange={handleSearchChange}
                onPaste={handleAsyncPaste}
            />
            <div className={classes.tree}>
                {isLoading && (<Loader className={classes.loader} />)}
                {isError && (
                    <Alert severity="error">
                        <AlertTitle>Failed to load Taxonomies</AlertTitle>
                        {error?.message}
                    </Alert>
                )}
                {taxonomyNodes && (
                    <DndProvider backend={HTML5Backend}>
                        <InfiniteTaxonomyTreeView
                            draggable={draggable}
                            droppable={droppable}
                            hasMore={!!hasNextPage}
                            loadMore={loadMore}
                            nodes={taxonomyNodes}
                            selectable={selectable}
                            setData={setTaxonomyNodes}
                            valueMap={valueMap}
                            onChange={onChange}
                        />
                    </DndProvider>
                )}
            </div>
        </CSSGrid>
    );
};
