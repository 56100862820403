import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { DataSource } from 'src/constants';

class ContentStoreCategoriesApi extends AbstractService implements Services.ContentStoreApi.Categories {
    public async createCategory(
        bearerToken: string | undefined | false,
        body: Models.ContentStoreApi.V3.CreateCategory,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Category> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request createCategory. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/categories`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .post(url, {
                    json: body,
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Category>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to create category',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async deleteCategory(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<void> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request deleteCategory. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/categories/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            await this.api
                .delete(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to delete category',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async getCategories(
        bearerToken: string | undefined | false,
        internalName?: string,
        ids?: string[],
        keys?: string[],
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.SourcedCategory>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getCategories. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/categories`;
        const searchParams = qs.stringify({
            internalName,
            ids,
            keys,
            offset,
            limit,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.Category>>();

            return {
                ...json,
                results: json.results.map((item) => ({
                    ...item,
                    source: DataSource.Saved,
                })),
            };
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to get categories',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async getCategory(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.SourcedCategory> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getCategory. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/categories/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Category>();

            return {
                ...json,
                source: DataSource.Saved,
            };
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch category',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async updateCategory(
        bearerToken: string | undefined | false,
        id: string,
        body: Models.ContentStoreApi.V3.UpdateCategory,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Category> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request updateCategory. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/categories/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .put(url, {
                    signal,
                    json: body,
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Category>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to update category',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async getCategoryMonolithKeys(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.CategoryMonolithKey[]> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getCategoryMonolithKeys. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/categorymonolithkeys`;
        const searchParams = qs.stringify({
            requestor: config.appName,
            categoryIds: id,
            limit: 10000,
        });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.CategoryMonolithKey>>();

            return json.results;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch categoryMonolithKeys',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async getCategoryMonolithKeysByMonolithKey(
        bearerToken: string | undefined | false,
        monolithKey: number,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.CategoryMonolithKey[]> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getCategoryMonolithKeys. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/categorymonolithkeys`;
        const searchParams = qs.stringify({
            requestor: config.appName,
            monolithKeys: monolithKey,
            limit: 10000,
        });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.CategoryMonolithKey>>();

            return json.results;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch categoryMonolithKeys by monolithKey',
                info: (e as Error).message,
                url,
                monolithKey,
                searchParams,
            });
        }
    }

    public async createCategoryMonolithKey(
        bearerToken: string | undefined | false,
        monolithKey: number,
        categoryHref: Models.V3.Href,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.CategoryMonolithKey> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request addCategoryMonolithKeys. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/categorymonolithkeys`;
        const body = {
            monolithKey,
            category: {
                href: categoryHref,
            },
        };
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .post(url, {
                    signal,
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                    json: body,
                })
                .json<Models.ContentStoreApi.V3.CategoryMonolithKey>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to add categoryMonolithKey',
                info: (e as Error).message,
                url,
                body,
            });
        }
    }

    public async deleteCategoryMonolithKey(
        bearerToken: string | undefined | false,
        categoryMonolithKeyHref: Models.V3.Href,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.CategoryMonolithKey[]> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request deleteCategoryMonolithKeys. No bearerToken provided.',
            });
        }

        const url = categoryMonolithKeyHref.substr(1, categoryMonolithKeyHref.length - 1);
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .delete(url, {
                    signal,
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.CategoryMonolithKey>>();

            return json.results;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to delete categoryMonolithKey',
                info: (e as Error).message,
                url,
            });
        }
    }

    public async mergeCategory(
        bearerToken: string | undefined | false,
        id: string,
        replacementId: string,
        signal?: AbortSignal,
    ): Promise<string> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request mergeCategory. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/categories/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
            replacementCategoryId: replacementId,
        });

        try {
            await this.api
                .delete(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to merge category',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }

        return replacementId;
    }
}

export const ContentStoreCategoriesService = new ContentStoreCategoriesApi(config.services.contentStoreApi);
