import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { mutateCategoryMonolithKeys } from 'src/mutations/mutateCategoryMonolithKeys';
import { QUERY_KEY } from 'src/queries/queryCategoryMonolithKeys';

export const useMutateCategoryMonolithKeys = (
    accessToken: string,
    id: string,
): UseMutationResult<
Models.ContentStoreApi.V3.CategoryMonolithKey[],
Error,
App.CategoryMonolithKey.MutationVariables
> => {
    const queryClient = useQueryClient();

    return useMutation<
    Models.ContentStoreApi.V3.CategoryMonolithKey[],
    Error,
    App.CategoryMonolithKey.MutationVariables
    >(
        mutateCategoryMonolithKeys,
        {
            onSuccess: (response: Models.ContentStoreApi.V3.CategoryMonolithKey[]) => {
                queryClient.setQueryData([QUERY_KEY, accessToken, id], response);
            },
            useErrorBoundary: true,
        },
    );
};
