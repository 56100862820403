import {
    Card,
    CardActionArea,
    CardHeader,
    makeStyles,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import {
    ComponentProps,
    MouseEvent,
} from 'react';

export interface PropTypes extends Omit<ComponentProps<typeof Card>, 'onClick'> {
    id: string;
    title: string;
    subtitle?: string;
    onClick?: (id: string) => void;
}

const useStyles = makeStyles({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardActionArea: {
        height: '100%',
    },
    cardHeader: {
        height: '100%',
        alignItems: 'flex-start',
        maxWidth: 'auto',
        display: 'block',
    },
});

export const SimpleCard = (props: PropTypes): JSX.Element => {
    const {
        children,
        className,
        id,
        title,
        subtitle = 'ID',
        onClick,
        ...rest
    } = props;
    const classes = useStyles();

    const handleShowDrawer = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (onClick) {
            onClick(id);
        }
    };

    return (
        <Card {...rest} className={clsx(classes.card, className)}>
            <CardActionArea className={classes.cardActionArea} disabled={!onClick} onClick={handleShowDrawer}>
                <CardHeader
                    className={classes.cardHeader}
                    subheader={`${subtitle}: ${id}`}
                    subheaderTypographyProps={{
                        variant: 'caption',
                    }}
                    title={(
                        <Typography
                            display="block"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            variant="h6"
                        >
                            {title}
                        </Typography>
                    )}
                    titleTypographyProps={{
                        variant: 'h6',
                    }}
                />
            </CardActionArea>
            {children}
        </Card>
    );
};
