import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryTaxonomyAssociation, QUERY_KEY } from 'src/queries/queryTaxonomyAssociaion';

type PagedTaxonomyAssociation = Models.V3.PageResult<Models.ContentApi.V3.TaxonomyAssociation>;

export const useQueryTaxonomyAssociation = (
    accessToken: string | undefined | false,
    search: App.TaxonomyAssociation.PagedSearch,
    options?: UseQueryOptions<PagedTaxonomyAssociation,
    Error,
    PagedTaxonomyAssociation,
    App.TaxonomyAssociation.PagedQueryKey
    >,
): UseQueryResult<PagedTaxonomyAssociation, Error> => useQuery<PagedTaxonomyAssociation,
Error,
PagedTaxonomyAssociation,
App.TaxonomyAssociation.PagedQueryKey
>(
    [QUERY_KEY, accessToken, search],
    queryTaxonomyAssociation,
    options,
);
