import {
    Grid,
    FormControl,
    Toolbar,
    TextField,
    Skeleton,
    Typography,
    experimentalStyled,
    makeStyles,
} from '@material-ui/core';
import { ComponentProps } from 'react';
import { SaveLoadingButton } from 'src/components/common/buttons/SaveLoadingButton';

const StyledToolbar = experimentalStyled(Toolbar)(({ theme }) => ({
    padding: `${theme.spacing(10)} ${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(8)}`,
    minHeight: 'auto',
    minWidth: '680px',
    [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(10)} ${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(8)}`,
        minHeight: 'auto',
    },
}));

const useStyles = makeStyles({
    form: {
        width: '100%',
    },
});

export const AddTranslationToolbarSkeleton = (props: ComponentProps<typeof Toolbar>): JSX.Element => {
    const classes = useStyles();

    return (
        <StyledToolbar {...props}>
            <form className={classes.form}>
                <Grid
                    container
                    alignItems="left"
                    direction="column"
                    spacing={4}
                >
                    <Grid item>
                        <Typography fontWeight="bold" variant="body1">
                            <Skeleton animation="wave" variant="rectangular" width="25%" />
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={4}
                        wrap="nowrap"
                    >
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <Skeleton animation="wave" variant="rectangular" width="100%" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton animation="wave" variant="rectangular" width="100%">
                                <TextField label="Translation value" />
                            </Skeleton>
                        </Grid>
                        <Grid item xs={2}>
                            <Skeleton animation="wave" variant="rectangular">
                                <SaveLoadingButton>Add</SaveLoadingButton>
                            </Skeleton>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </StyledToolbar>
    );
};
