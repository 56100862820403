import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { OverridableComponent, OverrideProps } from '@material-ui/core/OverridableComponent';
import { SystemProps } from '@material-ui/system';
import { ElementType, forwardRef } from 'react';

export interface CSSGridTypeMap<P = Record<string, unknown>, D extends ElementType = 'div'> {
    props: P & SystemProps & {
        alignItems?: string;
        children?: React.ReactNode;
        gap?: number;
        gridTemplateAreas?: string;
        gridTemplateColumns?: string;
        justifyContent?: string;
    };
    defaultComponent: D;
}

export type CSSGridProps<
    D extends ElementType = CSSGridTypeMap['defaultComponent'],
    P = Record<string, unknown>
> = OverrideProps<CSSGridTypeMap<P, D>, D>;

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        alignItems: (props: CSSGridProps): string | undefined => (props.alignItems || undefined),
        display: 'grid',
        gap: (props: CSSGridProps): string | undefined => (props.gap ? theme.spacing(props.gap) : undefined),
        gridTemplateAreas: (props: CSSGridProps): string | undefined => props.gridTemplateAreas || undefined,
        gridTemplateColumns: (props: CSSGridProps): string | undefined => props.gridTemplateColumns || undefined,
        justifyContent: (props: CSSGridProps): string | undefined => props.justifyContent || undefined,
    },
}));

// @ts-ignore
export const CSSGrid: OverridableComponent<CSSGridTypeMap> = forwardRef((props: CSSGridProps, ref): JSX.Element => {
    const {
        alignItems,
        className,
        component,
        gap,
        gridTemplateAreas,
        gridTemplateColumns,
        justifyContent,
        ...rest
    } = props;
    const classes = useStyles(props);
    const Component = (component || 'div') as ElementType;

    return (<Component className={clsx(classes.container, className)} {...rest} ref={ref} />);
});
