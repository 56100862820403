import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentProps } from 'react';

import { Loader } from 'src/components/common/Loader';

const useStyles = makeStyles({
    container: {
        height: '100%',
    },
});

export const LoadingGrid = (props: ComponentProps<typeof Grid>): JSX.Element => {
    const { className, ...rest } = props;
    const classes = useStyles();

    return (
        <Grid
            alignContent="center"
            justifyContent="center"
            {...rest}
            container
            className={clsx(classes.container, className)}
        >
            <Grid item>
                <Loader />
            </Grid>
        </Grid>
    );
};
