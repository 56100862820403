import clsx from 'clsx';
import { Tab as MuiTab, makeStyles, Theme } from '@material-ui/core';
import { ComponentProps } from 'react';

import { a11yProps } from 'src/utils/a11yProps';

const useStyles = makeStyles((theme: Theme) => ({
    tab: {
        minHeight: theme.spacing(12),
        '& > .MuiTab-wrapper': {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            '& > *:first-child': {
                marginBottom: 0,
                paddingRight: theme.spacing(2),
            },
        },
    },
}));

export const Tab = (props: ComponentProps<typeof MuiTab>): JSX.Element => {
    const { className, value, ...rest } = props;
    const classes = useStyles();

    return (
        <MuiTab
            {...rest}
            className={clsx(classes.tab, className)}
            value={value}
            {...a11yProps(value)}
        />
    );
};
