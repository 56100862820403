import {
    Button,
    Grid, IconButton, makeStyles, Theme, Tooltip,
} from '@material-ui/core';
import {
    useState, Suspense,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import qs from 'query-string';
import { useNavigate } from 'react-router-dom';
import { Info, Person } from '@material-ui/icons';
import { useInView } from 'react-intersection-observer';
import { useErrorHandler } from 'react-error-boundary';

import { auth } from 'src/lib/auth';
import { TitledAppBar } from 'src/components/common/TitledAppBar';
import { SearchResultsContainer } from 'src/components/common/Search/SearchResultsContainer';
import { FacetedSearch } from 'src/components/common/Search/FacetedSearch';
import { ENTITY_DESCRIPTOR_FACET, FACETS } from 'src/components/Entity/constants';
import { ErrorBoundary } from 'src/components/common/ErrorBoundary';
import { EntityResultsSkeleton } from 'src/components/Entity/EntityResultsSkeleton';
import { EntityResults } from 'src/components/Entity/EntityResults';
import {
    renderedIdsState, selectAllState, selectedIdsState,
} from 'src/atoms/selectedIdsAtom';
import { HELPER_TEXT, SEARCH_DESIGNS_PLACEHOLDER } from 'src/components/common/Search/constants';
import { useQueryEntitiesMetadata } from 'src/hooks/useQueryEnititesMetadata';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { BulkSelectionToolbar } from 'src/components/Entity/EntityBulkSelectionToolbar';
import { DesignConceptBox } from 'src/components/Entity/EntityBulkSelectionToolbar/DesignConceptBox';
import { validateEmail } from 'src/components/common/Search/FacetedSearch/utils';
import { isSearchPopulated } from 'src/utils';
import { StickyHeader } from 'src/components/common/StickyHeader';
import { ContentStoreEntitiesService } from 'src/services/Entities';
import { EXPORT_DESIGNS_LIMIT, UserEvent } from 'src/constants';
import { useTrackEvent } from 'src/hooks/useTrackEvent';

type PasteResultsState = {
    facetName: string;
    value: string;
};

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        gridArea: 'content',
    },
    myDesignsButton: {
        float: 'right',
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        '& > .MuiChip-icon': {
            color: theme.palette.success.main,
        },
    },
    alignCenter: {
        alignItems: 'center',
    },
}));

export const EntitiesPage = (): JSX.Element => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [search, setSearch] = useState<App.Entities.Search>({});
    const [showChips, setShowChips] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useRecoilState(selectAllState);
    const [selectedIds, setSelectedIds] = useRecoilState(selectedIdsState);
    const [renderedIds, setRenderedIds] = useRecoilState(renderedIdsState);
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const errorHandler = useErrorHandler();
    const { trackEvent } = useTrackEvent();

    const { ref: headerRef, inView, entry } = useInView({ initialInView: true });
    const headerIsVisible = inView || !entry;

    const {
        data: entitiesMetadata,
    } = useQueryEntitiesMetadata(
        accessToken,
        search,
        {
            enabled: !!accessToken && isSearchPopulated(search),
            onError: errorHandler,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            retry: false,
        },
    );

    const handleSubmit = (facetedSearch: FacetedSearch.FacetedSearchResult): void => {
        const { designConceptId, ...newSearch } = facetedSearch;

        if (designConceptId) {
            newSearch.designConceptId = designConceptId as string;
        }
        setSearch(newSearch);

        // Don't show design chips if there are no search facets or there's already a design concept selected
        if (Object.keys(newSearch).length === 0 || newSearch.designConceptId) {
            setShowChips(false);
        } else {
            setShowChips(true);
        }
    };

    const handleShowDesignConcept = (designConceptIdToSearch: string): void => {
        navigate({
            ...window.location,
            search: qs.stringify({ designConceptId: designConceptIdToSearch }),
        });
    };

    const handleOnClick = (id: string, reason: 'drawer' | 'designConceptId'): void => {
        switch (reason) {
            case 'designConceptId':
            default:
                setSelectedIds([]);
                setRenderedIds([]);
                handleShowDesignConcept(id);
        }
    };

    const handleAllSelectedCheck = (newArrLength: number): void => {
        setSelectAll(newArrLength === renderedIds?.length);
    };

    const handleSelectAllClick = (): void => {
        const newSelectedIds: string[] = renderedIds && selectedIds.length < renderedIds?.length ? renderedIds : [];

        setSelectedIds(newSelectedIds);
        setSelectAll(newSelectedIds.length === renderedIds?.length);
    };

    const handleFilterMyDesigns = (): void => {
        trackEvent({ eventName: UserEvent.MyNewDesignsClick });
        navigate({
            ...window.location,
            search: qs.stringify({ tags: [`strategist:${auth.getProfile()?.email}`, 'New'] }),
        });
    };

    const handleOnPaste = async (pasteData: string): Promise<undefined | null | false | PasteResultsState> => {
        if (validateEmail(pasteData)) {
            return { facetName: 'Tag', value: pasteData };
        }
        return undefined;
    };

    const handleExport = async (): Promise<void> => {
        const anchor = document.createElement('a');

        document.body.appendChild(anchor);

        const csvFile = await ContentStoreEntitiesService.getEntitiesCsv(
            accessToken,
            selectedIds.length ? { ids: selectedIds } : search,
            undefined,
            EXPORT_DESIGNS_LIMIT,
        );
        const csvBlob = new Blob([csvFile], { type: 'text/csv' });
        const objectUrl = window.URL.createObjectURL(csvBlob);

        anchor.href = objectUrl;
        anchor.download = `tagging_tool_export_${(new Date()).toISOString()}.csv`;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
    };

    const renderOptionRule = (value: string): string => value.replace('strategist:', '');

    const HeaderToolbar = (
        <BulkSelectionToolbar
            allSelected={selectAll}
            handleAllSelectedCheck={handleAllSelectedCheck}
            handleExport={handleExport}
            handleSelectAllClick={handleSelectAllClick}
        >
            {(showChips || undefined) && (
                <DesignConceptBox
                    designConceptIds={entitiesMetadata?.designConceptIds}
                />
            )}
        </BulkSelectionToolbar>
    );

    return (
        <>
            <TitledAppBar title="Designs">
                <Grid container className={classes.alignCenter} spacing={2}>
                    <Grid container item xs wrap="nowrap">
                        <Grid
                            container
                            item
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                            wrap="nowrap"
                        >
                            <Grid item xs>
                                <FacetedSearch
                                    showProductSearchButton
                                    defaultFacet={ENTITY_DESCRIPTOR_FACET}
                                    facets={FACETS}
                                    label="Search Designs"
                                    placeholder={SEARCH_DESIGNS_PLACEHOLDER}
                                    renderOptionRule={renderOptionRule}
                                    onPaste={handleOnPaste}
                                    onSubmit={handleSubmit}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title={HELPER_TEXT}>
                                    <IconButton color="primary">
                                        <Info />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Show My New Designs when user is logged in and aren't already filtering by their name */}
                    {auth.isLoggedIn()
                        && (!search?.tags || !search.tags?.find((t) => t === `strategist:${auth.getProfile()?.email}`))
                        && (
                            <Grid item>
                                <Grid item>
                                    <Button
                                        className={classes.myDesignsButton}
                                        startIcon={<Person />}
                                        variant="outlined"
                                        onClick={(): void => { handleFilterMyDesigns(); }}
                                    >
                                        My New Designs
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                </Grid>
            </TitledAppBar>
            <SearchResultsContainer className={classes.container}>
                {renderedIds?.length > 0 && (
                    <div ref={headerRef}>
                        {HeaderToolbar}
                    </div>
                )}
                {!headerIsVisible && (
                    <StickyHeader>
                        {HeaderToolbar}
                    </StickyHeader>
                )}
                <ErrorBoundary>
                    <Suspense fallback={<EntityResultsSkeleton />}>
                        <EntityResults
                            search={search}
                            onClick={handleOnClick}
                        />
                    </Suspense>
                </ErrorBoundary>
            </SearchResultsContainer>
        </>
    );
};
