import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

const RIVER_STREAM_ID = 'st-3cm9d93t7w';

class RiverServiceApi
    extends AbstractService
    implements MCP.Data.River.Services.IRiverService {
    public async trackEvent(
        bearerToken: string | undefined | false,
        body: MCP.Data.River.Models.WaterdropBody,
        signal?: AbortSignal,
    ): Promise<MCP.Data.River.Models.WaterdropResponse> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request trackEvent. No bearerToken provided.',
            });
        }

        const url = `${this.version}/streams/${RIVER_STREAM_ID}/waterdrops`;

        try {
            return await this.api
                .post(url, {
                    json: body,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<MCP.Data.River.Models.WaterdropResponse>();
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to track event',
                info: (e as Error).message,
                url,
                body,
            });
        }
    }
}

export const RiverService = new RiverServiceApi(config.services.riverService);
