import { QueryFunctionContext } from 'react-query';

import { ContentStoreKeywordsService } from 'src/services/Keywords';
import { DEFAULT_LIMIT } from 'src/constants';

export const QUERY_KEY = 'keywords';

export const queryKeywords = (
    context: QueryFunctionContext<App.Keywords.QueryKey, App.Keywords.PageParam>,
): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.SourcedKeyword>> => {
    const { pageParam: offset, queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentStoreKeywordsService.getKeywords(
        accessToken,
        search.keywordName,
        search.prefix,
        search.isExcludedFromAltText,
        search.ids,
        offset,
        DEFAULT_LIMIT,
    );
};
