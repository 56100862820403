import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { QUERY_KEY } from 'src/queries/queryCategoryMonolithKeys';
import { queryTaxonomyTree } from 'src/queries/queryTaxonomyTree';

type PagedTaxonomyNodes = Models.V3.PageResult<Models.ContentStoreApi.V3.TaxonomyNode>;
type PagedQueryKey = App.TaxonomyNodes.PagedQueryKey;

export const useQueryCategoryTaxonomyTree = (
    accessToken: string | undefined | false,
    id: string,
    options?: UseQueryOptions<PagedTaxonomyNodes, Error, PagedTaxonomyNodes, PagedQueryKey>,
): UseQueryResult<PagedTaxonomyNodes, Error> => useQuery<
PagedTaxonomyNodes,
Error,
PagedTaxonomyNodes,
PagedQueryKey
>(
    [QUERY_KEY, accessToken, { categoryIds: [id] }],
    queryTaxonomyTree,
    options,
);
