import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { createCategory } from 'src/mutations/createCategory';
import { QUERY_KEY } from 'src/queries/queryCategory';

export const useCreateCategory = (): UseMutationResult<Models.ContentStoreApi.V3.Category, Error, App.Category.CreateMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Models.ContentStoreApi.V3.Category, Error, App.Category.CreateMutationVariables>(
        createCategory,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            },
            useErrorBoundary: true,
        },
    );
};
