import { useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useRecoilValue } from 'recoil';
import {
    Search, Add,
} from '@material-ui/icons';
import {
    Button,
    FilterOptionsState,
    Typography,
    createFilterOptions,
    AutocompleteRenderGroupParams,
    makeStyles,
    Theme,
} from '@material-ui/core';

import { StandardSearch, PropTypes as StandardSearchProps } from 'src/components/common/Search/StandardSearch';
import { useQueryKeywords } from 'src/hooks/useQueryKeywords';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { TaggingData } from 'src/components/common/Tagging/TaggingData';
import { Tagging } from 'src/components/common/Tagging';
import { TaggingHeader } from 'src/components/common/Tagging/TaggingHeader';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { CopyButton } from 'src/components/common/buttons/CopyButton';
import { findId } from 'src/lib/findId';
import { Chip } from 'src/components/common/Tagging/Chip';
import { handlePillEventFactory } from 'src/components/Entity/EntityDetailsDrawer/utils/handlePillEvent';
import { DataSource, DEFAULT_CULTURE } from 'src/constants';
import { handleSearchFactory } from 'src/components/Entity/EntityDetailsDrawer/utils/handleSearch';
import { useCreateKeyword } from 'src/components/Keywords/hooks/useCreateKeyword';
import { ContentStoreKeywordsService } from 'src/services/Keywords';

type Keyword = Models.ContentStoreApi.V3.SourcedKeyword;

const filter = createFilterOptions<Keyword>();

export type PropTypes = Omit<StandardSearchProps<Keyword, true, false, false>, 'label' | 'options' | 'useQueryOptions' | 'value'> & {
    data: Keyword[];
    onAddAllSuggestedKeywords?: () => void;
};

const HELPER_TEXT = `\
Use the search box to find and add keywords to be saved. \
Click on any suggested pill to add them to be saved, and \
click on any selected pill to remove them from being saved\
`;

const getOptionLabel = (option: Keyword): string => option.keywordName;

const useStyles = makeStyles((theme: Theme) => ({
    groupHeader: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.body1.fontSize,
        width: '100%',
        padding: `0 ${theme.spacing(2)}`,
        '& .MuiAutocomplete-option': {
            paddingLeft: theme.spacing(0),
        },
        '& .MuiAutocomplete-option[data-focus="true"]': {
            background: 'none',
        },
    },
    addButton: {
        width: '100%',
        background: 'rgba(66, 165, 245, 0.1)', // palette.primary.main to rgb
        textTransform: 'none',
        paddingLeft: theme.spacing(4),
        justifyContent: 'flex-start',
    },
}));

export const KeywordsForm = (props: PropTypes): JSX.Element => {
    const {
        data = [],
        onChange,
        onAddAllSuggestedKeywords,
        ...rest
    } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const valueHrefs = data.filter((x) => x.source !== DataSource.Deleted).map((k) => k.href);
    const valueRef = useRef<Models.ContentStoreApi.V3.SourcedKeyword[]>([]);
    const createMutation = useCreateKeyword();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [keywordInput, setKeywordInput] = useState<string | undefined>(undefined);

    const suggestionsExist = !!data?.find((k) => k.source === DataSource.Suggested);

    const { data: queryData, isLoading: isKeywordsLoading, ...other } = useQueryKeywords(
        accessToken,
        {
            keywordName: undefined,
            prefix: keywordInput,
            ids: undefined,
            isExcludedFromAltText: undefined,
        },
        {
            enabled: !!accessToken,
            suspense: false,
        },
    );

    const useQueryOptions = (inputValue?: string): UseQueryResult<Keyword[], Error> => {
        setKeywordInput(inputValue);

        const options = queryData && queryData?.results?.length > 0
            ? (queryData.results as Keyword[])
                .filter((k) => !valueHrefs.includes(k.href))
                .sort((a, b) => a.keywordName.localeCompare(b.keywordName))
            : data;

        return {
            data: options,
            ...other,
        } as UseQueryResult<Keyword[], Error>;
    };

    const handleEvent = onChange && handlePillEventFactory(data, onChange);
    const handleSearch = async (
        event: React.SyntheticEvent<Element, Event>,
        value: Models.ContentStoreApi.V3.SourcedKeyword[],
    ): Promise<void> => {
        const newValue = value;

        if (!newValue[0].id && accessToken) {
            setIsLoading(true);
            const keyword = value[0].keywordName.split('Add New: ')[1];

            await createMutation.mutateAsync({
                accessToken,
                keyword: {
                    keywordName: keyword,
                    translations: { [DEFAULT_CULTURE]: keyword },
                },
                autoTranslate: true,
            });
            // eslint-disable-next-line prefer-destructuring
            newValue[0] = (await ContentStoreKeywordsService.getKeywords(accessToken, keyword)).results[0];
            newValue[0].source = DataSource.Selected;

            setIsLoading(false);
        }

        if (onChange) {
            handleSearchFactory(data, onChange)(event, newValue);
        }
    };

    const filterOptions = (options: Keyword[], params: FilterOptionsState<Keyword>): Keyword[] => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.keywordName);

        if (!!inputValue && !isExisting && accessToken) {
            filtered.unshift({
                keywordName: `Add New: ${inputValue}`,
                isExcludedFromAltText: true,
                toBeDeleted: false,
                translations: { [DEFAULT_CULTURE]: inputValue },
                id: '',
                source: DataSource.New,
                href: '',
            });
        }
        return filtered as Keyword[];
    };

    const renderNewKeywordOption = (params: AutocompleteRenderGroupParams): JSX.Element => (
        <>
            {params.group === DataSource.New && (
                <li key={params.key}>
                    <div className={classes.groupHeader}>
                        <Button className={classes.addButton} startIcon={<Add />}>
                            {params.children}
                        </Button>
                    </div>
                </li>
            )}
            {params.group !== DataSource.New && (
                <li key={params.key}>
                    <div>
                        {params.children}
                    </div>
                </li>
            )}
        </>
    );

    return (
        <Tagging icon={<Search />}>
            <TaggingHeader
                helperText={HELPER_TEXT}
                title={(
                    <CSSGrid
                        gap={2}
                        gridTemplateAreas={'". ."'}
                        gridTemplateColumns="auto 1fr"
                    >
                        <Typography variant="h6">Keywords</Typography>
                        <div>
                            <CopyButton
                                value={
                                    data.map(
                                        (x) => (typeof x === 'string' ? x : findId((x as Models.V3.HrefOnly).href)),
                                    ).join(',')
                                }
                            />
                        </div>
                    </CSSGrid>
                )}
            >
                <StandardSearch
                    {...rest}
                    autoComplete
                    disableCloseOnSelect
                    hideFilterOptionsWhileLoading
                    allowCopy={false}
                    filterOptions={filterOptions}
                    getOptionLabel={getOptionLabel}
                    getOptionSelected={(option: Keyword): boolean => !!data.find(
                        (item) => item.href === option.href,
                    )}
                    groupBy={(options): string => options.source}
                    isKeywordLoading={isLoading || isKeywordsLoading}
                    label="Search Keywords"
                    renderGroup={renderNewKeywordOption}
                    size="small"
                    useQueryOptions={useQueryOptions}
                    value={valueRef.current}
                    onChange={handleSearch}
                />
            </TaggingHeader>
            <TaggingData data={data.sort((a, b) => a.keywordName.localeCompare(b.keywordName))}>
                {(item: Keyword): JSX.Element => (
                    <Chip
                        data={item}
                        label={item.keywordName}
                        onClick={handleEvent}
                        onDelete={handleEvent}
                    />
                )}
            </TaggingData>
            {suggestionsExist && (
                <Button
                    color="secondary"
                    size="small"
                    startIcon={<Add />}
                    sx={{ mt: 4 }}
                    variant="text"
                    onClick={onAddAllSuggestedKeywords}
                >
                    Add All Suggestions
                </Button>
            )}
        </Tagging>
    );
};
