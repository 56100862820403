import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryDucs, QUERY_KEY } from 'src/queries/queryDucs';

type Duc = Models.DtecApi.Duc;

export const useQueryDucs = (
    accessToken: string | undefined | false,
    options?: UseQueryOptions<Duc[], Error, Duc[], App.Ducs.QueryKey>,

): UseQueryResult<Duc[], Error> => useQuery<Duc[], Error, Duc[], App.Ducs.QueryKey>(
    [QUERY_KEY, accessToken],
    queryDucs,
    {
        ...options,
    },
);
