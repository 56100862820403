import {
    Grid,
    makeStyles,
} from '@material-ui/core';

import { LogoIcon } from 'src/components/common/icons/Logo';

const useStyles = makeStyles({
    logo: {
        display: 'inline',
        verticalAlign: 'middle',
    },
    page: {
        height: '100vh',
        textAlign: 'center',
    },
});

export const Splash = (): JSX.Element => {
    const classes = useStyles();

    return (
        <Grid
            container
            item
            alignItems="center"
            className={classes.page}
            justifyContent="center"
            xs={12}
        >
            <Grid item xs={4}>
                <LogoIcon className={classes.logo} size="xxl" />
            </Grid>
        </Grid>
    );
};
