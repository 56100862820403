import { QueryFunctionContext } from 'react-query';

import { ContentStoreEntityProductSpecificationsService } from 'src/services/EntityProductSpecifications';

export const QUERY_KEY = 'EntityProductSpecification';

export const queryEntityProductSpecification = (
    context: QueryFunctionContext<App.EntityProductSpecifications.QueryKey>,
): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.ProductSpecification>> => {
    const { queryKey: [, accessToken, search] } = context;

    return ContentStoreEntityProductSpecificationsService.getEntityProductSpecifications(
        accessToken,
        search.entityId,
        search.productId,
    );
};
