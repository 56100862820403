import {
    Checkbox,
    Skeleton,
    TableCell,
    TableRow,
    TextField,
} from '@material-ui/core';

const PLACEHOLDERS: number[] = Array.from({ length: 6 }, (_, i) => i + 1);

export const CulturesFormBodySekeleton = (): JSX.Element => (
    <>
        {PLACEHOLDERS.map((item) => (
            <TableRow key={item}>
                <TableCell>
                    <Skeleton animation="wave" />
                </TableCell>
                <TableCell>
                    <TextField
                        disabled
                        aria-label="rank value"
                        inputProps={{
                            type: 'number',
                        }}
                        label="rank"
                        variant="outlined"
                    />
                </TableCell>
                <TableCell>
                    <Checkbox disabled />
                </TableCell>
                <TableCell />
            </TableRow>
        ))}
    </>
);
