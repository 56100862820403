import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentProps } from 'react';

import { EmptyState } from 'src/components/common/EmptyState';

const useStyles = makeStyles({
    container: {
        height: '100%',
    },
});

export const EntityDetailsEmptyState = (props: ComponentProps<typeof Grid>): JSX.Element => {
    const { className, ...rest } = props;
    const classes = useStyles();

    return (
        <Grid
            alignContent="center"
            justifyContent="center"
            {...rest}
            container
            className={clsx(classes.container, className)}
        >
            <Grid item>
                <EmptyState>
                    <Typography gutterBottom variant="h6">
                        No design found
                    </Typography>
                    <Typography variant="body2">
                        Start by creating an design
                    </Typography>
                </EmptyState>
            </Grid>
        </Grid>
    );
};
