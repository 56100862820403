import clsx from 'clsx';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
    AutocompleteRenderOptionState, Theme, Typography,
} from '@material-ui/core';
import { HTMLAttributes } from 'react';
import { Search, ArrowForward } from '@mui/icons-material';
import { styled } from '@mui/material';

const StyledArrowForward = styled(ArrowForward)(({ theme }) => ({
    marginLeft: 'auto',
    height: theme.spacing(5),
}));

export const renderOptionFactory = (classes: string, theme: Theme, renderOptionRule?: (value: string) => string) => (
    optionProps: HTMLAttributes<HTMLLIElement>,
    option: string,
    { inputValue }: AutocompleteRenderOptionState,
): JSX.Element => {
    const { className: optionClassName, ...restOptionProps } = optionProps;
    const matches = match(option, inputValue);
    const parts = parse(option, matches);

    return (
        <li className={clsx(classes, optionClassName)} {...restOptionProps}>
            <Search
                style={{
                    paddingRight: theme.spacing(2),
                }}
            />
            <div>
                {parts.map((part, index): JSX.Element => (
                    <Typography
                        component="span"
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${part.text}-${index}`}
                        // eslint-disable-next-line react/no-array-index-key
                        style={{
                            fontWeight: part.highlight
                                ? theme.typography.fontWeightMedium
                                : theme.typography.fontWeightRegular,
                        }}
                        variant="body2"
                    >
                        {renderOptionRule && (renderOptionRule(part.text))}
                        {!renderOptionRule && (part.text)}
                    </Typography>
                ))}
            </div>
            {option === 'Product Search' && (
                <StyledArrowForward />
            )}
        </li>
    );
};
