import clsx from 'clsx';
import { useThrottledCallback, useDebounce } from 'use-debounce';
import { mergeAndCompare } from 'merge-anything';
import {
    makeStyles,
    Theme,
    Alert,
    Autocomplete,
    AutocompleteProps,
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    TextField,
    useTheme,
    IconButton,
    FormControl,
    OutlinedInput,
    InputLabel,
    Snackbar,
    FilterOptionsState,
    CircularProgress,
    InputAdornment,
} from '@material-ui/core';
import {
    ChangeEvent,
    ComponentProps,
    KeyboardEvent,
    MouseEvent,
    ReactNode,
    SyntheticEvent,
    useEffect,
    useMemo,
    useState,
    ClipboardEvent,
} from 'react';
import { Search } from '@material-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { matchSorter } from 'match-sorter';

import { DEFAULT_PLACEHOLDER, PENDING_FACET_PROMPT } from 'src/components/common/Search/constants';
import {
    createFacetedRecord,
    findFacetByName,
    insertNewFacetRecord,
    parseSearchFromQueryString,
    createNewRecordFromNameOrValue,
    triggerSubmit,
    cloneFacetRecord,
    createFacetedRecordFromKey,
    createProductOptionsFacetedRecordFromKey,
    removeProductSearchFromCurrentSearch,
    removeCreatedTimeSearchFromCurrentSearch,
    createDateFacetedRecordFromKey,
} from 'src/components/common/Search/FacetedSearch/utils';
import { renderOptionFactory } from 'src/components/common/Search/renderOptionFactory';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { CustomInputRoot } from 'src/components/common/Search/FacetedSearch/CustomInputRoot';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { Tags } from 'src/components/common/Search/FacetedSearch/Tags';
import { KEY_EVENTS } from 'src/components/common/Search/FacetedSearch/events';
import { DEFAULT_UNSELECTED_INDEX, END_CREATED_TIME_KEY, START_CREATED_TIME_KEY } from 'src/components/common/Search/FacetedSearch/constants';
import qs from 'qs';
import { ProductOptionSearchDialog } from 'src/components/common/Search/FacetedSearch/ProductOptionSearch/ProductOptionSearchDialog';
import { productSearchDialogOpenState } from 'src/atoms/productSearchDialogOpenAtom';
import { CreatedTimeSearchDialog } from 'src/components/common/Search/FacetedSearch/CreatedTimeSearchDialog';
import { createdTimeSearchDialogOpenState } from 'src/atoms/createdTimeSearchDialogOpenAtom';

type PasteResultsState = {
    facetName: string;
    value: string;
};
export interface PropTypes<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
    > extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'onPaste' | 'options' | 'renderInput' | 'onChange' | 'onSubmit'> {
    facets: FacetedSearch.Facet[];
    TextFieldProps?: Partial<ComponentProps<typeof TextField>>;
    label?: ReactNode;
    onPaste?: (value: string) => Promise<undefined | null | false | PasteResultsState>;
    onSubmit?: (newSearch: FacetedSearch.FacetedSearchResult) => void;
    renderOptionRule?: (value: string) => string;
    initialState?: App.Entities.Search;
    defaultFacet: FacetedSearch.Facet;
    showProductSearchButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        padding: theme.spacing(4),
        '& .MuiOutlinedInput-input': {
            width: 'fit-content',
            minWidth: theme.spacing(75),
            borderRadius: theme.shape.borderRadius,
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            paddingLeft: theme.spacing(3),
        },
    },
    option: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
    },
    searchButton: {
        height: theme.spacing(6),
        width: theme.spacing(6),
    },
    endAdornment: {
        height: '100%',
        position: 'absolute',
        right: '9px',
        top: `calc(50% - ${theme.spacing(4)})`,
    },
}));

export const FacetedSearch = (props: PropTypes<string, true, false, true>): JSX.Element => {
    const {
        autoComplete = true,
        ChipProps = {},
        className,
        facets,
        TextFieldProps = {},
        label,
        placeholder = DEFAULT_PLACEHOLDER,
        size = 'small',
        onPaste,
        onSubmit,
        renderOptionRule,
        defaultFacet,
        showProductSearchButton,
        ...rest
    } = props;
    const facetNames = useMemo(() => facets.filter((f) => !f.hideFromDropdown).map((facet) => facet.name), [facets]);
    const facetIndex = useMemo(() => facets.reduce((accum, facet) => ({
        ...accum,
        [facet.name]: facet,
    }), {} as Record<string, FacetedSearch.Facet>), [facets]);
    const classes = useStyles();
    const theme = useTheme();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const [productSearchDialogOpen, setProductSearchDialogOpen] = useRecoilState(productSearchDialogOpenState);
    const [
        createdTimeSearchDialogOpen,
        setCreatedTimeSearchDialogOpen,
    ] = useRecoilState(createdTimeSearchDialogOpenState);
    const [pendingFacet, setPendingFacet] = useState<FacetedSearch.FacetedSearchRecord | null>(null);
    const [currentSearch, setCurrentSearch] = useState<FacetedSearch.FacetedSearchType>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [selectedFacet, setSelectedFacet] = useState<number>(DEFAULT_UNSELECTED_INDEX);
    const [options, setOptions] = useState<string[]>(facetNames);

    const components = useMemo(() => ({
        Root: CustomInputRoot,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    useEffect(() => {
        const initialState = parseSearchFromQueryString(location.search, facets, currentSearch);
        const { page } = qs.parse(location.search.includes('?') ? location.search.split('?')[1] : location.search);

        setCurrentSearch(initialState);
        triggerSubmit(initialState, navigate, location, onSubmit, page?.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    /**
     * General Functions
     */
    const resetOptions = (): void => {
        setOptions(facetNames);
    };

    /**
     * Returns whether or not the current option is selected. If an option (facet name) is pending
     * or is in the current search and not allowed to be multiple, return false; otherwise return
     * @param option
     * @returns
     */
    const getOptionSelected = (option: string): boolean => {
        if (pendingFacet && option === pendingFacet.facet?.name && pendingFacet.facet?.type !== 'multiple') {
            return true;
        }

        const [facet] = findFacetByName(option, facetIndex);

        if (facet?.type === 'multiple') {
            return false;
        }

        return !!Object.values(currentSearch).find((f) => f.facet?.name === option);
    };

    /**
     * Loops through each configured facet to see if the provided value is compatible.
     * If so, add the facet: value string to the list of options for the dropdown
     * @param value
     */
    const fetchCompatibleFacetsForValue = useThrottledCallback(async (value: string): Promise<void> => {
        try {
            setLoading(true);

            const compatibleFacetTasks = facets.map(async (f) => {
                if (f.isCompatible) {
                    const result = await f.isCompatible(value || '', accessToken);

                    return result ? [f, result] : undefined;
                }

                return undefined;
            });
            const compatibleFacets = await Promise.allSettled(compatibleFacetTasks);
            const compatibleOptionsList = compatibleFacets
                .filter((p): p is PromiseFulfilledResult<(FacetedSearch.Facet | string[])[] | undefined> => p.status === 'fulfilled')
                .map((p) => p.value)
                .filter((f): f is [FacetedSearch.Facet, string[]] => !!f)
                .map((f) => (`${f[0].name}: ${value}`));

            const flattenedCompatibleOptions = compatibleOptionsList.flat();

            setOptions([...flattenedCompatibleOptions, ...facetNames]);
        } catch (e) {
            setError(e as Error);
        } finally {
            setLoading(false);
        }
    }, 1000, { leading: false });

    /**
     * Fetches an autocompleteOptions result and sets it to the options
     * @param autocompleteOptions
     * @param value
     */
    const fetchAndSetAutocompleteOptions = useThrottledCallback(async (
        autocompleteOptions: FacetedSearch.AutocompleteOptions,
        value: string,
    ): Promise<void> => {
        try {
            setLoading(true);
            const results = await autocompleteOptions(value, accessToken);

            setOptions(results);
        } catch (e) {
            setError(e as Error);
        } finally {
            setLoading(false);
        }
    }, 1000, { leading: false });

    /**
     * If the facet exists and has an autocompleteOptions function, call it and set the results as options.
     * Otherwise reset the options.
     * @param autocompleteOptions
     * @param value
     */
    const setOrResetDropdownOptionsFromAutoCompleteValue = (
        autocompleteOptions: FacetedSearch.AutocompleteOptions | undefined,
        value: string,
    ): void => {
        if (autocompleteOptions) {
            fetchAndSetAutocompleteOptions(autocompleteOptions, value);
        } else {
            resetOptions();
        }
    };

    /**
     * Event Handlers
     */

    /**
     * Clears the current pending facet and resets the dropdown options
     */
    const handleClear = (): void => {
        if (pendingFacet) {
            setPendingFacet(null);
        }
        resetOptions();
    };

    /**
     * Resets the current search and pending search, and triggers a submit
     */
    const clearAll = (): void => {
        const searchToSubmit = {};

        setCurrentSearch(searchToSubmit);
        handleClear();
        triggerSubmit(searchToSubmit, navigate, location, onSubmit);
        resetOptions();
    };

    /**
     * Generates the updated current search with the provided facet, resets the dropdown options, and triggers a submit.
     * If `resetPending` is true, the also clears the pending facet state.
     *
     * @param record
     * @param resetPending
     */
    const updateCurrentSearch = (
        record: FacetedSearch.FacetedSearchRecord | null,
        resetPending = true,
    ): FacetedSearch.FacetedSearchType => {
        const searchToSubmit = (record) ? insertNewFacetRecord(currentSearch, record) : currentSearch;

        if (resetPending) {
            setPendingFacet(null);
        }

        // If there's no record this is a no-op since we're feeding it the same object
        setCurrentSearch(searchToSubmit);
        resetOptions();

        return searchToSubmit;
    };

    /**
     * Called when a user attempts to delete the Product Search Chip
     * @param key
     * @returns
     */
    const handleDeleteProductSearch = (event?: SyntheticEvent): void => {
        event?.preventDefault();

        setCurrentSearch(removeProductSearchFromCurrentSearch(currentSearch));
        resetOptions();
    };

    /**
     * Called when a user submit their search in the Product Search Dialog
     * @param productKey
     * @param productVersion
     * @param productOptions
     * @returns
     */
    const submitProductOptionDialog = (
        productKey: string,
        productVersion: string,
        productOptions: App.Entities.ProductOptions,
    ): void => {
        const newSearch = { ...removeProductSearchFromCurrentSearch(currentSearch) };

        handleDeleteProductSearch();
        const keyFacet = createFacetedRecordFromKey('productKey', productKey, facets);
        const versionFacet = createFacetedRecordFromKey('productVersion', `${productVersion}`, facets);
        const productOptionsFacet = createProductOptionsFacetedRecordFromKey(productOptions, facets);

        newSearch[keyFacet.id] = keyFacet;
        newSearch[versionFacet.id] = versionFacet;
        newSearch[productOptionsFacet.id] = productOptionsFacet;

        setCurrentSearch(newSearch);
        setProductSearchDialogOpen(false);

        // Timeout ensures dialog is closed during navitgation page load
        setTimeout(() => { triggerSubmit(newSearch, navigate, location, onSubmit); }, 100);
    };

    /**
     * Called when a user submit their search in the Created Date Search Dialog
     * @param startCreatedTime
     * @param endCreatedTime
     * @returns
     */
    const submitCreatedTimeDialog = (
        startCreatedTime: Date | null | undefined,
        endCreatedTime: Date | null | undefined,
    ): void => {
        const newSearch = { ...removeCreatedTimeSearchFromCurrentSearch(currentSearch) };

        if (startCreatedTime) {
            const startCreatedTimeFacet = createDateFacetedRecordFromKey(
                START_CREATED_TIME_KEY,
                startCreatedTime,
                facets,
            );

            newSearch[startCreatedTimeFacet.id] = startCreatedTimeFacet;
        }

        if (endCreatedTime) {
            const endCreatedTimeFacet = createDateFacetedRecordFromKey(END_CREATED_TIME_KEY, endCreatedTime, facets);

            newSearch[endCreatedTimeFacet.id] = endCreatedTimeFacet;
        }

        setCurrentSearch(newSearch);
        setCreatedTimeSearchDialogOpen(false);

        // Timeout ensures dialog is closed during navitgation page load
        setTimeout(() => { triggerSubmit(newSearch, navigate, location, onSubmit); }, 100);
    };

    /**
     * Set the dropdown values to be facet suggestions generated locally as opposed to awaiting server response.
     * Used on onPaste events.
     *
     * @param val
     */
    const setLocallyCompatibleFacets = (val: string): void => {
        const locallyCompatibleFacets = facets.filter((f) => f.isLocallyCompatible && f.isLocallyCompatible(val || '') && !getOptionSelected(f.name))
            .map((f) => `${f.name}: ${val}`);

        setOptions(locallyCompatibleFacets);
    };

    /**
     * Called when a user selects from the dropdown. A user can do this either via a click or enter.
     * Case:
     * - user selected a facet from the dropdown
     *      - if there's no pendingFacet, create one and perform the following step
     *      - if pendingFacet has no facet, find the facet by its name and assign it/update the state
     *      - otherwise, if there's a value in the pendingFacet, commit it, submit a search, and create
     *          a new pendingFacet. If not, discard it and create a new pendingFacet
     * - user selects a facet with a value
     * - user selects an autocompleteOption value
     *
     * Next, do the following to update the dropdown list:
     * - if the value is not empty and isnt the facet name, add the result of the
     *      autocompleteOptions to the list of facets
     * - otherwise show a typing prompt
     *
     * @param event
     * @param value
     * @param reason
     */
    const handleChange = async (
        event: ChangeEvent<unknown>,
        value: string | string[] | null,
        reason: AutocompleteChangeReason,
    ): Promise<void> => {
        if (event) {
            event.preventDefault();
        }

        if (reason === 'clear') {
            clearAll();
        } else if (value && reason === 'selectOption') {
            const changedValue = value[value.length - 1];
            const newOrExistingPendingFacet = pendingFacet ? cloneFacetRecord(pendingFacet) : createFacetedRecord();
            const [facet, facetValue] = findFacetByName(changedValue, facetIndex);
            // If there is a facet and no pending facet, the user has selected a facet name from the dropdown
            // There are five cases for selection:
            // 1. The user selected the product search option, which has the unique case of opening the dialog
            // 2. The user typed into the input and selected a facet name
            //      This occurs when compatible facets have been found for the user input or through the normal
            //      search component functionality
            // 3. The user selected a facet name from the dropdown (no typing)
            // 4. The user typed into the input and selected a facet name: value
            //      This occurs when compatible facets have been found for the user input
            // 5. The user typed into the input and selected a value
            //      This occurs when autocomplete options are provided

            if (facet?.key === 'productKey') {
                setProductSearchDialogOpen(true);
                resetOptions();
                setOpen(false);
            } else if (facet?.key === 'entityCreatedTime') {
                setCreatedTimeSearchDialogOpen(true);
                setOpen(false);
            } else if (facet && !newOrExistingPendingFacet.facet) {
                newOrExistingPendingFacet.facet = facet;

                // Handle cases 1 & 2. In the case of case 1, we want to reset the pendingFacet value
                // since what the user typed would have been set to the value in the handleInputChange
                // but it turns out that the user wasn't really entering a value input but just searching
                // for a facet to select
                if (facetValue) {
                    newOrExistingPendingFacet.value = facetValue;
                    updateCurrentSearch(newOrExistingPendingFacet);
                } else {
                    newOrExistingPendingFacet.value = undefined;
                    setPendingFacet(newOrExistingPendingFacet);
                    setOrResetDropdownOptionsFromAutoCompleteValue(
                        newOrExistingPendingFacet.facet?.autocompleteOptions,
                        '',
                    );
                }
            // If there is a facet and and the facet name matchs the pending facet name, then the user selected
            // an autocomplete of format `name: value` or selected the same facet name
            } else if (facet && newOrExistingPendingFacet.facet?.name === facet.name) {
                if (facetValue) {
                    newOrExistingPendingFacet.value = facetValue;
                }

                // newOrExistingPendingFacet.value may already exist even if facetValue is falsey
                // commit the search since they either selected a facet and filled out a pendingFacet
                // or selected a contiguous search item (i.e. autocomplete value) from the dropdown
                if (newOrExistingPendingFacet.value || newOrExistingPendingFacet.bulkValue) {
                    updateCurrentSearch(newOrExistingPendingFacet);
                } else {
                    setPendingFacet(newOrExistingPendingFacet);
                    setOrResetDropdownOptionsFromAutoCompleteValue(
                        newOrExistingPendingFacet.facet?.autocompleteOptions,
                        '',
                    );
                }
            // The user has selected a different facet than the existing pending facet, so complete the
            // current pending facet  and start a new pending facet
            } else if (facet && newOrExistingPendingFacet.facet?.name !== facet.name) {
                updateCurrentSearch(newOrExistingPendingFacet, false);

                const newPendingFacet = createFacetedRecord(facet);

                setPendingFacet(newPendingFacet);

                setOrResetDropdownOptionsFromAutoCompleteValue(
                    newPendingFacet.facet?.autocompleteOptions,
                    '',
                );
            // The user selected an autocompleteOption value (no facet name included)
            } else {
                newOrExistingPendingFacet.value = changedValue;

                updateCurrentSearch(newOrExistingPendingFacet);
            }
        // Handle the case where users press enter value in the input instead of selecting from dropdown
        } else if (event.type === 'keydown'
            && (event as KeyboardEvent).key === 'Enter'
            && pendingFacet?.facet
            && (pendingFacet?.value || pendingFacet?.bulkValue)
        ) {
            const searchToSubmit = updateCurrentSearch(pendingFacet);

            triggerSubmit(searchToSubmit, navigate, location, onSubmit);
            resetOptions();
        }
    };

    /**
     * Called when a user attempts to delete a Chip
     * @param key
     * @returns
     */
    const handleDeleteFacetFactory = (key: string) => (event: SyntheticEvent): void => {
        event.preventDefault();

        const searchToCommit = { ...currentSearch };

        delete searchToCommit[key];

        setCurrentSearch(searchToCommit);
        resetOptions();
    };

    /**
     * This is called whenever a user is focused on the search input and presses a key or change
     * event occurrs. `value` is the result of the recent change.
     *
     * This event is trigger simultaneously with onKeyDown (FYI).
     *
     * In addition to updating the pendingFacet, the dropdown options can be modified based on the user's
     * current search and selection. Redetermine what's the in dropdown based on the action the user just performed.\
     * Note: If the user pasted in the value (isPaste = true), only show locally generated suggestions.
     *
     * First:
     * - handle the case when the user presses enter with a pending facet
     *
     * Next:
     * - the input can either be a pure value or include a facet name in it
     *      - first check if the value starts with any facet names
     *      - if so, find the facet and set it to the pendingFacet, then trim the name from the
     *          value and add the cleaned value to the pendingFacet.
     *      - if not, then add the value to the pendingFacet
     *
     * Lastly, do the following to update the dropdown list:
     * - pending facet has a facet
     *      - if the value is not empty and isnt the facet name, add the result of the
     *          autocompleteOptions to the list of facets
     *      - otherwise show a typing prompt
     * - pending facet doesn't have a facet
     *      - if the value is not empty, then collect the list of all compatible facets and filter the
     *        dropdown to show the facets combined with the current value
     *      - otherwise show a typing prompt
     * - no other case; anything is probably a bug since pendingFacet should always exist at this point
     */
    const handleInputChange = async (event: ChangeEvent<unknown>, value: string, isPaste?: boolean): Promise<void> => {
        // We want to ignore click events and only handle the case when the user actually changes the text input
        if (!event || event.type === 'click') {
            return;
        }

        event.preventDefault();

        const newOrExistingPendingFacet = pendingFacet ? cloneFacetRecord(pendingFacet) : createFacetedRecord();
        const [facet, facetValue] = findFacetByName(value, facetIndex);

        switch ((event as KeyboardEvent).key) {
            // Blacklist all the events except backspace that are handled in onKeyDown
            // to prevent edge cases from occurring.
            case KEY_EVENTS.Clear:
            case KEY_EVENTS.Delete:
            case KEY_EVENTS.Enter:
            case KEY_EVENTS.Escape:
            case KEY_EVENTS.Tab:
                break;
            default:
                if (facet) {
                    // If there isn't a pre-existing facet before setting one, there isn't a value included with the
                    // facet in the input value, but the pendingFacet does have a value, this means that the user
                    // typed the facet name into the input fully, so we need to clear the pendingFacet value as we
                    // transfer the input to a facet
                    if (!newOrExistingPendingFacet.facet && !facetValue && newOrExistingPendingFacet.value) {
                        newOrExistingPendingFacet.value = '';
                    }

                    newOrExistingPendingFacet.facet = facet;

                    // The only way that we have a value after a facet is detected is if the user selected
                    // an autocomplete/compatible option from the dropdown
                    if (facetValue) {
                        newOrExistingPendingFacet.value = facetValue;
                    }

                    setPendingFacet(newOrExistingPendingFacet);

                    // newOrExistingPendingFacet.value may already exist even if facetValue is falsey
                    if (newOrExistingPendingFacet.value) {
                        setOrResetDropdownOptionsFromAutoCompleteValue(
                            newOrExistingPendingFacet.facet?.autocompleteOptions,
                            newOrExistingPendingFacet.value,
                        );
                    } else {
                        setOrResetDropdownOptionsFromAutoCompleteValue(
                            newOrExistingPendingFacet.facet?.autocompleteOptions,
                            '',
                        );
                    }
                } else if (!value && !newOrExistingPendingFacet.value) {
                    // If the value is empty and the pendingFacet has no value, then the user likely pressed a
                    // non-alphanum key or some other edge case happened
                    resetOptions();
                } else if (!value && !newOrExistingPendingFacet.facet) {
                    // If the user pasted input, no facet was found, then deleted the input, handle the delete here
                    setPendingFacet(null);
                    resetOptions();
                } else {
                    newOrExistingPendingFacet.value = value;

                    setPendingFacet(newOrExistingPendingFacet);

                    // The user typed or pasted a value in, but a facet may not have been selected yet
                    // so show the compatible facets in the dropdown (and nothing else)
                    if (!newOrExistingPendingFacet.facet) {
                        if (isPaste) {
                            setLocallyCompatibleFacets(newOrExistingPendingFacet.value || '');
                            setOpen(true);
                        } else {
                            fetchCompatibleFacetsForValue(newOrExistingPendingFacet.value);
                        }
                    } else if (newOrExistingPendingFacet.value) {
                        setOrResetDropdownOptionsFromAutoCompleteValue(
                            newOrExistingPendingFacet.facet?.autocompleteOptions,
                            newOrExistingPendingFacet.value,
                        );
                    } else {
                        resetOptions();
                    }
                }
                break;
        }
    };

    /**
     * Need this function as MUI Autocomplete can't take a function with "isPaste" as a param for onInputChange
     * @param event
     * @param value
     */
    const handleInputChangeWithPasteParam = async (event: ChangeEvent<unknown>, value: string):
    Promise<void> => handleInputChange(event, value);

    /**
     * This is called whenever a user is focused on the search input and presses a key.
     *
     * If the key pressed is enter and we don't have a pending facet, we short-circuit and trigger a submit
     * @param event
     */
    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
        switch (event.key) {
            case KEY_EVENTS.Enter:
                // This is only triggered when the input html element has value
                event.preventDefault();
                if (!pendingFacet) {
                    triggerSubmit(currentSearch, navigate, location, onSubmit);
                    resetOptions();
                }
                break;
            default:
                break;
        }
    };

    /**
     * This is called whenever a user is focused on the search input and presses a key.
     *
     * 1. If the user is tabbing, then either finalize the pendingFacet or change the highlighted facet in the dropdown
     *      based on if the pending facet has a value or not.
     * 2. If the user presses clear, cancel, or espace then clear the search bar
     * 3. If the user presses delete or backspace, delete either the pending facet (if the facet has no value), the
     *      facet highlighted by tabbing, or the last facet in the currentsearch state
     */
    const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>): Promise<void> => {
        const currentSearchKeys = Object.keys(currentSearch);

        switch ((event as KeyboardEvent).key) {
            case KEY_EVENTS.Backspace:
                if (pendingFacet && !pendingFacet?.value) {
                    event.preventDefault();
                    // If there's no typed content, but there's a pending facet, then the user is trying to delete
                    // the pending facet itself
                    setPendingFacet(null);
                    resetOptions();
                } else if (selectedFacet !== DEFAULT_UNSELECTED_INDEX) {
                    event.preventDefault();
                    // otherwise, delete the facet selected by tabbing
                    const searchToSubmit = { ...currentSearch };

                    delete searchToSubmit[currentSearchKeys[selectedFacet]];

                    setSelectedFacet(DEFAULT_UNSELECTED_INDEX);
                    setCurrentSearch(searchToSubmit);
                    resetOptions();
                } else if (!pendingFacet?.value) {
                    event.preventDefault();
                    // Lastly, delete right-most facet in the current search
                    const rightMostKey = currentSearchKeys.pop();

                    if (rightMostKey) {
                        const searchToSubmit = { ...currentSearch };

                        delete searchToSubmit[rightMostKey];

                        setCurrentSearch(searchToSubmit);
                        resetOptions();
                    }
                }

                break;
            case KEY_EVENTS.Cancel:
            case KEY_EVENTS.Clear:
            case KEY_EVENTS.Escape:
                event.preventDefault();
                handleClear();
                break;
            case KEY_EVENTS.Tab:
                // tabbing with input creates facet and retains facet name in search
                event.preventDefault();
                if (pendingFacet?.facet && (pendingFacet?.value || pendingFacet?.bulkValue)) {
                    updateCurrentSearch(pendingFacet);
                    resetOptions();
                } else {
                    // tabbing without input iterates through each facet
                    setSelectedFacet(
                        (currentSearchKeys.length && selectedFacet + 1 < currentSearchKeys.length)
                            ? selectedFacet + 1
                            : DEFAULT_UNSELECTED_INDEX,
                    );
                }
                break;
            case KEY_EVENTS.Delete:
                // first try deleting pending facet
                if (pendingFacet) {
                    event.preventDefault();
                    setPendingFacet(null);
                    resetOptions();
                } else if (selectedFacet !== DEFAULT_UNSELECTED_INDEX) {
                    // then deletes facet selected by tabbing
                    event.preventDefault();
                    const searchToCommit = { ...currentSearch };

                    delete searchToCommit[currentSearchKeys[selectedFacet]];

                    setSelectedFacet(DEFAULT_UNSELECTED_INDEX);
                    setCurrentSearch(searchToCommit);
                    resetOptions();
                }

                break;
            default: {
                break;
            }
        }

        // "Falls" through to handleInputChange when the keyboard event actually changed the input's value
        // Both onKeyDown and handleInputChange run simultaneously
    };

    /**
     * Closes the snack bar and clears any errors
     */
    const handleSnackBarClose = (): void => {
        setError(null);
    };

    /**
     * Controls the open state of the autocomplete dropdown
     * @param event
     */
    const handleClose = (event: SyntheticEvent<Element>): void => {
        event.preventDefault();

        setOpen(false);
    };

    /**
     * Controls the open state of the autocomplete dropdown
     * @param event
     */
    const handleOpen = (event: SyntheticEvent<Element>): void => {
        event.preventDefault();

        setOpen(true);
    };

    /**
    * Triggered when the user presses the Search Icon button
    * @param event
    */
    const handleSubmit = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        const searchToSubmit = updateCurrentSearch(pendingFacet);

        triggerSubmit(searchToSubmit, navigate, location, onSubmit);
    };

    /**
     * Handles the paste event. If there's no pending facet and the parent provided the onPaste callback,
     * the handler will ask the onPaste function to parse the paste data and see if it contains a facet
     * and/or value pair. If so, the facet will be extracted and added to the current search list.
     * Otherwise the paste handler forwards the string data from the clipboard to the input change handler.
     *
     * @param event
     * @returns
     */
    const handlePaste = async (event: ClipboardEvent<HTMLInputElement>): Promise<void> => {
        event.stopPropagation();
        event.preventDefault();

        const pasteData = event.clipboardData.getData('text/plain').trim();

        // If search is empty (no facet), ask implementation if paste result is a new facet
        if (!pendingFacet && onPaste) {
            const pasteResult = await onPaste(pasteData);

            // If there is a facet as a result of this paste, then add to current search
            if (pasteResult) {
                const record = createNewRecordFromNameOrValue(
                    pasteResult.facetName,
                    pasteResult.value,
                    facetIndex,
                );

                updateCurrentSearch(record);
                setOpen(true);

                return;
            }
        }

        // Otherwise, the user pasted content intended as a value, so trigger input handler.
        // isPaste=true so the dropdown knows to generate suggestions locally as opposed to awaiting server response
        handleInputChange(event, pasteData, true);
    };

    /**
     * Render Functions
     */
    const debouncedLoadingAdornment: JSX.Element | null = useDebounce((loading ? <CircularProgress color="inherit" size={20} /> : null), 500)[0];

    const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => {
        const textFieldProps = mergeAndCompare(
            (originVal, newVal, key) => {
                if (key === 'className') {
                    return clsx(originVal, newVal);
                }

                return newVal;
            },
            params,
            TextFieldProps,
        ) as AutocompleteRenderInputParams;

        return (
            <FormControl
                fullWidth
                disabled={textFieldProps.disabled}
                id={textFieldProps.id}
                size="small"
                variant="outlined"
            >
                <InputLabel
                    htmlFor="faceted-search-input"
                    {...textFieldProps.InputLabelProps}
                >
                    {label}
                </InputLabel>
                <OutlinedInput
                    autoFocus
                    {...textFieldProps.InputProps}
                    components={components}
                    endAdornment={(
                        <InputAdornment className={classes.endAdornment} position="end">
                            {loading && debouncedLoadingAdornment}
                        </InputAdornment>
                    )}
                    id="faceted-search-input"
                    inputProps={{
                        ...textFieldProps.inputProps,
                        'data-testid': 'faceted-search-input',
                    }}
                    label={label}
                    placeholder={pendingFacet === null ? PENDING_FACET_PROMPT : placeholder}
                    size="small"
                />
            </FormControl>
        );
    };

    const filterAndSortOptions = (opts: string[], { inputValue }: FilterOptionsState<string>): string[] => matchSorter(
        opts.filter((o) => !getOptionSelected(o)),
        inputValue,
        { sorter: (ops) => ops.sort((a, b) => a.item.length - b.item.length || a.item.localeCompare(b.item)) },
    );

    const filterOptions = (opts: string[]): string[] => opts.filter((o) => !getOptionSelected(o));

    const value = [
        pendingFacet?.facet?.name,
        ...Object.values(currentSearch).map((record) => record.facet?.name),
    ].filter(Boolean) as string[];

    return (
        <>
            <CSSGrid
                alignItems="center"
                gap={2}
                gridTemplateAreas={'". . ."'}
                gridTemplateColumns="1fr 24px 24px"
            >
                <Autocomplete
                    freeSolo
                    multiple
                    autoComplete={autoComplete}
                    loading={loading}
                    {...rest}
                    className={clsx(classes.input, className)}
                    filterOptions={pendingFacet?.facet ? filterAndSortOptions : filterOptions}
                    getOptionSelected={getOptionSelected}
                    inputValue={pendingFacet?.value as string || ''}
                    loadingText="Loading..."
                    open={open}
                    options={options}
                    renderInput={renderInput}
                    renderOption={renderOptionFactory(classes.option, theme, renderOptionRule)}
                    renderTags={(): JSX.Element => (
                        <Tags
                            ChipProps={ChipProps}
                            currentSearch={currentSearch}
                            handleDeleteFacetFactory={handleDeleteFacetFactory}
                            handleDeleteProductSearch={handleDeleteProductSearch}
                            pendingFacet={pendingFacet}
                            selectedFacet={selectedFacet}
                        />
                    )}
                    size={size}
                    value={value}
                    onChange={handleChange}
                    onClose={handleClose}
                    onInputChange={handleInputChangeWithPasteParam}
                    onKeyDown={handleKeyDown}
                    onKeyPress={handleKeyPress}
                    onOpen={handleOpen}
                    onPaste={onPaste && !pendingFacet?.value ? handlePaste : undefined}
                />
                <IconButton
                    aria-label="search"
                    className={classes.searchButton}
                    size="small"
                    onClick={handleSubmit}
                >
                    <Search />
                </IconButton>
            </CSSGrid>
            <Snackbar
                open={!!error}
                onClose={handleSnackBarClose}
            >
                <Alert severity="error" onClose={handleSnackBarClose}>
                    {error?.message}
                </Alert>
            </Snackbar>
            {productSearchDialogOpen && (
                <ProductOptionSearchDialog
                    existingSearch={currentSearch}
                    submitProductOptionDialog={submitProductOptionDialog}
                />
            )}
            {createdTimeSearchDialogOpen && (
                <CreatedTimeSearchDialog
                    existingSearch={currentSearch}
                    submitCreatedTimeSearchDialog={submitCreatedTimeDialog}
                />
            )}
        </>
    );
};
