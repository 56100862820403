import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { deleteTag } from 'src/mutations/deleteTag';
import { QUERY_KEY } from 'src/queries/queryTags';

export const useDeleteTag = (): UseMutationResult<void, Error, App.Tag.DeleteMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, App.Tag.DeleteMutationVariables>(
        deleteTag,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            },
            useErrorBoundary: true,
        },
    );
};
