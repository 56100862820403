import { ContentStoreCategoriesService } from 'src/services/Categories';
import { DataSource } from 'src/constants';

export const mutateCategoryMonolithKeys = async (
    {
        accessToken,
        categoryHref,
        updatedCategoryMonolithKeys,
        signal,
    }: App.CategoryMonolithKey.MutationVariables,
): Promise<Models.ContentStoreApi.V3.CategoryMonolithKey[]> => {
    const deletedMonolithKeys = updatedCategoryMonolithKeys.filter(
        (cmk) => cmk.source === DataSource.Deleted,
    );
    const keptMonolithKeys = updatedCategoryMonolithKeys.filter(
        (cmk) => cmk.source === DataSource.Saved,
    );
    const newMonolithKeysByInt = updatedCategoryMonolithKeys.filter(
        (cmk) => cmk.source === DataSource.Selected,
    );

    deletedMonolithKeys.forEach((cmk) => {
        if (cmk.href) {
            ContentStoreCategoriesService.deleteCategoryMonolithKey(accessToken, cmk.href, signal);
        }
    });
    const newMonolithKeysPromise = newMonolithKeysByInt.map((cmk) => (
        ContentStoreCategoriesService.createCategoryMonolithKey(accessToken, cmk.monolithKey, categoryHref, signal)
    ));
    const newMonolithKeys = await Promise.all(newMonolithKeysPromise);

    return newMonolithKeys.concat(keptMonolithKeys);
};
