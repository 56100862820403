import { ContentStoreTagsService } from 'src/services/Tags';

export const mutateTag = async (
    {
        accessToken,
        id,
        tag,
        signal,
    }: App.Tag.MutationVariables,
): Promise<Models.ContentStoreApi.V3.Tag> => ContentStoreTagsService.updateTag(
    accessToken,
    id,
    tag,
    signal,
);
