import { selector } from 'recoil';

import { auth } from 'src/lib/auth';

export const isAuthorizedQuery = selector({
    key: 'IsAuthorized',
    get: async (): Promise<string | false> => {
        await auth.ensureAuthentication({
            nextUri: window.location.pathname,
        });

        return auth.getAccessToken() || false;
    },
});
