import { ComponentProps, forwardRef } from 'react';
// @ts-expect-error
import { InputBaseRoot } from '@material-ui/core/InputBase/InputBase';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    scrollContainer: {
        alignItems: 'center',
        borderRadius: 30,
        display: 'inline-flex',
        width: '100%',
        flexWrap: 'wrap',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
    },
});

export const CustomInputRoot = forwardRef((restProps: ComponentProps<typeof InputBaseRoot>, ref): JSX.Element => {
    const {
        children,
        className: classNameRoot,
        ...restRoot
    } = restProps;
    const classes = useStyles();

    return (
        <InputBaseRoot className={classNameRoot} {...restRoot} ref={ref}>
            <div className={classes.scrollContainer}>
                {children}
            </div>
        </InputBaseRoot>
    );
});
