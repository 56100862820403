import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryAllEntities, QUERY_KEY } from 'src/queries/queryAllEntities';

type Entities = Models.ContentStoreApi.V3.Entity[];

export const useQueryAllEntities = (
    accessToken: string | undefined | false,
    search: App.Entities.Search,
    options?: UseQueryOptions<Entities, Error, Entities, App.Entities.QueryKey>,
): UseQueryResult<Entities, Error> => useQuery<Entities, Error, Entities, App.Entities.QueryKey>(
    [QUERY_KEY, accessToken, search],
    queryAllEntities,
    {
        staleTime: Infinity,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        ...options,
    },
);
