import {
    Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import {
    ALL_DESIGNS_APPROVED, BulkApprovalStatus, NO_DESIGNS_APPROVED, SOME_DESIGNS_APPROVED,
} from 'src/components/Entity/constants';

type HookReturnValue = [
    BulkApprovalStatus,
    Dispatch<SetStateAction<BulkApprovalStatus>>,
];

export const useBulkIndexable = (entities?: Models.ContentStoreApi.V3.Entity[] | false): HookReturnValue => {
    const [indexable, setIndexable] = useState<BulkApprovalStatus>(NO_DESIGNS_APPROVED);

    useEffect(() => {
        if (entities) {
            const indexableCount = entities.filter((e) => !!e.indexable).length;

            if (indexableCount) {
                if (indexableCount === entities.length) {
                    setIndexable(ALL_DESIGNS_APPROVED);
                } else {
                    setIndexable(SOME_DESIGNS_APPROVED);
                }
            } else {
                setIndexable(NO_DESIGNS_APPROVED);
            }
        }
    }, [entities]);

    return [indexable, setIndexable];
};
