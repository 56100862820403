import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryEntityProduct, QUERY_KEY } from 'src/queries/queryEntityProduct';

type PagedEntityProduct = Models.V3.PageResult<Models.ContentStoreApi.V3.EntityProduct>;

export const useQueryEntityProducts = (
    accessToken: string | undefined | false,
    search: App.EntityProducts.Search,
    options?: UseQueryOptions<PagedEntityProduct, Error, PagedEntityProduct, App.EntityProducts.QueryKey>,
): UseQueryResult<PagedEntityProduct, Error> => useQuery<PagedEntityProduct,
Error, PagedEntityProduct, App.EntityProducts.QueryKey>(
    [QUERY_KEY, accessToken, search],
    queryEntityProduct,
    options,
);
