import {
    Button, Checkbox, ListItemText, makeStyles, Theme,
} from '@material-ui/core';
import { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useRecoilValue } from 'recoil';
import { useCSVReader } from 'react-papaparse';
import {
    Dialog, DialogContent, DialogProps, DialogTitle, Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { useQueryCultures } from 'src/hooks/useQueryCultures';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { useQueryAllEntities } from 'src/hooks/useQueryAllEntities';
import { useQueryEntitiesByBatchedIds } from 'src/hooks/useQueryEntitiesByBatchedIds';
import { useMutateEntityRankings } from 'src/components/ForcedRankingEdit/hooks/useMutateEntityRankings';
import { ActionButtons } from 'src/components/common/ActionButtons';
import { ForcedRankingPreview } from 'src/components/ForcedRankingEdit/ForcedRankingPreview';
import { compareCultures } from 'src/components/ForcedRankingEdit/util/forcedRankingUtils';
import { Upload } from '@material-ui/icons';

export interface PropTypes extends Omit<DialogProps, 'onClose'> {
    rankingStrategy: Models.ContentStoreApi.V3.RankingStrategy;
    onClose: () => void;
}

export interface ExistingRankings {
    [culture: string]: [string, number][] | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
    addFileGrid: {
        alignItems: 'center',
        justifyContent: 'start',
    },
    cardLayout: {
        gridTemplateRows: 'auto auto',
        height: '100%',
        alignContent: 'space-between',
        minWidth: '50vw',
    },
    grid: {
        margin: theme.spacing(4),
        gridGap: theme.spacing(4),
        gridAutoFlow: 'row',
        gridTemplateColumns: '1fr 1fr',
    },
    hidden: {
        display: 'none',
    },
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    modal: {
        minHeight: '90vh',
    },
}));

export const ForcedRankingUpload = (props: PropTypes): JSX.Element => {
    const { rankingStrategy, onClose, ...rest } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const { CSVReader } = useCSVReader();

    const [inputData, setInputData] = useState<App.ForcedRanking.InputData | undefined>(undefined);
    const [cultures, setCultures] = useState<string[]>([]);
    const mutation = useMutateEntityRankings();

    const {
        data: unsortedCultureOptions,
    } = useQueryCultures(accessToken, {
        enabled: !!accessToken,
        suspense: false,
        refetchInterval: false,
    });

    const cultureOptions = unsortedCultureOptions?.sort(compareCultures);

    const { data: inputEntities } = useQueryEntitiesByBatchedIds(accessToken, {
        ids: !inputData ? [] : inputData.map((input) => input[0]),
        includeInactiveRankings: true,
    }, {
        enabled: !!inputData && !!inputData.length,
    });

    const { data: previousEntities, refetch } = useQueryAllEntities(accessToken, {
        rankingStrategyIds: rankingStrategy.rankingStrategyId,
        includeInactiveRankings: true,
    });

    const handleChange = (event: SelectChangeEvent<string[]>): void => {
        const {
            target: { value },
        } = event;

        setCultures(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleUpload = (results: any): void => {
        const inputWithInts = ((results.data as [string, string])
            .filter((result) => !!result[0])
            .map((result) => [result[0], parseInt(result[1], 10)]) as App.ForcedRanking.InputData)
            .sort((a, b) => a[1] - b[1]);

        setInputData(inputWithInts);
    };

    const handleCancel = async (): Promise<void> => {
        setInputData(undefined);
        setCultures([]);
        onClose();
    };

    const handleSave = async (): Promise<void> => {
        await mutation.mutateAsync({
            accessToken,
            previousEntities,
            inputData: inputData ?? [],
            cultures,
            rankingStrategy,
        });
        refetch();
    };

    const handleClose = (): void => {
        setInputData(undefined);
        setCultures([]);
        onClose();
    };

    return (
        <Dialog {...rest} maxWidth="xl" onClose={handleClose}>
            <CSVReader
                onUploadAccepted={handleUpload}
            >
                {({
                    getRootProps,
                    acceptedFile,
                }: any): JSX.Element => (
                    <div className={classes.modal}>
                        <DialogTitle>Upload a New Forced Ranking</DialogTitle>
                        <DialogContent>
                            <CSSGrid className={classes.cardLayout}>
                                <CSSGrid className={classes.grid}>
                                    <FormControl size="small">
                                        <InputLabel id="culture-multiselect-label">Cultures</InputLabel>
                                        <Select
                                            multiple
                                            id="culture-multiselect"
                                            input={<OutlinedInput label="Cultures" />}
                                            labelId="culture-multiselect-label"
                                            renderValue={(selected: string[]): string => selected.join(', ')}
                                            size="small"
                                            value={cultures}
                                            onChange={handleChange}
                                        >
                                            {cultureOptions?.map((cultureOption) => (
                                                <MenuItem value={cultureOption.cultureValue}>
                                                    <Checkbox checked={cultures.indexOf(cultureOption.cultureValue) > -1} size="small" />
                                                    <ListItemText primary={cultureOption.cultureValue} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <CSSGrid className={classes.addFileGrid}>
                                        <input accept=".csv" className={classes.hidden} id="contained-button-file" type="file" />
                                        <Button variant="contained" {...getRootProps()} fullWidth={false} startIcon={<Upload />}>
                                            {inputData && inputData.length ? 'Replace File' : 'Add File'}
                                        </Button>
                                    </CSSGrid>
                                </CSSGrid>
                                <ActionButtons
                                    disabled={!inputData || !cultures.length}
                                    onClose={handleCancel}
                                    onSave={handleSave}
                                />
                                <Typography variant="h6">
                                    {acceptedFile && `Previewing ${acceptedFile.name}`}
                                </Typography>
                                <ForcedRankingPreview
                                    previewData={inputData}
                                    previewEntities={inputEntities}
                                />
                            </CSSGrid>
                        </DialogContent>
                    </div>
                )}
            </CSVReader>
        </Dialog>
    );
};
