import { QueryFunctionContext } from 'react-query';

import { ContentStoreCategoriesService } from 'src/services/Categories';

export const QUERY_KEY = 'category';

export const queryCategory = (
    context: QueryFunctionContext<App.Category.QueryKey>,
): Promise<Models.ContentStoreApi.V3.Category> => {
    const { queryKey: [, accessToken, id] } = context;

    return ContentStoreCategoriesService.getCategory(accessToken, id);
};
