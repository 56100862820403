import { DataSource } from 'src/constants';

export const generateCategorySuggestionsPlaceholderData = (): Models.ContentStoreApi.V3.SourcedCategory[] => (
    [{
        id: '-1',
        href: '',
        source: DataSource.Loading,
        internalName: 'Loading Suggestions...',
        translations: {},
        isActive: false,
        categoryKey: -1,
    }]
);
