import { useRecoilValue } from 'recoil';
import { MouseEvent, useEffect, useState } from 'react';
import {
    Button, Grid, makeStyles, Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { KeywordResult } from 'src/components/Keywords/KeywordResult';
import { InfiniteSearchResultsGrid } from 'src/components/common/Search/InfiniteSearchResultsGrid';
import { queryKeywords, QUERY_KEY } from 'src/queries/queryKeywords';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { KeywordDetailsDrawer } from 'src/components/Keywords/KeywordDetailsDrawer';
import { SnackBarErrorBoundary } from 'src/components/common/SnackBarErrorBoundary';
import { useInfiniteScrollQuery } from 'src/hooks/useInfiniteScrollQuery';
import { EmptyState } from 'src/components/common/EmptyState';
import { NOT_APPLICABLE } from 'src/constants';
import { isSearchPopulated } from 'src/utils';
import { ZeroSearchState } from 'src/components/common/ZeroSearchState';

type Keyword = Models.ContentStoreApi.V3.Keyword;

interface PropTypes {
    search: App.Keywords.Search;
}

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        paddingRight: theme.spacing(4),
    },
}));

export const KeywordResults = (props: PropTypes): JSX.Element => {
    const { search } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const [selectedId, setSelectedId] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const { data, fetchNextPage, hasNextPage } = useInfiniteScrollQuery<Keyword, Error, App.Keywords.QueryKey>(
        [QUERY_KEY, accessToken, search],
        queryKeywords,
        {
            enabled: !!accessToken && isSearchPopulated(search),
            retry: 2,
            refetchInterval: 30000,
        },
    );

    const handleCloseDrawer = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        setSelectedId('');
        setIsOpen(false);
    };

    const handleShowDrawer = (id: string): void => {
        setSelectedId(id);
    };

    const handleNewKeyword = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        if (accessToken) {
            setIsOpen(true);
        }
    };

    const loadMore = async (): Promise<void> => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    useEffect(() => {
        setIsOpen(!!selectedId);
    }, [selectedId]);

    return (
        <SnackBarErrorBoundary>
            <Grid
                container
                className={classes.actions}
                justifyContent="flex-end"
                spacing={4}
            >
                <Grid item>
                    <Button
                        color="primary"
                        startIcon={<Add />}
                        variant="contained"
                        onClick={handleNewKeyword}
                    >
                        New Keyword
                    </Button>
                </Grid>
            </Grid>
            {!isSearchPopulated(search) && (<ZeroSearchState />)}
            {(isSearchPopulated(search) && !data?.length) && (
                <EmptyState>
                    Could not find keywords for the given parameters
                </EmptyState>
            )}
            {!!data?.length && (
                <InfiniteSearchResultsGrid
                    data={data}
                    hasMore={!!hasNextPage}
                    itemKey={(item: Models.ContentStoreApi.V3.Keyword): string => `item-${item.id}`}
                    loadMore={loadMore}
                    minWidth={200}
                    variant="cards"
                >
                    {(item: Models.ContentStoreApi.V3.Keyword): JSX.Element => (
                        <KeywordResult
                            id={item.id}
                            keywordName={item.keywordName || NOT_APPLICABLE}
                            onClick={handleShowDrawer}
                        />
                    )}
                </InfiniteSearchResultsGrid>
            )}
            {isOpen && (
                <KeywordDetailsDrawer
                    open
                    id={selectedId}
                    onClose={handleCloseDrawer}
                />
            )}
        </SnackBarErrorBoundary>
    );
};
