import { QueryFunctionContext } from 'react-query';

import { ContentStoreTaxonomiesService } from 'src/services/Taxonomies';

export const QUERY_KEY = 'taxonomy';

export const queryTaxonomy = (
    context: QueryFunctionContext<App.Taxonomy.QueryKey>,
): Promise<Models.ContentStoreApi.V3.Taxonomy> => {
    const { queryKey: [, accessToken, id] } = context;

    return ContentStoreTaxonomiesService.getTaxonomy(
        accessToken,
        id,
    );
};
