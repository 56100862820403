import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryKeyword, QUERY_KEY } from 'src/queries/queryKeyword';

type Keyword = Models.ContentStoreApi.V3.Keyword;

export const useQueryKeyword = (
    accessToken: string | undefined | false,
    id: string,
    options?: UseQueryOptions<Keyword, Error, Keyword, App.Keyword.QueryKey>,
): UseQueryResult<Keyword, Error> => useQuery<Keyword, Error, Keyword, App.Keyword.QueryKey>(
    [QUERY_KEY, accessToken, id],
    queryKeyword,
    options,
);
