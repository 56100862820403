import { makeStyles } from '@material-ui/core';

import { InfiniteSearchResultsGrid } from 'src/components/common/Search/InfiniteSearchResultsGrid';
import { EntityResultSkeleton } from 'src/components/Entity/EntityResult/EntityResultSkeleton';

const PLACEHOLDERS: number[] = Array.from({ length: 10 }, (_, i) => i + 1);

const useStyles = makeStyles({
    grid: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    },
});

export const EntityResultsSkeleton = (): JSX.Element => {
    const classes = useStyles();

    return (
        <InfiniteSearchResultsGrid<number>
            className={classes.grid}
            data={PLACEHOLDERS}
            hasMore={false}
            itemKey={(item: number): string => `item-${item}`}
        >
            {(): JSX.Element => (<EntityResultSkeleton />)}
        </InfiniteSearchResultsGrid>
    );
};
