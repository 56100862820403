import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { withPreviewUrl } from 'src/lib/decorators/withPreviewUrl';
import { findId } from 'src/lib/findId';
import { DEFAULT_LIMIT } from 'src/constants';
import axios from 'axios';

type Entity = Models.ContentStoreApi.V3.Entity;
type EmbeddedPayload = Models.ContentStoreApi.V3.Entity.Embedded.Payload;
type ValidEmbeddedRecord = Record<Models.V3.Href, Models.ContentStoreApi.V3.Entity.Embedded.Category
| Models.ContentStoreApi.V3.Entity.Embedded.EmbeddedCulture
| Models.ContentStoreApi.V3.Entity.Embedded.Keyword
| Models.ContentStoreApi.V3.Entity.Embedded.ProductSpecification
| Models.ContentStoreApi.V3.Entity.Embedded.Tag
| Models.ContentStoreApi.V3.Entity.Embedded.StrategyRanks
>;

class ContentStoreEntitiesApi extends AbstractService implements Services.ContentStoreApi.Entities {
    private static modifyEmbeddedRecord(embeddedRecord: ValidEmbeddedRecord): ValidEmbeddedRecord {
        return Object.entries(embeddedRecord).reduce((accum, [key, value]) => {
            // eslint-disable-next-line no-param-reassign
            accum[key] = {
                ...value,
                id: findId(key),
            };
            return accum;
        }, {} as ValidEmbeddedRecord);
    }

    private static modifyEmbeddedPayload(embedded: EmbeddedPayload): EmbeddedPayload {
        return Object.entries(embedded).reduce((accum, [key, value]) => ({
            ...accum,
            [key]: ContentStoreEntitiesApi.modifyEmbeddedRecord(value),
        }), {} as EmbeddedPayload);
    }

    // eslint-disable-next-line class-methods-use-this
    protected customizeResult(result: Entity): Entity {
        const modifiedResult: Entity = result;

        modifiedResult.id = ('href' in result) ? findId(result.href) : '';

        if (modifiedResult._embedded) {
            modifiedResult._embedded = ContentStoreEntitiesApi.modifyEmbeddedPayload(modifiedResult._embedded);
        }

        return modifiedResult;
    }

    public async createEntity(
        bearerToken: string | undefined | false,
        body: Models.ContentStoreApi.V3.CreateEntity,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Entity> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request createEntity. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/entities`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .post(url, {
                    json: body,
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Entity>();

            return withPreviewUrl(json);
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to create entity',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async deleteEntity(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<void> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request deleteEntity. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/entities/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            await this.api
                .delete(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to delete entity',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async getEntitiesCsv(
        bearerToken: string | undefined | false,
        search: App.Entities.Search,
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Blob> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getEntitiesCsv. No bearerToken provided.',
            });
        }

        const url = `${config.services.contentStoreApi.prefixUrl}/api/${this.version}/entities/csv`;

        const params = {
            ...search,
            offset,
            limit,
            requestor: config.appName,
            noCache: true,
        };

        try {
            const res = await axios.get(url, {
                params,
                paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
                headers: { Authorization: `Bearer ${bearerToken}` },
                responseType: 'blob',
                signal,
            });

            return res.data;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch entities csv',
                info: (e as Error).message,
                url,
                params,
            });
        }
    }

    public async getEntities(
        bearerToken: string | undefined | false,
        search: App.Entities.Search,
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.Entity>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getEntities. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/entities`;
        const searchParams = qs.stringify({
            ...search,
            offset,
            limit,
            requestor: config.appName,
            noCache: true,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.Entity>>();

            return {
                ...json,
                results: json.results.map((j) => withPreviewUrl(j)),
            };
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch entities',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async getAllEntities(
        bearerToken: string | undefined | false,
        search: App.Entities.Search,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Entity[]> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getAllEntities. No bearerToken provided.',
            });
        }

        const entities = await this.getEntities(bearerToken, search, 0, DEFAULT_LIMIT, signal);
        const { total } = entities;
        let offset = DEFAULT_LIMIT;
        let promises: Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.Entity>>[] = [];

        while (offset < total) {
            promises = promises.concat(this.getEntities(bearerToken, search, offset, DEFAULT_LIMIT, signal));
            offset += DEFAULT_LIMIT;
        }

        const remainingEntities = await Promise.all(promises);

        const allEntities = remainingEntities.reduce((accum, ent) => {
            const newArray = accum.concat(ent.results);

            return newArray;
        }, entities.results);

        return allEntities;
    }

    public async getEntitiesMetadata(
        bearerToken: string | undefined | false,
        search: App.Entities.Search,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.EntityMetadata> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getEntities. No bearerToken provided.',
            });
        }
        const url = `api/${this.version}/entities/metadata`;
        const searchParams = qs.stringify({
            ...search,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.EntityMetadata>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: `Failed to fetch entities's metadata: ${(e as Error).message}`,
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async getEntity(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Entity> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getEntity. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/entities/${id}`;
        const searchParams = qs.stringify({
            addProductOptions: true,
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Entity>();

            return withPreviewUrl(json);
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch entity',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async updateEntity(
        bearerToken: string | undefined | false,
        id: string,
        body: Models.ContentStoreApi.V3.UpdateEntity,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Entity> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request updateEntity. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/entities/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        const { _embedded, ...restBody } = body;

        try {
            const json = await this.api
                .put(url, {
                    signal,
                    json: restBody,
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Entity>();

            return withPreviewUrl(json);
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to update entity',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }
}

export const ContentStoreEntitiesService = new ContentStoreEntitiesApi(config.services.contentStoreApi);
