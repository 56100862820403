import { QueryFunctionContext } from 'react-query';

import { ContentStoreEntitiesService } from 'src/services/Entities';

export const QUERY_KEY = 'entitiesmetadata';

export const queryEntitiesMetadata = (
    context: QueryFunctionContext<App.Entities.QueryKey>,
): Promise<Models.ContentStoreApi.V3.EntityMetadata> => {
    const { queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentStoreEntitiesService.getEntitiesMetadata(
        accessToken,
        search,
    );
};
