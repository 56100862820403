import { Skeleton } from '@material-ui/core';

export const ImageSkeleton = (): JSX.Element => (
    <Skeleton
        animation="wave"
        sx={{
            height: '100%',
            width: '100%',
        }}
        variant="rectangular"
    />
);
