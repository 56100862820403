import {
    Chip, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';
import qs from 'qs';
import { useSetRecoilState } from 'recoil';

import { selectedIdsState } from 'src/atoms/selectedIdsAtom';

export interface PropTypes {
    designConceptId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    designConceptChip: {
        marginRight: theme.spacing(2),
    },
}));

export const DesignConceptChip = (props: PropTypes): JSX.Element => {
    const navigation = useNavigate();
    const {
        designConceptId,
    } = props;
    const classes = useStyles();
    const setSelectedIds = useSetRecoilState(selectedIdsState);

    const handleShowDesignConcept = (designConceptIdToSearch: string): void => {
        navigation({
            ...window.location,
            search: qs.stringify({ designConceptId: designConceptIdToSearch }),
        });
    };
    const handleFilterDesignConcept = (id: string): void => {
        setSelectedIds([]);
        handleShowDesignConcept(id);
    };
    const handleOnClick = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        handleFilterDesignConcept(designConceptId);
    };

    return (
        <Chip
            clickable
            className={classes.designConceptChip}
            color="primary"
            component="a"
            label={(
                <div>
                    <Typography display="inline" variant="body2">
                        {designConceptId}
                    </Typography>
                </div>
                )}
            variant="filled"
            onClick={handleOnClick}
        />
    );
};
