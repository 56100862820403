import { QueryFunctionContext } from 'react-query';

import { ContentStoreEntitiesService } from 'src/services/Entities';
import { DEFAULT_LIMIT } from 'src/constants';
import { prepareEntityForEditing } from 'src/utils/prepareEntityForEditting';

export const QUERY_KEY = 'entities';

export const queryEntities = (
    context: QueryFunctionContext<App.Entities.PagedQueryKey, App.Entities.PageParam>,
): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.Entity>> => {
    const { pageParam: offset, queryKey } = context;
    const [, accessToken, search, isEditing] = queryKey;

    const entities = ContentStoreEntitiesService.getEntities(
        accessToken,
        search,
        search?.offset || offset,
        DEFAULT_LIMIT,
    );

    return isEditing ? entities.then((result) => ({
        ...result,
        results: result.results.map(prepareEntityForEditing),
    })) : entities;
};
