import {
    Typography,
    Button,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { ComponentProps } from 'react';

export interface PropTypes extends ComponentProps<typeof Button> {
    title: string,
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: theme.palette.secondary.main,
        textTransform: 'none',
        '& .MuiButton-label': {
            display: 'grid',
            gridTemplateAreas: '". ."',
            gridTemplateColumns: '1fr auto',
            textAlign: 'left',
        },
        borderRadius: '0px',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));

export const TagCardTitle = (props: PropTypes): JSX.Element => {
    const {
        title,
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.title}>
            <Typography
                display="block"
                overflow="hidden"
                textOverflow="ellipsis"
                variant="h6"
            >
                {title}
            </Typography>
        </div>
    );
};
