import {
    Chip,
    Divider,
    Grid,
    makeStyles,
    Skeleton,
    Theme,
    Typography,
} from '@material-ui/core';
import {
    ComponentProps,
    SyntheticEvent,
    useEffect,
    useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    TabContext, TabPanel,
} from '@material-ui/lab';

import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { useMutateCategory } from 'src/components/Categories/hooks/useMutateCategory';
import { DetailsDrawer } from 'src/components/common/DetailsDrawer';
import { FormArea } from 'src/components/common/DetailsDrawer/FormArea';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { CategoryDetailsForm } from 'src/components/Categories/CategoryDetailsDrawer/CategoryDetailsForm';
import { drawerUpdatedState } from 'src/atoms/drawerUpdatedAtom';
import { useQueryFullCategory } from 'src/components/Categories/hooks/useQueryFullCategory';
import { DataSource, UserEvent } from 'src/constants';
import { useMutateCategoryMonolithKeys } from 'src/components/Categories/hooks/useMutateCategoryMonolithKeys';
import { MergeLoadingButton } from 'src/components/Categories/CategoryDetailsDrawer/MergeLoadingButton';
import { useMergeCategory } from 'src/components/Categories/hooks/useMergeCategory';
import { useDeleteCategory } from 'src/components/Categories/hooks/useDeleteCategory';
import { TabList } from 'src/components/common/Tabs/TabList';
import { Tab } from 'src/components/common/Tabs/Tab';
import { CategoryMonolithKeysTab } from 'src/components/Categories/CategoryDetailsDrawer/CategoryMonolithKeysTab';
import { CategoryTranslationsTab } from 'src/components/Categories/CategoryDetailsDrawer/CategoryTranslationsTab';
import { CategoryTaxonomiesTab } from 'src/components/Categories/CategoryDetailsDrawer/CategoryTaxonomiesTab';
import { useCreateCategory } from 'src/components/Categories/hooks/useCreateCategory';
import { useTrackEvent } from 'src/hooks/useTrackEvent';

export interface PropTypes extends Omit<ComponentProps<typeof DetailsDrawer>, 'id' | 'onDelete' | 'onSave' | 'middleButton'> {
    id?: string;
    changeSelectedId: (id: string) => void;
    closeDrawer: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        marginBottom: theme.spacing(6),
    },
    title: {
        display: 'flex',
        paddingRight: theme.spacing(18),
    },
    chipSkeleton: {
        borderRadius: theme.spacing(3),
        height: theme.spacing(12),
        width: theme.spacing(16),
        paddingLeft: theme.spacing(18),
        margin: 0,

    },
    tabPanel: {
        paddingTop: 0,
    },
}));

export const CategoryDetailsDrawer = (props: PropTypes): JSX.Element => {
    const {
        changeSelectedId,
        closeDrawer,
        id = '',
        open,
        onClose,
        ...rest
    } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const [internalName, setCategoryName] = useState<string>('');
    const [translations, setTranslations] = useState<Models.ContentStoreApi.V3.Translations>({});
    const [isActive, setIsActive] = useState<boolean>(false);
    const [categoryKey, setCategoryKey] = useState<number>(-1);
    const [monolithKeys, setMonolithKeys] = useState<Models.ContentStoreApi.V3.SourcedCategoryMonolithKey[]>([]);
    const setUpdatedState = useSetRecoilState(drawerUpdatedState);
    const [locales, setLocales] = useState<string[]>(Object.keys(translations));
    const [activeTab, setActiveTab] = useState('0');
    const { trackEvent } = useTrackEvent();
    const [
        categoryMonolithKeys,
        category,
    ] = useQueryFullCategory(accessToken, id);

    const {
        data, error, isLoading, isError,
    } = category;

    const categoryMonolithKeyData = categoryMonolithKeys.data;

    const createMutation = useCreateCategory();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const mutation = id && useMutateCategory(accessToken, id);
    const deleteMutation = useDeleteCategory();
    const mergeMutation = useMergeCategory();

    const monolithKeyMutation = useMutateCategoryMonolithKeys(accessToken, id);

    useEffect(() => {
        if (data) {
            setCategoryName(data.internalName);
            setTranslations(data.translations);
            setIsActive(data.isActive);
            setCategoryKey(data.categoryKey);
            setLocales(Object.keys(data.translations));
        }
    }, [data]);

    useEffect(() => {
        if (categoryMonolithKeyData) {
            const sourcedCategoryMonolithKeyData = categoryMonolithKeyData.map((cmk) => ({
                ...cmk,
                source: DataSource.Saved,
            }));

            setMonolithKeys(sourcedCategoryMonolithKeyData);
        }
    }, [categoryMonolithKeyData]);

    const handleCategoryNameChange = (newCategoryName: string): void => {
        setCategoryName(newCategoryName);
        setUpdatedState(true);
    };

    const handleTranslationChange = (locale: string, translation: string): void => {
        const updateTranslations = {
            ...translations,
            [locale]: translation,
        };

        setTranslations(updateTranslations);
        setLocales((previousLocales) => {
            previousLocales.push(locale);
            return previousLocales;
        });
        setUpdatedState(true);
    };

    const handleTranslationDelete = async (locale: string): Promise<void> => {
        const newTranslations = { ...translations };

        delete newTranslations[locale];
        setTranslations(newTranslations);
        setLocales((previousLocales) => previousLocales.filter((l) => l !== locale));
        setUpdatedState(true);
    };

    const handleSave = async (): Promise<void> => {
        const updatedCategory: Models.ContentStoreApi.V3.UpdateCategory = {
            internalName,
            translations,
            isActive,
            categoryKey,
        };

        if (id && mutation) {
            await mutation.mutateAsync({ accessToken, id, category: updatedCategory });

            if (data && categoryMonolithKeyData && monolithKeys) {
                await monolithKeyMutation.mutateAsync({
                    accessToken,
                    categoryHref: data.href,
                    updatedCategoryMonolithKeys: monolithKeys,
                });
            }
        } else {
            await createMutation.mutateAsync({
                accessToken,
                category: updatedCategory,
            });
        }
        setUpdatedState(false);
    };

    const handleMonolithKeysChange = (
        newMonolithKeys: Models.ContentStoreApi.V3.SourcedCategoryMonolithKey[],
    ): void => {
        setMonolithKeys(newMonolithKeys);
    };

    const handleTabChange = (
        event: SyntheticEvent<Element, Event>,
        newTabIndex: string,
    ): void => {
        event.preventDefault();

        setActiveTab(newTabIndex);
    };

    const handleTabClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        trackEvent({
            eventName: UserEvent.CategoriesTabSelection,
            eventValue:
                (event.target as HTMLElement).childNodes[1]?.nodeValue ?? undefined,
        });
    };

    const handleMerge = async (replacementId: string): Promise<void> => {
        await mergeMutation.mutateAsync({
            accessToken,
            replacementId,
            id,
        });

        closeDrawer();
    };

    const handleDelete = async (): Promise<void> => {
        if (id) {
            await deleteMutation.mutateAsync({ accessToken, id });
        }

        closeDrawer();
    };

    const mergeButton = (<MergeLoadingButton variant="outlined" onDelete={handleDelete} onMerge={handleMerge} />);

    return (
        <DetailsDrawer
            middleButton={mergeButton}
            open={open}
            onClose={onClose}
            onSave={handleSave}
            {...rest}
        >
            <FormArea title={(
                <CSSGrid className={classes.title}>
                    <Grid container>
                        <Grid container item xs={6}>
                            <Typography paragraph margin={0} variant="h6">
                                Category Details
                            </Typography>
                        </Grid>
                        <Grid container item justifyContent="flex-end" xs={6}>
                            <div>
                                {(isLoading && !!id) ? (
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Skeleton
                                                animation="wave"
                                                className={classes.chipSkeleton}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton
                                                animation="wave"
                                                className={classes.chipSkeleton}
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Chip
                                                label={(
                                                    <div>
                                                        <Typography display="inline" fontWeight="bold" variant="body2">{isActive ? 'Is Active' : 'Not Active'}</Typography>
                                                    </div>
                                                )}
                                                variant="filled"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                label={(
                                                    <div>
                                                        <Typography display="inline" fontWeight="bold" variant="body2">Category Key:</Typography>
                                                        {'\u00A0'}
                                                        <Typography display="inline" variant="body2">{categoryKey}</Typography>
                                                    </div>
                                                )}
                                                variant="filled"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </CSSGrid>
            )}
            >
                <CategoryDetailsForm
                    id={id}
                    internalName={internalName}
                    onCategoryNameChange={handleCategoryNameChange}
                />
            </FormArea>
            <Divider className={classes.divider} />
            <TabContext value={activeTab}>
                <TabList
                    boxProps={{
                        sx: { borderBottom: 1, borderColor: 'divider' },
                    }}
                    value={activeTab}
                    onChange={handleTabChange}
                    onClick={handleTabClick}
                >
                    <Tab
                        label="Translations"
                        value="0"
                    />
                    <Tab
                        label="Monolith Keys"
                        value="1"
                    />
                    <Tab
                        label="Taxonomies"
                        value="2"
                    />
                </TabList>
                <section className={classes.divider}>
                    {/* @ts-ignore */}
                    <TabPanel className={classes.tabPanel} index={0} value="0">
                        <CategoryTranslationsTab
                            error={error}
                            handleTranslationChange={handleTranslationChange}
                            handleTranslationDelete={handleTranslationDelete}
                            isError={isError}
                            isLoading={isLoading}
                            locales={locales}
                            translations={translations}
                        />
                    </TabPanel>
                    {/* @ts-ignore */}
                    <TabPanel className={classes.tabPanel} index={1} value="1">
                        <CategoryMonolithKeysTab
                            error={error}
                            handleMonolithKeysChange={handleMonolithKeysChange}
                            isError={isError}
                            isLoading={isLoading}
                            monolithKeys={monolithKeys}
                        />
                    </TabPanel>
                    {/* @ts-ignore */}
                    <TabPanel className={classes.tabPanel} index={2} value="2">
                        <CategoryTaxonomiesTab id={id} />
                    </TabPanel>
                </section>
            </TabContext>
            <Divider className={classes.divider} />
        </DetailsDrawer>
    );
};
