import {
    Routes as ReactRouterRoutes,
    Route,
} from 'react-router-dom';

import * as routes from 'src/lib/routes';
import { CategoriesPage } from 'src/pages/CategoriesPage';
import { EntitiesPage } from 'src/pages/EntitiesPage';
import { KeywordsPage } from 'src/pages/KeywordsPage';
import { Logout } from 'src/pages/Logout';
import { ForcedRankingPage } from 'src/pages/ForcedRankingPage';
import { ForcedRankingEditPage } from 'src/pages/ForcedRankingEditPage';
import { TagsPage } from 'src/pages/TagsPage';
import { TaxonomiesPage } from 'src/pages/TaxonomiesPage';
import { Layout } from 'src/components/common/Layout';
import { AuthBoundary } from 'src/components/AuthBoundary';
import { DesignTaggingGuidancePage } from 'src/pages/DesignTaggingGuidancePage';

export const Routes = (): JSX.Element => (
    <ReactRouterRoutes>
        <Route element={<Logout />} path={routes.LOGOUT} />
        <Route element={<Layout />} path={routes.ROOT}>
            <Route
                index
                element={(
                    <AuthBoundary>
                        <EntitiesPage />
                    </AuthBoundary>
                  )}
            />
            <Route
                element={(
                    <AuthBoundary>
                        <EntitiesPage />
                    </AuthBoundary>
                  )}
                path={routes.DESIGNS}
            />
            <Route
                element={(
                    <AuthBoundary>
                        <KeywordsPage />
                    </AuthBoundary>
                  )}
                path={routes.KEYWORDS}
            />
            <Route
                element={(
                    <AuthBoundary>
                        <TagsPage />
                    </AuthBoundary>
              )}
                path={routes.TAGS}
            />
            <Route
                element={(
                    <AuthBoundary>
                        <CategoriesPage />
                    </AuthBoundary>
              )}
                path={routes.CATEGORIES}
            />
            <Route
                element={(
                    <AuthBoundary>
                        <TaxonomiesPage />
                    </AuthBoundary>
              )}
                path={routes.TAXONOMIES}
            />
            <Route
                element={(
                    <AuthBoundary>
                        <ForcedRankingPage />
                    </AuthBoundary>
            )}
                path={routes.FORCEDRANKING}
            />
            <Route
                element={(
                    <AuthBoundary>
                        <ForcedRankingEditPage />
                    </AuthBoundary>
            )}
                path={routes.FORCEDRANKINGEDIT}
            />
            <Route
                element={(
                    <AuthBoundary>
                        <DesignTaggingGuidancePage />
                    </AuthBoundary>
            )}
                path={routes.DESIGN_TAGGING_GUIDANCE}
            />
        </Route>
    </ReactRouterRoutes>
);
