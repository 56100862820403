import { QueryFunctionContext } from 'react-query';

import { ContentStoreEntitiesService } from 'src/services/Entities';

export const QUERY_KEY = 'allEntities';

type Entities = Models.ContentStoreApi.V3.Entity[];

export const queryAllEntities = (
    context: QueryFunctionContext<App.Entities.QueryKey>,
): Promise<Entities> => {
    const { queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentStoreEntitiesService.getAllEntities(
        accessToken,
        search,
    );
};
