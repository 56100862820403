import { centralizedAuth as CentralizedAuth } from '@cimpress/simple-auth-wrapper';

import { config } from 'src/lib/config';

const auth = new CentralizedAuth({
    clientID: config.clientID,
    useRefreshTokens: true,
});

export { auth };
