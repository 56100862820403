import {
    Button,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { ComponentProps } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    page: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        height: '100',
        textAlign: 'center',
    },
}));

const triggerReload = (): void => {
    window.location.reload();
};

export const UnauthorizedPage = (props: ComponentProps<typeof Grid>): JSX.Element => {
    const classes = useStyles();

    return (
        <Grid
            container
            item
            alignItems="center"
            className={classes.page}
            justifyContent="center"
            xs={12}
            {...props}
        >
            <Grid item xs={4}>
                <Typography paragraph variant="h2">401</Typography>
                <Typography paragraph variant="h6">Unauthorized</Typography>
                <Button color="primary" variant="contained" onClick={triggerReload}>Log in again</Button>
            </Grid>
        </Grid>
    );
};
