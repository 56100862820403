import {
    Button, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { AppRegistration, FileDownload } from '@material-ui/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MouseEvent } from 'react';

import { selectedIdsState, totalIdsState } from 'src/atoms/selectedIdsAtom';
import { BulkEntityDetailsDrawer } from 'src/components/Entity/EntityDetailsDrawer';
import { entityBulkDetailsDrawerOpenState } from 'src/atoms/entityBulkDetailsDrawerOpenAtom';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { SaveLoadingButton } from 'src/components/common/buttons/SaveLoadingButton';
import { useTrackEvent } from 'src/hooks/useTrackEvent';
import { UserEvent } from 'src/constants';

export interface PropTypes {
    handleAllSelectedCheck: (newArrLength: number) => void;
    handleExport: () => Promise<void>;
    handleSelectAllClick: (bool: boolean) => void;
    children?: JSX.Element;
    allSelected: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    selectedText: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    toolbar: {
        gridTemplateColumns: 'auto  1fr auto auto',
        gap: theme.spacing(4),
    },
    noChipsToolbar: {
        gridTemplateColumns: '1fr auto auto',
        gap: theme.spacing(4),
    },
    toolButtons: {
        marginLeft: 'auto',
    },
    selectAllButton: {
        whiteSpace: 'nowrap',
    },
    editAllButton: {
        marginRight: theme.spacing(4),
    },
    designConceptBox: {
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        overflowX: 'auto',
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        justifyContent: 'flex-end',
    },
    hidden: {
        display: 'none',
    },
    exportFileGrid: {
        alignItems: 'center',
        justifyContent: 'start',
    },
}));

export const BulkSelectionToolbar = (props: PropTypes): JSX.Element => {
    const {
        handleAllSelectedCheck,
        handleExport,
        handleSelectAllClick,
        allSelected,
        children,
    } = props;
    const classes = useStyles();
    const [selectedIds, setSelectedIds] = useRecoilState(selectedIdsState);
    const [
        entityBulkDetailsDrawerOpen,
        setEntityDetailsDrawerOpen,
    ] = useRecoilState(entityBulkDetailsDrawerOpenState);
    const totalIds = useRecoilValue(totalIdsState);
    const { trackEvent } = useTrackEvent();

    const handleCloseDrawer = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        setEntityDetailsDrawerOpen(false);
        setSelectedIds([]);
        handleAllSelectedCheck(0);
    };

    const handleSelectAll = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (!allSelected) {
            trackEvent({ eventName: UserEvent.SelectAllEntitiesClick });
        }

        handleSelectAllClick(true);
    };

    const handleEdit = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        setEntityDetailsDrawerOpen(true);
    };

    return (
        <CSSGrid className={selectedIds.length ? classes.toolbar : classes.noChipsToolbar}>
            {!!selectedIds.length && (
                <Typography className={classes.selectedText}>
                    {`Selected Designs: ${selectedIds.length}`}
                </Typography>
            )}
            {!!totalIds && (
                <Typography className={classes.selectedText}>
                    {`Total Designs: ${totalIds}`}
                </Typography>
            )}
            <div className={classes.designConceptBox}>
                {children}
            </div>
            <div className={classes.toolButtons}>
                <SaveLoadingButton
                    className={classes.editAllButton}
                    Icon={FileDownload}
                    tooltipText={selectedIds.length ? 'Export all selected designs as a csv' : 'Export all search results as a csv'}
                    variant="outlined"
                    onClick={handleExport}
                >
                    {selectedIds.length ? 'Export Selected' : 'Export All'}
                </SaveLoadingButton>
                {selectedIds.length > 1 && (
                    <Button
                        className={classes.editAllButton}
                        startIcon={<AppRegistration />}
                        variant="outlined"
                        onClick={handleEdit}
                    >
                        Edit All
                    </Button>
                )}
                <Button
                    className={classes.selectAllButton}
                    variant="outlined"
                    onClick={handleSelectAll}
                >
                    {allSelected ? 'Select None' : 'Select All'}
                </Button>
            </div>
            {entityBulkDetailsDrawerOpen && (
            <BulkEntityDetailsDrawer
                open
                ids={selectedIds}
                onClose={handleCloseDrawer}
            />
            )}
        </CSSGrid>
    );
};
