import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';
import { HTMLProps } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    hidden: {
        visibility: 'hidden',
    },
});

export const SearchResultsItem = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const { children, className, ...rest } = props;
    const classes = useStyles();
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '300px',
    });

    return (
        <div {...rest} className={clsx(className, { [classes.hidden]: !inView })} ref={ref}>
            {children}
        </div>
    );
};
