import {
    SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import {
    Chip, Grid, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { Warning } from '@material-ui/icons';

import { StandardSearch } from 'src/components/common/Search/StandardSearch';
import { Tagging } from 'src/components/common/Tagging';
import { TaggingHeader } from 'src/components/common/Tagging/TaggingHeader';
import { findId } from 'src/lib/findId';
import { ProductOptionsGrid } from 'src/components/Entity/EntityDetailsDrawer/ProductSpecifications/ProductOptionsGrid';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { useQueryEntityProductsSpecifications } from 'src/hooks/useQueryEntityProductSpecifications';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { DELIMITER } from 'src/components/common/Search/FacetedSearch/constants';
import { useTrackEvent } from 'src/hooks/useTrackEvent';
import { UserEvent } from 'src/constants';

export interface PropTypes {
    data: Models.ContentStoreApi.V3.EntityProduct[];
    entityId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    filters: {
        gridTemplateColumns: '1fr minmax(auto, 168px) minmax(auto, 204px)',
        gap: theme.spacing(2),
    },
    optionsChip: {
        paddingBottom: theme.spacing(8),
    },
    productSpecificationsGrid: {
        gap: theme.spacing(3),
        gridTemplateAreas: '". ."',
    },
    container: {
        overflow: 'auto',
        height: '100%',
    },
    inactiveWarning: {
        padding: theme.spacing(0, 2, 4, 2),
        gap: theme.spacing(2),
        display: 'flex',
    },
}));

const PRODUCT_KEY_HELPER_TEXT = `\
To view the product specifications, select a Product Key from the dropdown. \
By default, the options are filtered to display the "active" version. You can \
change the version and further filter down by specific product option values.\
`;

export const ProductSpecificationsPanel = (props: PropTypes): JSX.Element => {
    const {
        data,
        entityId,
    } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const [selectedProduct, setSelectedProduct] = useState<Models.ContentStoreApi.V3.EntityProduct | undefined>();
    const [productVersions, setProductVerisons] = useState<string[]>();
    const [selectedProductOptions, setSelectedProductOptions] = useState<string[]>([]);
    const [productId, setProductId] = useState<string>('');
    const [filteredProducts, setFilteredProducts] = useState<Models.ContentStoreApi.V3.ProductSpecification[]>();
    const valueRef = useRef<string[]>([]);
    const { trackEvent } = useTrackEvent();

    const toProductOptionString = (values: string []): string => `${values[0]}: ${values[1]}`;
    const productKeysWithNames = [...new Set(data?.map((ep) => `${ep.productKey}: ${ep.productName}`))];

    useEffect((): void => {
        setProductId(findId(selectedProduct ? selectedProduct.href : ''));
    }, [selectedProduct]);

    const handleProductKeyChange = (productKeyWithName: string): void => {
        const productKey = productKeyWithName.split(DELIMITER)[0];

        trackEvent({ eventName: UserEvent.ProductSpecSearchKey, eventValue: productKey });

        const matchingProducts = data.filter((val) => val.productKey === productKey);

        // Default to the active version, else just grab the first
        const matchingProduct = matchingProducts
            && (matchingProducts.find((val) => val.active) ?? matchingProducts[0]);

        const matchingProductVersions = [...new Set(data
            .filter((ep) => ep.productKey === productKey)
            .map((ep) => ep.productVersion.toString()).reverse())];

        setSelectedProduct(matchingProduct);
        setProductVerisons(matchingProductVersions);
        setSelectedProductOptions([]);
    };

    const handleProductVersionChange = (value: string | string[]): void => {
        const productVersion = parseInt(Array.isArray(value) ? value[0].toString() : value, 10);

        trackEvent({ eventName: UserEvent.ProductSpecSearchVersion, eventValue: productVersion?.toString() });
        const filteredProduct = data?.find((val) => (Number(productVersion)
            ? val.productVersion === productVersion : true)
            && val.productKey === selectedProduct?.productKey);

        setSelectedProduct(filteredProduct);
        setSelectedProductOptions([]);
    };

    const {
        data: entityProductSpecifications,
    } = useQueryEntityProductsSpecifications(accessToken, { entityId, productId }, {
        enabled: !!accessToken && !!entityId && !!productId,
        suspense: false,
        refetchInterval: false,
    });

    const productOptions = entityProductSpecifications?.results.reduce((acc, curr) => {
        const ov = Array.from(new Set(Object.entries(curr.options)));

        for (const po of ov) {
            const v = toProductOptionString(po as string[]);

            if (acc.indexOf(v) === -1) {
                acc.push(v);
            }
        }

        return acc;
    }, [] as string[]);

    const handleProductOptionsChange = (selectedValue: string | string[]): void => {
        const optionsList = [...selectedProductOptions];
        const selectedValues = Array.isArray(selectedValue) ? selectedValue : [selectedValue];

        trackEvent({ eventName: UserEvent.ProductSpecSearchOption, eventValue: selectedValues.toString() });

        setSelectedProductOptions(optionsList?.concat(selectedValues));
    };

    useEffect((): void => {
        let fps = entityProductSpecifications?.results;

        if (selectedProductOptions !== undefined && selectedProductOptions.length) {
            fps = fps?.filter((ps) => {
                const options = Object.entries(ps.options).map((val) => toProductOptionString(val as string[]));

                return selectedProductOptions.every((f) => options.indexOf(f) !== -1);
            });
        }

        setFilteredProducts(fps);
    }, [entityProductSpecifications?.results, selectedProductOptions]);

    const handleDeleteOption = (key: string) => (event: SyntheticEvent): void => {
        event.preventDefault();

        const newSearch = [...selectedProductOptions];

        setSelectedProductOptions(newSearch.filter((option) => option !== key));
    };

    return (
        <div className={classes.container}>
            <Tagging gridTemplateColumns="1fr">
                <TaggingHeader
                    gridTemplateColumns="1fr"
                    helperText={PRODUCT_KEY_HELPER_TEXT}
                >
                    <CSSGrid className={classes.filters}>
                        <StandardSearch
                            fullWidth
                            id="productKeySearch"
                            label="Product Key"
                            multiple={false}
                            options={productKeysWithNames}
                            placeholder="Product Key"
                            value={selectedProduct ? `${selectedProduct?.productKey}: ${selectedProduct.productName}` : null}
                            onChange={(_, v): void => handleProductKeyChange(v.toString())}
                        />
                        <StandardSearch
                            fullWidth
                            disabled={selectedProduct === undefined}
                            id="productVersionSearch"
                            label="Product Version"
                            multiple={false}
                            options={productVersions}
                            placeholder="Product Version"
                            value={selectedProduct ? selectedProduct?.productVersion.toString() : null}
                            onChange={(_, v): void => handleProductVersionChange(v)}
                        />
                        <StandardSearch
                            fullWidth
                            disabled={selectedProduct === undefined}
                            id="productOptionsSearch"
                            label="Product Options"
                            options={productOptions}
                            placeholder="Product Options"
                            value={valueRef.current}
                            onChange={(_, v): void => handleProductOptionsChange(v)}
                        />
                    </CSSGrid>
                </TaggingHeader>
                <Grid container className={classes.optionsChip} spacing={2}>
                    {!!selectedProductOptions?.length && selectedProductOptions.map((item) => (
                        <Grid item key={item}>
                            <Chip label={item} variant="filled" onDelete={handleDeleteOption(item)} />
                        </Grid>
                    ))}
                </Grid>
            </Tagging>
            {selectedProduct
                ? (
                    <>
                        {!selectedProduct.active && (
                            <div className={classes.inactiveWarning}>
                                <Warning color="error" fontSize="small" />
                                <Typography variant="caption">This product version is currently inactive</Typography>
                            </div>
                        )}
                        <ProductOptionsGrid
                            productName={selectedProduct.productName}
                            productSpecifications={(filteredProducts !== undefined && filteredProducts?.length > 0)
                                ? filteredProducts
                                : entityProductSpecifications?.results}
                            productVersion={selectedProduct.productVersion}
                        />

                    </>
                )
                : (
                    <div>
                        <Typography
                            textAlign="center"
                            variant="h6"
                        >
                            No results yet!
                        </Typography>
                        <Typography
                            textAlign="center"
                            variant="subtitle1"
                        >
                            Select a product key from the dropdown to view the product options.
                        </Typography>
                    </div>
                )}
        </div>
    );
};
