import { useCallback, useEffect } from 'react';
import { useInView, IntersectionOptions } from 'react-intersection-observer';

export type InfinteScrollResult = [
    (node?: Element | null | undefined) => void,
];

export interface InfiniteScrollOptions extends IntersectionOptions {
    hasMore: boolean;
    loadMore?: () => Promise<void>;
}

export const useInfiniteScroll = (options: InfiniteScrollOptions): InfinteScrollResult => {
    const {
        hasMore,
        loadMore,
        ...rest
    } = options;
    const { ref: loaderRef, inView } = useInView(rest);
    const loadMoreCallback = useCallback(() => {
        if (inView && hasMore && loadMore) {
            loadMore();
        }
    }, [inView, hasMore, loadMore]);

    useEffect(() => {
        loadMoreCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    return [loaderRef];
};
