import {
    Button, Grid, makeStyles, TextField, Theme, Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { ChangeEvent, ComponentProps, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ConfirmationDialog } from 'src/components/common/ConfirmationDialog';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { TaggingHeader } from 'src/components/common/Tagging/TaggingHeader';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { ContentStoreCategoriesService } from 'src/services/Categories';
import { DataSource } from 'src/constants';

export interface PropTypes extends Omit<ComponentProps<typeof Grid>, 'id' | 'onChange'> {
    monolithKeys: Models.ContentStoreApi.V3.SourcedCategoryMonolithKey[];
    onMonolithKeysChange: (newMonolithKeys: Models.ContentStoreApi.V3.SourcedCategoryMonolithKey[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    addMonolithKeyContainer: {
        display: 'flex',
    },
    addMonolithKeyButton: {
        marginLeft: theme.spacing(2),
    },
}));

export const CategoryMonolithKeysHeader = (props: PropTypes): JSX.Element => {
    const {
        monolithKeys,
        onMonolithKeysChange,
    } = props;

    const accessToken = useRecoilValue(isAuthorizedQuery) as string;

    const classes = useStyles();

    const [newMonolithKey, setNewMonolithKey] = useState<string>('');
    const [
        existingMonolithKey,
        setExistingMonolithKey,
    ] = useState<Models.ContentStoreApi.V3.CategoryMonolithKey | undefined>();

    const addMonolithKey = (): void => {
        const keyToAdd = parseInt(newMonolithKey, 10);
        const newmonolithKey = {
            monolithKey: keyToAdd,
            category: undefined,
            href: undefined,
            source: DataSource.Selected,
        };
        const newMonolithKeys = monolithKeys?.concat(newmonolithKey);

        onMonolithKeysChange(newMonolithKeys);
        setExistingMonolithKey(undefined);
    };

    const addNewKey = async (): Promise<void> => {
        const keyToAdd = parseInt(newMonolithKey, 10);
        const existingMonolithKeys = await ContentStoreCategoriesService
            .getCategoryMonolithKeysByMonolithKey(accessToken, keyToAdd);

        if (existingMonolithKeys.length > 0) {
            setExistingMonolithKey(existingMonolithKeys[0]);
        } else {
            addMonolithKey();
        }
    };

    const deleteExistingMonolithKey = async (): Promise<void> => {
        if (existingMonolithKey && existingMonolithKey.href) {
            await ContentStoreCategoriesService
                .deleteCategoryMonolithKey(accessToken, existingMonolithKey.href);
        }
        setExistingMonolithKey(undefined);
        addMonolithKey();
    };

    const cancelNewKey = (): void => {
        setExistingMonolithKey(undefined);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setNewMonolithKey(event.target.value);
    };

    return (
        <TaggingHeader
            gridTemplateColumns="auto"
            title={(
                <CSSGrid
                    gap={2}
                    gridTemplateAreas={'". ."'}
                    gridTemplateColumns="auto 1fr"
                >
                    <Typography variant="h6">Monolith Keys</Typography>
                </CSSGrid>
            )}
        >
            <div className={classes.addMonolithKeyContainer}>
                <TextField
                    inputProps={{
                        type: 'number',
                    }}
                    label="Monolith Key"
                    size="small"
                    onChange={handleChange}
                />
                <Button
                    fullWidth
                    className={classes.addMonolithKeyButton}
                    color="primary"
                    size="small"
                    startIcon={<Add />}
                    variant="outlined"
                    onClick={addNewKey}
                >
                    Add
                </Button>
            </div>
            <ConfirmationDialog
                description={`Adding monolith key ${existingMonolithKey?.monolithKey} to this category will delete it from category ${existingMonolithKey?.category?.href}`}
                open={!!existingMonolithKey}
                title="Delete Monolith Key from Other Category?"
                onAccept={deleteExistingMonolithKey}
                onCancel={cancelNewKey}
            />
        </TaggingHeader>
    );
};
