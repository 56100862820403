import {
    Button, Grid, makeStyles, Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useRecoilValue } from 'recoil';
import { MouseEvent, useState } from 'react';
// import { useMutation, useQueryClient } from 'react-query';

import { SnackBarErrorBoundary } from 'src/components/common/SnackBarErrorBoundary';
import { useInfiniteScrollQuery } from 'src/hooks/useInfiniteScrollQuery';
import { queryRankingStrategies, QUERY_KEY } from 'src/queries/queryRankingStrategies';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { EmptyState } from 'src/components/common/EmptyState';
import { InfiniteSearchResultsGrid } from 'src/components/common/Search/InfiniteSearchResultsGrid';
import { ForcedRankingResult } from 'src/components/ForcedRanking/ForcedRankingResult';
// import { createRankingStrategy } from 'src/mutations/rankingStrategy';
import { NewForcedRanking } from 'src/components/ForcedRanking/NewForcedRanking';

interface PropTypes {
    search: App.RankingStrategy.Search;
}

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        paddingRight: theme.spacing(4),
    },
}));

export const ForcedRankingResults = (props: PropTypes): JSX.Element => {
    const { search } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const [open, setOpen] = useState(false);

    const { data, fetchNextPage, hasNextPage } = useInfiniteScrollQuery<
    Models.ContentStoreApi.V3.RankingStrategy, Error, App.RankingStrategy.QueryKey>(
        [QUERY_KEY, accessToken, search],
        queryRankingStrategies,
        {
            enabled: !!accessToken,
            retry: 2,
            refetchInterval: 30000,
        },
    );

    const loadMore = async (): Promise<void> => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    const handleOpen = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        setOpen(true);
    };

    const handleClose = async (): Promise<void> => {
        setOpen(false);
    };

    return (
        <SnackBarErrorBoundary>
            <Grid
                container
                className={classes.actions}
                justifyContent="flex-end"
                spacing={4}
            >
                <Grid item>
                    <Button
                        color="primary"
                        startIcon={<Add />}
                        variant="contained"
                        onClick={handleOpen}
                    >
                        New Forced Ranking Strategy
                    </Button>
                </Grid>
            </Grid>
            {!data?.length && (
                <EmptyState>
                    Could not find forced rankings for the given parameters
                </EmptyState>
            )}
            {!!data?.length && (
                <InfiniteSearchResultsGrid
                    data={data}
                    hasMore={!!hasNextPage}
                    itemKey={(item: Models.ContentStoreApi.V3.RankingStrategy): string => `item-${item.rankingStrategyId}`}
                    loadMore={loadMore}
                    minWidth={200}
                    variant="cards"
                >
                    {(item: Models.ContentStoreApi.V3.RankingStrategy): JSX.Element => (
                        <ForcedRankingResult
                            rankingStrategy={item}
                            onClick={(): void => undefined}
                        />
                    )}
                </InfiniteSearchResultsGrid>
            )}
            <NewForcedRanking open={open} onClose={handleClose} />
        </SnackBarErrorBoundary>
    );
};
