import { Card } from '@material-ui/core';
import { ComponentProps } from 'react';

import { TagCard } from 'src/components/Tags/Cards/TagCard';

export interface PropTypes extends Omit<ComponentProps<typeof Card>, 'onClick'> {
    id: string;
    tagName: string;
}

export const TagResult = (props: PropTypes): JSX.Element => {
    const {
        id,
        tagName,
        ...rest
    } = props;

    return (
        <TagCard
            {...rest}
            id={id}
            title={tagName}
        />
    );
};
