import {
    Grid, makeStyles, Theme,
} from '@material-ui/core';
import { useRecoilValue } from 'recoil';

import { TagResult } from 'src/components/Tags/TagResult';
import { InfiniteSearchResultsGrid } from 'src/components/common/Search/InfiniteSearchResultsGrid';
import { queryTags, QUERY_KEY } from 'src/queries/queryTags';
import { SnackBarErrorBoundary } from 'src/components/common/SnackBarErrorBoundary';
import { useInfiniteScrollQuery } from 'src/hooks/useInfiniteScrollQuery';
import { ZeroSearchState } from 'src/components/common/ZeroSearchState';
import { InlineAddForm } from 'src/components/Tags/Forms/InlineAddForm';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { isSearchPopulated } from 'src/utils';
import { EmptyState } from 'src/components/common/EmptyState';

type Tag = Models.ContentStoreApi.V3.Tag;

interface PropTypes {
    search: App.Tags.Search;
}

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        paddingRight: theme.spacing(4),
    },
}));

export const TagResults = (props: PropTypes): JSX.Element => {
    const { search } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery);

    const { data, fetchNextPage, hasNextPage } = useInfiniteScrollQuery<Tag, Error, App.Tags.QueryKey>(
        [QUERY_KEY, accessToken, search],
        queryTags,
        {
            enabled: !!accessToken && isSearchPopulated(search),
            retry: 2,
            refetchInterval: 30000,
        },
    );

    const loadMore = async (): Promise<void> => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    return (
        <SnackBarErrorBoundary>
            <Grid
                container
                className={classes.actions}
                justifyContent="flex-end"
                spacing={4}
            >
                <Grid item xs={4}>
                    <InlineAddForm />
                </Grid>
            </Grid>
            {!isSearchPopulated(search) && (<ZeroSearchState />)}
            {(isSearchPopulated(search) && !data?.length) && (
                <EmptyState>
                    Could not find Tags for the given parameters
                </EmptyState>
            )}
            {(!!data?.length) && (
                <InfiniteSearchResultsGrid
                    data={data}
                    hasMore={!!hasNextPage}
                    itemKey={(item: Models.ContentStoreApi.V3.Tag): string => `item-${item.id}`}
                    loadMore={loadMore}
                    minWidth={400}
                    variant="cards"
                >
                    {(item: Models.ContentStoreApi.V3.Tag): JSX.Element => (
                        <TagResult
                            id={item.id}
                            tagName={item.label || 'N/A'}
                        />
                    )}
                </InfiniteSearchResultsGrid>
            )}
        </SnackBarErrorBoundary>
    );
};
