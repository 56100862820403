import { SVGProps } from 'react';

export const GhostSvg = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg
        baseProfile="tiny"
        version="1.2"
        viewBox="0 0 128 128"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <switch>
            <g>
                <g fill="#E1ECEF">
                    <ellipse cx="45.17" cy="54.03" rx="8" ry="7.9" />
                    <ellipse cx="82.83" cy="54.03" rx="8" ry="7.9" />
                </g>
                <path
                    d="M64 73.98c-8.58 0-16.16 4.29-20.65 10.81l3.1 2.13c3.81-5.54 10.24-9.18 17.55-9.18 7.31 0 13.74 3.64 17.55 9.18l3.1-2.13C80.16 78.26 72.58 73.98 64 73.98z"
                    fill="#6FD0F5"
                />
                <path
                    d="M64 6c23.24 0 42.15 17.36 42.15 38.69v73.9a37.315 37.315 0 00-10.67-1.55c-5.14 0-10.32 1.35-15.74 4.11-5.43-2.76-10.6-4.11-15.74-4.11-5.14 0-10.32 1.35-15.74 4.11-5.43-2.76-10.6-4.11-15.74-4.11-3.66 0-7.25.53-10.67 1.55v-73.9C21.85 23.36 40.76 6 64 6m0-6C37.41 0 15.85 20.01 15.85 44.69v83.21c4.73-3.05 10.47-4.85 16.67-4.85 5.8 0 11.2 2.25 15.74 4.96 4.54-2.7 9.94-4.96 15.74-4.96s11.2 2.25 15.74 4.96c4.54-2.7 9.94-4.96 15.74-4.96 6.2 0 11.94 1.8 16.67 4.85V44.69C112.15 20.01 90.59 0 64 0z"
                    fill="#E1ECEF"
                />
            </g>
        </switch>
    </svg>
);
