import {
    Button,
    ListItem,
    ListItemIcon,
    ListItemProps,
    ListItemText,
    Tooltip,
    useTheme,
} from '@material-ui/core';
import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export interface PropTypes extends ListItemProps<'li'> {
    primary: string;
    title: string;
    to: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
    alignItems: 'center',
    color: theme.palette.secondary.dark,
    paddingLeft: theme.spacing(4),
    borderRadius: 'unset',
    textTransform: 'none',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: theme.spacing(12),
}));

const StyledNavLink = styled(NavLink)({
    textDecoration: 'unset',
    width: '100%',
});

const StyledListItemText = styled(ListItemText)({
    textAlign: 'left',
});

export const NavItem = (props: PropTypes): JSX.Element => {
    const {
        children,
        primary,
        title,
        to,
        ...rest
    } = props;
    const theme = useTheme();

    return (
        <ListItem
            {...rest}
            sx={{
                paddingRight: 0,
                paddingLeft: 0,
            }}
        >
            <StyledNavLink to={to}>
                {({ isActive }): JSX.Element => (
                    <StyledButton
                        fullWidth
                        aria-label={title}
                        color="inherit"
                        style={{
                            ...(isActive
                                ? { color: theme.palette.primary.dark }
                                : undefined
                            ),
                        }}
                    >
                        <Tooltip title={title}>
                            <StyledListItemIcon
                                style={{
                                    ...(isActive
                                        ? { color: theme.palette.primary.dark }
                                        : undefined
                                    ),
                                }}
                            >
                                {children}
                            </StyledListItemIcon>
                        </Tooltip>
                        <StyledListItemText
                            primary={primary}
                            primaryTypographyProps={{
                                variant: 'body1',
                                style: {
                                    whiteSpace: 'normal',
                                },
                            }}
                        />
                    </StyledButton>
                )}
            </StyledNavLink>
        </ListItem>
    );
};
