import {
    makeStyles,
    Theme,
    Typography,
    useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { HTMLProps } from 'react';

import { CopyButton } from 'src/components/common/buttons/CopyButton';
import { NOT_APPLICABLE } from 'src/constants';

export type PropTypes = HTMLProps<HTMLDivElement> & ({
    allowCopy?: true,
    value: string,
} | {
    allowCopy: false,
    value?: string,
});

const useStyles = makeStyles((theme: Theme) => ({
    cell: {
        borderBottom: '1px rgba(0,0,0,.1) solid',
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    copy: {
        marginLeft: theme.spacing(2),
    },
    value: {
        marginTop: theme.spacing(2),
        whiteSpace: 'normal',
    },
}));

export const PropertyCell = (props: PropTypes): JSX.Element => {
    const {
        allowCopy = true,
        children,
        className,
        title,
        value,
        ...rest
    } = props;
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={clsx(classes.cell, className)} {...rest}>
            <Typography color={theme.palette.grey['600']} variant="body1">
                {title}
                {(allowCopy && value) && (<CopyButton className={classes.copy} value={value} />)}
            </Typography>
            {!children && (
                <Typography
                    gutterBottom
                    paragraph
                    className={classes.value}
                    fontWeight={theme.typography.fontWeightMedium}
                    variant="body2"
                >
                    {value || NOT_APPLICABLE}
                </Typography>
            )}
            {!!children && children}
        </div>
    );
};
