import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { findId } from 'src/lib/findId';

type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;

class ContentStoreTaxonomiesApi extends AbstractService<Models.ContentStoreApi.V3.Taxonomy> implements Services.ContentStoreApi.Taxonomies {
    private customizeTaxonomyNode(taxonomyNode: TaxonomyNode): TaxonomyNode {
        return (taxonomyNode.children)
            ? {
                ...taxonomyNode,
                children: taxonomyNode.children.map((child) => ({
                    ...this.customizeTaxonomyNode(child),
                    id: findId(child.href),
                })),
                _embedded: {
                    ...taxonomyNode._embedded,
                    category: {
                        ...taxonomyNode._embedded.category,
                        id: findId(taxonomyNode._embedded.category.href),
                    },
                },
            }
            : taxonomyNode;
    }

    protected customizeResult(result: Models.ContentStoreApi.V3.Taxonomy): Models.ContentStoreApi.V3.Taxonomy {
        const modifiedResult = {
            ...result,
            id: ('href' in result) ? findId(result.href) : '',
        };

        if ('taxonomyNode' in result) {
            modifiedResult.taxonomyNode = {
                ...this.customizeTaxonomyNode(modifiedResult.taxonomyNode),
                id: findId(result.taxonomyNode.href),
            };
        }

        return modifiedResult;
    }

    public async getTaxonomies(
        bearerToken: string | undefined | false,
        culture?: string,
        taxonomyIds?: string[],
        taxonomyNodeIds?: string[],
        names?: string[],
        taxonomyIntents?: string[],
        maxDepth?: number,
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.Taxonomy>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getTaxonomies. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/taxonomies`;
        const searchParams = qs.stringify({
            culture,
            taxonomyIds,
            taxonomyNodeIds,
            names,
            taxonomyIntents,
            maxDepth,
            offset,
            limit,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.Taxonomy>>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch taxonomies',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async getTaxonomy(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Taxonomy> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getTaxonomy. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/taxonomies/${id}`;
        const searchParams = qs.stringify({
            maxDepth: 3,
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Taxonomy>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch taxonomy',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async createTaxonomy(
        bearerToken: string | undefined | false,
        body: Models.ContentStoreApi.V3.UpsertTaxonomy,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Taxonomy> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request createTaxonomy. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/taxonomies`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .post(url, {
                    searchParams,
                    signal,
                    json: body,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Taxonomy>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to create taxonomy',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async updateTaxonomy(
        bearerToken: string | undefined | false,
        id: string,
        body: Models.ContentStoreApi.V3.UpsertTaxonomy,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.Taxonomy> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request updateTaxonomy. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/taxonomies/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .put(url, {
                    signal,
                    json: body,
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.Taxonomy>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to update taxonomy',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async deleteTaxonomy(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<void> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request deleteTaxonomy. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/taxonomies/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            await this.api
                .delete(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to delete keyword',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }
}

export const ContentStoreTaxonomiesService = new ContentStoreTaxonomiesApi(config.services.contentStoreApi);
