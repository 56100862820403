import {
    Checkbox, Grid, makeStyles, TextField, Theme, Typography,
} from '@material-ui/core';
import { ComponentProps } from 'react';

import { ClearableTextField } from 'src/components/common/inputs/ClearableTextField';

export interface PropTypes extends Omit<ComponentProps<typeof Grid>, 'id' | 'onChange'> {
    id?: string;
    keywordName: string;
    isExcludedFromAltText: boolean;
    toBeDeleted: boolean;
    onKeywordNameChange: (keywordName: string) => void;
    onIsExcludedFromAltTextChange: (isExcludedFromAltText: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    altTextRow: {
        marginLeft: `${theme.spacing(2)}`,
    },
    checkbox: {
        padding: 0,
    },
}));

export const KeywordDetailsForm = (props: PropTypes): JSX.Element => {
    const {
        id,
        keywordName,
        isExcludedFromAltText,
        toBeDeleted,
        onKeywordNameChange,
        onIsExcludedFromAltTextChange,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <Grid container direction="column" spacing={6} {...rest}>
            <Grid item>
                <TextField
                    disabled
                    fullWidth
                    label="ID"
                    size="small"
                    value={id}
                />
            </Grid>
            <Grid container item spacing={4}>
                <Grid item xs>
                    <ClearableTextField
                        fullWidth
                        label="Keyword Name"
                        size="small"
                        value={keywordName}
                        onChange={onKeywordNameChange}
                    />
                </Grid>
            </Grid>
            <Grid container item alignItems="center" className={classes.altTextRow} direction="row" spacing={2}>
                <Grid item>
                    <Typography>Exclude from Alt Text</Typography>
                </Grid>
                <Grid item>
                    <Checkbox
                        checked={isExcludedFromAltText}
                        className={classes.checkbox}
                        onChange={(ev): void => onIsExcludedFromAltTextChange(ev.target.checked)}
                    />
                </Grid>
            </Grid>
            <Grid container item alignItems="center" className={classes.altTextRow} spacing={2}>
                <Grid item>
                    <Typography>{`To be deleted: ${toBeDeleted}`}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
