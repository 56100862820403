import { ContentStoreKeywordsService } from 'src/services/Keywords';

export const mutateKeyword = async (
    {
        accessToken,
        id,
        keyword,
        signal,
    }: App.Keyword.MutationVariables,
): Promise<Models.ContentStoreApi.V3.Keyword> => ContentStoreKeywordsService.updateKeyword(
    accessToken,
    id,
    keyword,
    signal,
);
