import {
    Grid, makeStyles,
} from '@material-ui/core';
import { Suspense, useState } from 'react';

import { TitledAppBar } from 'src/components/common/TitledAppBar';
import { SearchResultsContainer } from 'src/components/common/Search/SearchResultsContainer';
import { FacetedSearch } from 'src/components/common/Search/FacetedSearch';
import { ResultsSkeleton } from 'src/components/common/skeletons/ResultsSkeleton';
import { ErrorBoundary } from 'src/components/common/ErrorBoundary';
import { SEARCH_DTG_PLACEHOLDER } from 'src/components/common/Search/constants';
import { DesignTaggingGuidanceResults } from 'src/components/DesignTaggingGuidances/DesignTaggingGuidanceResults';
import { FACETS, TAXONOMY_IDS_FACET } from 'src/components/DesignTaggingGuidances/constants';

const useStyles = makeStyles({
    container: {
        gridArea: 'content',
    },
});

export const DesignTaggingGuidancePage = (): JSX.Element => {
    const classes = useStyles();
    const [search, setSearch] = useState<App.DesignTaggingGuidances.Search>({} as App.DesignTaggingGuidances.Search);

    const handleSubmit = (facetedSearch: FacetedSearch.FacetedSearchResult): void => {
        const newSearch = {
            ducIds: facetedSearch.ducIds as string[],
            ducName: facetedSearch.ducName as string,
            taxonomyIds: facetedSearch.taxonomyIds as string[],
            taxonomyName: facetedSearch.taxonomyName as string,
        };

        setSearch(newSearch);
    };

    return (
        <>
            <TitledAppBar title="Design Tagging Guidance">
                <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    wrap="nowrap"
                >
                    <Grid item xs={10}>
                        <FacetedSearch
                            defaultFacet={TAXONOMY_IDS_FACET}
                            facets={FACETS}
                            label="Search Design Tagging Guidance"
                            placeholder={SEARCH_DTG_PLACEHOLDER}
                            onSubmit={handleSubmit}
                        />
                    </Grid>
                </Grid>
            </TitledAppBar>
            <SearchResultsContainer className={classes.container}>
                <ErrorBoundary>
                    <Suspense fallback={<ResultsSkeleton />}>
                        <DesignTaggingGuidanceResults search={search} />
                    </Suspense>
                </ErrorBoundary>
            </SearchResultsContainer>
        </>
    );
};
