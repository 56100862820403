import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { createDesignTaggingGuidance } from 'src/mutations/createDesignTaggingGuidance';
import { QUERY_KEY } from 'src/queries/queryDesignTaggingGuidances';

type DesignTaggingGuidance = Models.ContentStoreApi.V3.DesignTaggingGuidance;
type CreateMutationVariables = App.DesignTaggingGuidance.CreateMutationVariables;

export const useCreateDesignTaggingGuidance = (): UseMutationResult<DesignTaggingGuidance, Error, CreateMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<DesignTaggingGuidance, Error, CreateMutationVariables>(
        createDesignTaggingGuidance,
        {
            onSuccess: () => {
                setTimeout(() => { queryClient.refetchQueries([QUERY_KEY]); }, 1000);
            },
            useErrorBoundary: true,
        },
    );
};
