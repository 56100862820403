export const DEFAULT_LIMIT = 25;

export const DEFAULT_TAXONOMY_LIMIT = 200;

export const DEFAULT_STATUS = 500;

export const ENTITY_IDS_BATCH_SIZE = 100;

export const DEFAULT_CULTURE = 'en-us';

export const IMG_DIMENSION_MULTIPLIER = 58;

export const SNACKBAR_TIMEOUT = 10000;

export const NOT_APPLICABLE = 'N/A';

export const RENDERING_URL = 'https://rendering.mcp.cimpress.com/v2/vp/preview?';

export const CONTENT_DELIVERY_URL = 'https://cdn.prod.delivery.content.cimpress.io/api/v1/accounts/ozoDdrmewShEcbUDWX8J3V/contentAreas/IoBX4KKMc0uyUyA0tFQDg/assets:deliver';

export const GALLERY_CONFLUENCE_URL = 'https://vistaprint.atlassian.net/wiki/spaces/';

export const TAGGING_TOOL_DOCUMENTATION = `${GALLERY_CONFLUENCE_URL}GAL/pages/2323842245/Gallery+Tagging+Tool+Documentation`;

export const UDS_URL = 'http://uds.documents.cimpress.io/v2/transient/vp?';

export const NEW_TAG_LABEL = 'New';

export const MAX_RETRY_TIMEOUT = 30000;

export const MIN_RETRY_TIMEOUT = 1000;

export const EXPORT_DESIGNS_LIMIT = 50000;

export enum DataSource {
    Loading = 'loading',
    Deleted = 'deleted',
    New = 'new',
    Saved = 'saved',
    Suggested = 'suggested',
    Selected = 'selected',
    Unselected = 'unselected'
}

export enum TaxonomyIntent {
    Other='Other',
    UseCase='UseCase',
    Descriptive='Descriptive'
}

export enum UserEvent {
    LocalizedPreviewButtonClick = 'LocalizedPreviewButtonClick',
    CopyClick = 'CopyClick',
    MyNewDesignsClick = 'MyNewDesignsClick',
    ApproveToggle = 'ApproveToggle',
    ProductSpecSearchKey = 'ProductSpecSearchKey',
    ProductSpecSearchVersion = 'ProductSpecSearchVersion',
    ProductSpecSearchOption = 'ProductSpecSearchOption',
    SelectAllEntitiesClick = 'SelectAllEntitiesClick',
    SingleEditSaved = 'SingleEditSaved',
    BulkEditSaved = 'BulkEditSaved',
    DesignConceptFilterClick = 'DesignConceptFilterClick',
    SingleEntityTabSelection = 'SingleEntityTabSelection',
    BulkEntityTabSelection = 'BulkEntityTabSelection',
    CategoriesTabSelection = 'CategoriesTabSelection',
    TaxonomiesTabSelection = 'TaxonomiesTabSelection'
}
