import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
    Box, SvgIconProps, Typography, makeStyles, Theme, IconButton,
} from '@material-ui/core';
import {
    TreeItem, treeItemClasses, TreeItemProps,
} from '@material-ui/lab';
import clsx from 'clsx';
import { Delete } from '@material-ui/icons';
import { ElementType, RefObject } from 'react';

import { TreeItemContent } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/TreeItemContent';
import { DraggableCommand } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableCommand';
import { CSSGrid } from 'src/components/common/CSSGrid';

type StyledTreeItemProps = TreeItemProps & {
    descriptionText?: string;
    labelAction?: JSX.Element;
    labelIcon?: ElementType<SvgIconProps>;
    labelInfo?: JSX.Element;
    labelText: string;
    droppable?: DraggableCommand;
    hasChildren?: boolean;
    treeItemRef?: RefObject<HTMLDivElement>;
    taxonomyNodeId?: string;
    onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    '&.leaf': {
        [`& .${treeItemClasses.content}`]: {
            '& .Mui-ContentWrapper': {
                [`& .${treeItemClasses.label}`]: {
                    borderLeft: `1px solid ${theme.palette.text.secondary}`,
                    borderTopLeftRadius: theme.spacing(2),
                    borderBottomLeftRadius: theme.spacing(2),
                },
                [`& .${treeItemClasses.iconContainer}`]: {
                    width: 0,
                    visibility: 'hidden',
                },
            },
        },
    },
    [`& .${treeItemClasses.content}`]: {
        paddingRight: theme.spacing(1),
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'unset',
        },
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: 'unset',
        },
        '& .Mui-ContentWrapper': {
            alignItems: 'center',
            borderRadius: theme.spacing(2),
            color: theme.palette.text.secondary,
            display: 'grid',
            fontWeight: theme.typography.fontWeightMedium,
            gridTemplateAreas: '". ."',
            gridTemplateColumns: 'auto 1fr',
            justifyContent: 'left',
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            minHeight: theme.spacing(10),
            width: '100%',
            '&:hover': {
                backgroundColor: theme.palette.common.white,
                [`& .${treeItemClasses.iconContainer}`]: {
                    color: theme.palette.common.black,
                },
            },
            [`& .${treeItemClasses.label}`]: {
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.text.secondary}`,
                borderLeft: 'none',
                borderTopRightRadius: theme.spacing(2),
                borderBottomRightRadius: theme.spacing(2),
                color: 'inherit',
                display: 'flex',
                fontWeight: 'inherit',
                height: '100%',
            },
            [`& .${treeItemClasses.iconContainer}`]: {
                alignItems: 'center',
                borderBottom: `1px solid ${theme.palette.text.secondary}`,
                borderLeft: `1px solid ${theme.palette.text.secondary}`,
                borderTop: `1px solid ${theme.palette.text.secondary}`,
                borderTopLeftRadius: theme.spacing(2),
                borderBottomLeftRadius: theme.spacing(2),
                height: '100%',
                marginRight: 0,
                width: theme.spacing(8),
            },
            backgroundColor: theme.palette.common.white,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: theme.spacing(4),
    },
    '&.replace': {
        [`& > .${treeItemClasses.content}`]: {
            '& > .Mui-ContentWrapper': {
                [`& > .${treeItemClasses.iconContainer}`]: {
                    borderTop: `1px dashed ${theme.palette.primary.main}`,
                    borderLeft: `1px dashed ${theme.palette.primary.main}`,
                    borderBottom: `1px dashed ${theme.palette.primary.main}`,
                },
                [`& > .${treeItemClasses.label}`]: {
                    borderTop: `1px dashed ${theme.palette.primary.main}`,
                    borderRight: `1px dashed ${theme.palette.primary.main}`,
                    borderBottom: `1px dashed ${theme.palette.primary.main}`,
                },
            },
        },
    },
}));

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        position: 'relative',
        zIndex: 1,
    },
    stripeBackground: {
        opacity: '40%',
        backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.main} 25%,
            transparent 25%, transparent 50%, ${theme.palette.primary.main} 50%,
            ${theme.palette.primary.main} 75%, transparent 75%, ${theme.palette.common.white})`,
        margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(0)}`,
        backgroundSize: '10px 10px',
        borderRadius: theme.spacing(2),
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        borderWidth: '1px',
        minHeight: theme.spacing(10),
    },
    selector: {
        margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} 0`,
        zIndex: -1,
    },
    cloneIcon: {
        color: theme.palette.error.main,
        right: theme.spacing(1),
        '& svg': {
            height: theme.typography.body1.fontSize,
            width: theme.typography.body1.fontSize,
        },
    },
    labelGrid: {
        gridAutoColumns: '1fr',
        width: '100%',
    },
}));

export const StyledTreeItem = (props: StyledTreeItemProps): JSX.Element => {
    const {
        descriptionText,
        labelAction: LabelAction,
        labelIcon: LabelIcon,
        labelInfo: LabelInfo,
        labelText,
        droppable,
        treeItemRef,
        hasChildren,
        onDelete,
        nodeId,
        taxonomyNodeId,
        ...rest
    } = props;
    const classes = useStyles(props);

    return (
        <>
            {droppable && droppable === DraggableCommand.Above && (
                <div className={clsx(classes.stripeBackground, classes.selector)} />
            )}
            <div className={classes.container}>
                <StyledTreeItemRoot
                    className={clsx({ replace: droppable && droppable === DraggableCommand.Replace })}
                    ContentComponent={TreeItemContent}
                    label={(
                        <Box
                            ref={treeItemRef}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: 0.5,
                                pr: 0,
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            {LabelAction && (<Box component="span" sx={{ mr: 1 }}>{LabelAction}</Box>)}
                            {LabelIcon && (<Box color="inherit" component={LabelIcon} sx={{ mr: 1 }} />)}
                            <CSSGrid className={classes.labelGrid}>
                                <Typography sx={{ fontWeight: 'inherit', flexGrow: 1 }} variant="body2">
                                    {labelText}
                                </Typography>
                                {descriptionText && (
                                <Typography sx={{ fontWeight: 'inherit', flexGrow: 1 }} variant="caption">
                                    {descriptionText}
                                </Typography>
                                )}
                            </CSSGrid>
                            {LabelInfo}
                            {onDelete && (
                                <IconButton
                                    className={classes.cloneIcon}
                                    size="small"
                                    onClick={(event): void => { onDelete(event, taxonomyNodeId ?? nodeId); }}
                                    // Stops the ripple effect in the rest of the card
                                    onMouseDown={(event): void => event.stopPropagation()}
                                    onTouchStart={(event): void => event.stopPropagation()}
                                >
                                    <Delete />
                                </IconButton>
                            )}

                        </Box>
                    )}
                    nodeId={nodeId}
                    {...rest}
                />
            </div>
            {droppable && droppable === DraggableCommand.Below && (
                <div className={clsx(classes.stripeBackground, classes.selector)} />
            )}
        </>
    );
};
