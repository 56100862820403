import { Tabs, Box } from '@material-ui/core';
import { TabPanel, TabContext, TreeView } from '@material-ui/lab';
import {
    AccountTree, Category, FilterAlt, FilterList,
} from '@material-ui/icons';
import { Tab } from '@mui/material';
import React, {
    useState, SyntheticEvent, ComponentProps, HTMLProps,
} from 'react';
import { TaxonomyPanel } from 'src/components/common/TaxonomyDiscovery/SearchPanels/TaxonomyPanel';
import { CategoriesPanel } from 'src/components/common/TaxonomyDiscovery/SearchPanels/CategoriesPanel';
import { queryCategories, QUERY_KEY as QUERY_CATEGORIES_KEY } from 'src/queries/queryCategories';
import { useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { DataSource, UserEvent } from 'src/constants';
import { DraggableType } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableType';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { DesignGuidancePanel } from 'src/components/common/TaxonomyDiscovery/SearchPanels/DesignGuidancePanel';
import { DesignGuidancePanelWithPRD } from 'src/components/common/TaxonomyDiscovery/SearchPanels/DesignGuidancePanelWithPRD';
import { useTrackEvent } from 'src/hooks/useTrackEvent';

type PagedQueryKey = App.Categories.PagedQueryKey;
type SourcedCategory = Models.ContentStoreApi.V3.SourcedCategory;
type ValueMap<T extends SourcedCategory> = Record<string, T>;

export interface PropTypes<T extends SourcedCategory> extends Omit<HTMLProps<HTMLDivElement>, 'value' | 'onChange' | 'draggable'> {
    entityId?: string[];
    selectable?: boolean,
    draggable?: string,
    droppable?: string[],
    ducIds?: string[],
    gridProps?: Partial<ComponentProps<typeof CSSGrid>>;
    onChange: (event: SyntheticEvent<Element, Event>, value: SourcedCategory[]) => void;
    treeProps?: Partial<ComponentProps<typeof TreeView>>;
    value?: T[];
}

export const SearchPanels = <T extends SourcedCategory>(props: PropTypes<T>): JSX.Element => {
    const {
        selectable = false,
        className,
        ducIds,
        onChange,
        value,
        draggable = DraggableType.None,
        droppable = [DraggableType.None],
        entityId,
        ...rest
    } = props;

    const useDUCGuidance = ducIds && ducIds.length === 1;
    const useProductGuidance = !!entityId;

    const [tabValue, setTabValue] = useState(useProductGuidance ? 'guidancePRD' : 'taxonomy');
    const queryClient = useQueryClient();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const { trackEvent } = useTrackEvent();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string): void => {
        trackEvent({ eventName: UserEvent.TaxonomiesTabSelection, eventValue: newValue });
        setTabValue(newValue);
    };

    const valueMap = value && value
        .filter((c) => c.source !== DataSource.Deleted && c.source !== DataSource.Suggested)
        .reduce((accum, c) => ({
            ...accum,
            [c.href]: c,
        }), {} as ValueMap<T>);

    const handleAsyncPaste = async (ids: string[]): Promise<T[]> => {
        const data = await queryClient.fetchQuery<
        Models.V3.PageResult<T>,
        Error,
        Models.V3.PageResult<T>,
        PagedQueryKey
        >(
            [QUERY_CATEGORIES_KEY, accessToken, {
                ids,
                internalName: undefined,
            }],
            // @ts-expect-error
            queryCategories,
        );

        return data.results;
    };

    return (
        <Box sx={{
            height: '100%',
            flexDirection: 'column',
            display: 'grid',
            gridTemplateRows: 'minmax(48px, auto) 1fr',
        }}
        >
            <TabContext value={tabValue}>
                <Tabs indicatorColor="secondary" textColor="secondary" value={tabValue} variant="fullWidth" onChange={handleTabChange}>
                    {useProductGuidance && (<Tab icon={<FilterList />} iconPosition="start" label="guidance (PRD)" sx={{ minHeight: '48px' }} value="guidancePRD" />) }
                    {useDUCGuidance && (<Tab icon={<FilterAlt />} iconPosition="start" label="guidance (DUC)" sx={{ minHeight: '48px' }} value="guidance" />) }
                    <Tab icon={<AccountTree />} iconPosition="start" label="taxonomies" sx={{ minHeight: '48px' }} value="taxonomy" />
                    <Tab icon={<Category />} iconPosition="start" label="categories" sx={{ minHeight: '48px' }} value="category" />
                </Tabs>
                {useProductGuidance && (
                    <TabPanel
                        sx={{
                            overflow: 'hidden',
                            paddingBottom: 0,
                        }}
                        value="guidancePRD"
                    >
                        <DesignGuidancePanelWithPRD
                            className={className}
                            draggable={draggable}
                            droppable={droppable}
                            entityId={entityId}
                            selectable={selectable}
                            valueMap={valueMap}
                            onChange={onChange}
                            {...rest}
                        />
                    </TabPanel>
                )}
                {useDUCGuidance && (
                    <TabPanel
                        sx={{
                            overflow: 'hidden',
                            paddingBottom: 0,
                        }}
                        value="guidance"
                    >
                        <DesignGuidancePanel
                            className={className}
                            draggable={draggable}
                            droppable={droppable}
                            ducIds={ducIds}
                            selectable={selectable}
                            valueMap={valueMap}
                            onChange={onChange}
                            {...rest}
                        />
                    </TabPanel>
                )}
                <TabPanel
                    sx={{
                        overflow: 'hidden',
                        paddingBottom: 0,
                    }}
                    value="taxonomy"
                >
                    <TaxonomyPanel
                        className={className}
                        draggable={draggable}
                        droppable={droppable}
                        handleAsyncPaste={handleAsyncPaste}
                        selectable={selectable}
                        valueMap={valueMap}
                        onChange={onChange}
                        {...rest}
                    />
                </TabPanel>
                <TabPanel
                    sx={{
                        overflow: 'hidden',
                        paddingBottom: 0,
                    }}
                    value="category"
                >
                    <CategoriesPanel
                        className={className}
                        draggable={draggable}
                        droppable={droppable}
                        handleAsyncPaste={handleAsyncPaste}
                        selectable={selectable}
                        valueMap={valueMap}
                        onChange={onChange}
                        {...rest}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
};
