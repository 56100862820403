export function findIntersection<T extends Models.V3.HrefOnly>(arrs: T[][]): T[] {
    return arrs.reduce(
        (accum, arr) => accum.filter((item) => !!arr.find((item2) => item.href === item2.href)),
    );
}

export function findCulturesIntersection<T extends Models.ContentStoreApi.V3.Entity.BulkCulture>(arrs: T[][]): T[] {
    const bulkCultures: Record<string, T> = {};

    arrs.forEach((cultureList) => {
        cultureList.forEach((culture) => {
            const existingCulture = bulkCultures[culture.href];

            if (existingCulture) {
                if (culture.userEnabled) {
                    bulkCultures[culture.href] = {
                        ...existingCulture,
                        enabledCount: (existingCulture.enabledCount || 0) + 1,
                    };
                } else if (existingCulture.userEnabled && !culture.userEnabled) {
                    bulkCultures[culture.href] = { ...existingCulture, userEnabled: false };
                }
            } else {
                bulkCultures[culture.href] = {
                    ...culture,
                    enabledCount: culture.userEnabled ? 1 : 0,
                    changedEnabledValue: false,
                };
            }
        });
    });

    return Object.values(bulkCultures);
}
