import clsx from 'clsx';
import {
    makeStyles,
    SvgIcon,
    Theme,
    Tooltip,
} from '@material-ui/core';
import { Check, Delete } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import {
    ComponentProps, MouseEvent, useRef, useState,
} from 'react';

import { useSuccessEffect } from 'src/hooks/useSuccessEffect';

export interface PropTypes extends Omit<ComponentProps<typeof LoadingButton>, 'pending' | 'onClick'> {
    onClick: (event: MouseEvent<HTMLButtonElement>, controller?: AbortController) => Promise<void> | void;
    iconProps?: ComponentProps<typeof SvgIcon>;
}

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: 'rgba(209, 44, 11, 0.04)',
            borderColor: theme.palette.error.main,
        },
    },
    iconButton: {
        minWidth: 'auto',
        borderRadius: '50%',
        padding: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '& .MuiButton-startIcon': {
            margin: 0,
        },
    },
    success: {
        color: theme.palette.success.main,
    },
}));

export const DeleteLoadingButton = (props: PropTypes): JSX.Element => {
    const {
        children,
        className,
        iconProps = {},
        onClick,
        ...rest
    } = props;
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [successEffect, setSuccessEffect] = useSuccessEffect(false);
    const abortControllerRef = useRef<AbortController | undefined>(new AbortController());

    const handleClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        try {
            setIsLoading(true);
            await onClick(event, abortControllerRef.current);
        } finally {
            setIsLoading(false);
            if (!abortControllerRef.current?.signal.aborted) {
                setSuccessEffect(true);
            }
        }
    };

    return (
        <Tooltip title="delete">
            <span>
                <LoadingButton
                    className={clsx(className, classes.button, { [classes.iconButton]: !children })}
                    loading={isLoading}
                    {...rest}
                    aria-label="delete"
                    startIcon={successEffect
                        ? <Check className={classes.success} {...iconProps} />
                        : <Delete color="error" {...iconProps} />}
                    onClick={handleClick}
                >
                    {children}
                </LoadingButton>
            </span>
        </Tooltip>
    );
};
