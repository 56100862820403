import {
    Button,
    Card,
    Drawer,
    IconButton,
    makeStyles,
    PaperProps as MuiPaperProps,
    Theme,
    Tooltip,
} from '@material-ui/core';
import { Cancel, Close } from '@material-ui/icons';
import clsx from 'clsx';
import {
    ComponentProps, MouseEvent, useState,
} from 'react';

import { DeleteLoadingButton } from 'src/components/common/buttons/DeleteLoadingButton';
import { SaveLoadingButton } from 'src/components/common/buttons/SaveLoadingButton';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { ConfirmationDialog } from 'src/components/common/ConfirmationDialog';
import { useRecoilState } from 'recoil';
import { drawerUpdatedState } from 'src/atoms/drawerUpdatedAtom';

export interface PropTypes extends ComponentProps<typeof Drawer> {
    onSave?: () => Promise<void>;
    onDelete?: (event: MouseEvent<HTMLButtonElement>, controller?: AbortController) => Promise<void>;
    middleButton?: JSX.Element;
    options?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        backgroundColor: theme.palette.common.white,
        bottom: 0,
        gap: theme.spacing(4),
        justifyContent: 'flex-end',
        gridAutoFlow: 'column dense',
        position: 'sticky',
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(5),
    },
    actionsContainer: {
        marginLeft: theme.spacing(-6),
        marginRight: theme.spacing(-6),
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(6),
        right: theme.spacing(5),
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        padding: `${theme.spacing(6)} ${theme.spacing(6)} 0 ${theme.spacing(6)}`,
        maxWidth: '100%',
        minWidth: '100%',
        overflowY: 'hidden',
        width: '100%',
        [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
            maxWidth: theme.spacing(260),
            minWidth: theme.spacing(200),
            width: 'auto',
        },
        backgroundColor: '#f8f8f8',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: '1fr auto',
    },
    scrollContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
        overflowY: 'scroll',
    },
    enabledToggle: {
        position: 'absolute',
    },
}));

export const DetailsDrawer = (props: PropTypes): JSX.Element => {
    const {
        children,
        middleButton,
        options,
        onClose,
        onDelete,
        onSave,
        PaperProps = {} as Partial<MuiPaperProps>,
        ...rest
    } = props;
    const classes = useStyles();
    const { className: paperPropsClassName, ...paperPropsRest } = PaperProps;
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [updatedState, setUpdatedState] = useRecoilState(drawerUpdatedState);
    const modalTitle = 'You have unsaved changes.';
    const modalDescription = 'Would you like to save before closing?';

    const handleClose = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (updatedState) {
            setModalOpen(true);
        } else if (onClose) {
            onClose(event, 'escapeKeyDown');
        }
    };

    const onModalClose = (event?: MouseEvent<HTMLButtonElement>): void => {
        setModalOpen(false);
        setUpdatedState(false);
        if (onClose && event) {
            onClose(event, 'escapeKeyDown');
        }
    };

    const handleSave = (): Promise<void> => {
        if (onSave) {
            return onSave();
        }
        return Promise.resolve();
    };

    const handleAccept = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (onSave) {
            handleSave();
        }

        if (onClose) {
            onClose(event, 'escapeKeyDown');
        }
        setModalOpen(false);
    };

    return (
        <Drawer
            anchor="right"
            PaperProps={{
                className: clsx(classes.drawer, paperPropsClassName),
                ...paperPropsRest,
            }}
            onClose={handleClose}
            {...rest}
        >
            <div className={classes.scrollContainer}>
                <Tooltip className={classes.closeButton} title="close panel">
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Tooltip>
                {children}
            </div>
            <Card className={classes.actionsContainer} elevation={2}>
                <CSSGrid
                    className={classes.actions}
                    justifyContent="flex-end"
                >
                    {options}
                    <Tooltip title="cancel">
                        <Button
                            color="secondary"
                            startIcon={<Cancel />}
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Tooltip>
                    {!!onDelete && (
                        <DeleteLoadingButton variant="outlined" onClick={onDelete}>
                            Delete
                        </DeleteLoadingButton>
                    )}
                    {!!middleButton && middleButton}
                    {onSave && (
                        <SaveLoadingButton variant="outlined" onClick={handleSave}>
                            Save
                        </SaveLoadingButton>
                    )}
                    <ConfirmationDialog
                        description={modalDescription}
                        open={modalOpen && updatedState}
                        title={modalTitle}
                        onAccept={handleAccept}
                        onCancel={onModalClose}
                    />
                </CSSGrid>
            </Card>
        </Drawer>
    );
};
