import {
    useEffect, useState,
} from 'react';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { generateSizes, generateSrcSet } from 'src/lib/image';

type HookReturnValue = [
    ReadonlyArray<ReactImageGalleryItem>,
];

type EntityWithPreviewUrls = Models.ContentStoreApi.V3.Entity & {
    previewUrls: Models.ContentStoreApi.V3.PreviewUrls;
};

export const useBulkPreviews = (entities?: Models.ContentStoreApi.V3.Entity[] | false): HookReturnValue => {
    const [bulkPreviews, setBulkPreviews] = useState<ReadonlyArray<ReactImageGalleryItem>>([]);

    useEffect(() => {
        if (entities) {
            const list: ReadonlyArray<ReactImageGalleryItem> = entities
                .filter((entity): entity is EntityWithPreviewUrls => !!entity.previewUrls)
                .map((entity) => {
                    const srcSet = generateSrcSet(entity.previewUrls);
                    const sizes = generateSizes(entity.previewUrls);

                    return {
                        id: entity.id,
                        original: entity.previewUrls['1080w'],
                        srcSet,
                        sizes,
                        zoomSrc: entity.previewUrls['1920w'],
                    };
                });

            setBulkPreviews(list);
        }
    }, [entities]);

    return [bulkPreviews];
};
