import {
    CircularProgress, makeStyles, Theme, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { ComponentProps } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    progress: {
        marginRight: theme.spacing(3),
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const Loader = (props: ComponentProps<typeof Typography>): JSX.Element => {
    const { className, ...rest } = props;
    const classes = useStyles();

    return (
        <Typography className={clsx(classes.wrapper, className)} {...rest}>
            <CircularProgress className={classes.progress} />
            Loading...
        </Typography>
    );
};
