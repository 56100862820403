import { Card } from '@material-ui/core';
import { ComponentProps } from 'react';

import { ResultCard } from 'src/components/common/ResultCard';
import { useDeleteKeyword } from 'src/components/Keywords/hooks/useDeleteKeyword';

export interface PropTypes extends Omit<ComponentProps<typeof Card>, 'onClick'> {
    id: string;
    keywordName: string;
    onClick: (id: string) => void;
}

export const KeywordResult = (props: PropTypes): JSX.Element => {
    const {
        id,
        keywordName,
        ...rest
    } = props;

    const deleteMutation = useDeleteKeyword();

    return (
        <ResultCard
            {...rest}
            deleteMutation={deleteMutation}
            id={id}
            itemName="Keyword"
            title={keywordName}
        />
    );
};
