// Designs
export const DESIGNS = 'designs';

// Logout
export const LOGOUT = '/logout';

// Keywords
export const KEYWORDS = 'keywords';

// Tags
export const TAGS = 'tags';

// Categories
export const CATEGORIES = 'categories';

// Taxonomies
export const TAXONOMIES = 'taxonomies';

export const FORCEDRANKING = 'forcedRanking';

export const FORCEDRANKINGEDIT = `${FORCEDRANKING}/:id`;

// Design Tagging Guidance
export const DESIGN_TAGGING_GUIDANCE = 'designTaggingGuidance';

export const ROOT = '/';
