import { UseQueryResult } from 'react-query';
import { useQueryCategory } from 'src/components/Categories/hooks/useQueryCategory';
import { useQueryCategoryMonolithKeys } from 'src/components/Categories/hooks/useQueryCategoryMonolithKeys';

export const useQueryFullCategory = (
    accessToken: string | undefined | false,
    id: string,
): [
    UseQueryResult<Models.ContentStoreApi.V3.CategoryMonolithKey[], Error>,
    UseQueryResult<Models.ContentStoreApi.V3.Category, Error>,
] => {
    const res1 = useQueryCategoryMonolithKeys(accessToken, id, {
        enabled: !!id,
        suspense: false,
        refetchInterval: false,
    });
    const res2 = useQueryCategory(accessToken, id, {
        enabled: !!id,
        suspense: false,
        refetchInterval: false,
    });

    return [res1, res2];
};
