import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { DataSource } from 'src/constants';

class ContentStoreRankingStrategiesApi extends AbstractService implements Services.ContentStoreApi.Rankings {
    public async createRankingStrategy(
        bearerToken: string | undefined | false,
        body: Models.ContentStoreApi.V3.CreateRankingStrategy,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.RankingStrategy> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request createRanking. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/rankingStrategies`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .post(url, {
                    json: body,
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.RankingStrategy>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to create forced ranking strategy',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async deleteRankingStrategy(
        bearerToken: string | undefined | false,
        id: number,
        signal?: AbortSignal,
    ): Promise<void> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request deleteRankingStrategy. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/rankingStrategies/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            await this.api
                .delete(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to delete ranking strategy',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async getRankingStrategy(
        bearerToken: string | undefined | false,
        id: number,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.RankingStrategy> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getRankingStrategy. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/rankingStrategies/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.RankingStrategy>();

            return {
                ...json,
            };
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch ranking strategy',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async getRankingStrategies(
        bearerToken: string | undefined | false,
        strategyName?: string,
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.RankingStrategy>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getRankingStrategies. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/rankingStrategies`;
        const searchParams = qs.stringify({
            name: strategyName,
            type: 'Forced',
            offset,
            limit,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.RankingStrategy>>();

            return {
                ...json,
                results: json.results.map((item) => ({
                    ...item,
                    startDate: item.startDate ? new Date(item.startDate) : null,
                    endDate: item.endDate ? new Date(item.endDate) : null,
                    source: DataSource.Saved,
                })),
            };
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to get ranking strategies',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async updateRankingStrategy(
        bearerToken: string | undefined | false,
        id: number,
        body: Models.ContentStoreApi.V3.RankingStrategy,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.RankingStrategy> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request updateRankingStrategy. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/rankingStrategies/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        const updatedRankingStrategy: Models.ContentStoreApi.V3.CreateRankingStrategy = (({
            rankingStrategyId,
            isActive,
            ...o
        }): Models.ContentStoreApi.V3.CreateRankingStrategy => o)(body);

        try {
            const json = await this.api
                .put(url, {
                    signal,
                    json: updatedRankingStrategy,
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.RankingStrategy>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to update ranking strategy',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }
}

export const ContentStoreRankingStrategiesService = new ContentStoreRankingStrategiesApi(config.services.contentStoreApi);
