import clsx from 'clsx';
import {
    Divider,
    Drawer,
    Grid,
    List,
    ListItem,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    Image, Search, LocalOffer, Category, AccountTree, Description, FormatListNumbered,
} from '@material-ui/icons';

import { ExpandButton } from 'src/components/NavDrawer/ExpandButton';
import { Title } from 'src/components/NavDrawer/Title';
import { navBarExpandedState } from 'src/components/NavDrawer/atoms';
import { NavItem } from 'src/components/NavDrawer/NavItem';
import { NavLink } from 'src/components/NavDrawer/NavLink';
import * as routes from 'src/lib/routes';
import { TAGGING_TOOL_DOCUMENTATION } from 'src/constants';
import { DuckSvg } from 'src/components/common/icons/DuckSvg';

const DRAWER_WIDTH = 220;

const useStyles = makeStyles((theme: Theme) => ({
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(14)} + 1px)`,
    },
    drawerOpen: {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerGrid: {
        height: '100%',
        margin: 0,
        width: '100%',
    },
    expandButtonClose: {
        transform: `translateX(calc(${theme.spacing(14)} - ${theme.spacing(3)}))`,
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    expandButtonOpen: {
        transform: `translateX(calc(${DRAWER_WIDTH}px - ${theme.spacing(3)}))`,
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    duck: {
        height: theme.spacing(6),
        width: theme.spacing(6),
    },
}));

export const NavDrawer = (): JSX.Element => {
    const classes = useStyles();
    const expanded = useRecoilValue(navBarExpandedState);
    const setExpanded = useSetRecoilState(navBarExpandedState);

    const toggleDrawer = (): void => {
        const currentlyExpanded = !expanded;

        setExpanded(currentlyExpanded);
    };

    return (
        <Drawer
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: expanded,
                    [classes.drawerClose]: !expanded,
                }),
            }}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: expanded,
                [classes.drawerClose]: !expanded,
            })}
            variant="permanent"
        >
            <Grid
                container
                className={classes.drawerGrid}
                direction="column"
            >
                <ExpandButton
                    className={clsx({
                        [classes.expandButtonOpen]: expanded,
                        [classes.expandButtonClose]: !expanded,
                    })}
                    expanded={expanded}
                    onClick={toggleDrawer}
                />
                <Grid item>
                    <Title />
                    <Divider />
                </Grid>
                <Grid item xs>
                    <List>
                        <NavItem
                            key="designs"
                            primary="Designs"
                            title="navigate to designs"
                            to={routes.DESIGNS}
                        >
                            <Image />
                        </NavItem>
                        <NavItem
                            key="keywords"
                            primary="Keywords"
                            title="navigate to keywords"
                            to={routes.KEYWORDS}
                        >
                            <Search />
                        </NavItem>
                        <NavItem
                            key="tags"
                            primary="Tags"
                            title="navigate to tags"
                            to={routes.TAGS}
                        >
                            <LocalOffer />
                        </NavItem>
                        <NavItem
                            key="categories"
                            primary="Categories"
                            title="navigate to categories"
                            to={routes.CATEGORIES}
                        >
                            <Category />
                        </NavItem>
                        <NavItem
                            key="taxonomies"
                            primary="Taxonomies"
                            title="navigate to taxonomies"
                            to={routes.TAXONOMIES}
                        >
                            <AccountTree />
                        </NavItem>
                        <NavItem
                            key="forcedRanking"
                            primary="Forced Ranking"
                            title="navigate to forced ranking"
                            to={routes.FORCEDRANKING}
                        >
                            <FormatListNumbered />
                        </NavItem>
                        <NavItem
                            key="designTaggingGuidance"
                            primary="Design Tagging Guidance"
                            title="navigate to design tagging guidance"
                            to={routes.DESIGN_TAGGING_GUIDANCE}
                        >
                            <DuckSvg className={classes.duck} fill="currentColor" />
                        </NavItem>
                    </List>
                </Grid>
                <Grid item>
                    <List>
                        <ListItem button>
                            <NavLink
                                href={TAGGING_TOOL_DOCUMENTATION}
                                icon={<Description />}
                                target="_blank"
                                text="Documentation"
                                tooltip="Tagging Tool documentation"
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Drawer>
    );
};
