import Ky, { NormalizedOptions } from 'ky';
import { findId } from 'src/lib/findId';

/**
 * Abstract (base) class for all Service implementations.
 */
export class AbstractService<T extends Models.V3.Base = Models.V3.Base> {
    api: typeof Ky;

    version: string;

    constructor(serviceConfig: App.ServiceConfig) {
        this.api = Ky.create({
            ...serviceConfig,
            hooks: {
                ...serviceConfig.hooks,
                afterResponse: [this.withIdHook.bind(this)],
            },
        });

        this.version = serviceConfig.version;
    }

    // eslint-disable-next-line class-methods-use-this
    protected customizeResult(result: T): T & { id: string } {
        let newId = result.id;

        if ('href' in result) {
            newId = findId(result.href);
        }

        return {
            ...result,
            id: newId,
        };
    }

    private async withIdHook(req: Request, opts: NormalizedOptions, res: Response): Promise<Response> {
        if (res.ok && res.status !== 204) {
            const json = await res.json();

            if ('href' in json) {
                const newJson = this.customizeResult(json);

                return new Response(JSON.stringify(newJson), {
                    status: res.status,
                    statusText: res.statusText,
                    headers: res.headers,
                });
            }

            if ('results' in json) {
                json.results = json.results.map((result: T) => this.customizeResult(result));

                return new Response(JSON.stringify(json), {
                    status: res.status,
                    statusText: res.statusText,
                    headers: res.headers,
                });
            }

            if (Array.isArray(json)) {
                const newJson = json.map(this.customizeResult);

                return new Response(JSON.stringify(newJson), {
                    status: res.status,
                    statusText: res.statusText,
                    headers: res.headers,
                });
            }

            return new Response(JSON.stringify(this.customizeResult(json)), {
                status: res.status,
                statusText: res.statusText,
                headers: res.headers,
            });
        }

        return res;
    }
}
