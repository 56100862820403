import { QueryFunctionContext } from 'react-query';

import { ContentStoreEntityProductsService } from 'src/services/EntityProduct';

export const QUERY_KEY = 'entityProduct';

export const queryEntityProduct = (
    context: QueryFunctionContext<App.EntityProducts.QueryKey>,
): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.EntityProduct>> => {
    const { queryKey: [, accessToken, search] } = context;

    return ContentStoreEntityProductsService.getEntityProducts(
        accessToken,
        search.id,
    );
};
