import { HTMLProps } from 'react';

export const MissingImage = (props: HTMLProps<HTMLImageElement>): JSX.Element => (
    <img
        {...props}
        alt="could be found"
        crossOrigin="anonymous"
        src="/assets/images/missing-image.png"
    />
);
