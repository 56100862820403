import { createFacet } from 'src/components/common/Search/FacetedSearch';

export const TAG_LABEL_FACET: FacetedSearch.Facet = createFacet({
    name: 'Label',
    key: 'label',
    type: 'single',
});

export const FACETS: FacetedSearch.Facet[] = [
    TAG_LABEL_FACET,
];
