import { useRecoilValue } from 'recoil';
import { UseQueryResult } from 'react-query';
import { Typography } from '@material-ui/core';
import { LocalOffer } from '@material-ui/icons';

import { StandardSearch, PropTypes as StandardSearchProps } from 'src/components/common/Search/StandardSearch';
import { useQueryTags } from 'src/hooks/useQueryTags';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { TaggingData } from 'src/components/common/Tagging/TaggingData';
import { Chip } from 'src/components/common/Tagging/Chip';
import { Tagging } from 'src/components/common/Tagging';
import { TaggingHeader } from 'src/components/common/Tagging/TaggingHeader';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { CopyButton } from 'src/components/common/buttons/CopyButton';
import { handlePillEventFactory } from 'src/components/Entity/EntityDetailsDrawer/utils/handlePillEvent';
import { handleSearchFactory } from 'src/components/Entity/EntityDetailsDrawer/utils/handleSearch';
import { findId } from 'src/lib/findId';
import { DataSource } from 'src/constants';
import { useRef } from 'react';

type EntityTag = Models.ContentStoreApi.V3.SourcedTag;

export type PropTypes = Omit<StandardSearchProps<EntityTag, true, false, false>, 'label' | 'options' | 'useQueryOptions' | 'value'> & {
    data: EntityTag[];
};

const HELPER_TEXT = `\
Use the search box to find and add tags to be saved. \
Click on any suggested pill to add them to be saved, and \
click on any selected pill to remove them from being saved\
`;
const getOptionLabel = (option: EntityTag): string => option.label;

export const TagsForm = (props: PropTypes): JSX.Element => {
    const {
        data = [],
        onChange,
        ...rest
    } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const valueHrefs = data.filter((x) => x.source !== DataSource.Deleted).map((k) => k.href);
    const valueRef = useRef<Models.ContentStoreApi.V3.SourcedTag[]>([]);

    const useQueryOptions = (inputValue?: string): UseQueryResult<EntityTag[], Error> => {
        const { data: queryData, ...other } = useQueryTags(
            accessToken,
            {
                ids: undefined,
                label: inputValue,
            },
            {
                enabled: !!accessToken,
                suspense: false,
            },
        );

        const options = queryData && queryData?.results?.length > 0
            ? (queryData.results as EntityTag[])
                .filter((k) => !valueHrefs.includes(k.href))
                .sort((a, b) => a.label.localeCompare(b.label))
            : data;

        return {
            data: options,
            ...other,
        } as UseQueryResult<EntityTag[], Error>;
    };

    const handleEvent = onChange && handlePillEventFactory(data, onChange);
    const handleSearch = onChange && handleSearchFactory(data, onChange);

    return (
        <Tagging icon={<LocalOffer />}>
            <TaggingHeader
                helperText={HELPER_TEXT}
                title={(
                    <CSSGrid
                        gap={2}
                        gridTemplateAreas={'". ."'}
                        gridTemplateColumns="auto 1fr"
                    >
                        <Typography variant="h6">Tags</Typography>
                        <div>
                            <CopyButton
                                value={
                                    data.map(
                                        (x) => (typeof x === 'string' ? x : findId((x as Models.V3.HrefOnly).href)),
                                    ).join(',')
                                }
                            />
                        </div>
                    </CSSGrid>
                )}
            >
                <StandardSearch
                    {...rest}
                    autoComplete
                    allowCopy={false}
                    getOptionLabel={getOptionLabel}
                    getOptionSelected={(option: EntityTag): boolean => !!data.find(
                        (item) => item.href === option.href,
                    )}
                    label="Search Tags"
                    size="small"
                    useQueryOptions={useQueryOptions}
                    value={valueRef.current}
                    onChange={handleSearch}
                />
            </TaggingHeader>
            <TaggingData data={data.sort((a, b) => a.label.localeCompare(b.label))}>
                {(item: EntityTag): JSX.Element => (
                    <Chip
                        data={item}
                        label={item.label}
                        onClick={handleEvent}
                        onDelete={handleEvent}
                    />
                )}
            </TaggingData>
        </Tagging>
    );
};
