import { generateEntityRankHref, generateRanksByCulture } from 'src/components/ForcedRankingEdit/util/forcedRankingUtils';
import { ContentStoreEntityRankingsService } from 'src/services/EntityRankings';

export const mutateEntityRankings = (
    {
        accessToken,
        previousEntities,
        inputData,
        rankingStrategy,
        cultures,
        signal,
    }: App.EntityRankings.MutationVariables,
): Promise<Models.ContentStoreApi.V3.EntityRanking[]> => {
    // TODO: combine with toDelete
    const relevantPreviousEntities = previousEntities?.filter((entity) => {
        const { ranksByCulture } = entity._embedded
            .strategyRanks[generateEntityRankHref(entity, rankingStrategy)].ranks;

        return !!cultures.find((c) => ranksByCulture && !!ranksByCulture[c]);
    });

    // find entities to update
    /* eslint-disable no-param-reassign */
    const toUpdate = previousEntities?.reduce((memo, entity) => {
        const inputMatch = inputData.find((input) => input[0] === entity.id);

        if (inputMatch) {
            const { ranksByCulture } = entity._embedded
                .strategyRanks[generateEntityRankHref(entity, rankingStrategy)].ranks;
            let changed = false;
            const [inputId, inputValue] = inputMatch;

            const newRanksByCulture = cultures.reduce((ranksMemo, c) => {
                if (!ranksMemo[c] || ranksMemo[c] !== inputValue) {
                    ranksMemo[c] = inputValue;
                    changed = true;
                }
                return ranksMemo;
            }, ranksByCulture);

            if (changed) {
                memo[inputId] = {
                    ranksByCulture: newRanksByCulture,
                };
            }
        }

        return memo;
    }, {} as Record<string, Models.ContentStoreApi.V3.EntityRanking>);

    // find entities to create
    const toCreate = inputData.reduce((memo, input) => {
        const shouldCreate = !previousEntities?.find((entity) => input[0] === entity.id);

        if (shouldCreate) {
            const [inputId, inputValue] = input;

            const newRanksByCulture = generateRanksByCulture(cultures, {}, inputValue);

            memo[inputId] = {
                ranksByCulture: newRanksByCulture,
            };
        }

        return memo;
    }, {} as Record<string, Models.ContentStoreApi.V3.EntityRanking>);

    // find entities to delete
    const toDelete = relevantPreviousEntities?.reduce((memo, entity) => {
        const shouldDelete = !inputData.find((input) => input[0] === entity.id);

        if (shouldDelete) {
            const { ranksByCulture } = entity._embedded
                .strategyRanks[generateEntityRankHref(entity, rankingStrategy)].ranks;
            const newRanksByCulture = generateRanksByCulture(cultures, ranksByCulture, undefined);

            memo[entity.id] = {
                ranksByCulture: newRanksByCulture,
            };
        }

        return memo;
    }, {} as Record<string, Models.ContentStoreApi.V3.EntityRanking>);
    /* eslint-enable no-param-reassign */

    const allPuts = {
        ...toUpdate,
        ...toDelete,
        ...toCreate,
    };

    const promises = Object.entries(allPuts).map(([id, entityRank]) => (
        ContentStoreEntityRankingsService.updateEntityRanking(
            accessToken,
            entityRank,
            id,
            rankingStrategy.rankingStrategyId,
            signal,
        )
    ));

    return Promise.all(promises);
};
