import {
    Grid, Typography, Chip, Box, styled, useTheme, Skeleton,
} from '@mui/material';
import { CSSGrid } from 'src/components/common/CSSGrid';

const ProductOptionsGrid = styled(CSSGrid)(({ theme }) => ({
    padding: theme.spacing(0, 4, 0),
    gap: theme.spacing(4),
    overflow: 'auto',
}));

const SelectedChip = styled(Chip)(() => ({
    border: '1px solid transparent',
}));

const SkeltonChip = styled(Skeleton)(({ theme }) => ({
    borderRadius: theme.spacing(4),
}));

export const ProductOptionTogglesSkeleton = (): JSX.Element => {
    const availableProductOptions: MCP.Products.ProductSearch.Models.Option[] = [
        {
            name: 'Placeholder1',
            type: 'Placeholder1',
            values: ['Placeholder', 'Placeholder', 'Placeholder'],
            ranges: ['Placeholder', 'Placeholder', 'Placeholder'],
        }, {
            name: 'Placeholder2',
            type: 'Placeholder2',
            values: ['Placeholder', 'Placeholder', 'Placeholder'],
            ranges: ['Placeholder', 'Placeholder', 'Placeholder'],
        },
    ];

    const theme = useTheme();

    return (
        <ProductOptionsGrid>
            {!!availableProductOptions && !!availableProductOptions.length
            && availableProductOptions.map((productOption) => (
                <Grid item key={productOption.name} xs={12}>
                    <Typography marginBottom={theme.spacing(2)} variant="body2">
                        <Skeleton animation="wave" variant="rectangular">
                            <Box component="span" fontWeight="bold">
                                {productOption.name}
                            </Box>
                        </Skeleton>
                    </Typography>
                    <Grid container direction="row" spacing={2}>
                        {productOption.values.length && productOption.values.map((value) => (
                            <Grid item key={`${productOption.name}-${value}`}>
                                <SkeltonChip animation="wave" variant="rectangular">
                                    <SelectedChip label={value} />
                                </SkeltonChip>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </ProductOptionsGrid>
    );
};
