import { Grid, TextField } from '@material-ui/core';
import { ComponentProps } from 'react';

import { ClearableTextField } from 'src/components/common/inputs/ClearableTextField';

export interface PropTypes extends Omit<ComponentProps<typeof Grid>, 'id' | 'onChange'> {
    id?: string;
    internalName: string;
    onCategoryNameChange: (internalName: string) => void;
}

export const CategoryDetailsForm = (props: PropTypes): JSX.Element => {
    const {
        id,
        internalName,
        onCategoryNameChange,
        ...rest
    } = props;

    return (
        <Grid container direction="column" spacing={6} {...rest}>
            <Grid item>
                <TextField
                    disabled
                    fullWidth
                    label="ID"
                    size="small"
                    value={id}
                />
            </Grid>
            <Grid container item spacing={4}>
                <Grid item xs>
                    <ClearableTextField
                        fullWidth
                        label="Category Name"
                        size="small"
                        value={internalName}
                        onChange={onCategoryNameChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
