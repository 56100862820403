import { createFacet } from 'src/components/common/Search/FacetedSearch';

export const STRATEGY_NAME_FACET: FacetedSearch.Facet = createFacet({
    name: 'Strategy Name',
    key: 'strategyName',
    type: 'single',
});

export const FACETS: FacetedSearch.Facet[] = [
    STRATEGY_NAME_FACET,
];
