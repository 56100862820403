import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { mutateDesignTaggingGuidance } from 'src/mutations/mutateDesignTaggingGuidance';
import { QUERY_KEY } from 'src/queries/queryDesignTaggingGuidances';

import { QUERY_KEY as S_QUERY_KEY } from 'src/queries/queryDesignTaggingGuidance';

export const useMutateDesignTaggingGuidance = (
    accessToken: string,
    id: string,
): UseMutationResult<Models.ContentStoreApi.V3.DesignTaggingGuidance,
Error,
App.DesignTaggingGuidance.MutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<
    Models.ContentStoreApi.V3.DesignTaggingGuidance,
    Error,
    App.DesignTaggingGuidance.MutationVariables
    >(
        mutateDesignTaggingGuidance,
        {
            onSuccess: (response: Models.ContentStoreApi.V3.DesignTaggingGuidance) => {
                queryClient.invalidateQueries(QUERY_KEY);
                queryClient.invalidateQueries(S_QUERY_KEY);
                queryClient.setQueryData([QUERY_KEY, accessToken, id], response);
            },
            useErrorBoundary: true,
        },
    );
};
