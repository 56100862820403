import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';

import { SearchResultsItem } from 'src/components/common/Search/SearchResultItem';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { ComponentProps } from 'react';

export interface PropTypes<T> extends ComponentProps<typeof CSSGrid> {
    children: (item: T) => JSX.Element;
    data?: T[];
    itemKey: (item: T) => string;
    variant?: 'cards';
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        paddingTop: theme.spacing(4),
    },
    footer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    grid: {
        gridGap: theme.spacing(2),
    },
    cards: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    },
}));

export const PaginatedSearchResultsGrid = <T, >(props: PropTypes<T>): JSX.Element => {
    const {
        children,
        className,
        data,
        itemKey,
        variant,
    } = props;
    const classes = useStyles(props);

    return (
        <div className={classes.container}>
            <CSSGrid className={clsx(classes.grid, className, !!variant && classes[variant])}>
                {data?.map((item): JSX.Element => (
                    <SearchResultsItem key={itemKey(item)}>
                        {children(item)}
                    </SearchResultsItem>
                ))}
            </CSSGrid>
        </div>
    );
};
