import { makeStyles, Theme } from '@material-ui/core';
import {
    Tooltip, Typography,
} from '@mui/material';
import clsx from 'clsx';
import { ComponentProps } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    bannerBar: {
        backgroundColor: theme.palette.success.light,
        borderRadius: '20px',
        position: 'relative',
        height: theme.spacing(5),
        width: 'auto',
        display: 'inline-flex',
        justifyContent: 'center',
        alignContent: 'center',
        boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} rgba(0, 0, 0, 0.1)`,
        padding: `0 ${theme.spacing(2)}`,
    },
    approvedText: {
        color: 'white',
        alignSelf: 'center',
        margin: theme.spacing(0.5, 0),
    },
    unapprovedBar: {
        backgroundColor: theme.palette.error.light,
    },
}));

export interface PropTypes extends Omit<ComponentProps<typeof Tooltip>, 'title' | 'children'> {
    active: boolean;
    activeText: string;
    activeTooltipTitle: string;
    inactiveText: string;
    inactiveTooltipTitle: string;
}

export const ResultCardBanner = (props: PropTypes): JSX.Element => {
    const {
        active,
        activeText,
        inactiveText,
        activeTooltipTitle,
        inactiveTooltipTitle,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <Tooltip title={active ? activeTooltipTitle : inactiveTooltipTitle} {...rest}>
            <div className={active ? classes.bannerBar : clsx(classes.bannerBar, classes.unapprovedBar)}>
                <Typography
                    className={classes.approvedText}
                    variant="caption"
                >
                    {active ? activeText : inactiveText}
                </Typography>
            </div>
        </Tooltip>
    );
};
