import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

type DesignTaggingGuidance = Models.ContentStoreApi.V3.DesignTaggingGuidance;

class ContentStoreDesignTaggingGuidanceApi extends AbstractService<DesignTaggingGuidance> implements Services.ContentStoreApi.DesignTaggingGuidances {
    public async getDesignTaggingGuidances(
        bearerToken: string | undefined | false,
        search: App.DesignTaggingGuidances.PagedSearch | null,
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<DesignTaggingGuidance>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getDesignTaggingGuidances. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/designTaggingGuidance`;
        const searchParams = qs.stringify({
            ...search,
            offset,
            limit,
            requestor: config.appName,
        }, { arrayFormat: 'repeat' });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<DesignTaggingGuidance>>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch design tagging guidances',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async getDesignTaggingGuidance(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<DesignTaggingGuidance> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getDesignTaggingGuidance. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/designTaggingGuidance/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<DesignTaggingGuidance>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch design tagging guidance',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }

    public async createDesignTaggingGuidance(
        bearerToken: string | undefined | false,
        body: Models.ContentStoreApi.V3.CreateDesignTaggingGuidance,
        signal?: AbortSignal,
    ): Promise<DesignTaggingGuidance> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request createDesignTaggingGuidance. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/designTaggingGuidance`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .post(url, {
                    searchParams,
                    signal,
                    json: body,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<DesignTaggingGuidance>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to create design tagging guidance',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async updateDesignTaggingGuidance(
        bearerToken: string | undefined | false,
        id: string,
        body: Models.ContentStoreApi.V3.UpdateDesignTaggingGuidance,
        signal?: AbortSignal,
    ): Promise<DesignTaggingGuidance> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request updateTaxonomy. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/designTaggingGuidance/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .put(url, {
                    signal,
                    json: body,
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<DesignTaggingGuidance>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to update design tagging guidance',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async deleteDesignTaggingGuidance(
        bearerToken: string | undefined | false,
        id: string,
        signal?: AbortSignal,
    ): Promise<void> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request deleteDesignTaggingGuidance. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/designTaggingGuidance/${id}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            await this.api
                .delete(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to delete design tagging guidance',
                info: (e as Error).message,
                url,
                id,
                searchParams,
            });
        }
    }
}

export const ContentStoreDesignTaggingGuidanceService = new ContentStoreDesignTaggingGuidanceApi(config.services.contentStoreApi);
