import { makeStyles, Theme, Typography } from '@material-ui/core';
import {
    createRef, HTMLProps, RefObject, useEffect, useRef, useState,
} from 'react';
import {
    useDrag, DragSourceMonitor, useDrop, DropTargetMonitor, XYCoord,
} from 'react-dnd';
import { useThrottledCallback } from 'use-debounce';

import { DraggableType } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableType';
import { DraggableCommand } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableCommand';
import { getCurrentCommandByPosition } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/treeUtils';
import clsx from 'clsx';
import { hoveredElementState } from 'src/atoms/hoveredCategoryAtom';
import { useRecoilState } from 'recoil';

type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;
type Category = Models.ContentStoreApi.V3.Category;

export interface DragItem<T extends (Category | TaxonomyNode)> {
    id: string;
    originalIndex: number,
    node: T;
}

export interface PropTypes<T extends (Category | TaxonomyNode)> extends Omit<HTMLProps<HTMLDivElement>, 'draggable' | 'children'> {
    node: T;
    id: string;
    index: number,
    draggable?: string,
    droppable?: string[],
    depth: number,
    children: (droppable: DraggableCommand | undefined, ref: RefObject<HTMLDivElement>) => JSX.Element;
    moveNode: (id: string, targetId: string, droppedNode: T, command: DraggableCommand) => void,
}

const useStyles = makeStyles((theme: Theme) => ({
    dragPreview: {
        transform: 'translate(0, 0)',
    },
    childAreaText: {
        marginLeft: theme.spacing(4),
        fontSize: 14,
    },
    childDropArea: {
        borderRadius: theme.spacing(2),
        margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(4)}`,
        minHeight: theme.spacing(10),
        display: 'flex',
        alignItems: 'center',
        opacity: '75%',
        border: `1px dashed ${theme.palette.common.black}`,
        '&.active': {
            borderColor: theme.palette.primary.main,
        },
    },
}));

export const TreeItemDraggableDecorator = <T extends (Category | TaxonomyNode)>(props: PropTypes<T>): JSX.Element => {
    const {
        node,
        children,
        id,
        draggable = DraggableType.None,
        droppable = [DraggableType.None],
        depth = 0,
        index: originalIndex,
        moveNode,
        onChange,
        ...rest
    } = props;
    const ref = useRef<HTMLDivElement>(null);
    const treeItemRef = createRef<HTMLDivElement>();

    const classes = useStyles();
    const [currentCommand, setCurrentCommand] = useState<DraggableCommand>();
    const [hoveredElement, setHoveredElement] = useRecoilState(hoveredElementState);

    useEffect(() => {
        if (hoveredElement !== id && typeof currentCommand !== 'undefined') {
            setCurrentCommand(undefined);
        }
    }, [hoveredElement, id, currentCommand]);

    const onDrop = (item: DragItem<T>, monitor: DropTargetMonitor): void => {
        const { id: draggedId, node: taxonomyNode } = item;
        const isOverCurrent = monitor.isOver({ shallow: true });

        if (!isOverCurrent || !ref.current) {
            return;
        }

        if (draggedId !== id && currentCommand) {
            moveNode(draggedId, id, taxonomyNode, currentCommand);
            setCurrentCommand(undefined);
        }
    };

    const throttledHover = useThrottledCallback((item: DragItem<T>, monitor: DropTargetMonitor): void => {
        const { id: draggedId } = item;
        const isOverCurrent = monitor.isOver({ shallow: true });

        if (!isOverCurrent || !treeItemRef || !treeItemRef.current) {
            setCurrentCommand(undefined);
            return;
        }

        if (draggedId !== id) {
            const clientOffset = monitor.getClientOffset() as XYCoord;

            const draggableCommand = getCurrentCommandByPosition(
                treeItemRef.current,
                clientOffset,
                currentCommand,
            );

            if (draggableCommand !== currentCommand) {
                setHoveredElement(id);
                setCurrentCommand(draggableCommand);
            }
        }
    }, 100);

    const [{ isDragging }, categoryDrag] = useDrag(
        () => ({
            type: draggable,
            canDrag: draggable !== DraggableType.None,
            item: { id, originalIndex, node },
            collect: (monitor: DragSourceMonitor): { isDragging: boolean; } => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item: DragItem<T>, monitor: DragSourceMonitor): void => {
                const { id: droppedId, node: nNode } = item;
                const didDrop = monitor.didDrop();

                if (!didDrop) {
                    moveNode(droppedId, id, nNode, DraggableCommand.Remove);
                }
            },
        }),
        [id, originalIndex, moveNode, node, currentCommand],
    );

    const [, categoryDrop] = useDrop(() => ({
        accept: droppable,
        collect: (monitor): { isOver: boolean; isOverCurrentCategory: boolean } => ({
            isOver: monitor.isOver(),
            isOverCurrentCategory: monitor.isOver({ shallow: true }),
        }),
        drop: onDrop,
        hover: throttledHover,
    }), [node, currentCommand]);

    const [{ isOverCurrentChildArea }, childAreaDrop] = useDrop(() => ({
        accept: droppable,
        collect: (monitor): { isOverCurrentChildArea: boolean } => ({
            isOverCurrentChildArea: monitor.isOver({ shallow: true }),
        }),
        drop: (item: DragItem<T>, monitor: DropTargetMonitor): void => {
            const { id: draggedId, node: taxonomyNode } = item;
            const isOverCurrent = monitor.isOver({ shallow: true });

            if (!isOverCurrent || !ref.current) {
                return;
            }

            if (draggedId !== id) {
                moveNode(draggedId, id, taxonomyNode, DraggableCommand.AddFirstChild);
            }
        },
    }), [node]);

    const showChildrenDropArea = (!('children' in node) || !node.children || !node.children.length)
    && droppable.filter((d) => d !== DraggableType.None).length > 0
    && !isDragging
    && depth < 2;

    categoryDrag(categoryDrop(ref));
    return (
        <>
            <div className={classes.dragPreview} ref={ref} {...rest}>
                <div ref={ref} style={{ opacity: isDragging ? 0 : 1 }}>
                    {children(currentCommand, treeItemRef)}
                </div>
            </div>
            {showChildrenDropArea && (
                <div
                    className={clsx(classes.childDropArea, { active: isOverCurrentChildArea })}
                    ref={childAreaDrop}
                >
                    <Typography className={classes.childAreaText}>
                        Drop a category here to add a new child
                    </Typography>
                </div>
            )}
        </>
    );
};
