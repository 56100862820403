export enum KEY_EVENTS {
    Backspace = 'Backspace',
    Cancel = 'Cancel',
    Clear = 'Clear',
    Enter = 'Enter',
    Escape = 'Escape',
    Delete = 'Delete',
    Keydown = 'keydown',
    Tab = 'Tab',
}

export enum MOUSE_EVENTS {
    Click = 'click',
}
