import {
    Chip, makeStyles, Theme, Tooltip,
} from '@material-ui/core';
import { ComponentProps, MouseEvent, useState } from 'react';

import { Edit, Tune, SvgIconComponent } from '@material-ui/icons';
import { useSetRecoilState } from 'recoil';
import { productSearchDialogOpenState } from 'src/atoms/productSearchDialogOpenAtom';

export interface PropTypes extends ComponentProps<typeof Chip> {
    facet: FacetedSearch.FacetedSearchRecord;
    productVersion?: string;
    productOptions?: App.Entities.ProductOptions;
}

const useStyles = makeStyles((theme: Theme) => ({
    chip: {
        marginBottom: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        whiteSpace: 'nowrap',
    },
}));

export const ProductSearchTag = (props: PropTypes): JSX.Element => {
    const {
        facet,
        productVersion,
        productOptions,
        ...rest
    } = props;
    const classes = useStyles();
    const setProductSearchDialogOpenState = useSetRecoilState(productSearchDialogOpenState);
    const [MappedIcon, setMappedIcon] = useState<SvgIconComponent>(Tune);

    const handleClick = (event: MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();

        setProductSearchDialogOpenState(true);
    };
    const handleMouseOver = (): void => {
        setMappedIcon(Edit);
    };

    const handleMouseOut = (): void => {
        setMappedIcon(Tune);
    };

    return (
        <Tooltip title="Edit product search details">
            <Chip
                className={classes.chip}
                {...rest}
                icon={<MappedIcon />}
                label={facet.value}
                onClick={handleClick}
                {...rest}
                data-testid={`${facet.facet?.key}-product-search-tag`}
                onMouseOut={handleMouseOut}
                onMouseOver={handleMouseOver}
            />
        </Tooltip>
    );
};
