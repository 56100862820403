import {
    Card,
    CardContent,
    Skeleton,
    Typography,
    makeStyles,
    Theme,
    Grid,
} from '@material-ui/core';

import { IMG_DIMENSION_MULTIPLIER } from 'src/constants';

const PLACEHOLDERS: number[] = Array.from({ length: 6 }, (_, i) => i + 1);

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100%',
    },
    title: {
        fontSize: '0.7em',
        top: theme.spacing(1),
        left: theme.spacing(1),
    },
    media: {
        height: theme.spacing(IMG_DIMENSION_MULTIPLIER),
        width: theme.spacing(IMG_DIMENSION_MULTIPLIER),
        margin: `${theme.spacing(8)} ${theme.spacing(3)}`,
    },
    chip: {
        borderRadius: theme.spacing(3),
        height: theme.spacing(8),
    },
    container: {
        height: '100%',
    },
    fullWidth: {
        width: '100%',
    },
}));

export const EntityResultSkeleton = (): JSX.Element => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <Grid
                container
                alignItems="center"
                className={classes.container}
                direction="column"
                justifyContent="center"
            >
                <Grid item>
                    <Skeleton
                        animation="wave"
                        className={classes.media}
                        variant="rectangular"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <CardContent>
                        <Typography paragraph className={classes.title} variant="caption">
                            <Skeleton animation="wave" />
                        </Typography>
                        <Grid container spacing={2}>
                            {PLACEHOLDERS.map((item) => (
                                <Grid item key={item} xs={4}>
                                    <Skeleton
                                        animation="wave"
                                        className={classes.chip}
                                        // width="33%"
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};
