import clsx from 'clsx';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { ComponentProps } from 'react';

export interface PropTypes extends ComponentProps<typeof Typography> {
    count?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        backgroundColor: theme.palette.info.main,
        borderRadius: theme.spacing(4),
        color: theme.palette.common.white,
        fontSize: 10,
        marginRight: theme.spacing(4),
        padding: `${theme.spacing(1)} calc(${theme.spacing(2)} + 2px)`,
        textAlign: 'center',
    },
}));

export const NumberSelectedIcon = (props: PropTypes): JSX.Element | null => {
    const { className, count, ...rest } = props;
    const classes = useStyles();

    return (!!count || null) && (
        <Typography className={clsx(classes.icon, className)} {...rest}>
            {count}
        </Typography>
    );
};
