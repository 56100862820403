import clsx from 'clsx';
import {
    makeStyles, Theme,
} from '@material-ui/core';
import {
    ComponentProps,
    useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import { TreeView } from '@material-ui/lab';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { useQueryDesignTaggingGuidances } from 'src/hooks/useQueryDesignTaggingGuidances';
import { findId } from 'src/lib/findId';
import {
    FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography,
} from '@mui/material';
import { useQueryDucs } from 'src/hooks/useQueryDucs';
import { Loader } from 'src/components/common/Loader';
import { DesignGuidanceTaxonomy, DesignGuidanceTaxonomyPropTypes } from 'src/components/common/TaxonomyDiscovery/SearchPanels/DesignGuidanceTaxonomy';

type Category = Models.ContentStoreApi.V3.SourcedCategory;

export interface DesignGuidancePanelPropTypes<T extends Category> extends Omit<DesignGuidanceTaxonomyPropTypes<T>, 'taxonomyIds'> {
    ducIds: string[],
    gridProps?: Partial<ComponentProps<typeof CSSGrid>>;
    treeProps?: Partial<ComponentProps<typeof TreeView>>;
}

export const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    grid: {
        height: '100%',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        gridTemplateRows: 'auto 1fr',
        gridAutoFlow: 'row dense',
    },
    loader: {
        marginTop: theme.spacing(10),
    },
    tree: {
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
    },
    root: {
        height: 'fit-content',
    },
    content: {
        padding: theme.spacing(5),
    },
    select: {
        paddingBottom: theme.spacing(6),
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.common.white,
        },
    },
    alignCenter: {
        alignContent: 'center',
    },
}));

export const DesignGuidancePanel = <T extends Category>(props: DesignGuidancePanelPropTypes<T>): JSX.Element => {
    const {
        className,
        ducIds,
        onChange,
        valueMap,
        draggable,
        droppable,
        ...rest
    } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery);

    const [selectedDucIds, setSelectedDucIds] = useState<string[]>(ducIds);

    const { data: ducs } = useQueryDucs(accessToken, {
        suspense: false,
        enabled: !!accessToken,
    });

    const {
        data: designGuidance,
        isLoading,
    } = useQueryDesignTaggingGuidances(accessToken, {
        ducIds,
    }, {
        enabled: !!accessToken,
        suspense: false,
        refetchOnWindowFocus: false,
    });

    const taxonomyIds = designGuidance?.results
        .filter((dg) => selectedDucIds.includes(dg.ducId))
        .reduce((accum, dg) => accum
            .concat(dg.taxonomies.map((t) => findId(t.taxonomy.href))), [] as string[]);

    const handleChange = (event: SelectChangeEvent<string[]>): void => {
        const {
            target: { value },
        } = event;

        setSelectedDucIds(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <CSSGrid {...rest} className={clsx(classes.grid, className)}>
            <div>
                {ducIds.length > 1 && (
                    <FormControl fullWidth className={classes.select}>
                        <InputLabel id="design-guidance-ducid-select-label">DUCs</InputLabel>
                        <Select
                            fullWidth
                            multiple
                            id="design-guidance-ducid-select"
                            input={<OutlinedInput label="Name" />}
                            value={selectedDucIds}
                            onChange={handleChange}
                        >
                            {ducIds.map((ducId) => (
                                <MenuItem
                                    key={ducId}
                                    value={ducId}
                                >
                                    {ducs?.find((duc) => duc.id === ducId)?.name || ducId}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </div>
            <div className={classes.tree}>
                {isLoading && (<Loader className={classes.loader} />)}
                {!isLoading && taxonomyIds && !!taxonomyIds.length && (
                    <DesignGuidanceTaxonomy taxonomyIds={taxonomyIds} {...props} />
                )}
                {!isLoading && taxonomyIds && !taxonomyIds.length && (
                    <CSSGrid className={classes.alignCenter}>
                        <Typography>
                            No Design Tagging Guidance found for the selected DUCs:
                        </Typography>
                        <Typography textAlign="center" variant="caption">
                            { selectedDucIds.join(', ') }
                        </Typography>
                    </CSSGrid>
                )}
            </div>
        </CSSGrid>
    );
};
