import {
    alpha, Avatar, Chip, experimentalStyled as styled,
} from '@material-ui/core';

import { GhostSvg } from 'src/components/common/icons/GhostSvg';

const StyledChip = styled(Chip)(({ theme }) => ({
    width: 'min-content',
    border: `1px dashed ${alpha(theme.palette.secondary.main, 0.5)}`,
    color: alpha(theme.palette.secondary.main, 0.6),
    '& > .MuiChip-avatar': {
        marginLeft: theme.spacing(2),
    },
    '& > .MuiChip-avatarColorSecondary': {
        color: 'transparent',
        backgroundColor: 'transparent',
    },
}));

export const ZeroState = (): JSX.Element => (
    <StyledChip
        avatar={(
            <Avatar>
                <GhostSvg />
            </Avatar>
        )}
        color="secondary"
        label="Nothing here yet..."
        variant="outlined"
    />
);
