import {
    makeStyles, Button, Dialog, Typography, TextField, Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { ChangeEvent, useState } from 'react';
import { LoadingButton } from '@material-ui/lab';
import { CSSGrid } from 'src/components/common/CSSGrid';

const useStyles = makeStyles((theme: Theme) => ({
    cancel: {
        color: theme.palette.common.white,
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        '& .MuiPaper-root': {
            padding: theme.spacing(8),
        },
    },
    buttons: {
        display: 'grid',
        paddingTop: theme.spacing(4),
        gap: theme.spacing(2),
        gridTemplateAreas: '". ."',
        justifyContent: 'center',
    },
    container: {
        maxWidth: theme.spacing(125),
    },
    grid: {
        gap: theme.spacing(3),
    },
    buttonGroup: {
        gridAutoFlow: 'column',
    },
    errorButton: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: 'rgba(209, 44, 11, 0.04)',
            borderColor: theme.palette.error.main,
        },
    },
}));

export const MergeDialog = (props: App.CustomConfirmation.PropTypes): JSX.Element => {
    const {
        handleConfirm, handleClose, handleDelete, className, ...rest
    } = props;
    const classes = useStyles();
    const [replacementId, setReplacementId] = useState('');
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const onClick = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        setLoading(true);
        try {
            await handleConfirm(replacementId);
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    const onDelete = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        setDeleteLoading(true);
        try {
            await handleDelete();
        } finally {
            setDeleteLoading(false);
            handleClose();
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        setReplacementId(event.target.value);
    };

    return (
        <Dialog
            className={clsx(classes.modal, className)}
            onClose={handleClose}
            {...rest}
        >
            <CSSGrid className={clsx(classes.container, classes.grid)}>
                <Typography
                    textAlign="center"
                    variant="h6"
                >
                    Merge Category with
                </Typography>
                <TextField
                    label="Replacement Category Id"
                    size="small"
                    value={replacementId}
                    onChange={onChange}
                />
                <Typography
                    textAlign="center"
                    variant="caption"
                >
                    (Merging will delete the existing category and retag all content and taxonomy nodes)
                </Typography>
                <CSSGrid className={clsx(classes.grid)}>
                    <LoadingButton
                        color="primary"
                        loading={loading}
                        variant="contained"
                        onClick={onClick}
                    >
                        Merge
                    </LoadingButton>
                    <CSSGrid className={clsx(classes.grid, classes.buttonGroup)}>
                        <LoadingButton
                            className={clsx(classes.errorButton)}
                            loading={deleteLoading}
                            variant="outlined"
                            onClick={onDelete}
                        >
                            Delete without merging
                        </LoadingButton>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </CSSGrid>
                </CSSGrid>
            </CSSGrid>
        </Dialog>
    );
};
