import { Card } from '@material-ui/core';
import { ComponentProps } from 'react';

import { ResultCard } from 'src/components/common/ResultCard';

export interface PropTypes extends Omit<ComponentProps<typeof Card>, 'onClick'> {
    id: string;
    internalName: string;
    numTaxonomies: number;
    onClick: (id: string) => void;
}

export const DesignTaggingGuidanceResult = (props: PropTypes): JSX.Element => {
    const {
        id,
        internalName,
        numTaxonomies,
        ...rest
    } = props;

    const taxonomyCount = `Suggested taxonomies: ${numTaxonomies}`;

    return (
        <ResultCard
            {...rest}
            id={id}
            itemName="DesignTaggingGuidance"
            title={internalName}
        >
            {taxonomyCount}
        </ResultCard>
    );
};
