import { MouseEvent } from 'react';

import { PropTypes as StandardSearchProps } from 'src/components/common/Search/StandardSearch';
import { ChipNextState } from 'src/components/common/Tagging/Chip';

export const handlePillEventFactory = <T extends Models.V3.SourcedBase>(
    data: T[],
    onChange: StandardSearchProps<T, true, false, false>['onChange'],
) => (
        event: MouseEvent<HTMLElement>,
        item: T,
        nextState: ChipNextState,
    ): void => {
        if (!onChange) {
            return;
        }

        const updatedList = [...data];
        const index = data.findIndex((d) => d.href === item.href);
        const updatedItem = {
            ...item,
            source: nextState,
        };

        updatedList[index] = updatedItem;
        onChange(event, updatedList);
    };
