import {
    Card,
    CardHeader,
    makeStyles,
    IconButton,
    CardActionArea,
    Theme,
    Grid,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import clsx from 'clsx';
import {
    ComponentProps,
    MouseEvent,
    useState,
} from 'react';
import { useRecoilValue } from 'recoil';

import { InlineAddForm } from 'src/components/Tags/Forms/InlineAddForm';
import { TagCardTitle } from 'src/components/Tags/Cards/TagCardTitle';
import { useDeleteTag } from 'src/components/Tags/hooks/useDeleteTag';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { ConfirmationDialog } from 'src/components/common/ConfirmationDialog';

export interface PropTypes extends Omit<ComponentProps<typeof Card>, 'onClick'> {
    id: string;
    title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardActionArea: {
        height: '100%',
    },
    actions: {
        display: 'grid',
        gridTemplateAreas: '". ."',
        justifyItems: 'center',
    },
    title: {
        marginTop: theme.spacing(1),
        height: '100%',
        alignItems: 'flex-start',
        maxWidth: 'auto',
        display: 'block',
    },
    delete: {
        display: 'grid',
        justifyContent: 'flex-end',
        gridTemplateAreas: '". ."',
    },
    deleteIconRow: {
        position: 'absolute',

        justifyContent: 'flex-end',
    },
    deleteIcon: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        justifyContent: 'flex-end',
        color: theme.palette.error.main,
        '& svg': {
            height: theme.typography.body1.fontSize,
            width: theme.typography.body1.fontSize,
        },
    },
}));

export const TagCard = (props: PropTypes): JSX.Element => {
    const {
        className,
        id,
        title,
        ...rest
    } = props;
    const classes = useStyles();
    const [editing, setEditing] = useState<boolean>(false);
    const [modalOpen, SetModalOpen] = useState(false);
    const deleteMutation = useDeleteTag();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const modalPrompt = `Are you sure you want to delete Tag "${title}"?`;

    const onUpdateSubmit = (): void => {
        setEditing(false);
    };

    const handleEdit = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        setEditing(!editing);
    };

    const onModalClose = (): void => {
        SetModalOpen(false);
    };

    const onModalOpen = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        SetModalOpen(true);
    };

    const handleDelete = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        if (accessToken && id) {
            await deleteMutation.mutateAsync({
                accessToken,
                id,
            });
        }

        SetModalOpen(false);
    };

    return (
        <Card {...rest} className={clsx(classes.card, className)}>
            {editing && (
                <div
                    className={classes.actions}
                >
                    <InlineAddForm
                        id={id}
                        labelValue={title}
                        onCancel={handleEdit}
                        onUpdateSubmit={onUpdateSubmit}
                    />
                </div>
            )}
            {!editing && (
                <CardActionArea className={classes.cardActionArea} disabled={!onModalOpen} onClick={handleEdit}>
                    <Grid container className={classes.deleteIconRow} spacing={1}>
                        <Grid item>
                            <IconButton
                                className={classes.deleteIcon}
                                size="small"
                                onClick={onModalOpen}
                    // Stops the ripple effect in the rest of the card
                                onMouseDown={(event): void => event.stopPropagation()}
                                onTouchStart={(event): void => event.stopPropagation()}
                            >
                                <Delete />
                            </IconButton>

                        </Grid>
                    </Grid>
                    <CardHeader
                        className={classes.title}
                        subheader={`ID: ${id}`}
                        subheaderTypographyProps={{
                            variant: 'caption',
                        }}
                        title={(
                            <TagCardTitle title={title} />
                        )}
                        titleTypographyProps={{
                            variant: 'h6',
                        }}
                    />
                </CardActionArea>
            )}

            {modalOpen && (
                <ConfirmationDialog
                    open
                    title={modalPrompt}
                    onAccept={handleDelete}
                    onCancel={onModalClose}
                />
            )}
        </Card>
    );
};
