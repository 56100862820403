import ReactImageMagnify from '@blacklab/react-image-magnify';
import { ComponentProps } from 'react';

import { Hint } from 'src/components/common/MagnifiedImage/Hint';
import { generateSizes, generateSrcSet } from 'src/lib/image';

interface PropTypes extends Omit<ComponentProps<typeof ReactImageMagnify>, 'imageProps' | 'magnifiedImageProps'> {
    previewUrls?: Models.ContentStoreApi.V3.PreviewUrls;
    imageProps?: ComponentProps<typeof ReactImageMagnify>['imageProps'];
    magnifiedImageProps: Partial<ComponentProps<typeof ReactImageMagnify>['magnifiedImageProps']> & {
        height: number;
        width: number;
    };
}

export const MagnifiedImage = (props: PropTypes): JSX.Element => {
    const {
        previewUrls,
        imageProps: imagePropsProp,
        magnifiedImageProps: magnifiedImagePropsProp,
        ...rest
    } = props;

    if (!previewUrls && !imagePropsProp?.src) {
        throw new Error('Either "previewUrls" or "imagePropsProp.src" must be specified');
    }

    const imageProps = {
        ...imagePropsProp,
        sizes: imagePropsProp?.sizes || generateSizes(previewUrls),
        src: imagePropsProp?.src || previewUrls?.['480w'],
        srcSet: imagePropsProp?.srcSet || generateSrcSet(previewUrls),
    };

    const magnifiedImageProps = {
        ...magnifiedImagePropsProp,
        src: magnifiedImagePropsProp?.src || previewUrls?.['1920w'] || imagePropsProp?.src || '',
    };

    return (
        <ReactImageMagnify
            {...rest}
            activationInteractionHint="click"
            hintComponent={Hint}
            imageProps={imageProps}
            magnifiedImageProps={magnifiedImageProps}
            magnifyContainerProps={{
                height: 400,
                width: 400,
                style: {
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    zIndex: 1501,
                },
                ...rest.magnifyContainerProps,
            }}
            portalProps={{
                horizontalOffset: 8,
                placement: 'auto',
                style: {
                    zIndex: 1500,
                },
                ...rest.portalProps,
            }}
        />
    );
};
