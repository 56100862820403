import clsx from 'clsx';
import {
    Box,
    makeStyles, Theme, Toolbar, Typography,
} from '@material-ui/core';
import { ComponentProps, ReactNode } from 'react';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { LabelTooltip } from 'src/components/common/TaxonomyDiscovery/LabelTooltip';
import { Help } from '@material-ui/icons';

export interface PropTypes extends Omit<ComponentProps<typeof Toolbar>, 'title'> {
    title: ReactNode;
    tooltip?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        marginBottom: theme.spacing(6),
        gridArea: 'form',
    },
    grid: {
        gridTemplateAreas: `
            "header"
            "form"
        `,
        gridTemplateColumns: '1fr auto auto',
        width: '100%',
    },
    header: {
        marginBottom: theme.spacing(6),
    },
    headerTitle: {
        gridArea: 'header',
    },
    toolbar: {
        padding: 0,
        minHeight: 'auto',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            padding: 0,
            minHeight: 'auto',
            width: '100%',
        },
    },
}));

export const FormArea = (props: PropTypes): JSX.Element => {
    const {
        children,
        className,
        title,
        tooltip,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <Toolbar className={clsx(classes.toolbar, className)} {...rest}>
            <CSSGrid className={classes.grid}>
                {tooltip && (
                    <Box className={classes.header}>
                        <Typography
                            className={classes.headerTitle}
                            style={{ display: 'inline' }}
                            variant="h6"
                        >
                            {title}
                        </Typography>
                        <LabelTooltip title={tooltip}>
                            <Help />
                        </LabelTooltip>
                    </Box>
                )}
                {!tooltip && (
                    <Typography paragraph className={classes.headerTitle} variant="h6">
                        {title}
                    </Typography>
                )}
                <section className={classes.form}>
                    {children}
                </section>
            </CSSGrid>
        </Toolbar>
    );
};
