import 'react-image-gallery/styles/css/image-gallery.css';

import { render } from 'react-dom';

import { App } from 'src/App';
import { getLogger } from 'src/lib/logger';

try {
    render(<App />, document.getElementById('app'));
} catch (e) {
    // TODO show something
    getLogger().error((e as Error));
}
