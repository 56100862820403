import clsx from 'clsx';
import {
    Grid, makeStyles, Theme,
} from '@material-ui/core';
import { ComponentProps } from 'react';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { ResultCardBanner } from 'src/components/common/ResultCardBanner';

export interface PropTypes extends ComponentProps<typeof Grid> {
    active: boolean;
    approved: boolean;
    culturesCount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    bannerBar: {
        backgroundColor: theme.palette.success.light,
        borderRadius: '20px',
        position: 'relative',
        height: theme.spacing(5),
        width: 'auto',
        display: 'inline-flex',
        justifyContent: 'center',
        alignContent: 'center',
        boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} rgba(0, 0, 0, 0.1)`,
    },
    grid: {
        margin: theme.spacing(2),
    },
    approvedText: {
        color: 'white',
        alignSelf: 'center',
        margin: theme.spacing(0.5, 0),
    },
    unapprovedBar: {
        backgroundColor: theme.palette.error.light,
    },
}));

const ACTIVE_TOOLTIP = 'Design is active in DTeC';
const INACTIVE_TOOLTIP = 'Design is inactive in DTeC';
const APPROVED_TOOLTIP = 'Design is approved';
const UNAPPROVED_TOOLTIP = 'Design is unapproved';
const CULTURES_ENABLED_TOOLTIP = 'Number of enabled cultures';
const CULTURES_DISABLED_TOOLTIP = 'Design has no enabled cultures';

export const ApprovalBanner = (props: PropTypes): JSX.Element => {
    const {
        className,
        active,
        approved,
        culturesCount,
    } = props;
    const classes = useStyles();

    return (
        <CSSGrid
            className={clsx(classes.grid, className)}
            gap={1}
            gridTemplateColumns="1fr 1fr 1fr"
        >
            <ResultCardBanner
                active={active}
                activeText="DTec Active"
                activeTooltipTitle={ACTIVE_TOOLTIP}
                inactiveText="DTeC Inactive"
                inactiveTooltipTitle={INACTIVE_TOOLTIP}
            />
            <ResultCardBanner
                active={approved}
                activeText="Approved"
                activeTooltipTitle={APPROVED_TOOLTIP}
                inactiveText="Unapproved"
                inactiveTooltipTitle={UNAPPROVED_TOOLTIP}
            />
            <ResultCardBanner
                active={!!culturesCount}
                activeText={`Cultures: ${culturesCount}`}
                activeTooltipTitle={CULTURES_ENABLED_TOOLTIP}
                inactiveText="No Cultures"
                inactiveTooltipTitle={CULTURES_DISABLED_TOOLTIP}
            />
        </CSSGrid>
    );
};
