import { PreviewUrlBuilder } from 'src/lib/previewUrlBuilder';

export function withPreviewUrl(
    entity: Models.ContentStoreApi.V3.Entity,
    culture?: string,
): Models.ContentStoreApi.V3.Entity {
    return {
        ...entity,
        previewUrls: {
            '480w': PreviewUrlBuilder.build(
                entity.entityDescriptor,
                '480',
                culture,
            ),
            '1080w': PreviewUrlBuilder.build(
                entity.entityDescriptor,
                '480',
                culture,
            ),
            '1920w': PreviewUrlBuilder.build(
                entity.entityDescriptor,
                '960',
                culture,
            ),
        },
    };
}
