import {
    TextField, Theme, makeStyles,
} from '@material-ui/core';
import { DesktopDatePicker, LocalizationProvider } from '@material-ui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CSSGrid } from 'src/components/common/CSSGrid';

export interface PropTypes {
    rankingStrategy: Models.ContentStoreApi.V3.RankingStrategy;
    setRankingStrategy: (rankingStrategy: Models.ContentStoreApi.V3.RankingStrategy | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        gridTemplateColumns: '2fr 1fr 1fr',
        gridGap: theme.spacing(4),
        margin: theme.spacing(4),
    },
}));

export const ForcedRankingEdit = (props: PropTypes): JSX.Element => {
    const {
        rankingStrategy,
        setRankingStrategy,
    } = props;
    const classes = useStyles();

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue = event.target.value;

        if (/^[a-zA-Z0-9\s]+$/.test(newValue) || newValue === '') {
            const newRankingStrategy = {
                ...rankingStrategy,
                strategyName: newValue,
            };

            setRankingStrategy(newRankingStrategy);
        }
    };

    const handleStartChange = (newValue: Date | null): void => {
        const newRankingStrategy = {
            ...rankingStrategy,
            startDate: newValue,
        };

        setRankingStrategy(newRankingStrategy);
    };

    const handleEndChange = (newValue: Date | null): void => {
        const newRankingStrategy = {
            ...rankingStrategy,
            endDate: newValue,
        };

        setRankingStrategy(newRankingStrategy);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CSSGrid className={classes.grid}>
                <TextField
                    fullWidth
                    required
                    id="strategy-name"
                    label="Ranking strategy name"
                    value={rankingStrategy?.strategyName}
                    onChange={handleNameChange}
                />
                <DesktopDatePicker
                    inputFormat="MM/DD/YYYY"
                    label="Start Date"
                    renderInput={(params): JSX.Element => <TextField {...params} />}
                    value={rankingStrategy.startDate}
                    onChange={handleStartChange}
                />
                <DesktopDatePicker
                    inputFormat="MM/DD/YYYY"
                    label="End Date"
                    renderInput={(params): JSX.Element => <TextField {...params} />}
                    value={rankingStrategy.endDate}
                    onChange={handleEndChange}
                />
            </CSSGrid>
        </LocalizationProvider>
    );
};
