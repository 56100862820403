import clsx from 'clsx';
import {
    makeStyles, Theme,
} from '@material-ui/core';
import { HTMLProps } from 'react';

import { PropertyCell } from 'src/components/Entity/EntityDetailsDrawer/PropertiesPanel/PropertyCell';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { NOT_APPLICABLE } from 'src/constants';
import { useRecoilValue } from 'recoil';
import { useQueryDucs } from 'src/hooks/useQueryDucs';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { toDUCsWithNames } from 'src/utils/ducUtils';

export interface PropTypes extends Omit<HTMLProps<HTMLDivElement>, 'data'> {
    data: Models.ContentStoreApi.V3.Entity;
}

const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        gridTemplateAreas: '". ."',
        height: '100%',
        overflowY: 'auto',
    },
    productSpecifications: {
        gridColumn: '1 / span 2',
    },
    productSpecificationsGrid: {
        gap: theme.spacing(4),
        gridTemplateAreas: '". ."',
        paddingTop: theme.spacing(4),
    },
}));

export const PropertiesPanel = (props: PropTypes): JSX.Element => {
    const {
        className,
        data,
        ...rest
    } = props;
    const classes = useStyles();

    const accessToken = useRecoilValue(isAuthorizedQuery);
    const { data: ducs } = useQueryDucs(accessToken, {
        suspense: false,
        enabled: !!accessToken,
    });

    return (
        <CSSGrid className={clsx(classes.grid, className)} {...rest}>
            <PropertyCell
                title="ID"
                value={data.id}
            />
            <PropertyCell
                title="DUC IDs"
                value={data.ducIds ? toDUCsWithNames(data.ducIds, ducs) : NOT_APPLICABLE}
            />
            <PropertyCell
                title="Ensemble Line Id"
                value={data.ensembleLineId || NOT_APPLICABLE}
            />
            <PropertyCell
                title="Has Logo"
                value={data.hasLogo?.toString() || NOT_APPLICABLE}
            />
            <PropertyCell
                title="Is Active"
                value={data.isActive?.toString() || NOT_APPLICABLE}
            />
            <PropertyCell
                title="Number of Placeholders"
                value={data.numberOfPlaceHolders?.toString() || '0'}
            />
            <PropertyCell
                title="Template Catalog ID"
                value={data.templateId || NOT_APPLICABLE}
            />
            <PropertyCell
                title="Template Token"
                value={data.entityDescriptor || NOT_APPLICABLE}
            />
            <PropertyCell
                title="Date Created"
                value={data.createdDate ? (new Date(data.createdDate)).toDateString() : NOT_APPLICABLE}
            />
            <PropertyCell
                title="Last Modified"
                value={data.modifiedDate ? (new Date(data.modifiedDate)).toDateString() : NOT_APPLICABLE}
            />
        </CSSGrid>
    );
};
