import clsx from 'clsx';
import {
    Alert,
    makeStyles,
    Snackbar,
    Theme,
    useTheme,
} from '@material-ui/core';
import {
    ComponentProps,
    Dispatch,
    FormEvent,
    SetStateAction,
    SyntheticEvent,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Attachment,
    Category,
    Language,
    List,
    Settings,
} from '@material-ui/icons';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { DetailsDrawer } from 'src/components/common/DetailsDrawer';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { useMutateEntity } from 'src/components/Entity/hooks/useMutateEntity';
import { useQueryEntity } from 'src/components/Entity/hooks/useQueryEntity';
import { EntityDetailsEmptyState } from 'src/components/Entity/EntityDetailsDrawer/EntityDetailsEmptyState';
import { LoadingGrid } from 'src/components/common/Loader/LoadingGrid';
import { ErrorGrid } from 'src/components/common/ErrorState/ErrorGrid';
import { TaggingPanel } from 'src/components/Entity/EntityDetailsDrawer/TaggingPanel';
import { PropertiesPanel } from 'src/components/Entity/EntityDetailsDrawer/PropertiesPanel';
import { MagnifiedImage } from 'src/components/common/MagnifiedImage';
import { ApproveButton } from 'src/components/Entity/EntityDetailsDrawer/ApproveButton';
import { TabList } from 'src/components/common/Tabs/TabList';
import { Tab } from 'src/components/common/Tabs/Tab';
import { TaxonomyDiscovery } from 'src/components/common/TaxonomyDiscovery';
import { drawerUpdatedState } from 'src/atoms/drawerUpdatedAtom';
import { saveable } from 'src/components/Entity/EntityDetailsDrawer/utils/saveable';
import { union } from 'src/utils/union';
import { CategoriesForm } from 'src/components/Entity/EntityDetailsDrawer/CategoriesForm';
import { CulturesForm } from 'src/components/Entity/EntityDetailsDrawer/CultureForm';
import { KeywordsForm } from 'src/components/Entity/EntityDetailsDrawer/KeywordsForm';
import { TagsForm } from 'src/components/Entity/EntityDetailsDrawer/TagsForm';
import { toSourcedBase } from 'src/utils/toSourcedBase';
import { useQueryKeywordSuggestions } from 'src/hooks/useQueryKeywordSuggestions';
import { useQueryCategorySuggestions } from 'src/hooks/useQueryCategorySuggestions';

import {
    generateKeywordSuggestionsPlaceholderData,
} from 'src/components/Entity/EntityDetailsDrawer/utils/generateKeywordPlaceholderData';
import {
    generateCategorySuggestionsPlaceholderData,
} from 'src/components/Entity/EntityDetailsDrawer/utils/generateCategoryPlaceholderData';
import {
    SNACKBAR_TIMEOUT, DataSource, NEW_TAG_LABEL, UserEvent,
} from 'src/constants';
import { ProductSpecificationsPanel } from 'src/components/Entity/EntityDetailsDrawer/ProductSpecifications';
import { useQueryEntityProducts } from 'src/hooks/useQueryEntityProducts';
import { HeaderImageThumbnail } from 'src/components/Entity/EntityDetailsDrawer/HeaderImageThumbnail';
import { TitleProperties } from 'src/components/Entity/EntityDetailsDrawer/TitleProperties';
import { HeaderTextColumn } from 'src/components/Entity/EntityDetailsDrawer/HeaderTextColumn';
import { LocalizedPreviewButton } from 'src/components/common/LocalizedPreview/LocalizedPreviewButton';
import { useTrackEvent } from 'src/hooks/useTrackEvent';

export interface PropTypes extends Omit<ComponentProps<typeof DetailsDrawer>, 'id' | 'onDelete' | 'onSave'> {
    id: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: 'fit-content',
    },
    container: {
        flex: 1,
        overflow: 'hidden',
        '& > .MuiTabPanel-root': {
            height: '100%',
            paddingRight: 0,
        },
    },
    drawer: {
        width: '75vw',
    },
    media: {
        maxHeight: '100%',
        verticalAlign: 'top',
        objectFit: 'contain',
        filter: 'drop-shadow(7px 7px 10px rgba(0,0,0,0.20))',
    },
    previewUrlWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    tab: {
        minHeight: theme.spacing(12),
        '& > .MuiTab-wrapper': {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            '& > *:first-child': {
                marginBottom: 0,
                paddingRight: theme.spacing(2),
            },
        },
    },
    tabPanel: {
        paddingTop: 0,
    },
}));

export const SingleEntityDetailsDrawer = (props: PropTypes): JSX.Element => {
    const {
        id,
        open,
        onClose,
        ...rest
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const [activeTab, setActiveTab] = useState('0');
    const [categories, setCategories] = useState<Models.ContentStoreApi.V3.SourcedCategory[]>([]);
    const [cultures, setCultures] = useState<Models.ContentStoreApi.V3.Entity.Culture[]>([]);
    const [keywords, setKeywords] = useState<Models.ContentStoreApi.V3.SourcedKeyword[]>([]);
    const [tags, setTags] = useState<Models.ContentStoreApi.V3.SourcedTag[]>([]);
    const [suggestionsError, setSuggestionsError] = useState<Error | null>(null);
    const [indexable, setIndexable] = useState(false);
    const setUpdatedState = useSetRecoilState(drawerUpdatedState);
    const mutation = useMutateEntity(accessToken, id);
    const { trackEvent } = useTrackEvent();

    const keywordSuggestionsPlaceholderData = useMemo(
        () => generateKeywordSuggestionsPlaceholderData(),
        [],
    );

    const categorySuggestionsPlaceholderData = useMemo(
        () => generateCategorySuggestionsPlaceholderData(),
        [],
    );

    const updateState = (
        newCultures?: Models.ContentStoreApi.V3.Entity.Culture[],
        isIndexable?: boolean,
        newCategories: Models.ContentStoreApi.V3.SourcedCategory[] = [],
        newKeywords: Models.ContentStoreApi.V3.SourcedKeyword[] = [],
        newTags: Models.ContentStoreApi.V3.SourcedTag[] = [],
    ): void => {
        setCategories(union(categories, newCategories));
        setKeywords(union(keywords, newKeywords));
        setTags(union(tags, newTags));

        if (newCultures) {
            setCultures(newCultures);
        }

        if (isIndexable !== undefined) {
            setIndexable(isIndexable);
        }
    };

    const {
        data, error, isError, isLoading,
    } = useQueryEntity(accessToken, id, {
        enabled: !!accessToken,
        suspense: false,
        refetchInterval: false,
        retry: 1,
    });

    const {
        data: keywordSuggestions,
        error: keywordSuggestionsError,
    } = useQueryKeywordSuggestions(accessToken, { ids: [id] }, {
        enabled: !!accessToken && !!id && !!data,
        suspense: false,
        refetchInterval: false,
        retry: 0,
        placeholderData: keywordSuggestionsPlaceholderData,
    });

    const {
        data: categorySuggestions,
        error: categorySuggestionsError,
    } = useQueryCategorySuggestions(accessToken, { ids: [id] }, {
        enabled: !!accessToken && !!id && !!data,
        suspense: false,
        refetchInterval: false,
        retry: 0,
        placeholderData: categorySuggestionsPlaceholderData,
    });

    const {
        data: entityProduct,
    } = useQueryEntityProducts(accessToken, { id }, {
        enabled: !!accessToken && !!id && !!data,
        suspense: false,
        refetchInterval: false,
    });

    useEffect(() => {
        if (data) {
            updateState(
                data?.cultures || [],
                data?.indexable || false,
                Object.values(data._embedded?.categories || {}),
                Object.values(data._embedded?.keywords || {}),
                Object.values(data._embedded?.tags || {}),
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (categorySuggestionsError) {
            setSuggestionsError(categorySuggestionsError);
        }
    }, [categorySuggestionsError]);

    useEffect(() => {
        if (keywordSuggestionsError) {
            setSuggestionsError(keywordSuggestionsError);
        }
    }, [keywordSuggestionsError]);

    const categoriesWithSuggestions = useMemo(
        () => (categorySuggestions?.length ? union(categories, categorySuggestions) : categories),
        [categorySuggestions, categories],
    );

    const keywordsWithSuggestions = useMemo(
        () => (keywordSuggestions?.length ? union(keywords, keywordSuggestions) : keywords),
        [keywordSuggestions, keywords],
    );

    const handleChangeFactory = <T extends Models.V3.SourcedBase>(
        setState: Dispatch<SetStateAction<T[]>>,
    ) => (
            event: SyntheticEvent<Element, Event>,
            value: T[] | T,
        ): void => {
            event.preventDefault();

            setState(value as T[]);
            setUpdatedState(true);
        };

    const handleCategoryClick = (event: SyntheticEvent<Element, Event>): void => {
        event.preventDefault();

        setActiveTab('2');
    };

    const handleCultureChange = (value: Models.ContentStoreApi.V3.Entity.Culture[]): void => {
        setCultures(value);
        setUpdatedState(true);
    };

    const handleTabChange = (
        event: SyntheticEvent<Element, Event>,
        newTabIndex: string,
    ): void => {
        event.preventDefault();

        setActiveTab(newTabIndex);
    };

    const handleTabClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        trackEvent({
            eventName: UserEvent.SingleEntityTabSelection,
            eventValue:
                (event.target as HTMLElement).childNodes[1]?.nodeValue ?? undefined,
        });
    };

    const toggleIndexable = (event: FormEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (!indexable) {
            setTags(tags.filter((t) => !(t.label === NEW_TAG_LABEL && t.source === DataSource.Saved)));
        }
        setIndexable(!indexable);
        setUpdatedState(true);
    };

    const handleSave = async (): Promise<void> => {
        const updatedEntity: Models.ContentStoreApi.V3.UpdateEntity = {
            ...data,
            cultures,
            categories: categories
                .filter(saveable)
                .map((x) => toSourcedBase(x)),
            keywords: keywords
                .filter(saveable)
                .map((x) => toSourcedBase(x)),
            tags: tags
                .filter(saveable)
                .map((x) => toSourcedBase(x)),
            indexable,
        };

        trackEvent({ eventName: UserEvent.SingleEditSaved });

        await mutation.mutateAsync({ accessToken, id, entity: updatedEntity }, {
            onSuccess: (entity) => {
                if (entity) {
                    updateState(
                        entity?.cultures || [],
                        entity?.indexable || false,
                        Object.values(entity._embedded?.categories || {}),
                        Object.values(entity._embedded?.keywords || {}),
                        Object.values(entity._embedded?.tags || {}),
                    );
                }
            },
        });
        setUpdatedState(false);
    };

    const handleSnackBarClose = (): void => {
        setSuggestionsError(null);
    };

    const handleAddAllSuggestedKeywords = (): void => {
        const keywordsWithSavedSuggestions = keywordsWithSuggestions.map((val) => ({
            ...val,
            source: val.source === DataSource.Suggested ? DataSource.Selected : val.source,
        }));

        setKeywords(keywordsWithSavedSuggestions);
    };

    const handleAddAllSuggestedCategories = (): void => {
        const categoriesWithSavedSuggestions = categoriesWithSuggestions.map((val) => ({
            ...val,
            source: val.source === DataSource.Suggested ? DataSource.Selected : val.source,
        }));

        setCategories(categoriesWithSavedSuggestions);
    };

    return (
        <DetailsDrawer
            open={open}
            PaperProps={{
                className: classes.drawer,
            }}
            onClose={onClose}
            onSave={handleSave}
            {...rest}
        >
            <HeaderImageThumbnail
                textColumn={(
                    <HeaderTextColumn
                        actions={(
                            <>
                                { data && (
                                    <LocalizedPreviewButton
                                        entity={data}
                                    />
                                )}
                                <ApproveButton
                                    approved={indexable}
                                    onChange={toggleIndexable}
                                />
                            </>
                        )}
                        title="Design Details"
                    >
                        {(!isLoading && !!data) && (
                        <TitleProperties data={data} />
                        )}
                    </HeaderTextColumn>
                )}
            >
                <div className={classes.previewUrlWrapper}>
                    {(!isLoading && !!data) && (
                        <MagnifiedImage
                            imageProps={{
                                alt: `${id} preview`,
                                className: clsx(classes.media),
                                title: `${id} preview`,
                            }}
                            magnifiedImageProps={{
                                height: 960,
                                width: 960,
                            }}
                            magnifyContainerProps={{
                                scale: 0.75,
                            }}
                            previewUrls={data.previewUrls}
                            style={{
                                maxHeight: '100%',
                                minHeight: theme.spacing(25),
                                width: '100%',
                                alignItems: 'flex-start',
                            }}
                        />
                    )}
                </div>
            </HeaderImageThumbnail>
            <TabContext value={activeTab}>
                <TabList
                    boxProps={{
                        sx: { borderBottom: 1, borderColor: 'divider' },
                    }}
                    value={activeTab}
                    variant="scrollable"
                    onChange={handleTabChange}
                    onClick={handleTabClick}
                >
                    <Tab
                        icon={<Settings />}
                        label="Tagging"
                        value="0"
                    />
                    <Tab
                        icon={<List />}
                        label="Properties"
                        value="1"
                    />
                    <Tab
                        icon={<Category />}
                        label="Categories"
                        value="2"
                    />
                    <Tab
                        icon={<Language />}
                        label="Cultures"
                        value="3"
                    />
                    <Tab
                        icon={<Attachment />}
                        label="Product Specifications"
                        value="4"
                    />
                </TabList>
                <section className={classes.container}>
                    {isLoading && (<LoadingGrid />)}
                    {isError && (
                        <ErrorGrid
                            error={error}
                            title="Failed to get design details"
                        />
                    )}
                    {(!isLoading && !data) && (<EntityDetailsEmptyState />)}
                    {(!isLoading && !!data) && (
                        <>
                            {/* @ts-ignore */}
                            <TabPanel className={classes.tabPanel} index={0} value="0">
                                <TaggingPanel>
                                    <KeywordsForm
                                        data={keywordsWithSuggestions}
                                        onAddAllSuggestedKeywords={handleAddAllSuggestedKeywords}
                                        onChange={handleChangeFactory(setKeywords)}
                                    />
                                    <TagsForm
                                        data={tags}
                                        onChange={handleChangeFactory(setTags)}
                                    />
                                    <CategoriesForm
                                        data={categoriesWithSuggestions}
                                        onAddAllSuggestedCategories={handleAddAllSuggestedCategories}
                                        onChange={handleChangeFactory(setCategories)}
                                        onClick={handleCategoryClick}
                                    />
                                </TaggingPanel>
                            </TabPanel>
                            {/* @ts-ignore */}
                            <TabPanel index={1} value="1">
                                <PropertiesPanel data={data} />
                            </TabPanel>
                            {/* @ts-ignore */}
                            <TabPanel index={2} value="2">
                                <TaxonomyDiscovery
                                    ducIds={data.ducIds}
                                    entityId={[data.id]}
                                    value={categories}
                                    onChange={handleChangeFactory(setCategories)}
                                />
                            </TabPanel>
                            {/* @ts-ignore */}
                            <TabPanel className={classes.tabPanel} index={3} value="3">
                                <TaggingPanel>
                                    <CulturesForm
                                        values={cultures}
                                        onChange={handleCultureChange}
                                    />
                                </TaggingPanel>
                            </TabPanel>
                            {/* @ts-ignore */}
                            <TabPanel index={4} value="4">
                                <ProductSpecificationsPanel data={entityProduct?.results ?? []} entityId={id} />
                            </TabPanel>
                        </>
                    )}
                </section>
            </TabContext>
            <Snackbar
                autoHideDuration={SNACKBAR_TIMEOUT}
                open={!!suggestionsError}
                onClose={handleSnackBarClose}
            >
                <Alert severity="error" onClose={handleSnackBarClose}>
                    {`Failed to load suggestions: ${suggestionsError?.message}`}
                </Alert>
            </Snackbar>
        </DetailsDrawer>
    );
};
