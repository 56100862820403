import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { deleteTaxonomy } from 'src/mutations/deleteTaxonomy';
import { QUERY_KEY } from 'src/queries/queryTaxonomies';

export const useDeleteTaxonomy = (): UseMutationResult<void, Error, App.Taxonomy.DeleteMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, App.Taxonomy.DeleteMutationVariables>(
        deleteTaxonomy,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
                setTimeout(() => { queryClient.refetchQueries([QUERY_KEY]); }, 1000);
            },
            useErrorBoundary: true,
        },
    );
};
