import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { HTMLProps, SyntheticEvent } from 'react';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { SearchPanels } from 'src/components/common/TaxonomyDiscovery/SearchPanels';

type Category = Models.ContentStoreApi.V3.SourcedCategory;

export interface PropTypes<T extends Category> extends Omit<HTMLProps<HTMLDivElement>, 'onChange' | 'value'> {
    entityId: string[];
    ducIds?: string[];
    onChange: (event: SyntheticEvent<Element, Event>, value: Category[]) => void;
    value?: T[];
}

const useStyles = makeStyles((theme: Theme) => ({
    fullHeight: {
        height: '100%',
        overflow: 'hidden',
    },
    grid: {
        gridTemplateAreas: '"."',
        height: '100%',
    },
    tab: {
        minWidth: theme.spacing(11),
    },
}));

export const TaxonomyDiscovery = <T extends Category>(props: PropTypes<T>): JSX.Element => {
    const {
        className,
        ducIds,
        value = [],
        entityId,
        onChange,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <CSSGrid className={clsx(classes.grid, className)} {...rest}>
            <div className={classes.fullHeight}>
                <SearchPanels selectable ducIds={ducIds} entityId={entityId} value={value} onChange={onChange} />
            </div>
        </CSSGrid>
    );
};
