import { HTMLProps } from 'react';

export const ErrorImage = (props: HTMLProps<HTMLImageElement>): JSX.Element => (
    <img
        {...props}
        alt="failed to load"
        crossOrigin="anonymous"
        src="/assets/images/error-image.png"
    />
);
