import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

class ContentStoreCulturesApi extends AbstractService implements Services.ContentStoreApi.Cultures {
    public async getCultures(
        bearerToken: string | undefined | false,
    ): Promise<Models.ContentStoreApi.V3.Culture[]> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getCultures. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/cultures`;
        const searchParams = qs.stringify({
            limit: 1000,
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentStoreApi.V3.Culture>>();

            return json.results;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch cultures',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }
}

export const ContentStoreCulturesService = new ContentStoreCulturesApi(config.services.contentStoreApi);
