import { QueryFunctionContext } from 'react-query';

import { ContentStoreTaxonomyNodesService } from 'src/services/TaxonomyNodes';
import { DEFAULT_LIMIT } from 'src/constants';

export const QUERY_KEY = 'categoryTaxonomyTree';

export const queryTaxonomyTree = (
    context: QueryFunctionContext<App.TaxonomyNodes.PagedQueryKey, App.TaxonomyNodes.PageParam>,
): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.TaxonomyNode>> => {
    const { pageParam: offset, queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentStoreTaxonomyNodesService.getTaxonomyTree(
        accessToken,
        search?.parentNodeIds,
        search?.taxonomyNodeIds,
        search?.categoryIds,
        search?.categoryKeys,
        search?.monolithKeys,
        search?.culture,
        search?.maxDepth,
        offset,
        DEFAULT_LIMIT,
    );
};
