import { Link } from 'react-router-dom';
import {
    makeStyles,
    Theme,
    Grid,
    Tooltip,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';

interface PropTypes {
    href?: string;
    icon: JSX.Element;
    target?: string;
    text: React.ReactNode;
    to?: string;
    tooltip: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        color: theme.palette.secondary.dark,
        textDecoration: 'none',
        width: '100%',
    },
    iconContainer: {
        alignItems: 'center',
        color: 'inherit',
        height: '100%',
        minWidth: theme.spacing(12),
    },
}));

export const NavLink = (props: PropTypes): JSX.Element => {
    const {
        href,
        icon,
        target,
        text,
        to,
        tooltip,
    } = props;
    const classes = useStyles();

    const renderContent = (): JSX.Element => (
        <Grid container direction="row" wrap="nowrap">
            <Grid item>
                <ListItemIcon className={classes.iconContainer}>
                    {icon}
                </ListItemIcon>
            </Grid>
            <Grid item>
                <ListItemText>
                    {text}
                </ListItemText>
            </Grid>
        </Grid>
    );

    return (
        <Tooltip enterDelay={500} title={tooltip}>
            <>
                {href && (
                    <a className={classes.link} href={href} target={target}>
                        {renderContent()}
                    </a>
                )}
                {to && (
                    <Link className={classes.link} to={to}>
                        {renderContent()}
                    </Link>
                )}
            </>
        </Tooltip>
    );
};
