import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import {
    queryCategoryMonolithKeys,
    QUERY_KEY,
} from 'src/queries/queryCategoryMonolithKeys';

type CategoryMonolithKey = Models.ContentStoreApi.V3.CategoryMonolithKey;

export const useQueryCategoryMonolithKeys = (
    accessToken: string | undefined | false,
    id: string,
    options?: UseQueryOptions<CategoryMonolithKey[], Error, CategoryMonolithKey[], App.CategoryMonolithKey.QueryKey>,
): UseQueryResult<CategoryMonolithKey[], Error> => useQuery<
CategoryMonolithKey[],
Error,
CategoryMonolithKey[],
App.Category.QueryKey
>(
    [QUERY_KEY, accessToken, id],
    queryCategoryMonolithKeys,
    options,
);
