import {
    Grid, TextField,
} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { ChangeEvent, ComponentProps } from 'react';

import { ClearableTextField } from 'src/components/common/inputs/ClearableTextField';
import { TaxonomyIntent } from 'src/constants';

export interface PropTypes extends Omit<ComponentProps<typeof Grid>, 'id' | 'onChange'> {
    id: string | null;
    taxonomyName: string;
    intent?: string;
    onNameChange: (taxonomyName: string) => void;
    onIntentChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const TaxonomyDetailsForm = (props: PropTypes): JSX.Element => {
    const {
        id,
        taxonomyName,
        intent,
        onNameChange,
        onIntentChange,
        ...rest
    } = props;

    return (
        <Grid container direction="column" spacing={6} {...rest}>
            <Grid item>
                <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: id !== null }}
                    label="ID"
                    size="small"
                    value={id}
                />
            </Grid>
            <Grid container item spacing={4}>
                <Grid item xs>
                    <ClearableTextField
                        fullWidth
                        required
                        label="Taxonomy Name"
                        size="small"
                        value={taxonomyName}
                        onChange={onNameChange}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    required
                    select
                    helperText="The intent of the taxonomy"
                    id="taxonomy-intent-select"
                    key={intent}
                    label="Intent"
                    size="small"
                    value={intent}
                    variant="outlined"
                    onChange={onIntentChange}
                >
                    {Object.values(TaxonomyIntent).map((i) => (
                        <MenuItem key={i.toString()} value={i.toString()}>
                            {i.toString()}
                        </MenuItem>
                    ))}
                </TextField>

            </Grid>
        </Grid>
    );
};
