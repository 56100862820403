import { QueryFunctionContext } from 'react-query';

import { ContentStoreTaxonomiesService } from 'src/services/Taxonomies';
import { DEFAULT_TAXONOMY_LIMIT } from 'src/constants';

export const QUERY_KEY = 'taxonomies';

export const queryTaxonomies = (
    context: QueryFunctionContext<App.Taxonomies.PagedQueryKey, App.Taxonomies.PageParam>,
): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.Taxonomy>> => {
    const { pageParam: offset, queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentStoreTaxonomiesService.getTaxonomies(
        accessToken,
        search?.culture,
        search?.ids,
        search?.taxonomyNodeIds,
        search?.names,
        search?.taxonomyIntents,
        search?.maxDepth,
        search?.offset || offset,
        search?.limit || DEFAULT_TAXONOMY_LIMIT,
    );
};
