import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core';
import { MouseEvent } from 'react';
import { Add, ClearAll } from '@material-ui/icons';

import { CSSGrid } from 'src/components/common/CSSGrid';

export interface PropTypes extends Omit<ButtonProps, 'onClick' | 'onChange'> {
    cultures: Models.ContentStoreApi.V3.Entity.Culture[];
    onChange: (value: Models.ContentStoreApi.V3.Entity.Culture[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    disableAll: {
        borderColor: theme.palette.warning.main,
        color: theme.palette.warning.main,
    },
    enableAll: {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main,
    },
}));

export const ToggleAllCultures = (props: PropTypes): JSX.Element => {
    const {
        cultures,
        onChange,
        ...rest
    } = props;
    const classes = useStyles();

    const handleClick = (toEnable: boolean) => (event: MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();

        const updatedCultures = cultures.map((culture: Models.ContentStoreApi.V3.Entity.BulkCulture) => ({
            ...culture,
            userEnabled: toEnable,
            changedEnabledValue: true,
        }));

        onChange(updatedCultures);
    };

    return (
        <CSSGrid gap={3} gridTemplateAreas={'". ."'}>
            <Button
                className={classes.disableAll}
                startIcon={<ClearAll />}
                variant="outlined"
                onClick={handleClick(false)}
                {...rest}
            >
                Disable All
            </Button>
            <Button
                className={classes.enableAll}
                startIcon={<Add />}
                variant="outlined"
                onClick={handleClick(true)}
                {...rest}
            >
                Enable All
            </Button>
        </CSSGrid>
    );
};
