import { createFacet } from 'src/components/common/Search/FacetedSearch';
import { isAlphaNumeric, isGuid } from 'src/utils';

export const CATEGORY_NAME_FACET: FacetedSearch.Facet = createFacet({
    name: 'Name',
    key: 'internalName',
    type: 'single',
    isCompatible: async (val: string): Promise<string[] | false> => (isAlphaNumeric(val) ? [val] : false),
});

export const CATEGORY_IDS_FACET: FacetedSearch.Facet = createFacet({
    name: 'Id',
    key: 'ids',
    type: 'multiple',
    isCompatible: async (val: string): Promise<string[] | false> => (isGuid(val) ? [val] : false),
});

export const CATEGORY_KEYS_FACET: FacetedSearch.Facet = createFacet({
    name: 'Key (internal)',
    key: 'keys',
    type: 'multiple',
    isCompatible: async (val: string): Promise<string[] | false> => (!Number.isNaN(val) ? [val] : false),
});

export const FACETS: FacetedSearch.Facet[] = [
    CATEGORY_NAME_FACET,
    CATEGORY_IDS_FACET,
    CATEGORY_KEYS_FACET,
];
