import { useMutation, UseMutationResult } from 'react-query';

import { mutateEntityRankings } from 'src/mutations/mutateEntityRankings';

export type EntityRankingById = Record<string, Models.ContentStoreApi.V3.EntityRanking>;
export type EntityRankings = Models.ContentStoreApi.V3.EntityRanking[];

export const useMutateEntityRankings = (): UseMutationResult<EntityRankings, Error, App.EntityRankings.MutationVariables> => useMutation<
EntityRankings, Error, App.EntityRankings.MutationVariables>(
    mutateEntityRankings,
    {
        useErrorBoundary: true,
    },
);
