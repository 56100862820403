import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryTaxonomies, QUERY_KEY } from 'src/queries/queryTaxonomies';

type PagedTaxonomies = Models.V3.PageResult<Models.ContentStoreApi.V3.Taxonomy>;
type PagedQueryKey = App.Taxonomies.PagedQueryKey;

export const useQueryTaxonomies = (
    accessToken: string | undefined | false,
    search: App.Taxonomies.PagedSearch,
    options?: UseQueryOptions<PagedTaxonomies, Error, PagedTaxonomies, PagedQueryKey>,
): UseQueryResult<PagedTaxonomies, Error> => useQuery<PagedTaxonomies, Error, PagedTaxonomies, PagedQueryKey>(
    [QUERY_KEY, accessToken, search],
    queryTaxonomies,
    options,
);
