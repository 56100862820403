import {
    HTMLProps, SyntheticEvent, useEffect, useState,
} from 'react';
import {
    makeStyles, Theme,
} from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { InfiniteTaxonomyTreeView } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/InfiniteTaxonomyTreeView';
import { useRecoilValue } from 'recoil';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { Loader } from 'src/components/common/Loader';
import { queryTaxonomies, QUERY_KEY } from 'src/queries/queryTaxonomies';
import { useInfiniteScrollQuery } from 'src/hooks/useInfiniteScrollQuery';
import { MAX_RETRY_TIMEOUT, MIN_RETRY_TIMEOUT } from 'src/constants';

type Category = Models.ContentStoreApi.V3.SourcedCategory;
type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;
type ValueMap<T extends Category> = Record<string, T>;
type PagedTaxonomyKey = App.Taxonomies.PagedQueryKey;
type Taxonomy = Models.ContentStoreApi.V3.Taxonomy;

export interface DesignGuidanceTaxonomyPropTypes<T extends Category> extends Omit<HTMLProps<HTMLDivElement>, 'value' | 'onChange' | 'draggable'> {
    selectable?: boolean,
    draggable?: string,
    droppable?: string[],
    onChange: (event: SyntheticEvent<Element, Event>, value: Category[]) => void;
    taxonomyIds: string[];
    valueMap?: ValueMap<T>;
}

export const useStyles = makeStyles((theme: Theme) => ({
    loader: {
        marginTop: theme.spacing(10),
    },
}));

export const DesignGuidanceTaxonomy = <T extends Category>(props: DesignGuidanceTaxonomyPropTypes<T>): JSX.Element => {
    const {
        selectable = false,
        onChange,
        valueMap,
        draggable,
        droppable,
        taxonomyIds,
    } = props;
    const classes = useStyles();
    const [taxonomyNodes, setTaxonomyNodes] = useState<TaxonomyNode[]>([]);
    const accessToken = useRecoilValue(isAuthorizedQuery);

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isSuccess,
    } = useInfiniteScrollQuery<Taxonomy, Error, PagedTaxonomyKey>(
        [`${QUERY_KEY}_taxonomy_panel`, accessToken, {
            culture: 'en-us', // TODO remove
            ids: taxonomyIds,
            maxDepth: 2,
        }],
        queryTaxonomies,
        {
            suspense: false,
            retry: 3,
            retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : MIN_RETRY_TIMEOUT, MAX_RETRY_TIMEOUT),
        },
    );

    useEffect(() => {
        if (isSuccess && data && !!data.length) {
            setTaxonomyNodes(data.map((t) => t.taxonomyNode));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, data?.length]);

    const loadMore = async (): Promise<void> => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    return (
        <>
            {isLoading && (<Loader className={classes.loader} />)}
            {taxonomyNodes && (
                <DndProvider backend={HTML5Backend}>
                    <InfiniteTaxonomyTreeView
                        draggable={draggable}
                        droppable={droppable}
                        hasMore={!!hasNextPage}
                        loadMore={loadMore}
                        nodes={taxonomyNodes}
                        selectable={selectable}
                        setData={setTaxonomyNodes}
                        valueMap={valueMap}
                        onChange={onChange}
                    />
                </DndProvider>
            )}
        </>
    );
};
