import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import {
    IconButton,
    makeStyles,
    Theme,
    useTheme,
} from '@material-ui/core';
import { ComponentProps, MouseEventHandler } from 'react';
import {
    ChevronLeft, ChevronRight, Fullscreen, FullscreenExit,
} from '@material-ui/icons';
import clsx from 'clsx';
import { MagnifiedImage } from 'src/components/common/MagnifiedImage';

export interface PropTypes extends ComponentProps<typeof ImageGallery> {
    previews: ReadonlyArray<ReactImageGalleryItem>;
}

const useStyles = makeStyles((theme: Theme) => ({
    fullScreenIcon: {
        color: theme.palette.common.black,
        filter: 'none',
        padding: theme.spacing(1),
        right: theme.spacing(10),
        '& svg': {
            height: '1.25em',
            width: '1.25em',
        },
    },
    bulletButton: {
        '&.image-gallery-bullet': {
            color: theme.palette.common.black,
            border: `1px solid ${theme.palette.common.black}`,
            boxShadow: 'none',

            '&.active': {
                background: theme.palette.common.black,
            },
        },
    },
    media: {
        maxHeight: theme.spacing(62),
        objectFit: 'contain',
        filter: 'drop-shadow(4px 4px 4px rgba(0,0,0,0.20))',
        padding: `0 ${theme.spacing(10)} ${theme.spacing(2)}`,
        alignSelf: 'center',
    },
    navIcon: {
        padding: 'unset',
        color: theme.palette.common.black,
    },
    galleryContainer: {
        alignItems: 'center',
        display: 'flex',
        minHeight: theme.spacing(25),
        padding: `0 ${theme.spacing(1)}`,
    },
}));

export const PreviewGallery = (props: ComponentProps<typeof ImageGallery>): JSX.Element => {
    const { items } = props;
    const classes = useStyles();
    const theme = useTheme();

    items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.bulletClass = classes.bulletButton;
    });

    const renderFullscreenButton = (
        onClick: MouseEventHandler<HTMLElement>,
        isFullscreen: boolean,
    ): JSX.Element => (
        <IconButton
            className={clsx('image-gallery-icon', 'image-gallery-fullscreen-button', classes.fullScreenIcon)}
            onClick={onClick}
        >
            {!isFullscreen && (<Fullscreen />)}
            {isFullscreen && (<FullscreenExit />)}
        </IconButton>
    );

    const renderItem = (item: ReactImageGalleryItem): JSX.Element => (
        <MagnifiedImage
            imageProps={{
                alt: item.originalAlt,
                className: clsx(classes.media),
                sizes: item.sizes,
                src: item.original,
                srcSet: item.srcSet,
                title: item.originalTitle,
            }}
            magnifiedImageProps={{
                src: item.originalTitle || '',
                height: 960,
                width: 960,
            }}
            style={{
                // setting this minHeight prevents zoom icon over-lapping carousel arrows
                minHeight: theme.spacing(31),
                maxHeight: '100%',
                display: 'flex',
            }}
        />
    );

    const renderLeftNav = (
        onClick: MouseEventHandler<HTMLElement>,
        disabled: boolean,
    ): JSX.Element => (
        <IconButton
            aria-label="Previous Slide"
            className={clsx('image-gallery-icon', 'image-gallery-left-nav', classes.navIcon)}
            disabled={disabled}
            onClick={onClick}
        >
            <ChevronLeft />
        </IconButton>
    );

    const renderRightNav = (
        onClick: MouseEventHandler<HTMLElement>,
        disabled: boolean,
    ): JSX.Element => (
        <IconButton
            aria-label="Next Slide"
            className={clsx('image-gallery-icon', 'image-gallery-right-nav', classes.navIcon)}
            disabled={disabled}
            onClick={onClick}
        >
            <ChevronRight />
        </IconButton>
    );

    return (
        <ImageGallery
            {...props}
            additionalClass={classes.galleryContainer}
            renderFullscreenButton={renderFullscreenButton}
            renderItem={renderItem}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={false}
        />
    );
};
