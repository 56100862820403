import { ContentStoreEntitiesService } from 'src/services/Entities';
import { prepareEntityForEditing } from 'src/utils/prepareEntityForEditting';

export const mutateEntity = async (
    {
        accessToken,
        id,
        entity,
        signal,
    }: App.Entity.MutationVariables,
): Promise<Models.ContentStoreApi.V3.Entity> => prepareEntityForEditing(await ContentStoreEntitiesService.updateEntity(
    accessToken,
    id,
    entity,
    signal,
));
