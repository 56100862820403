import clsx from 'clsx';
import {
    Button, makeStyles, Theme, ToggleButton, Tooltip,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { ComponentProps } from 'react';
import { useTrackEvent } from 'src/hooks/useTrackEvent';
import { UserEvent } from 'src/constants';

export interface PropTypes extends Omit<ComponentProps<typeof ToggleButton>, 'value'> {
    approved: boolean;
    tooltipProps?: Omit<ComponentProps<typeof Tooltip>, 'children'>;
    buttonText?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    approveButton: {
        borderRadius: theme.spacing(15),
        whiteSpace: 'nowrap',
        padding: theme.spacing(2.5),
        height: 'min-content',
    },
    success: {
        color: theme.palette.success.main,
    },
}));

export const ApproveButton = (props: PropTypes): JSX.Element => {
    const {
        className,
        approved,
        tooltipProps = {
            title: approved ? 'Unapprove this design for gallery usage' : 'Approve this design for gallery usage',
        },
        buttonText = approved ? 'Approved' : 'Approve',
        ...rest
    } = props;
    const classes = useStyles();
    const { trackEvent } = useTrackEvent();

    const handleApproveToggle = (): void => {
        trackEvent({ eventName: UserEvent.ApproveToggle, eventValue: approved ? 'Unapproved' : 'Approved' });
    };

    return (
        <Tooltip {...tooltipProps}>
            <ToggleButton
                {...rest}
                className={clsx(classes.approveButton, className)}
                color={approved ? 'primary' : 'secondary'}
                component={Button}
                selected={approved}
                size="medium"
                value="indexable"
                variant="outlined"
                onClick={handleApproveToggle}
            >
                <Check className={clsx({ [classes.success]: approved })} />
                <span>{buttonText}</span>
            </ToggleButton>
        </Tooltip>
    );
};
