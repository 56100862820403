import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryEntities, QUERY_KEY } from 'src/queries/queryEntities';

type PagedEntities = Models.V3.PageResult<Models.ContentStoreApi.V3.Entity>;

export const useQueryEntities = (
    accessToken: string | undefined | false,
    search: App.Entities.PagedSearch,
    options?: UseQueryOptions<PagedEntities, Error, PagedEntities, App.Entities.PagedQueryKey>,
    isEditing = true,
): UseQueryResult<PagedEntities, Error> => useQuery<PagedEntities, Error, PagedEntities, App.Entities.PagedQueryKey>(
    [QUERY_KEY, accessToken, search, isEditing],
    queryEntities,
    options,
);
