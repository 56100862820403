import clsx from 'clsx';
import {
    AppBar,
    makeStyles,
    Theme,
    Typography,
    Toolbar,
} from '@material-ui/core';
import { ComponentProps } from 'react';
import { CSSGrid } from 'src/components/common/CSSGrid';

const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        width: '100%',
        gridAutoFlow: 'row',
        gridTemplateAreas: '". ."',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
    },
    fontRegular: {
        fontWeight: theme.typography.fontWeightRegular,
    },
    root: {
        gridArea: 'header',
        minHeight: theme.spacing(16),
    },
    spacingLeft: {
        paddingLeft: theme.spacing(6),
    },
}));

export const TitledAppBar = (props: ComponentProps<typeof AppBar>): JSX.Element => {
    const { children, title, ...rest } = props;
    const classes = useStyles();

    return (
        <AppBar color="inherit" position="sticky" {...rest} className={clsx(classes.spacingLeft, classes.root)}>
            <Toolbar>
                <CSSGrid className={classes.grid} gap={3}>
                    <Typography className={classes.fontRegular} variant="h6">
                        {title}
                    </Typography>
                    {children}
                </CSSGrid>
            </Toolbar>
        </AppBar>
    );
};
