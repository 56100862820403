import { styled } from '@mui/material';
import { ComponentProps } from 'react';
import { Outlet } from 'react-router-dom';

import { Main } from 'src/components/common/Main';
import { NavDrawer } from 'src/components/NavDrawer';

const StyledMain = styled(Main)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
}));

const StyledRoot = styled('div')({
    display: 'flex',
    width: '100%',
});

export const Layout = (props: ComponentProps<typeof StyledMain>): JSX.Element => (
    <StyledRoot>
        <NavDrawer />
        <StyledMain {...props}>
            <Outlet />
        </StyledMain>
    </StyledRoot>
);
