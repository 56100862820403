import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { experimentalStyled } from '@material-ui/core';
import { MouseEvent } from 'react';

interface PropTypes extends IconButtonProps {
    expanded: boolean;
    onClick: () => void;
}

const StyledIconButton = experimentalStyled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    color: theme.palette.common.black,
    padding: 0,
    position: 'fixed',
    top: theme.spacing(19),
    zIndex: 200,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

export const ExpandButton = (props: PropTypes): JSX.Element => {
    const {
        onClick,
        expanded,
        ...rest
    } = props;

    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        onClick();
    };

    return (
        <StyledIconButton
            onClick={handleClick}
            {...rest}
        >
            {expanded && (<ChevronLeft color="inherit" />)}
            {!expanded && (<ChevronRight color="inherit" />)}
        </StyledIconButton>
    );
};
