import qs from 'qs';
import { v4 } from 'uuid';
import { Location } from 'history';
import { DELIMITER, END_CREATED_TIME_KEY, START_CREATED_TIME_KEY } from 'src/components/common/Search/FacetedSearch/constants';
import { NavigateFunction } from 'react-router-dom';

type GetNewOrExistingIndex = (facet: FacetedSearch.Facet, value: string) => string;

export function createFacet(facet: Partial<FacetedSearch.Facet>): FacetedSearch.Facet {
    return {
        toString: (): string => (facet.name ? `${facet.name}${DELIMITER} ` : ''),
        formatLabel: (self: FacetedSearch.Facet, value: string) => `${self.toString()}${value}`,
        ...facet,
    } as FacetedSearch.Facet;
}

export function findFacetByName(
    name: string,
    facets: Record<string, FacetedSearch.Facet>,
): [FacetedSearch.Facet | undefined, string] {
    const delimiterIndex = name.indexOf(DELIMITER);

    return delimiterIndex > 0 ? [facets[name.slice(0, delimiterIndex)], name.slice(delimiterIndex + 1)] : [facets[name], ''];
}

export function findFacetByKey(key: string, facets: FacetedSearch.Facet[]): FacetedSearch.Facet | undefined {
    return facets.find((f) => f.key === key);
}

export const createIndex = (): string => v4();

export const splitAndTrimToArray = (value: string, seperator: string): string[] => value.split(seperator).map((s) => s.trim()).filter((v) => v);

export const insertNewFacetRecord = (
    source: FacetedSearch.FacetedSearchType,
    record: FacetedSearch.FacetedSearchRecord,
): FacetedSearch.FacetedSearchType => {
    const newSource = { ...source };

    let newRecord: FacetedSearch.FacetedSearchRecord = record;

    if (!!record.value && !!record.facet?.bulkSearchSeperator && !record.bulkValue) {
        const splitArr = splitAndTrimToArray(record.value, record.facet?.bulkSearchSeperator);

        if (splitArr.length > 1) {
            newRecord = {
                ...record,
                value: undefined,
                bulkValue: splitArr,
            };
        }
    }

    newSource[record.id] = { ...newRecord };

    return newSource;
};

export function formatSearch(search: FacetedSearch.FacetedSearchType): FacetedSearch.FacetedSearchResult {
    return Object.values(search).reduce((accum, record) => {
        const {
            facet, value, bulkValue, productOptionsValue,
        } = record;

        if (!facet) {
            return accum;
        }

        const { key, type } = facet;

        if (productOptionsValue) {
            return {
                ...accum,
                [key]: Object.entries(productOptionsValue || {}).reduce((optionsAccum, [optionKey, optionValue]) => {
                    // eslint-disable-next-line no-param-reassign
                    optionsAccum[optionKey as string] = Array.isArray(optionValue)
                        ? Array.from(optionValue) : [optionValue];

                    return optionsAccum;
                }, {} as Record<string, string[]>),
            };
        }

        if (bulkValue) {
            return {
                ...accum,
                [key]: bulkValue,
            };
        }

        const cleanValue = value?.trim();

        if (!cleanValue) {
            return accum;
        }

        if (type === 'multiple') {
            if (!(key in accum)) {
                return {
                    ...accum,
                    [key]: [cleanValue],
                };
            }

            (accum[key] as string[]).push(cleanValue);

            return accum;
        }

        return {
            ...accum,
            [key]: cleanValue,
        };
    }, {} as FacetedSearch.FacetedSearchResult);
}

export function createFacetedRecord(
    facet?: FacetedSearch.Facet,
    value?: string,
): FacetedSearch.FacetedSearchRecord {
    return {
        facet,
        id: createIndex(),
        // @ts-expect-error
        // eslint-disable-next-line no-underscore-dangle
        _value: value && value.trim(),
        get value(): string | undefined {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            return this._value;
        },
        set value(v: string | undefined) {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            this._value = v && v.trimStart();
        },
    };
}

export function createBulkFacetedRecord(
    facet?: FacetedSearch.Facet,
    value?: string[],
): FacetedSearch.FacetedSearchRecord {
    return {
        facet,
        id: createIndex(),
        // @ts-expect-error
        // eslint-disable-next-line no-underscore-dangle
        _bulkValue: value,
        _value: value && JSON.stringify(value),
        get bulkValue(): string[] | undefined {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            return this._bulkValue;
        },
        set bulkValue(v: string[] | undefined) {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            this._bulkValue = v;
        },
        get value(): string | undefined {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            return this._value;
        },
        set value(v: string | undefined) {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            this._value = v && v.trimStart();
        },
    };
}

export function createProductOptionsFacetedRecord(
    facet?: FacetedSearch.Facet,
    value?: App.Entities.ProductOptions,
): FacetedSearch.FacetedSearchRecord {
    return {
        facet,
        id: createIndex(),
        // @ts-expect-error
        // eslint-disable-next-line no-underscore-dangle
        _productOptionsValue: value,
        _value: value && JSON.stringify(value),
        get productOptionsValue(): App.Entities.ProductOptions | undefined {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            return this._productOptionsValue;
        },
        set productOptionsValue(v: App.Entities.ProductOptions | undefined) {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            this._productOptionsValue = v;
        },
        get value(): string | undefined {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            return this._value;
        },
        set value(v: string | undefined) {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            this._value = v && v.trimStart();
        },
    };
}

export function cloneFacetRecord(record: FacetedSearch.FacetedSearchRecord): FacetedSearch.FacetedSearchRecord {
    return {
        ...record,
        get value(): string | undefined {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            return this._value;
        },
        set value(v: string | undefined) {
            // @ts-expect-error
            // eslint-disable-next-line no-underscore-dangle
            this._value = v && v.trimStart();
        },
    };
}

export function createFacetedRecordFromKey(
    key: string,
    value: string | string[] | App.Entities.ProductOptions,
    facets: FacetedSearch.Facet[],
): FacetedSearch.FacetedSearchRecord {
    const facet = findFacetByKey(key, facets);

    if (!facet) {
        throw new Error(`Could not find facet for key ${key}`);
    }

    if (key === 'productOptions') {
        return createProductOptionsFacetedRecord(facet, value as App.Entities.ProductOptions);
    }
    return !Array.isArray(value) ? createFacetedRecord(facet, value as string) : createBulkFacetedRecord(facet, value);
}

export function createDateFacetedRecordFromKey(
    key: string,
    value: Date,
    facets: FacetedSearch.Facet[],
): FacetedSearch.FacetedSearchRecord {
    const facet = findFacetByKey(key, facets);

    if (!facet) {
        throw new Error(`Could not find facet for key ${key}`);
    }

    const newDate = new Date(value);

    newDate.setHours(0, 0, 0, 0);
    const stringifiedDate = `${newDate.getFullYear()}/${newDate.getMonth() + 1}/${newDate.getDate()}`;

    return createFacetedRecordFromKey(key, stringifiedDate, facets);
}

export function removeProductSearchFromCurrentSearch(
    currentSearch: FacetedSearch.FacetedSearchType,
): FacetedSearch.FacetedSearchType {
    const newSearch = { ...currentSearch };

    const productSearchKeyNames: string[] = ['productKey', 'productVersion', 'productOptions'];

    Object.keys(currentSearch).forEach((key) => {
        if (productSearchKeyNames.includes(currentSearch[key].facet?.key ?? '')) {
            delete newSearch[key];
        }
    });

    return newSearch;
}

export function removeCreatedTimeSearchFromCurrentSearch(
    currentSearch: FacetedSearch.FacetedSearchType,
): FacetedSearch.FacetedSearchType {
    const newSearch = { ...currentSearch };

    const createdDateSearchKeyNames: string[] = [START_CREATED_TIME_KEY, END_CREATED_TIME_KEY];

    Object.keys(currentSearch).forEach((key) => {
        if (createdDateSearchKeyNames.includes(currentSearch[key].facet?.key ?? '')) {
            delete newSearch[key];
        }
    });

    return newSearch;
}

export function createProductOptionsFacetedRecordFromKey(
    value: App.Entities.ProductOptions,
    facets: FacetedSearch.Facet[],
): FacetedSearch.FacetedSearchRecord {
    const facet = findFacetByKey('productOptions', facets);

    if (!facet) {
        throw new Error(`Could not find facet for key productOptions`);
    }
    return createProductOptionsFacetedRecord(facet, value);
}

export function createFacetedRecordFromName(
    name: string,
    value: string,
    facets: Record<string, FacetedSearch.Facet>,
): FacetedSearch.FacetedSearchRecord {
    const [facet] = findFacetByName(name, facets);

    if (!facet) {
        throw new Error(`Could not find facet for name ${name}`);
    }

    return createFacetedRecord(facet, value);
}

export const getNewOrExistingIndexFactory = (search: FacetedSearch.FacetedSearchType): GetNewOrExistingIndex => {
    const searchLookup = Object.entries(search);
    const existsInLookup = (key: string, value: string):
    [string, FacetedSearch.FacetedSearchRecord] | undefined => (
        searchLookup.find((entry) => (entry[1].facet?.key === key && entry[1].value === value))
    );

    return (facet: FacetedSearch.Facet, value: string): string => {
        const existing = existsInLookup(facet.key, value);

        return existing ? existing[0] : createIndex();
    };
};

/**
* Check if the facet already exists via 3 cases:
* 1. Facet is a single search either from URL and just typed in (most common case)
* 2. Facet is a bulk search from the URL (bulk search facets have a defined seperator)
* 3. Facet is a bulk search that was just typed in, so it needs to be split into an array of terms
*/
export const insertRecordIfNotExists = (
    key: string,
    value: string | string[] | App.Entities.ProductOptions,
    existingSearchRecords: FacetedSearch.FacetedSearchRecord[],
    facets: FacetedSearch.Facet[],
    accum: FacetedSearch.FacetedSearchType,
): FacetedSearch.FacetedSearchType => {
    const found = !!existingSearchRecords.find((record) => (record.facet?.key === key
        && (
            record.value === value
            || (
                record.facet?.bulkSearchSeperator
                && (
                    (
                        Array.isArray(value)
                        && value.map((t) => t.trim()).every((el) => record.bulkValue?.includes(el))
                    )
                    || (
                        !Array.isArray(value) && splitAndTrimToArray(value as string, record.facet.bulkSearchSeperator)
                            .every((el) => record.bulkValue?.includes(el))
                    )))
            || (typeof value === 'object' && JSON.stringify(record.productOptionsValue) === JSON.stringify(value))
        )));

    if (!found) {
        const newRecord = createFacetedRecordFromKey(key, value, facets);

        return {
            ...accum,
            [newRecord.id]: newRecord,
        };
    }

    return accum;
};

export const parseSearchFromQueryString = (
    search: string,
    facets: FacetedSearch.Facet[],
    existingSearch: FacetedSearch.FacetedSearchType,
): FacetedSearch.FacetedSearchType => {
    const parsedQuery = qs.parse(search.includes('?') ? search.split('?')[1] : search);
    const newSearch = { ...existingSearch };
    const existingSearchRecords = Object.values(existingSearch);

    return Object.entries(parsedQuery).reduce(
        (accum, [key, value]) => {
            if (key === 'page') {
                return {
                    ...accum,
                };
            }
            if (key === 'productOptions') {
                const productOptionsValue: App.Entities.ProductOptions = Object.entries(
                    value as App.Entities.ProductOptions,
                ).reduce((accum2, [key2, val]) => ({
                    ...accum2,
                    [key2]: Array.isArray(val)
                        ? Array.from(val) : [val],
                }), {} as App.Entities.ProductOptions);

                return insertRecordIfNotExists(
                    key,
                    productOptionsValue || undefined,
                    existingSearchRecords,
                    facets,
                    accum,
                );
            }
            if (Array.isArray(value)) {
                const facetFound = findFacetByKey(key, facets);

                if (facetFound?.bulkSearchSeperator) {
                    return insertRecordIfNotExists(key, value as string[], existingSearchRecords, facets, accum);
                }

                return (value as string[]).reduce((accum1, v) => insertRecordIfNotExists(
                    key,
                    v,
                    existingSearchRecords,
                    facets,
                    accum1,
                ), accum);
            }

            return insertRecordIfNotExists(key, value as string, existingSearchRecords, facets, accum);
        },

        newSearch,
    );
};

export const triggerSubmit = (
    search: Record<string, FacetedSearch.FacetedSearchRecord>,
    navigate: NavigateFunction,
    location: Location,
    onSubmit?: (newSearch: FacetedSearch.FacetedSearchResult) => void,
    page?: string,
): void => {
    if (onSubmit) {
        const formattedSearch = formatSearch(search);

        navigate({
            ...location,
            search: qs.stringify({
                ...formattedSearch,
                page,
            }, { arrayFormat: 'repeat' }),
        });

        onSubmit(formattedSearch);
    }
};

export const createNewRecordFromNameOrValue = (
    facetName: string,
    value: string,
    facets: Record<string, FacetedSearch.Facet>,
): FacetedSearch.FacetedSearchRecord => {
    if (!facetName) {
        const valueArr = value.split(DELIMITER);

        valueArr.forEach((str, index) => { valueArr[index] = str.trim(); });

        // Conditional operator allows for tags with colons to be selected. E.g. 'strategist:First Last'
        return valueArr.length > 2
            ? createFacetedRecordFromName(valueArr[0], `${valueArr[1]}:${valueArr[2]}`, facets)
            : createFacetedRecordFromName(valueArr[0], valueArr[1], facets);
    }

    return createFacetedRecordFromName(facetName, value, facets);
};

export const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
};
