import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryTags, QUERY_KEY } from 'src/queries/queryTags';

type PagedTags = Models.V3.PageResult<Models.ContentStoreApi.V3.SourcedTag>;

export const useQueryTags = (
    accessToken: string | undefined | false,
    search: App.Tags.Search,
    options?: UseQueryOptions<PagedTags, Error, PagedTags, App.Tags.QueryKey>,
): UseQueryResult<PagedTags, Error> => useQuery<PagedTags, Error, PagedTags, App.Tags.QueryKey>(
    [QUERY_KEY, accessToken, search],
    queryTags,
    options,
);
