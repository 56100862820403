import { useState, Suspense } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
    Card, makeStyles, Theme, Link, Tooltip,
} from '@material-ui/core';

import * as routes from 'src/lib/routes';
import { TitledAppBar } from 'src/components/common/TitledAppBar';
import { ForcedRankingEdit } from 'src/components/ForcedRankingEdit/ForcedRankingEdit';
import { deleteRankingStrategy, mutateRankingStrategy, useQueryRankingStrategy } from 'src/mutations/rankingStrategy';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { ActionButtons } from 'src/components/common/ActionButtons';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { ForcedRankingUpload } from 'src/components/ForcedRankingEdit/ForcedRankingUpload';
import { ExistingRankingPreview } from 'src/components/ForcedRankingEdit/ExistingRankingPreview';
import { Button, Typography } from '@mui/material';
import { Upload } from '@material-ui/icons';
import { Splash } from 'src/components/common/Splash';

const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        gridArea: 'footer',
        marginLeft: theme.spacing(-8),
    },
    container: {
        gridArea: 'content',
        gap: theme.spacing(4),
        gridTemplateRows: 'auto auto 1fr',
        marginRight: theme.spacing(7),
    },
    content: {
        gridTemplateColumns: '3fr 1fr',
        gridGap: theme.spacing(4),
        alignItems: 'center',
    },
    title: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(4),
    },
}));

export const ForcedRankingEditPage = (): JSX.Element => {
    const params = useParams();
    const id = parseInt(params.id!, 10);
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const navigate = useNavigate();
    const classes = useStyles();

    const { data } = useQueryRankingStrategy(accessToken, { id });
    const [rankingStrategy, setRankingStrategy] = useState<
    Models.ContentStoreApi.V3.RankingStrategy | undefined>(data);
    const [open, setOpen] = useState<boolean>(false);

    const saveMutation = useMutation<
    Models.ContentStoreApi.V3.RankingStrategy, Error, App.RankingStrategy.MutationVariables>(
        mutateRankingStrategy,
        {
            useErrorBoundary: true,
        },
    );

    const deleteMutation = useMutation<
    void, Error, App.RankingStrategy.DeleteMutationVariables>(
        deleteRankingStrategy,
        {
            useErrorBoundary: true,
        },
    );

    const handleSave = async (): Promise<void> => {
        if (rankingStrategy && accessToken) {
            await saveMutation.mutateAsync({ accessToken, id, rankingStrategy });
        }
    };

    const handleCancel = async (): Promise<void> => {
        setRankingStrategy(data);
    };

    const handleOpen = async (): Promise<void> => {
        setOpen(true);
    };

    const handleClose = async (): Promise<void> => {
        setOpen(false);
    };

    const handleDelete = async (): Promise<void> => {
        if (rankingStrategy && accessToken) {
            await deleteMutation.mutateAsync({ accessToken, id });
        }
        navigate(`/${routes.FORCEDRANKING}`);
    };

    return (
        <>
            <TitledAppBar title="Forced Ranking" />
            <CSSGrid className={classes.container}>
                {rankingStrategy && (
                    <>
                        <CSSGrid className={classes.content}>
                            <Card>
                                <Typography className={classes.title} variant="h6">
                                    Edit Ranking Strategy
                                </Typography>
                                <ForcedRankingEdit
                                    rankingStrategy={rankingStrategy}
                                    setRankingStrategy={setRankingStrategy}
                                />
                                <ActionButtons
                                    deleteModalDescription="Are you sure you want to delete this ranking strategy?"
                                    deleteModalTitle="Delete"
                                    disabled={!rankingStrategy?.strategyName}
                                    onClose={handleCancel}
                                    onDelete={handleDelete}
                                    onSave={handleSave}
                                />
                            </Card>
                            <Button size="large" startIcon={<Upload />} variant="contained" onClick={handleOpen}>
                                Upload New Ranking
                            </Button>
                            <ForcedRankingUpload open={open} rankingStrategy={rankingStrategy} onClose={handleClose} />
                        </CSSGrid>
                        <div>
                            <Tooltip title="View galleries using this strategy in the Gallery Management Tool">
                                <Link href={`https://manage.gallery.vpsvc.com/gallery?rankingStrategy=${rankingStrategy.strategyName}`} rel="noreferrer" target="_blank">
                                    Galleries using this strategy
                                </Link>
                            </Tooltip>
                        </div>
                        <Suspense fallback={Splash}>
                            <ExistingRankingPreview rankingStrategy={rankingStrategy} />
                        </Suspense>
                    </>
                )}
            </CSSGrid>
        </>
    );
};
