import { QueryFunctionContext } from 'react-query';

import { ContentStoreKeywordsService } from 'src/services/Keywords';

export const QUERY_KEY = 'keyword';

export const queryKeyword = (
    context: QueryFunctionContext<App.Keyword.QueryKey>,
): Promise<Models.ContentStoreApi.V3.Keyword> => {
    const { queryKey: [, accessToken, id] } = context;

    return ContentStoreKeywordsService.getKeyword(accessToken, id);
};
