import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryEntity, QUERY_KEY } from 'src/queries/queryEntity';

type Entity = Models.ContentStoreApi.V3.Entity;

export const useQueryEntity = (
    accessToken: string | undefined | false,
    id: string,
    options?: UseQueryOptions<Entity, Error, Entity, App.Entity.QueryKey>,
): UseQueryResult<Entity, Error> => useQuery<Entity, Error, Entity, App.Entity.QueryKey>(
    [QUERY_KEY, accessToken, id],
    queryEntity,
    options,
);
