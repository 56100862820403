import { useRecoilValue } from 'recoil';
import { MouseEvent, useState } from 'react';
import {
    Button, Grid, makeStyles, Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { InfiniteSearchResultsGrid } from 'src/components/common/Search/InfiniteSearchResultsGrid';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { SnackBarErrorBoundary } from 'src/components/common/SnackBarErrorBoundary';
import { useInfiniteScrollQuery } from 'src/hooks/useInfiniteScrollQuery';
import { EmptyState } from 'src/components/common/EmptyState';
import { LoadingGrid } from 'src/components/common/Loader/LoadingGrid';
import { NOT_APPLICABLE } from 'src/constants';
import { queryDesignTaggingGuidances, INFINITE_QUERY_KEY } from 'src/queries/queryDesignTaggingGuidances';
import { useQueryDucs } from 'src/hooks/useQueryDucs';
import { DesignTaggingGuidanceAddDrawer } from 'src/components/DesignTaggingGuidances/DesignTaggingGuidanceAddDrawer';
import { DesignTaggingGuidanceDetailsDrawer } from 'src/components/DesignTaggingGuidances/DesignTaggingGuidanceDetailsDrawer';
import { DesignTaggingGuidanceResult } from 'src/components/DesignTaggingGuidances/DesignTaggingGuidanceResult';

type DesignTaggingGuidance = Models.ContentStoreApi.V3.DesignTaggingGuidance;

interface PropTypes {
    search: App.DesignTaggingGuidances.Search;
}

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        paddingRight: theme.spacing(4),
    },
}));

export const DesignTaggingGuidanceResults = (props: PropTypes): JSX.Element => {
    const { search } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const [selectedId, setSelectedId] = useState<string | null>();
    const [addModalOpen, setAddModalOpen] = useState(false);

    const {
        data: ducData,
    } = useQueryDucs(accessToken, {
        enabled: !!accessToken,
        suspense: false,
        refetchInterval: false,
    });

    const hydratedSearch = { ...search };

    if (search.ducName) {
        const ducIdsForName = ducData?.reduce((accum, dd) => {
            if (dd.name.toLowerCase().indexOf(search.ducName!.toLowerCase()) > -1) {
                return accum.concat(dd.id);
            }

            return accum;
        }, [] as string[]);

        hydratedSearch.ducIds = search.ducIds ? ducIdsForName?.concat(search.ducIds) : ducIdsForName;
    }

    const { data, fetchNextPage, hasNextPage } = useInfiniteScrollQuery<
    DesignTaggingGuidance, Error, App.DesignTaggingGuidances.PagedQueryKey>(
        [INFINITE_QUERY_KEY, accessToken, hydratedSearch],
        queryDesignTaggingGuidances,
        {
            enabled: !!accessToken,
            retry: 2,
            refetchInterval: 30000,
        },
    );

    const closeDrawer = (): void => {
        setSelectedId('');
        setAddModalOpen(false);
    };

    const handleCloseDrawer = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        closeDrawer();
    };

    const handleShowDrawer = (id: string): void => {
        setSelectedId(id);
    };

    const handleNewDTG = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        if (accessToken) {
            setAddModalOpen(true);
        }
    };

    const loadMore = async (): Promise<void> => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    return (
        <SnackBarErrorBoundary>
            <Grid
                container
                className={classes.actions}
                justifyContent="flex-end"
                spacing={4}
            >
                <Grid item>
                    <Button
                        color="primary"
                        startIcon={<Add />}
                        variant="contained"
                        onClick={handleNewDTG}
                    >
                        New Design Tagging Guidance
                    </Button>
                </Grid>
            </Grid>
            {!data && (<LoadingGrid />)}
            {(data && !data.length) && (
                <EmptyState>
                    Could not find design tagging guidances for the given parameters
                </EmptyState>
            )}
            {(data && !!data.length) && (
                <InfiniteSearchResultsGrid
                    data={data}
                    hasMore={!!hasNextPage}
                    itemKey={(item: Models.ContentStoreApi.V3.DesignTaggingGuidance): string => `item-${item.id}`}
                    loadMore={loadMore}
                    minWidth={200}
                    variant="cards"
                >
                    {(item: Models.ContentStoreApi.V3.DesignTaggingGuidance): JSX.Element => (
                        <DesignTaggingGuidanceResult
                            id={item.id}
                            internalName={(ducData?.find((duc) => duc.id === item.ducId)?.name) || NOT_APPLICABLE}
                            numTaxonomies={item.taxonomies.length}
                            onClick={handleShowDrawer}
                        />
                    )}
                </InfiniteSearchResultsGrid>
            )}
            {!!selectedId && (
                <DesignTaggingGuidanceDetailsDrawer
                    open
                    changeSelectedId={handleShowDrawer}
                    closeDrawer={closeDrawer}
                    ducId={data?.find((d) => d.id === selectedId)?.ducId || ''}
                    ducName={ducData?.find(
                        (c) => c.id === (data?.find((d) => d.id === selectedId)?.ducId),
                    )?.name || NOT_APPLICABLE}
                    id={selectedId}
                    onClose={handleCloseDrawer}
                />
            )}
            {addModalOpen && (
                <DesignTaggingGuidanceAddDrawer
                    open
                    changeSelectedId={handleShowDrawer}
                    closeDrawer={closeDrawer}
                    id=""
                    mappedDucs={data}
                    onClose={handleCloseDrawer}
                />
            )}
        </SnackBarErrorBoundary>
    );
};
