import {
    ListItemText, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { useQueryAllEntities } from 'src/hooks/useQueryAllEntities';
import { useRecoilValue } from 'recoil';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { useQueryCultures } from 'src/hooks/useQueryCultures';
import { compareCultures, getRank } from 'src/components/ForcedRankingEdit/util/forcedRankingUtils';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { ForcedRankingPreview } from 'src/components/ForcedRankingEdit/ForcedRankingPreview';

export type PropTypes = {
    rankingStrategy: Models.ContentStoreApi.V3.RankingStrategy;
};

export interface ExistingRankings {
    [culture: string]: App.ForcedRanking.InputData | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
    globalWrapper: {
        marginTop: theme.spacing(8),
        gridTemplateRows: 'auto auto 1fr',
    },
}));

export const ExistingRankingPreview = (props: PropTypes): JSX.Element => {
    const { rankingStrategy } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;

    const [existingRankings, setExistingRankings] = useState<ExistingRankings>();
    const [culture, setCulture] = useState<string>();

    const { data: previousEntities } = useQueryAllEntities(accessToken, {
        rankingStrategyIds: rankingStrategy.rankingStrategyId,
        includeInactiveRankings: true,
    });

    const {
        data: unsortedCultureOptions,
    } = useQueryCultures(accessToken, {
        enabled: !!accessToken,
        suspense: false,
        refetchInterval: false,
    });

    const cultureOptions = unsortedCultureOptions?.sort(compareCultures);

    useEffect(() => {
        /* eslint-disable no-param-reassign */
        const byCulture = cultureOptions?.reduce((memo, cultureOption) => {
            const entityRankings = previousEntities?.map(
                (entity) => ([entity.id, getRank(entity, cultureOption, rankingStrategy)]),
            ) as App.ForcedRanking.InputData;

            memo[cultureOption.cultureValue] = entityRankings
                .filter((entity) => entity[1])
                .sort((a, b) => a[1] - b[1]);
            return memo;
        }, {} as ExistingRankings);
        /* eslint-enable no-param-reassign */

        setExistingRankings(byCulture);
    }, [previousEntities, rankingStrategy, cultureOptions]);

    const handleChange = (event: SelectChangeEvent<string>): void => {
        const {
            target: { value },
        } = event;

        setCulture(value);
    };

    return (
        <CSSGrid className={classes.globalWrapper} gap={4}>
            <Typography variant="h6">
                Preview Ranking
            </Typography>
            <CSSGrid gap={4} gridTemplateColumns="1fr auto auto 3fr">
                <FormControl>
                    <InputLabel id="culture-select-label">Culture</InputLabel>
                    <Select
                        id="culture-select"
                        labelId="culture-select-label"
                        value={culture}
                        onChange={handleChange}
                    >
                        {cultureOptions?.filter((cultureOption) => (
                            existingRankings && existingRankings[cultureOption.cultureValue]
                                && existingRankings[cultureOption.cultureValue]?.length
                        ))
                            .map((cultureOption) => (
                                <MenuItem value={cultureOption.cultureValue}>
                                    <ListItemText primary={cultureOption.cultureValue} />
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </CSSGrid>
            {existingRankings && culture && existingRankings[culture] && (
                <ForcedRankingPreview
                    culture={culture}
                    previewData={existingRankings[culture]}
                    previewEntities={previousEntities}
                />
            )}
        </CSSGrid>
    );
};
