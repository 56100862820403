import clsx from 'clsx';
import {
    alpha,
    Chip as MuiChip,
    makeStyles,
    Theme,
    Tooltip,
} from '@material-ui/core';
import {
    SvgIconComponent, Check, Lightbulb, Save, DeleteOutlined, HourglassEmpty, Clear,
} from '@material-ui/icons';
import {
    ComponentProps, MouseEvent, useEffect, useState,
} from 'react';

import { DataSource } from 'src/constants';

export type ChipNextState = DataSource.Selected | DataSource.Suggested | DataSource.Deleted;

export interface PropTypes<T> extends Omit<ComponentProps<typeof MuiChip>, 'onClick' | 'onDelete'> {
    data: T;
    onClick?: (event: MouseEvent<HTMLElement>, item: T, nextState: ChipNextState) => void;
    onDelete?: (event: MouseEvent<HTMLElement>, item: T, nextState: ChipNextState) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '&.MuiChip-root': {
            '& > .MuiChip-icon': {
                marginLeft: '7px',
            },
            '& > .MuiChip-deleteIcon': {
                opacity: 0,
                visibility: 'hidden',
                width: 0,
                color: theme.palette.error.main,
                transition: '.1s ease-in',
                transitionDelay: '0s',
                '&:hover': {
                    color: alpha(theme.palette.error.main, 0.5),
                },
            },
            '&:hover': {
                '& > .MuiChip-deleteIcon': {
                    opacity: 1,
                    width: '1em',
                    visibility: 'visible',
                    transition: '.1s ease-out',
                    transitionDelay: '.2s',
                },
            },
        },
    },
    [DataSource.Saved]: {
        '&.MuiChip-root': {
            borderColor: theme.palette.success.main,
            color: theme.palette.success.main,
            '& > .MuiChip-icon': {
                color: theme.palette.success.main,
            },
        },
    },
    [DataSource.Selected]: {
        '&.MuiChip-root': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            '& > .MuiChip-icon': {
                color: theme.palette.primary.main,
            },
            '&:hover': {
                borderColor: alpha(theme.palette.primary.main, 0.5),
                color: alpha(theme.palette.primary.main, 0.5),
                '& > .MuiChip-icon': {
                    color: alpha(theme.palette.error.main, 0.5),
                },
            },
        },
    },
    [DataSource.Suggested]: {
        '&.MuiChip-root': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.secondary.main,
            '& > .MuiChip-icon': {
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                borderColor: alpha(theme.palette.secondary.main, 0.5),
                color: alpha(theme.palette.secondary.main, 0.5),
                '& > .MuiChip-icon': {
                    color: alpha(theme.palette.warning.main, 0.5),
                },
            },
        },
    },
    deleted: {
        '&.MuiChip-root': {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
            '& > .MuiChip-icon': {
                color: theme.palette.error.main,
            },
        },
    },
}));

const iconMap: Record<string, SvgIconComponent> = {
    [DataSource.Saved]: Check,
    [DataSource.Selected]: Save,
    [DataSource.Suggested]: Lightbulb,
    [DataSource.Loading]: HourglassEmpty,
};

const tooltipMap: Record<string, string> = {
    [DataSource.Saved]: 'Existing item. Use the delete button to remove item',
    [DataSource.Selected]: 'Pending save. Click to remove selected item',
    [DataSource.Suggested]: 'Click to add suggested item',
    [DataSource.Loading]: 'Loading...',
};

export const Chip = <T extends Models.V3.SourcedOnly>(props: PropTypes<T>): JSX.Element => {
    const {
        className,
        data,
        onClick,
        onDelete,
        ...rest
    } = props;
    const source = data.source || DataSource.Saved;
    const classes = useStyles();
    const [initialSource] = useState<DataSource>(source);
    const [MappedIcon, setMappedIcon] = useState<SvgIconComponent>(iconMap[source || DataSource.Saved]);

    useEffect(() => {
        setMappedIcon(iconMap[source || DataSource.Saved]);
    }, [source]);

    const handleClick = (event: MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();

        let nextState: ChipNextState = DataSource.Deleted;

        if (initialSource === DataSource.Suggested && source === DataSource.Selected) {
            nextState = DataSource.Suggested;
        } else if (source === DataSource.Suggested) {
            nextState = DataSource.Selected;
        }

        if (onClick) {
            onClick(event, data, nextState);
        }
    };

    const handleDelete = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        // TODO augment this by checking suggested list when initialSource is "saved" (another ticket)
        const nextState = (initialSource === DataSource.Suggested && source === DataSource.Selected)
            ? DataSource.Suggested
            : DataSource.Deleted;

        if (onDelete) {
            onDelete(event, data, nextState);
        }
    };

    const handleMouseOver = (): void => {
        if (source === DataSource.Selected) {
            setMappedIcon(Clear);
        }
    };

    const handleMouseOut = (): void => {
        setMappedIcon(iconMap[source || DataSource.Saved]);
    };

    const isClickable = onClick && source !== DataSource.Saved && source !== DataSource.Loading;
    const isDeletable = onDelete && source === DataSource.Saved;

    return (
        <Tooltip disableInteractive title={tooltipMap[source]}>
            <MuiChip
                {...rest}
                className={clsx(classes.root, className, {
                    [classes.deleted]: !!data.toBeDeleted,
                    [classes.saved]: source === DataSource.Saved,
                    [classes.selected]: source === DataSource.Selected,
                    [classes.suggested]: source === DataSource.Suggested,
                })}
                deleteIcon={<DeleteOutlined />}
                icon={<MappedIcon fontSize="small" />}
                sx={{ userSelect: 'unset' }}
                variant="outlined"
                onClick={isClickable ? handleClick : undefined}
                onDelete={isDeletable ? handleDelete : undefined}
                onMouseOut={handleMouseOut}
                onMouseOver={handleMouseOver}
            />
        </Tooltip>
    );
};
