import { QueryFunctionContext } from 'react-query';

import { ContentStoreProductsService } from 'src/services/Products';
import { DEFAULT_LIMIT } from 'src/constants';

export const QUERY_KEY = 'products';

export const queryProducts = (
    context: QueryFunctionContext<App.Products.QueryKey, App.Products.PageParam>,
): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.Product>> => {
    const { pageParam: offset, queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentStoreProductsService.getProducts(
        accessToken,
        search?.productKey,
        search?.productVersion,
        search?.productName,
        search?.ids,
        search?.active,
        offset,
        DEFAULT_LIMIT,
    );
};
