import { ComponentProps, RefObject } from 'react';

import { StyledTreeItem } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/StyledTreeItem';
import { DraggableCommand } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableCommand';
import { OpenCategoryButton } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/OpenCategoryButton';

type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;
type Category = Models.ContentStoreApi.V3.Category;

export interface PropTypes<T extends (Category | TaxonomyNode)> extends Omit<ComponentProps<typeof StyledTreeItem>, 'onChange' | 'nodeId' | 'children'> {
    droppable?: DraggableCommand | undefined;
    depth: number;
    treeItemRef?: RefObject<HTMLDivElement>;
    children?: (node: TaxonomyNode, index: number, depth: number) => JSX.Element;
    node: T;
    categoryId: string;
    descriptionText?: string;
    onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void
}

export const NonSelectableTreeItem = <T extends (Category | TaxonomyNode)>(props: PropTypes<T>): JSX.Element => {
    const {
        children,
        descriptionText,
        node,
        droppable,
        depth,
        treeItemRef,
        labelText,
        categoryId,
        onDelete,
        ...rest
    } = props;

    return (
        <StyledTreeItem
            descriptionText={descriptionText}
            droppable={droppable}
            hasChildren={!!('children' in node) && !!node.children && !!node.children.length}
            labelInfo={(
                <OpenCategoryButton categoryId={categoryId} />
            )}
            labelText={labelText}
            nodeId={`${categoryId}-${depth}`}
            taxonomyNodeId={node.id}
            treeItemRef={treeItemRef}
            onDelete={onDelete}
            {...rest}
        >
            {('children' in node) && children && !!node.children && node.children.map(
                (c, index) => children(c, index, depth + 1),
            )}
        </StyledTreeItem>
    );
};
