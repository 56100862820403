import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryEntitiesByBatchedIds, QUERY_KEY } from 'src/queries/queryEntitiesByBatchedIds';

type PagedEntities = Models.ContentStoreApi.V3.Entity[];

export const useQueryEntitiesByBatchedIds = (
    accessToken: string | undefined | false,
    search: App.Entities.Search,
    options?: UseQueryOptions<PagedEntities, Error, PagedEntities, App.Entities.QueryKey>,
): UseQueryResult<PagedEntities, Error> => useQuery<PagedEntities, Error, PagedEntities, App.Entities.QueryKey>(
    [QUERY_KEY, accessToken, search],
    queryEntitiesByBatchedIds,
    options,
);
