import { QueryFunctionContext } from 'react-query';

import { ContentStoreCategoriesService } from 'src/services/Categories';

export const QUERY_KEY = 'categoryMonolithKeys';

export const queryCategoryMonolithKeys = (
    context: QueryFunctionContext<App.CategoryMonolithKey.QueryKey>,
): Promise<Models.ContentStoreApi.V3.CategoryMonolithKey[]> => {
    const { queryKey: [, accessToken, id] } = context;

    return ContentStoreCategoriesService.getCategoryMonolithKeys(accessToken, id);
};
