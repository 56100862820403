import {
    Tooltip, Zoom, makeStyles, Theme, TooltipProps,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    span: {
        '& $svg': {
            color: theme.palette.primary.light,
            height: theme.spacing(3),
            marginLeft: theme.spacing(1),
            width: theme.spacing(3),
        },
    },
}));

const useToolTipStyles = makeStyles((theme: Theme) => ({
    tooltip: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(2),
        maxWidth: 'none',
        maxHeight: theme.spacing(60),
        overflow: 'auto',
    },
    arrow: {
        color: theme.palette.grey[200],
    },
}));

export const LabelTooltip = (props: TooltipProps): JSX.Element => {
    const {
        children,
        title,
        ...rest
    } = props;
    const classes = useStyles();
    const tooltipClasses = useToolTipStyles();

    return (
        <Tooltip
            arrow
            classes={tooltipClasses}
            placement="right"
            title={title}
            TransitionComponent={Zoom}
            {...rest}
        >
            <span className={classes.span}>
                {children}
            </span>
        </Tooltip>
    );
};
