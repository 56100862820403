import { HintProps } from '@blacklab/react-image-magnify';
import { Button, Tooltip, styled } from '@mui/material';
import { ZoomIn } from '@mui/icons-material';
import { ComponentProps } from 'react';

export type PropTypes = HintProps & ComponentProps<typeof Button>;

const StyledButton = styled(Button)<Partial<PropTypes>>(({ isTouchDetected, theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    position: 'absolute',
    border: 'none',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: theme.spacing(6),
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    padding: theme.spacing(2),
    minWidth: 'unset',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        border: 'none',
        color: theme.palette.primary.main,
    },
    '& > .MuiButton-startIcon.MuiButton-iconSizeLarge': {
        marginLeft: !isTouchDetected ? 0 : theme.spacing(1),
        marginRight: !isTouchDetected ? 0 : theme.spacing(2),
    },
}));

export const Hint = (props: PropTypes): JSX.Element => {
    const {
        isMouseDetected,
        isTouchDetected,
        hintTextMouse,
        hintTextTouch,
        ...rest
    } = props;

    return (
        <Tooltip title={hintTextMouse}>
            <StyledButton
                {...rest}
                color="secondary"
                size="large"
                startIcon={<ZoomIn />}
                variant="outlined"
            >
                {isTouchDetected && hintTextTouch}
            </StyledButton>
        </Tooltip>
    );
};
