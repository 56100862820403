import { QueryFunctionContext } from 'react-query';

import { ContentStoreSuggestionsService } from 'src/services/Suggestions';
import { DEFAULT_LIMIT } from 'src/constants';

export const QUERY_KEY = 'keywordSuggestions';

export const queryKeywordSuggestions = (
    context: QueryFunctionContext<App.Suggestions.QueryKey>,
): Promise<Models.ContentStoreApi.V3.SourcedKeyword[]> => {
    const { queryKey: [, accessToken, search] } = context;

    return ContentStoreSuggestionsService.getKeywordSuggestions(
        accessToken,
        search.ids,
        DEFAULT_LIMIT,
    );
};
