import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { deleteDesignTaggingGuidance } from 'src/mutations/deleteDesignTaggingGuidance';
import { QUERY_KEY } from 'src/queries/queryDesignTaggingGuidances';

export const useDeleteDesignTaggingGuidance = (): UseMutationResult<void, Error, App.DesignTaggingGuidance.DeleteMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, App.DesignTaggingGuidance.DeleteMutationVariables>(
        deleteDesignTaggingGuidance,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            },
            useErrorBoundary: true,
        },
    );
};
