import { TableCell, TableRow } from '@material-ui/core';
import { ComponentProps } from 'react';

import { ErrorState } from 'src/components/common/ErrorState';

export interface PropTypes extends ComponentProps<typeof TableRow> {
    error?: Error | null;
    tableCellProps?: Partial<ComponentProps<typeof TableCell>>;
    title?: string;
}

export const ErrorRow = (props: PropTypes): JSX.Element => {
    const {
        error,
        tableCellProps,
        title,
        ...rest
    } = props;

    return (
        <TableRow {...rest}>
            <TableCell colSpan={3} {...tableCellProps}>
                <ErrorState
                    message={error ? error.message : 'Unknown error'}
                    title={title}
                />
            </TableCell>
        </TableRow>
    );
};
