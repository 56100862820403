import { QueryFunctionContext } from 'react-query';

import { ContentStoreDesignTaggingGuidanceService } from 'src/services/DesignTaggingGuidances';
import { DEFAULT_LIMIT } from 'src/constants';

export const QUERY_KEY = 'designTaggingGuidances';

export const INFINITE_QUERY_KEY = 'infiniteDesignTaggingGuidances';

export const queryDesignTaggingGuidances = (
    context: QueryFunctionContext<App.DesignTaggingGuidances.PagedQueryKey, App.DesignTaggingGuidances.PageParam>,
): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.DesignTaggingGuidance>> => {
    const { pageParam: offset, queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentStoreDesignTaggingGuidanceService.getDesignTaggingGuidances(
        accessToken,
        search,
        offset,
        DEFAULT_LIMIT,
    );
};
