import { atom, AtomEffect, DefaultValue } from 'recoil';

const localStorageEffect = <T>(key: string): AtomEffect<T> => ({ setSelf, onSet }): void => {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, JSON.stringify(newValue));
        }
    });
};

export const navBarExpandedState = atom({
    key: 'NavBarExpanded',
    default: false,
    effects_UNSTABLE: [
        localStorageEffect<boolean>('NavBarExpanded'),
    ],
});

export const navBarMobileState = atom({
    key: 'NavBarMobile',
    default: false,
    effects_UNSTABLE: [
        localStorageEffect<boolean>('NavBarMobile'),
    ],
});
