import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { queryKeywordSuggestions, QUERY_KEY } from 'src/queries/queryKeywordSuggestions';

export const useQueryKeywordSuggestions = (
    accessToken: string | undefined | false,
    search: App.Suggestions.Search,
    options?: UseQueryOptions<Models.ContentStoreApi.V3.SourcedKeyword[],
    Error, Models.ContentStoreApi.V3.SourcedKeyword[], App.Suggestions.QueryKey>,
): UseQueryResult<Models.ContentStoreApi.V3.SourcedKeyword[],
Error> => useQuery<Models.ContentStoreApi.V3.SourcedKeyword[],
Error, Models.ContentStoreApi.V3.SourcedKeyword[],
App.Suggestions.QueryKey>(
    [QUERY_KEY, accessToken, search],
    queryKeywordSuggestions,
    options,
);
