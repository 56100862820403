import { Card, makeStyles, Theme } from '@material-ui/core';
import { ComponentProps } from 'react';

import * as routes from 'src/lib/routes';
import { ResultCard } from 'src/components/common/ResultCard';
import { generatePath, Link } from 'react-router-dom';
import { ResultCardBanner } from 'src/components/common/ResultCardBanner';
import { CSSGrid } from 'src/components/common/CSSGrid';

export interface PropTypes extends Omit<ComponentProps<typeof Card>, 'onClick' | 'id'> {
    rankingStrategy: Models.ContentStoreApi.V3.RankingStrategy;
    onClick: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    noUnderline: {
        textDecoration: 'none',
    },
    tooltip: {
        margin: `${theme.spacing(2)} 0 0 ${theme.spacing(2)}`,
    },
}));

const generateRankingDescription = (rankingStrategy: Models.ContentStoreApi.V3.RankingStrategy): string => {
    if (rankingStrategy.startDate && rankingStrategy.endDate) {
        return `${rankingStrategy.startDate?.toLocaleDateString('en-US')} - ${rankingStrategy.endDate?.toLocaleDateString('en-US')}`;
    } if (rankingStrategy.startDate) {
        return `Starts ${rankingStrategy.startDate?.toLocaleDateString('en-US')}`;
    } if (rankingStrategy.endDate) {
        return `Ends ${rankingStrategy.endDate?.toLocaleDateString('en-US')}`;
    }
    return 'No dates';
};

const ACTIVE_TEXT = 'Active';
const INACTIVE_TEXT = 'Inactive';

export const ForcedRankingResult = (props: PropTypes): JSX.Element => {
    const {
        rankingStrategy,
        ...rest
    } = props;
    const classes = useStyles();

    const description = generateRankingDescription(rankingStrategy);

    return (
        <Link
            className={classes.noUnderline}
            to={`/${generatePath(routes.FORCEDRANKINGEDIT, { id: rankingStrategy.rankingStrategyId.toString() })}`}
        >
            <ResultCard<number>
                {...rest}
                allowDefault
                headerRenderedComponent={(
                    <CSSGrid justifyContent="start">
                        <ResultCardBanner
                            active={rankingStrategy.isActive}
                            activeText={ACTIVE_TEXT}
                            activeTooltipTitle={ACTIVE_TEXT}
                            className={classes.tooltip}
                            inactiveText={INACTIVE_TEXT}
                            inactiveTooltipTitle={INACTIVE_TEXT}
                        />
                    </CSSGrid>
                )}
                id={rankingStrategy.rankingStrategyId}
                itemName="Ranking Strategy"
                title={rankingStrategy.strategyName}
            >
                {description}
            </ResultCard>
        </Link>
    );
};
