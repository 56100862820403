import {
    Checkbox, Grid, makeStyles, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Theme, Tooltip, Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {
    ChangeEvent, ComponentProps, MouseEvent,
} from 'react';
import { useRecoilValue } from 'recoil';

import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { useQueryCultures } from 'src/hooks/useQueryCultures';
import { DeleteLoadingButton } from 'src/components/common/buttons/DeleteLoadingButton';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { AddCultureForm } from 'src/components/Entity/EntityDetailsDrawer/CultureForm/AddCultureForm';
import { ToggleAllCultures } from 'src/components/Entity/EntityDetailsDrawer/CultureForm/ToggleAllCultures';
import { CulturesFormBodySekeleton } from 'src/components/Entity/EntityDetailsDrawer/CultureForm/CulturesFormBodySekeleton';
import { ErrorRow } from 'src/components/common/ErrorState/ErrorRow';
import { CultureList } from 'src/components/Entity/EntityDetailsDrawer/CultureForm/CultureList';

type Culture = Models.ContentStoreApi.V3.Entity.BulkCulture;

export interface PropTypes extends Omit<ComponentProps<typeof Table>, 'onChange'> {
    values: Culture[];
    bulkDesignCount?: number
    onChange: (value: Culture[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        paddingTop: theme.spacing(8),
    },
    table: {
        '& tbody': {
            '& tr:first-child > td': {
                paddingTop: theme.spacing(3),
            },
        },
    },
    infoIcon: {
        fontSize: theme.spacing(4),
    },
}));

export const CulturesForm = (props: PropTypes): JSX.Element => {
    const {
        onChange,
        values,
        bulkDesignCount,
        ...rest
    } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const classes = useStyles();

    const {
        data: cultureOptions, error, isLoading, isError,
    } = useQueryCultures(accessToken, {
        enabled: !!accessToken,
        suspense: false,
        refetchInterval: false,
    });

    const handleDeleteFactory = (href: string) => async (
        event: MouseEvent<HTMLButtonElement>,
    ): Promise<void> => {
        event.preventDefault();

        const updatedCultures = values.slice();
        const cultureToUpdatedIndex = updatedCultures.findIndex((c) => c.href === href);

        updatedCultures.splice(cultureToUpdatedIndex, 1);
        onChange(updatedCultures);
    };

    const handleEnabledChange = (
        event: ChangeEvent<HTMLInputElement>,
    ): void => {
        const { name: href, checked } = event.target;
        const updatedCultures = values.slice();
        const cultureToUpdatedIndex = updatedCultures.findIndex((c) => c.href === href);
        const cultureToUpdate = { ...updatedCultures[cultureToUpdatedIndex] };

        cultureToUpdate.userEnabled = checked;
        cultureToUpdate.changedEnabledValue = true;

        updatedCultures[cultureToUpdatedIndex] = cultureToUpdate;

        onChange(updatedCultures);
    };

    const handleSubmit = (culture: Culture): void => {
        onChange(values.concat(culture));
    };

    const handleRankValueChange = (
        event: ChangeEvent<HTMLInputElement>,
    ): void => {
        const { name: href, value } = event.target;
        const updatedCultures = values.slice();
        const cultureToUpdatedIndex = updatedCultures.findIndex((c) => c.href === href);
        const cultureToUpdate = { ...updatedCultures[cultureToUpdatedIndex] };

        cultureToUpdate.rankValue = parseInt(value, 10);

        updatedCultures[cultureToUpdatedIndex] = cultureToUpdate;

        onChange(updatedCultures);
    };

    const generateEnabledRatio = (culture: Culture): string | undefined => (bulkDesignCount
        && !culture.changedEnabledValue
        && culture?.enabledCount
        && culture.enabledCount < bulkDesignCount
        ? `${culture.enabledCount}/${bulkDesignCount}` : undefined);

    const generateCheckBoxToolTip = (culture: Culture, enabledRatio?: string): string => {
        if (enabledRatio) {
            return `${enabledRatio} designs enabled for this culture. Check to enable all`;
        } if (culture?.userEnabled) {
            return bulkDesignCount ? 'Uncheck to disable culture for all designs' : 'Uncheck to disable culture';
        }
        return bulkDesignCount ? 'Check to enable culture for all designs' : 'Check to enable culture';
    };

    return (
        <CSSGrid className={classes.form} gap={4}>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Typography variant="h6">Cultures</Typography>
                </Grid>
                <Grid item>
                    <ToggleAllCultures cultures={values} onChange={onChange} />
                </Grid>
            </Grid>
            <TableContainer>
                <Table {...rest} className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Culture</TableCell>
                            <TableCell>Rank</TableCell>
                            <TableCell>Enabled</TableCell>
                            <TableCell>
                                <Tooltip
                                    placement="right"
                                    title="DTeC culture availability cannot be set in this tool, it is shown for informational purposes only.
                                    If a culture is disabled in this column, the design cannot be made to show in that culture.
                                    This usually means the design has not been localized"
                                >
                                    <span>
                                        <span>DTeC Avail.</span>
                                        <InfoIcon className={classes.infoIcon} />
                                    </span>
                                </Tooltip>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                        <AddCultureForm
                            options={cultureOptions}
                            values={values}
                            onSubmit={handleSubmit}
                        />
                    </TableHead>
                    <TableBody>
                        {isLoading && (<CulturesFormBodySekeleton />)}
                        {isError && (
                            <ErrorRow
                                error={error}
                                title="Failed to load cultures"
                            />
                        )}
                        {(!!values?.length && !!cultureOptions) && (
                            <CultureList cultureOptions={cultureOptions} cultures={values}>
                                {(culture: Culture, cultureValue: string): JSX.Element => {
                                    const enabledRatio = generateEnabledRatio(culture);

                                    return (
                                        <TableRow key={culture.href}>
                                            <TableCell>
                                                {cultureValue}
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    aria-label={`${cultureValue} rank value`}
                                                    disabled={!!bulkDesignCount}
                                                    inputProps={{
                                                        type: 'number',
                                                    }}
                                                    label="rank"
                                                    name={culture.href}
                                                    size="small"
                                                    value={bulkDesignCount ? null : culture.rankValue}
                                                    variant="outlined"
                                                    onChange={handleRankValueChange}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title={generateCheckBoxToolTip(culture, enabledRatio)}>
                                                    <Checkbox
                                                        checked={culture.userEnabled}
                                                        color="primary"
                                                        indeterminate={!!enabledRatio}
                                                        inputProps={{
                                                            'aria-label': `${cultureValue} enabled`,
                                                        }}
                                                        name={culture.href}
                                                        onChange={handleEnabledChange}
                                                    />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                {culture.dTeCEnabled ? 'Yes' : 'No'}
                                            </TableCell>
                                            <TableCell>
                                                <DeleteLoadingButton
                                                    onClick={handleDeleteFactory(culture.href)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }}
                            </CultureList>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </CSSGrid>
    );
};
