import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { mutateTag } from 'src/mutations/mutateTags';
import { QUERY_KEY } from 'src/queries/queryTags';

export const useMutateTag = (
    accessToken: string | false,
    id?: string,
): UseMutationResult<Models.ContentStoreApi.V3.Tag, Error, App.Tag.MutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Models.ContentStoreApi.V3.Tag, Error, App.Tag.MutationVariables>(
        mutateTag,
        {
            onSuccess: (response: Models.ContentStoreApi.V3.Tag) => {
                queryClient.setQueryData([QUERY_KEY, accessToken, id], response);
                queryClient.invalidateQueries(QUERY_KEY);
            },
            useErrorBoundary: true,
        },
    );
};
