import { QueryFunctionContext } from 'react-query';

import { ContentStoreCategoriesService } from 'src/services/Categories';
import { DEFAULT_LIMIT } from 'src/constants';

export const QUERY_KEY = 'categories';

export const queryCategories = (
    context: QueryFunctionContext<App.Categories.PagedQueryKey, App.Categories.PageParam>,
): Promise<Models.V3.PageResult<Models.ContentStoreApi.V3.SourcedCategory>> => {
    const { pageParam: offset, queryKey } = context;
    const [, accessToken, search] = queryKey;

    return ContentStoreCategoriesService.getCategories(
        accessToken,
        search?.internalName,
        search?.ids,
        search?.keys,
        offset,
        DEFAULT_LIMIT,
    );
};
