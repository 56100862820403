import {
    Button,
    IconButton,
    makeStyles,
    Theme,
    CircularProgress,
} from '@material-ui/core';
import {
    Add, Tag, Cancel, Save,
} from '@material-ui/icons';
import { FormEvent, useState, MouseEvent } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import { createTag } from 'src/mutations/createTag';
import { QUERY_KEY } from 'src/queries/queryTags';
import { ClearableTextField } from 'src/components/common/inputs/ClearableTextField';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { ContentStoreTagsService } from 'src/services/Tags';
import { useMutateTag } from 'src/components/Tags/hooks/useMutateTag';

type Tag = Models.ContentStoreApi.V3.Tag;

export interface PropTypes {
    id?: string;
    labelValue?: string;
    onUpdateSubmit?: () => void;
    onCancel?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        display: 'grid',
        gap: theme.spacing(2),
        gridTemplateAreas: '". ."',
    },
    inline: {
        alignItems: 'center',
        display: 'grid',
        gap: theme.spacing(1),
        gridTemplateAreas: '". . ."',
        justifyContent: 'center',
        padding: `${theme.spacing(9)} ${theme.spacing(2)}`,
    },
}));

export const InlineAddForm = (props: PropTypes): JSX.Element => {
    const {
        id,
        labelValue = '',
        onUpdateSubmit,
        onCancel,
    } = props;
    const classes = useStyles();
    const [value, setValue] = useState<string>(labelValue);
    const [duplicate, setDuplicate] = useState<boolean>(false);
    const accessToken = useRecoilValue(isAuthorizedQuery);
    const [isDebouncing, setIsDebouncing] = useState(false);
    const queryClient = useQueryClient();

    const isDuplicate = async (): Promise<boolean> => {
        const substringTags = await (
            await ContentStoreTagsService.getTags(accessToken, undefined, value, 0, 1)
        ).results;

        if (!id && substringTags.find((tag) => tag.label.toLowerCase() === value.toLowerCase())) {
            return true;
        }
        if (id && substringTags.find((tag) => tag.label.toLowerCase() === value.toLowerCase() && tag.id !== id)) {
            return true;
        }
        return false;
    };

    const debounced = useDebouncedCallback(async () => {
        const duplicateValue = await isDuplicate();

        setDuplicate(duplicateValue);
        setIsDebouncing(false);
    }, 750);

    const onChange = (newValue: string): void => {
        setIsDebouncing(true);
        setValue(newValue);
        debounced();
    };

    const addMutation = useMutation<Tag, Error, App.Tag.CreateMutationVariables>(
        createTag,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            },
            useErrorBoundary: true,
        },
    );

    const updateMutation = useMutateTag(accessToken, id);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        if (accessToken) {
            if (id) {
                await updateMutation.mutateAsync({
                    accessToken,
                    id,
                    tag: {
                        label: value,
                    },
                });

                if (onUpdateSubmit) {
                    onUpdateSubmit();
                }
            } else {
                await addMutation.mutateAsync({
                    accessToken,
                    tag: {
                        label: value,
                    },
                });

                setValue('');
            }
        }
    };

    return (
        <form
            noValidate
            autoComplete="off"
            className={id ? classes.inline : classes.form}
            onSubmit={handleSubmit}
        >
            <ClearableTextField
                fullWidth
                error={duplicate}
                InputProps={{
                    endAdornment: (isDebouncing ? <CircularProgress color="inherit" size={20} /> : null),
                }}
                label={duplicate ? 'Tag Already Exists' : 'Tag Name'}
                size="small"
                value={value}
                onChange={onChange}
            />
            {id && (
                <>
                    <IconButton
                        color="primary"
                        disabled={duplicate || !value}
                        size="small"
                        type="submit"
                    >
                        <Save />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        size="small"
                        onClick={onCancel}
                    >
                        <Cancel />
                    </IconButton>
                </>
            )}
            {!id && (
                <Button
                    color="primary"
                    disabled={duplicate || !value}
                    startIcon={<Add />}
                    type="submit"
                    variant="contained"
                >
                    New Tag
                </Button>
            )}
        </form>
    );
};
