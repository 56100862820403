import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

const getProductVersionQuery = (
    version?: string,
): MCP.Products.ProductSearch.Models.ProductVersionQuery => {
    const searchQuery = [];

    if (version) {
        searchQuery.push({
            type: 'match',
            fieldName: 'version',
            value: version,
        });
    }

    return {
        query: {
            type: 'compound',
            operator: 'and',
            compoundQuery: searchQuery,
        },
        sortOrder: [{
            fieldName: 'version',
            direction: 'desc',
        }],
        scroll: false,
        shouldAggregate: true,
    };
};

class ProductSearchServiceApi extends AbstractService implements MCP.Products.ProductSearch.Services.IProductSearchService {
    public async getProductVersion(
        bearerToken: string | undefined | false,
        productKey: string,
        version?: string,
        signal?: AbortSignal,
    ): Promise<MCP.Products.ProductSearch.Models.ProductVersionsResponse> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getProductVesion. No bearerToken provided.',
            });
        }

        const url = `v1/products/${productKey}/versions:search`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        const body = getProductVersionQuery(version);

        try {
            const json = await this.api
                .post(
                    url,
                    {
                        json: body,
                        searchParams,
                        signal,
                        headers: {
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    },
                )
                .json<MCP.Products.ProductSearch.Models.ProductVersionsResponse>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch product version',
                info: (e as Error).message,
                url,
                productKey,
                body,
            });
        }
    }
}

export const ProductSearchService = new ProductSearchServiceApi(config.services.productSearchServiceApi);
