import { ContentStoreTaxonomiesService } from 'src/services/Taxonomies';

export const createTaxonomy = async (
    {
        accessToken,
        taxonomy,
        signal,
    }: App.Taxonomy.CreateMutationVariables,
): Promise<Models.ContentStoreApi.V3.Taxonomy> => ContentStoreTaxonomiesService.createTaxonomy(
    accessToken,
    taxonomy,
    signal,
);
