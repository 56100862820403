import { ContentStoreCategoriesService } from 'src/services/Categories';

export const mutateCategory = async (
    {
        accessToken,
        id,
        category,
        signal,
    }: App.Category.MutationVariables,
): Promise<Models.ContentStoreApi.V3.Category> => ContentStoreCategoriesService.updateCategory(
    accessToken,
    id,
    category,
    signal,
);
