import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

class ContentApiTaxonomyAssociation extends AbstractService implements Services.ContentApi.TaxonomyAssociation {
    public async getTaxonomyAssociation(
        bearerToken: string | undefined | false,
        search: App.TaxonomyAssociation.Search,
        offset?: number,
        limit?: number,
        signal?: AbortSignal,
    ): Promise<Models.V3.PageResult<Models.ContentApi.V3.TaxonomyAssociation>> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getTaxonomyAssociation. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/TaxonomyAssociations`;
        const searchParams = qs.stringify({
            ...search,
            offset,
            limit,
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .get(url, {
                    signal,
                    searchParams,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.V3.PageResult<Models.ContentApi.V3.TaxonomyAssociation>>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch Taxonomy Association',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }
}

export const ContentApiTaxonomyAssociationService = new ContentApiTaxonomyAssociation(config.services.contentApi);
