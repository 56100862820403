import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { mutateEntities } from 'src/mutations/mutateEntities';
import { QUERY_KEY } from 'src/queries/queryEntities';

export const useMutateEntities = (
    accessToken: string,
    ids: string[],
): UseMutationResult<App.Entities.BatchMutationResponse, Error, App.Entities.MutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<App.Entities.BatchMutationResponse, Error, App.Entities.MutationVariables>(
        mutateEntities,
        {
            onSuccess: (response: App.Entities.BatchMutationResponse) => {
                queryClient.invalidateQueries(QUERY_KEY, {
                    refetchInactive: true,
                });
                queryClient.setQueryData([QUERY_KEY, accessToken, ids], { results: response.fullfilledResponses });
            },
            useErrorBoundary: true,
            retry: 1,
        },
    );
};
