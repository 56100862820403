import clsx from 'clsx';
import {
    Grid, makeStyles, Theme, Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        height: theme.spacing(20),
        width: theme.spacing(20),
    },
    text: {
        textAlign: 'center',
    },
    wrapper: {
        paddingTop: theme.spacing(8),
    },
}));

interface ZeroSearchStateProps {
    className?: string,
}

export const ZeroSearchState = (props: ZeroSearchStateProps): JSX.Element => {
    const { className } = props;
    const classes = useStyles();

    return (
        <Grid
            container
            alignItems="center"
            className={clsx(classes.wrapper, className)}
            direction="column"
            justifyContent="center"
            spacing={2}
        >
            <Grid item>
                <Search className={classes.icon} color="primary" />
            </Grid>
            <Grid item className={classes.text}>
                <Typography fontWeight="medium" variant="h6">
                    No results yet!
                </Typography>
                <Typography fontWeight="medium" variant="body1">
                    Use the search bar to find what you&apos;re looking for
                </Typography>
            </Grid>
        </Grid>
    );
};
