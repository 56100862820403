import qs from 'qs';

import { AbstractService } from 'src/services/AbstractService';
import { config } from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';

class ContentStoreEntityRankingsApi extends AbstractService implements Services.ContentStoreApi.EntityRankings {
    public async updateEntityRanking(
        bearerToken: string | undefined | false,
        body: Models.ContentStoreApi.V3.EntityRanking,
        entityId: string,
        rankingStrategyId: number,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.EntityRanking> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request updateEntityRanking. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/entities/${entityId}/RankingStrategy/${rankingStrategyId}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .put(url, {
                    json: body,
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.EntityRanking>();

            return json;
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to create entity ranking',
                info: (e as Error).message,
                url,
                searchParams,
            });
        }
    }

    public async deleteEntityRanking(
        bearerToken: string | undefined | false,
        entityId: string,
        rankingStrategyId: number,
        signal?: AbortSignal,
    ): Promise<void> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request deleteEntityRanking. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/entities/${entityId}/RankingStrategy/${rankingStrategyId}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            await this.api
                .delete(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                });
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to delete entity ranking',
                info: (e as Error).message,
                url,
                entityId,
                rankingStrategyId,
                searchParams,
            });
        }
    }

    public async getEntityRanking(
        bearerToken: string | undefined | false,
        entityId: string,
        rankingStrategyId: number,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.EntityRanking> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getEntityRanking. No bearerToken provided.',
            });
        }

        const url = `api/${this.version}/entities/${entityId}/RankingStrategy/${rankingStrategyId}`;
        const searchParams = qs.stringify({
            requestor: config.appName,
        });

        try {
            const json = await this.api
                .get(url, {
                    searchParams,
                    signal,
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                })
                .json<Models.ContentStoreApi.V3.EntityRanking>();

            return {
                ...json,
            };
        } catch (e) {
            throw new ServiceError({
                ...(e as Error),
                message: 'Failed to fetch ranking strategy',
                info: (e as Error).message,
                url,
                entityId,
                rankingStrategyId,
                searchParams,
            });
        }
    }

    public async getEntityRankings(
        bearerToken: string | undefined | false,
        entityIds: string[],
        rankingStrategyId: number,
        signal?: AbortSignal,
    ): Promise<Models.ContentStoreApi.V3.EntityRanking[]> {
        if (!bearerToken) {
            throw new ServiceError({
                message: 'Unable to perform request getEntityRankings. No bearerToken provided.',
            });
        }

        const entityRankingPromises = entityIds.map(async (entityId) => {
            const entity = await this.getEntityRanking(bearerToken, entityId, rankingStrategyId, signal);

            return {
                ...entity,
                id: entityId,
            };
        });

        return Promise.all(entityRankingPromises);
    }
}

export const ContentStoreEntityRankingsService = new ContentStoreEntityRankingsApi(config.services.contentStoreApi);
