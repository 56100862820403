import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { deleteKeyword } from 'src/mutations/deleteKeyword';
import { QUERY_KEY } from 'src/queries/queryKeywords';

export const useDeleteKeyword = (): UseMutationResult<void, Error, App.Keyword.DeleteMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, App.Keyword.DeleteMutationVariables>(
        deleteKeyword,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            },
            useErrorBoundary: true,
        },
    );
};
