import {
    Dispatch, SetStateAction, useEffect, useState,
} from 'react';

import { findCulturesIntersection } from 'src/components/Entity/EntityDetailsDrawer/utils/findIntersection';

type Model = Models.ContentStoreApi.V3.Entity.BulkCulture;
type UpdatedModels = Models.ContentStoreApi.V3.Entity.Culture[];
type GenerateSaveableList = (entity: Models.ContentStoreApi.V3.Entity) => UpdatedModels;
type HookReturnValue = [
    Model[],
    Dispatch<SetStateAction<Model[]>>,
    GenerateSaveableList,
];

export const useBulkCultures = (entities?: Models.ContentStoreApi.V3.Entity[] | false): HookReturnValue => {
    const [initialModels, setInitialModels] = useState<Model[]>([]);
    const [modifiedModels, setModifiedModels] = useState<Model[]>([]);

    const generateSaveableList: GenerateSaveableList = (
        entity: Models.ContentStoreApi.V3.Entity,
    ): UpdatedModels => {
        const deletedModels = initialModels.filter(
            (model) => !modifiedModels.find((m) => m.href === model.href),
        );

        // Unless the bulk enabled checkbox was altered, don't change the user enabled status
        const updatedEnabledValueModels = new Set((entity.cultures?.map((culture) => ({
            ...culture,
            userEnabled: modifiedModels.find((m) => m.href === culture.href && m.changedEnabledValue)?.userEnabled
                ?? culture.userEnabled,
        }))));

        return Array.from(updatedEnabledValueModels)
            .filter((model) => !deletedModels.find((m) => m.href === model.href));
    };

    useEffect(() => {
        if (entities) {
            const allModels = entities.map((entity) => Object.values(entity.cultures || []));
            const sharedModels = findCulturesIntersection<Model>(allModels);

            setInitialModels(sharedModels);
            setModifiedModels(sharedModels);
        }
    }, [entities]);

    return [modifiedModels, setModifiedModels, generateSaveableList];
};
